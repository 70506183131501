import { CommonModule } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import {
  CleanLineIdPipe,
  ContractPipe,
  CustomerMasterPipe,
  CustomerStatusPipe,
  DeliveryNoteCustomerPipe,
  DeliveryNotePipe,
  JobLinePipe,
  JobPipe,
  MachineMasterPipe,
  ModelCategoryPipe,
  OrderLinePipe,
  OrderPipe,
  StockMasterPipe,
  TransferOrderPipe
} from "@shared/models";
import { NgxMaskModule } from "ngx-mask";

import { CalendarModule } from "angular-calendar";
import { RRuleModule } from "@modules/rrule/rrule.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";// TODO: for angular 14 upwards - use library: ngx-google-places-autocomplete
import { NxtJsonViewModule } from "nxt-json-view";
import { BootstrapModule } from "./bootstrap.module";
import {
  AbilityDateSelectorComponent,
  AddressComponent,
  AnchorComponent,
  AttachmentGalleryComponent,
  AttachmentsComponent,
  BackButtonComponent,
  BankComponent,
  CaptureComponent,
  ColumnChooserComponent,
  ColumnDirective,
  ColumnSelectorComponent,
  ContactComponent,
  DateRangeComponent,
  ExportButtonsComponent,
  FooterDirective,
  FormFieldComponent,
  HeadingDirective,
  LookupDialogComponent,
  MachineCategoriesComponent,
  MachineMasterComponent,
  MachineOptionsComponent,
  MachineSkillsComponent,
  ModelMasterComponent,
  NavDropdownComponent,
  NumberInputComponent,
  PageActionsComponent,
  PagerComponent,
  PageStatusComponent,
  PrintComponent,
  ScanComponent,
  SelectResourceComponent,
  SelectUserComponent,
  SpinnerComponent,
  TableComponent,
  ToggleComponent,
  ToolTipComponent,
  UploadSplashComponent,
  WebLayoutComponent,
  FileButtonComponent,
  CalendarComponent,
  CalendarHeaderComponent,
  PhoneInputComponent,
  JsonViewComponent,
  TableControlsComponent,
  SaveButtonsComponent,
  SheetImporterComponent,
  SirvMediaViewerComponent,
  StockMasterComponent,
  UnlockMachineModalComponent,
  CustomDialogComponent,
  AbilityTimeSelectorComponent,
  AbilityTimeInputComponent,
  ConfirmDeleteDialogComponent,
  FilterDropdownComponent,
  CheckboxDropdownComponent,
  JobSourceSelectComponent,
  MultiFileUploadComponent
} from "./components";
import {
  BootstrapInputDirective,
  CustomerAddressTypeaheadDirective,
  DealerBranchAddressTypeaheadDirective,
  CustomerTypeaheadDirective,
  DealerTypeaheadDirective,
  ExportDirective,
  HasClaimDirective,
  HasTranslationDirective,
  IconTypeaheadDirective,
  LabelDirective,
  LayoutTypeaheadDirective,
  LoanMachineTypeaheadDirective,
  LookupDisplayDirective,
  LookupDisplayValueDirective,
  LookupFuncDirective,
  LookupQueryTypeaheadDirective,
  LookupTypeaheadDirective,
  MapDirective,
  MaxValidator,
  MinValidator,
  ModelTypeaheadDirective,
  OrderLineHistoryTypeaheadDirective, PicklistTypeaheadDirective,
  ProductHintDirective,
  ResourceTypeaheadDirective,
  SimpleUserTypeaheadDirective,
  StockTypeaheadDirective,
  SupplierTypeaheadDirective,
  TelephoneDirective,
  TypeaheadDirective,
  UserTypeaheadDirective,
  ViewContainerRefDirective,
  ZoneTypeaheadDirective,
  PostalCodeTypeaheadDirective,
  OrderTypeaheadDirective,
  PrintReceiptsDirective,
  UserListTypeaheadDirective,
  WarehouseBinTypeaheadDirective,
  LoadConfigTypeaheadDirective,
  CustomerContactTypeaheadDirective,
  CustomerContextDirective,
  TemplateCustomerContextDirective
} from "./directives";
import { MaterialModule } from "./material.module";
import { AddressPipe } from "./pipes/adddress.pipe";
import { LookupValuePipe } from "./pipes/lookup-value.pipe";
import { CustomerLookupPipe } from "./pipes/customer-lookup.pipe";
import { NoZeroPipe, ToFixedPipe } from "./pipes/no-zero.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { DateExPipe } from "./utilities/date.utilities";
import { ContactPipe, ContactSettingPipe } from "./pipes/contact.pipe";
import { CustomerPipe } from "./pipes/customer.pipe";
import { SubstitutePipe, Translate2Pipe } from "./pipes/translate2.pipe";
import { RoomplannerComponent } from './components/roomplanner/roomplanner.component';
import { KeyboardShortcutLegendComponent } from "./components/keyboard-shortcut-legend/keyboard-shortcut-legend.component";
import { KeyboardShortcutDirective } from "./directives/keyboard-shortcut.directive";
import { SortPipe } from "./pipes/sort.pipe";
import { PostalCodePipe } from "./pipes/postalcode.pipe";

const components = [
  TypeaheadDirective,
  ResourceTypeaheadDirective,
  LookupTypeaheadDirective,
  ModelTypeaheadDirective,
  UserTypeaheadDirective,
  LookupQueryTypeaheadDirective,
  DealerTypeaheadDirective,
  LookupFuncDirective,
  ExportButtonsComponent,
  LayoutTypeaheadDirective,
  LookupDisplayValueDirective,
  LookupDisplayDirective,
  LabelDirective,
  TelephoneDirective,
  BootstrapInputDirective,
  MapDirective,
  PicklistTypeaheadDirective,
  ColumnDirective,
  HeadingDirective,
  FooterDirective,
  CustomerTypeaheadDirective,
  StockTypeaheadDirective,
  ZoneTypeaheadDirective,
  CustomerAddressTypeaheadDirective,
  DealerBranchAddressTypeaheadDirective,
  SimpleUserTypeaheadDirective,
  OrderLineHistoryTypeaheadDirective,
  UploadSplashComponent,
  SpinnerComponent,
  FormFieldComponent,
  PageStatusComponent,
  AddressComponent,
  AnchorComponent,
  ContactComponent,
  BankComponent,
  MachineMasterComponent,
  BackButtonComponent,
  PagerComponent,
  TableComponent,
  AbilityDateSelectorComponent,
  AbilityTimeSelectorComponent,
  AbilityTimeInputComponent,
  CaptureComponent,
  NumberInputComponent,
  NavDropdownComponent,
  AttachmentsComponent,
  LookupDialogComponent,
  MachineCategoriesComponent,
  MachineSkillsComponent,
  MachineOptionsComponent,
  ModelMasterComponent,
  LookupDialogComponent,
  PageActionsComponent,
  SelectResourceComponent,
  SelectUserComponent,
  PagerComponent,
  NoZeroPipe,
  AddressPipe,
  TruncatePipe,
  CustomerLookupPipe,
  CustomerPipe,
  ExportDirective,
  ColumnChooserComponent,
  PrintComponent,
  ToggleComponent,
  LookupValuePipe,
  DeliveryNoteCustomerPipe,
  CleanLineIdPipe,
  ContactPipe,
  ContactSettingPipe,
  ModelCategoryPipe,
  MinValidator,
  MaxValidator,
  JobLinePipe,
  JobPipe,
  MinValidator,
  MaxValidator,
  OrderPipe,
  TransferOrderPipe,
  OrderLinePipe,
  DeliveryNotePipe,
  MachineMasterPipe,
  StockMasterPipe,
  ContractPipe,
  CustomerMasterPipe,
  CustomerStatusPipe,
  Translate2Pipe,
  MinValidator,
  MaxValidator,
  WebLayoutComponent,
  IconTypeaheadDirective,
  AttachmentGalleryComponent,
  LoanMachineTypeaheadDirective,
  ScanComponent,
  ProductHintDirective,
  DateExPipe,
  HasClaimDirective,
  HasTranslationDirective,
  SupplierTypeaheadDirective,
  DateRangeComponent,
  ViewContainerRefDirective,
  ToolTipComponent,
  ColumnSelectorComponent,
  FileButtonComponent,
  CalendarComponent,
  CalendarHeaderComponent,
  SirvMediaViewerComponent,
  RoomplannerComponent,
  PhoneInputComponent,
  PostalCodeTypeaheadDirective,
  KeyboardShortcutLegendComponent,
  KeyboardShortcutDirective,
  OrderTypeaheadDirective,
  PrintReceiptsDirective,
  JsonViewComponent,
  TableControlsComponent,
  SheetImporterComponent,
  SaveButtonsComponent,
  StockMasterComponent,
  UserListTypeaheadDirective,
  CustomDialogComponent,
  UnlockMachineModalComponent,
  WarehouseBinTypeaheadDirective,
  ToFixedPipe,
  CustomerContactTypeaheadDirective,
  SubstitutePipe,
  SortPipe,
  LoadConfigTypeaheadDirective,
  ConfirmDeleteDialogComponent,
  CustomerContextDirective,
  TemplateCustomerContextDirective,
  PostalCodePipe,
  FilterDropdownComponent,
  CheckboxDropdownComponent,
  PostalCodePipe,
  JobSourceSelectComponent,
  MultiFileUploadComponent
];

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NgxMaskModule,
  MaterialModule,
  HttpClientModule,
  HttpClientJsonpModule,
  BootstrapModule,
  TranslateModule,
  CalendarModule,
  RRuleModule,
  GoogleMapsModule,
  GooglePlaceModule,
  NxtJsonViewModule
];
@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...modules, ...components],
})
export class SharedModule {}

