import { Directive, Input, OnChanges } from "@angular/core";
import { ConsolidatedPick, LookupObjectDto, OrderType, PickListDto, newSearchDto } from "@shared/models";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { LookupListService, SalesOrderService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiPicklist]"
})
export class PicklistTypeaheadDirective extends BaseObservableTypeaheadHelper<LookupObjectDto> implements OnChanges {
  @Input() typeId: OrderType;
  @Input() mode: string = 'all';
  @Input() extraParams: Record<string, string> = {};

  constructor(typeAhead: TypeaheadDirective, private salesOrderService: SalesOrderService, private lookupsService: LookupListService) {
    super(typeAhead, ["abiPicklist"]);
    this.dropDownDisplay = "code";
    this.inputDisplay = "code";
  }

  protected getId(item: LookupObjectDto): string {
    return item.code;
  }

  protected getName(item: LookupObjectDto): string {
    return item.description;
  }

  protected filteredList(term: string): Observable<LookupObjectDto[]> {
    // const query = newSearchDto();
    // query.orderBy = ["pickListId"];
    // query.filters['pickListId'] = term;
    // query.filters['mode'] = this.mode;// gets all/printed/not-printed
    // query.filters = { ...query.filters, ...this.extraParams };
    // return this.salesOrderService.getPickLists(this.typeId, query).pipe(switchMap(rl => of(rl.items)));
    return this.lookupsService.queryList<LookupObjectDto>('consolidatedpicklist', [], term);//.pipe(switchMap(rl => of(rl.items)));
  }

}
