import { Injectable } from "@angular/core";
export interface Storage {
  setItem(key: string, value: string);
  getItem(key: string): string;
  removeItem(key: string);
  clear();
}

export abstract class StorageService {
  abstract get local(): Storage;
  abstract get session(): Storage;
}

@Injectable()
export class BrowserStorage extends StorageService {
  get local(): Storage {
    return localStorage;
  }
  get session(): Storage {
    return sessionStorage;
  }
}

class ServerStorageImplementation implements Storage {
  setItem(key: string, value: string) {
  }

  getItem(key: string): string {
    return "";
  }
  removeItem(key: string) {
  }

  clear() {
  }
}

export class ServerStorage extends StorageService {
  readonly local: Storage;
  readonly session: Storage;

  constructor() {
    super();

    this.local = new ServerStorageImplementation();
    this.session = new ServerStorageImplementation();
  }
}
