<ng-container *ngIf="hasView(); else mediaList">
    <div
        class="Sirv"
        [attr.data-src]="getView().src"
        [attr.data-options]="getView().options"
    ></div>
</ng-container>
<ng-template #mediaList>
    <div class="Sirv">
        <div
            *ngFor="let item of carousel"
            [attr.data-src]="item.src"
            [attr.data-type]="item.type || ''"
            [attr.data-options]="item.options || ''"
        ></div>
    </div>
</ng-template>
