import { MenuStatus, WebMenuDto } from "@modules/models";

export interface WebLayoutDto {
  /**
   * Autogenerated GUID
   */
  layoutId: string;
  /**
   * 'USER' or 'PUBLIC'
   */
  typeId: WebLayoutType;
  /**
   * Just the short name
   */
  caption: string;
  /**
   * a longer description to describe how this layout is used
   */
  description: string;
  /**
   * '0'/'1' - can be displayed in the main dropdown - or in the sub-dropdown
   */
  quickLink: string;
  /**
   * Icon name (fontawesome)
   */
  icon: string;
  /**
   * actual layout data used for table/column configurations
   */
  data: any;
}

export enum WebLayoutType {
  USER = 'USER',
  PUBLIC = 'PUBLIC',
}

export interface HasChildren<T> {
  children?: T[]
}


export class RouteInfo implements HasChildren<RouteInfo> {
  layouts: WebLayoutDto[];

  // RELATIVE
  get link(): any[] {
    const lnk = this.innerLink.map((l) => (l instanceof Function ? l() : l));
    return !!this.parentId ? ["../../", ...lnk] : lnk;
  }
  // ABSOLUTE
  get absoluteLink(): any[] {
    const lnk = this.innerLink.map((l) => (l instanceof Function ? l() : l));
    return !!this.tenant ? [this.tenant, ...lnk] : lnk;
  }
  constructor(
    public name: string,
    private innerLink: any[],
    public image: string,
    public parentId?: string,
    public layoutName?: string,// could be: Menu ID
    public children?: RouteInfo[],
    public tenant?: string,
    public description?: string,
    public displaySequence?: number,
    public roles?: string[],
  ) {
  }

  static fromRouteInfoToWebMenuDto(ri: RouteInfo): WebMenuDto {
    return {
      menuId: ri.layoutName,
      description: ri.description,
      status: 'A' as MenuStatus,
      icon: ri.image,
      url: ri.innerLink,
      displaySequence: ri.displaySequence,
      parentId: ri.parentId,
      roles: ri.roles
    };
  }

  static fromWebMenuDto(dto: WebMenuDto, tenant: string): RouteInfo {
    return new RouteInfo(
      dto.description,
      dto.url,
      dto.icon,
      dto.parentId,
      dto.menuId,
      dto.children?.map((c) => RouteInfo.fromWebMenuDto(c, tenant)),
      tenant,
      dto.description,
      dto.displaySequence,
      dto.roles
    );
  }
  toWebMenuDto(): WebMenuDto {
    return {
      menuId: this.layoutName,
      description: this.description,
      status: 'A' as MenuStatus,
      icon: this.image,
      url: this.innerLink,
      displaySequence: this.displaySequence,
      parentId: this.parentId,
      roles: this.roles
    };
  }
}
