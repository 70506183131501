import { NgModule } from "@angular/core";
import {
  // NgbAccordionModule,
  // NgbAlertModule,
  NgbButtonsModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  // NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  // NgbPopoverModule,
  // NgbProgressbarModule,
  // NgbRatingModule,
  // NgbTabsetModule,
  NgbTimepickerModule,
  // NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";


const exportedModules = [
  // NgbAccordionModule,
  // NgbAlertModule,
  NgbButtonsModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  // NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  // NgbPopoverModule,
  // NgbProgressbarModule,
  // NgbRatingModule,
  // NgbTabsetModule,
  NgbTimepickerModule,
  // NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
];

@NgModule({
  imports: [...exportedModules],
  exports: [...exportedModules],
  declarations: []
})
export class BootstrapModule {}
