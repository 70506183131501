import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DATE_RANGES, DateRangePosition, convertToDate, convertToNgbDate } from "@modules/common";
import { NgbDate, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";

@Component({
  selector: "abi-date-range-filter",
  templateUrl: "./date-range.component.html",
  styleUrls: ["./date-range.component.scss"],
})
export class DateRangeComponent implements OnInit {
  @Output() changeDate: EventEmitter<string> = new EventEmitter();
  @Input() initialValue = "";
  @Input() displayMonths = 1;
  @Input() outsideDays = "visible";

  dateRanges = DATE_RANGES;
  hoveredDate: NgbDate | null = null;

  model: NgbDate | null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  today: NgbDate;

  inputValue = "";

  constructor(
    public formatter: NgbDateParserFormatter,
    private translate: TranslateService
  ) {
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
    // this.today = this.calendar.getToday();
  }

  ngOnInit(): void {
    if (this.initialValue) {
      // set from/to dates
      if (this.initialValue.includes("||")) {
        // custom range
        const dateParts = this.initialValue.split("||");
        this.fromDate = NgbDate.from(convertToNgbDate(dateParts[0]));
        this.toDate = NgbDate.from(convertToNgbDate(dateParts[1]));
      } else if (this.initialValue.length) {
        // preset-range name
        const foundRangeItem = this.findRangeByName(this.initialValue);
        if (foundRangeItem) {
          this.fromDate = NgbDate.from(convertToNgbDate(foundRangeItem[DateRangePosition.Start]()));
          this.toDate = NgbDate.from(convertToNgbDate(foundRangeItem[DateRangePosition.End]()));
        } else {
          // just a single date?
          this.fromDate = NgbDate.from(convertToNgbDate(this.initialValue));
          this.toDate = NgbDate.from(convertToNgbDate(this.initialValue));
        }
      }
      this.updateInputValue(false);
    }
  }

  // Update Displayed Value in Filter field and emit value to Filter callbacks
  updateInputValue(emit: boolean = true) {
    const today = NgbDate.from(convertToNgbDate(Date.today()));
    const yesterday = NgbDate.from(
      convertToNgbDate(Date.today().add(-1, "day"))
    );
    if (!this.fromDate && !this.toDate) {
      // Nothing selected
      this.inputValue = "";
      if (emit) this.changeDate.emit(null);
    } else if (
      (this.fromDate.equals(this.toDate) || (this.fromDate && !this.toDate)) &&
      !this.fromDate.equals(today) &&
      !this.fromDate.equals(yesterday)
    ) {
      // single date display (& not today/yesterday)
      this.inputValue = convertToDate(this.fromDate).format();
      if (emit) this.changeDate.emit(this.inputValue);
    } else {
      // anything including today/yesterday (as they exist in the presets)
      const foundRangeItem = this.findRangeItem(
        this.fromDate,
        this.toDate || this.fromDate
      );
      if (foundRangeItem) {
        this.translate
          .get(foundRangeItem[0])
          .pipe(take(1))
          .subscribe((trans) => {
            this.inputValue = trans;
          });
        if (emit) this.changeDate.emit(foundRangeItem[4]);
      } else {
        this.inputValue = "Custom Range...";
        if (emit)
          this.changeDate.emit(
            `${convertToDate(this.fromDate).format()}||${convertToDate(
              this.toDate
            ).format()}`
          );
      }
    }
  }

  clear() {
    this.fromDate = null;
    this.toDate = null;
    this.updateInputValue();
  }

  rangeFilterFunction(fromDate, toDate) {
    return (rangeItem) =>
      convertToDate(fromDate).valueOf() === rangeItem[DateRangePosition.Start]().valueOf() &&
      convertToDate(toDate).valueOf() === rangeItem[DateRangePosition.End]().valueOf();
  }

  findRangeByName(name: string) {
    return DATE_RANGES.find((item) => item[4] === name);
  }

  // compare existing selections with ones stored in the presets and get 'Translated Name'
  findRangeItem(fromDate: NgbDate | Date, toDate: NgbDate | Date) {
    return fromDate && toDate
      ? DATE_RANGES
          .filter(this.rangeFilterFunction(fromDate, toDate))
          .shift()
      : null;
  }

  isRangeSelected(index: number) {
    if (!(this.fromDate && this.toDate)) return false;
    const currentDateRangeIndex = DATE_RANGES.findIndex(
      this.rangeFilterFunction(this.fromDate, this.toDate)
    );
    return currentDateRangeIndex > -1 && index === currentDateRangeIndex;
  }

  presetSelected(selectionIndex: number) {
    const fromDate = DATE_RANGES[selectionIndex][DateRangePosition.Start]();
    const toDate = DATE_RANGES[selectionIndex][DateRangePosition.End]();
    this.fromDate = NgbDate.from(convertToNgbDate(fromDate));
    this.toDate = NgbDate.from(convertToNgbDate(toDate));
    this.updateInputValue();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.updateInputValue();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isMuted(date, selected, currentMonth, disabled) {
		return !selected && (date.month !== currentMonth || disabled);
	}
}
