import { Injectable } from "@angular/core";
import { LookupObjectDto, SearchDto } from "@modules/models";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "@env/environment";
import { LanguageScreenDto } from "@shared/models/LanguageScreenDto";
import { DataService, ServiceConfig } from "..";

interface LanguageScreenSearchResult {
  items: LanguageScreenDto[];
  totalResults: number;
}

@Injectable({
  providedIn: "root"
})
export class LanguageService extends DataService {

  constructor(protected config: ServiceConfig,
              private translate: TranslateService) {
    super(config);
  }

  private getRealValueFromDto(dto: LanguageScreenDto): string {
    if (dto.value === undefined) return dto.coreValue;
    return dto.value === "" || dto.value ? dto.value : dto.coreValue;
  }

  private applyTranslation(dto: LanguageScreenDto) {
    const key = dto.screenId + '.' + dto.fieldId;
    const value = this.getRealValueFromDto(dto);
    this.translate.setTranslation(this.translate.currentLang || 'en', { [dto.screenId]: { [dto.fieldId]: value }}, true);
  }

  public getLanguages(): Observable<LookupObjectDto[]> {
    return this.http.get<LookupObjectDto[]>('values/list/codelanguage');
  }

  public getAllTranslations(): Observable<LanguageScreenDto[]> {
    return this.http.get<LanguageScreenDto[]>("values/languageScreen");
  }

  public searchTranslations(query: SearchDto): Observable<LanguageScreenSearchResult> {
    return this.http.get<LanguageScreenSearchResult>("values/languagescreen", { params: this.searchQueryToParams(query) });
  }

  // SETTINGS CRUD
  public updateSetting(setting: LanguageScreenDto): Observable<LanguageScreenDto> {
    return this.http.put<LanguageScreenDto>(`values/languageScreen`, setting)
    .pipe(tap(result => this.applyTranslation(setting)));
  }

  public addSetting(setting: LanguageScreenDto): Observable<LanguageScreenDto> {
    return this.http.post<LanguageScreenDto>(`values/languageScreen`, setting)
    .pipe(tap(result => this.applyTranslation(setting)));
  }

  public deleteSetting(screenId: string, fieldId: string, languageId: string): Observable<any> {
    return this.http.delete(`values/languageScreen/${screenId}/${fieldId}/${languageId}`)
    .pipe(tap(result => {
      // fetches a fresh copy via the loader (but doesnt trigger a full re-render)
      this.translate.reloadLang(this.translate.currentLang || 'en');
    }));
  }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (!environment.production) {
      console.warn("Missing Trans.: " + params.key);
    }
    return params.key;
  }
}
