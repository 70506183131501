import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'abi-upload-splash',
  templateUrl: './upload-splash.component.html',
  styleUrls: ['./upload-splash.component.scss']
})
export class UploadSplashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
