<div
    class="btn-toolbar justify-content-between d-block d-md-none flex-nowrap"
    role="toolbar"
>
    <ng-content></ng-content>

    <div class="btn-group btn-group-justified" data-testid="mobile-nav">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="prevPage()"
            [disabled]="!canGoPrev()"
        >
            <i class="fas fa-chevron-left"></i>
        </button>

        <button
            type="button"
            [matMenuTriggerFor]="navs"
            class="text-left btn btn-outline-primary w-100 btn-block"
            [innerHTML]="activeText"
        ></button>

        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="nextPage()"
            [disabled]="!canGoNext()"
        >
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>

    <ng-content select="[after]"></ng-content>
</div>

<ng-content select="ul[ngbNav]"></ng-content>

<mat-menu #navs="matMenu">
    <button
        type="button"
        mat-menu-item
        *ngFor="let item of navItems"
        (click)="selectNavItem(item)"
        [innerHTML]="item.elRef.nativeElement.innerHTML"
    ></button>
</mat-menu>
