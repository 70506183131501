import { Injectable } from "@angular/core";
import { LookupListService } from "@services";
import {
  AttachmentService,
  BaseAttachmentDto,
  CachedCollection,
  ContractDto,
  CustomerCallCycleDto,
  CustomerContactDto,
  CustomerMasterDto,
  CustomerVisitDto,
  DealerBranchAddressDto,
  DealerBranchDto,
  generateCheckDigit,
  JobSummaryDto,
  KeyedCollection,
  ListResultDto,
  LookupListEx,
  LookupObjectDto,
  newLookupObject,
  newVisitSetup,
  OrderDto,
  SearchDto,
} from "@shared/models";
import { from, Observable, of, pipe } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";
import { environment } from "@env/environment";
import moment from "moment";
import { ICallCycleForm } from "@modules/admin/call-form/call-form.component";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { map, switchMap } from "rxjs/operators";
import { DataService, Queue, ServiceConfig } from "../";
import { Progress } from "./data.service";
import { ListDataService } from "./list-data.service";

export interface CustomerLookupDto extends LookupObjectDto {
  addresses: any[];
}

export const CustomerVisitState = {
  //... more here
  FINISHED: 'FIN',
  CANCELED: 'CXC'
};

@Injectable({
  providedIn: "root",
})
export class CustomerService extends DataService implements AttachmentService {
  // Note that we want to kkep these out of repeating rendering cycles
  /**
   * @deprecated use a simple map with observables instead (they also support caching)
   * @see this.customers
   */
  cachedCustomers: KeyedCollection<CustomerMasterDto> = new KeyedCollection<CustomerMasterDto>([], (item) => item.id);
  customers: Record<string, Observable<CustomerMasterDto>> = {};
  // cachedCustomers: CachedCollection<CustomerMasterDto> = new CachedCollection<CustomerMasterDto>([], (item) => item.id, 60000);// 1 minute cache experiments
  constructor(
    config: ServiceConfig,
    private lookupService: LookupListService,
    protected listDataService: ListDataService,// code smell, something is prevening extending the service... like we do in other services
  ) {
    super(config);
  }

  // Uses Queue to call Cusomers individually - internaly may reference Cached items
  queue = new Queue<CustomerMasterDto>();
  getCustomer(customerId: string, nocache = false): Observable<CustomerMasterDto> {
    return this.getCustomerInternal(customerId, nocache);
    // return from<Promise<CustomerMasterDto>>(this.queue.enqueue(() => this.getCustomerInternal(customerId, nocache).toPromise()));
  }

  // Works with Internal Chaching
  private getCustomerInternal(customerId: string, nocache = false): Observable<CustomerMasterDto> {

    if (!this.customers[customerId] && !nocache) {
      this.customers[customerId] = this.getCustomerByCode(customerId);
    }
    else if(nocache) {
      return this.getCustomerByCode(customerId);
    }

    return this.customers[customerId];
  }

  getCustomerByCode(customerCode: string): Observable<CustomerMasterDto> {
    return this.http.get<CustomerMasterDto>(`customers/${customerCode}`).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getSalesOrdersByCustomer(customerId: string): Observable<OrderDto[]> {
    return this.http.get<OrderDto[]>(`salesorders/customer/${customerId}`);
  }

  // customers/{customerid}/visits/{addressid}/{visitid}
  getCustomerVisit(
    customerId: string,
    addressId: string,
    visitId: string
  ): Observable<CustomerVisitDto> {
    return this.http.get<CustomerVisitDto>(
      `customers/${this.safeEncode(customerId)}/visits/${addressId}/${visitId}`
    );
  }

  deleteCustomerVisit(
    customerId: string,
    addressId: string,
    visitId: string
  ): Observable<CustomerVisitDto> {
    return this.http.delete<CustomerVisitDto>(
      `customers/${this.safeEncode(customerId)}/visits/${addressId}/${visitId}`
    );
  }

  queryCustomerVisits(search: SearchDto): Observable<ListResultDto<CustomerVisitDto>> {
    return this.http.get<ListResultDto<CustomerVisitDto>>(
      "customers/search/visits",
      {
        params: {
          ...search.filters,
          pageNumber: search.pageNumber,
          pageSize: search.pageSize,
          orderBy: search.orderBy[0] || ""
        },
      }
    );
  }

  getCustomerVisitInstance(uncheckedVisit: ICallCycleForm): Promise<CustomerVisitDto> {
    return new Promise((res, rej) => {
      if(!uncheckedVisit) rej(null);
      if(uncheckedVisit.visitId) {
        res(this.convertCallFormDtoToVisit(uncheckedVisit));
      } else {
        this.newVisitFromCycle(
          uncheckedVisit.customerId,
          uncheckedVisit.addressId,
          uncheckedVisit.cycleId,
          uncheckedVisit.startDate.format()
        )
        .subscribe((convertedVisit: CustomerVisitDto) => {
          res(convertedVisit);
        });
      }
    });
  }

   // TODO: place in Dto/service file
   convertCallFormStartToDate(startDate: Date, startTime: NgbTimeStruct) {
    const combinedDate = new Date(startDate);
    combinedDate.setHours(startTime.hour);
    combinedDate.setMinutes(startTime.minute);
    combinedDate.setSeconds(startTime.second);
    return combinedDate;
  }

  /**
   * Convert for Server format
   */
  convertCallFormDtoToVisit(cv: ICallCycleForm): CustomerVisitDto {
    const newVisit = newVisitSetup();
    newVisit.visitId = cv.visitId;
    newVisit.addressId = cv.addressId;
    newVisit.customerId = cv.customerId;
    newVisit.resourceId = cv.resourceId;
    newVisit.cycleId = cv.cycleId;
    newVisit.notes = cv.notes || ""; // ensure non-nulls
    newVisit.plannedStartTime = this.convertCallFormStartToDate(
      cv.startDate,
      cv.startTime
    );
    newVisit.plannedEndTime = this.convertCallFormStartToDate(
      cv.startDate,
      cv.endTime
    );
    if (cv.recurring && cv.rrule) {
      newVisit.callCycle = this.newCallCycle(cv);
    }
    // console.log("newVisit", newVisit);
    return newVisit;
  }

    // generate CustomerCallCycleDto from CallForm Data
  // this is used inside the CustomerVisit Dto
  newCallCycle(cv: ICallCycleForm): CustomerCallCycleDto {
    return {
      cycleId: cv.cycleId,
      resourceId: cv.resourceId,
      startDate: this.convertCallFormStartToDate(cv.startDate, cv.startTime), // this must be RRule extracted data for sql query
      // endDate: RRuleObjectFromString(cv.rrule).all().pop(),
      endDate: this.convertCallFormStartToDate(cv.startDate, cv.endTime), // this must be RRule extracted data for sql query
      // we dont use separate 'time' vars as this is already integrated int othe 'date' (above)
      startTime: this.convertCallFormStartToDate(Date.minDate(), cv.startTime),// CODE SMELL: possibly problematic to use a date from 1900...
      endTime: this.convertCallFormStartToDate(Date.minDate(), cv.endTime),// CODE SMELL: possibly problematic to use a date from 1900...
      rRule: cv.rrule, // check if this is object instance or just string...
    };
  }

  // TODO: cleanup this function call with a SearchDto
  searchCustomerVisits(
    customerId: string,
    addressId: string,
    start: Date,
    end: Date,
    resourceId: string,
    extraParams?: {[key: string]: any}
  ): Observable<ListResultDto<CustomerVisitDto>> {
    const rangeParamValue = (start && end) ? `${start.format()}${"||"}${end.format()}` : '';
    // start and end params are date ranges... so duplicate the range within each param (for backend query)
    return this.http.get<ListResultDto<CustomerVisitDto>>(
      "customers/search/visits",
      {
        params: {
          ...(customerId ? {customerId} : {}),
          ...(addressId ? {addressId} : {}),
          ...(resourceId ? {resourceId} : {}),
          ...(extraParams ? {...extraParams} : {}),
          start: rangeParamValue,
          end: rangeParamValue,
          pageNumber: 1,
          pageSize: 1000,
        },
      }
    );
  }

  /**
   * Public Search (with msked data)
   */
  publicCustomerSearch(
    firstName: string,
    lastName: string,
    telephone: string,
    email: string,
    serial: string,
    customerId: string
  ): Observable<CustomerMasterDto[]> {

    const query: SearchDto = {
      filters: {
        ...(firstName ? {firstName} : {}),
        ...(lastName ? {lastName} : {}),
        ...(telephone ? {telephone} : {}),
        ...(email ? {email} : {}),
        ...(serial ? { 'machine.serialnumber': serial } : {}),
        ...(customerId ? {customerId} : {}),
        statusId: 'A',
      },
      pageNumber: 1,
      pageSize: 50,
      orderBy: ["primaryContact.lastName"],
    };

    return this.http.get<CustomerMasterDto[]>(
      `customers`,
      {
        params: this.searchQueryToParams(query, true),
      }
    );
  }

  getVisits(date: Date): Observable<CustomerMasterDto[]> {
    return this.http.get<CustomerMasterDto[]>(
      `customers/visits/${date.format()}`
    );
  }

  getCustomerContracts(customerId: string): Observable<ContractDto[]> {
    return this.http.get<ContractDto[]>(`contracts/bycustomer/${customerId}`);
  }

  /**
   * Normal System Search
   */
  standardCustomerSearch(
    telephone: string,
    idNumber: string,
    customerId: string,
    lastname: string,
    fullName: string,
    email: string,
    serial: string
  ): Observable<ListResultDto<CustomerMasterDto>> {
    const query: SearchDto = {
      filters: {},
      pageNumber: 1,
      pageSize: 50,
      orderBy: ["primaryContact.lastName"],
    };

    if (telephone) {
      query.filters["primaryContact.telephone"] = telephone;
    }
    if (idNumber) {
      query.filters["primaryContact.idNumber"] = idNumber;
    }
    if (customerId) {
      query.filters["customerId"] = customerId;
    }
    if (lastname) {
      query.filters["primaryContact.lastName"] = lastname;
    }
    if (fullName) {
      query.filters["primaryContact.fullName"] = fullName;
    }
    if (email) {
      query.filters["primaryContact.emailAddress"] = email;
    }
    // TODO: define serial field for search
    if (serial) {
      query.filters["machine.serialnumber"] = serial;
    }
    query.filters["statusId"] = "A";
    return this.searchCustomers(query);
  }

  querySuppliers(
    query: SearchDto
  ): Observable<ListResultDto<CustomerMasterDto>> {
    return this.http.get<ListResultDto<CustomerMasterDto>>('finance/suppliers/search', {params: this.searchQueryToParams(query)});
  }

  getSupplier(supplierId: string): Observable<CustomerMasterDto> {
    return this.http.get<CustomerMasterDto>(`suppliers/${supplierId}`);
  }

  /**
   * Returns the Model List for Lookup Lists
   * @param categories array of Model categories
   * @param query string search text
   * @param extraFilters additional filter to apply after the categories
   * @returns Observable ModelMasterDto
   */
  queryCustomers(
    query: string,
    categoryId?: string
  ): Observable<CustomerLookupDto[]> {
    return this.listDataService.queryList<CustomerLookupDto>(
      "customer",
      [...(categoryId ? [categoryId] : [])],
      query
    );
  }

  buildQueryString(paramMap) {
    return Object.keys(paramMap)
      .map((key) => `${key}=${paramMap[key]}`)
      .join("&");
  }

  searchCustomers(
    query: SearchDto
  ): Observable<ListResultDto<CustomerMasterDto>> {
    return this.http.get<ListResultDto<CustomerMasterDto>>('customers/search', {params: this.searchQueryToParams(query)});
  }

  newVisit(
    customerId: string,
    visit: CustomerVisitDto
  ): Observable<CustomerVisitDto> {
    return this.http.post<CustomerVisitDto>(
      `customers/${this.safeEncode(customerId)}/visits`,
      visit
    );
  }

  updateVisit(
    customerId: string,
    visit: CustomerVisitDto
  ): Observable<CustomerVisitDto> {
    return this.http.put<CustomerVisitDto>(
      `customers/${this.safeEncode(customerId)}/visits`,
      visit
    );
  }

  deleteVisit(customerId: string, visitId: string) {
    return this.http.delete(`customers/${this.safeEncode(customerId)}/visits/${visitId}`);
  }

  /**
   * Generates a new Visit based on given cycle info
   * @param customerId
   * @param addressId
   * @param cycleId
   * @param date string (format: yyyy-mm-dd)
   * @returns
   */
  newVisitFromCycle(customerId: string, addressId: string, cycleId: string, date: string): Observable<CustomerVisitDto> {
    return this.http.get<CustomerVisitDto>(`customers/${this.safeEncode(customerId)}/cycle/${addressId}/${cycleId}/${date}`);
  }

  // CallCycle CRUDS
  // customers/{customerid}/{addressid}/callcycle post and put
  createCallCycle(customerId: string, addressId: string, callCycle: CustomerCallCycleDto) {
    return this.http.post(`customers/${this.safeEncode(customerId)}/${addressId}/callcycle`, callCycle);
  }
  updateCallCycle(customerId: string, addressId: string, callCycle: CustomerCallCycleDto) {
    return this.http.put(`customers/${this.safeEncode(customerId)}/${addressId}/callcycle/${callCycle.cycleId}`, callCycle);
  }
  deleteCallCycle(customerId: string, addressId: string, callCycle: CustomerCallCycleDto) {
    return this.http.delete(`customers/${this.safeEncode(customerId)}/${addressId}/callcycle/${callCycle.cycleId}`);
  }

  queryCustomerAddresses(query: string): Observable<LookupObjectDto[]> {
    return this.listDataService.queryList('customeraddress', [], query);
  }

  /**
   * Get List of 'Branch Addresses' from Customers Data
   * Note: we don't have a way to manage DealerBranches yet
   * @deprecated DealerBranchAddresses are deprecated
   */
  queryAddresses(query: string): Observable<DealerBranchAddressDto[]> {
    return this.http.get<DealerBranchAddressDto[]>(
      `customers/branchaddresses?query=${query}`
    );
  }

  updateDealerBranch(dealerId: string, branchId: string, updates: Record<string, any>): Observable<DealerBranchDto> {
    return this.http.put<DealerBranchDto>(`values/item/dealerbranch/${dealerId}/${branchId}`, updates);
  }

  getDealerBranch(dealerId: string, branchId: string): Observable<DealerBranchDto> {
    return this.http.get<DealerBranchDto>(`values/item/DealerBranch/${dealerId}_${branchId}`);
  }

  // Note that a product setting can wrap this customer into a Job (for registration verification)
  createNewCustomer(customer: CustomerMasterDto) {
    return this.http.post<CustomerMasterDto>("customers", customer);
  }

  /**
   * When Registering on the Public site and selecting an Existing Customer
   */
  updateCustomer(
    customer: CustomerMasterDto,
    _generateCheckDigit = false
  ): Observable<CustomerMasterDto> {
    return this.http.put<CustomerMasterDto>(
      `customers/${customer.id}${
        _generateCheckDigit ? "/" + generateCheckDigit(customer.id) : ""
      }`,
      customer
    ).pipe(tap((updatedCustomer) => {
      this.cachedCustomers.add(updatedCustomer);
    }));
  }

  addContact(customerId: string, contact: any): Observable<CustomerContactDto> {
    return this.getCustomer(customerId)
    .pipe(switchMap((customer: CustomerMasterDto) => {
      customer.contacts.push(contact);
      return this.updateCustomer(customer).pipe(map((updatedCustomer) => updatedCustomer.contacts.pop()));
    }));
  }

  getCustomerCategories(): Observable<any> {
    return this.lookupService.getList<LookupObjectDto>("codecustomercategory");
  }

  uploadAttachment(
    parentId: string, // not used here
    file: File,
    description: string,
    progress: Progress,
    _generateCheckDigit: boolean,
    extras: any, // CustomerVisit Dto
    typeId?: string
  ): Observable<BaseAttachmentDto> {
    const visit = extras as CustomerVisitDto;
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    const url = `customers/${visit.customerId}/${visit.addressId}/${visit.visitId}/attachment${typeId && `/${typeId}` || ''}`;
    return this.http
      .post<BaseAttachmentDto>(url, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(this.uploading(progress));
  }

  downloadAttachment(
    parentId: string,
    attachmentId: string,
    extras: any
  ): Observable<Blob> {
    const visit = extras as CustomerVisitDto;
    return this.http.get(
      `customers/${extras.customerId}/${extras.addressId}/${extras.visitId}/attachment/${attachmentId}`,
      {
        responseType: "blob",
      }
    );
  }

  deleteAttachment(
    parentId: string,
    attachmentId: string,
    extras?: any
  ): Observable<any> {
    const visit = extras as CustomerVisitDto;
    return this.http.delete(
      `customers/${visit.customerId}/${visit.addressId}/${visit.visitId}/attachment/${attachmentId}`
    );
  }

  // used by gallery etc
  downloadLink(
    attachment: BaseAttachmentDto,
    parentId?: string,
    extras?: any
  ): string {
    const visit = extras as CustomerVisitDto;
    if (parentId) {
      parentId += "/";
    }
    return `customers/${visit.customerId}/${visit.addressId}/${visit.visitId}/attachment/${attachment.attachmentId}`;
  }

  // full url

  fullUrl(
    attachment: BaseAttachmentDto,
    parentId?: string,
    extras?: any
  ): string {
    const visit = extras as CustomerVisitDto;

    // eslint-disable-next-line max-len
    return `${environment.webApi}/api/${this.appQuery.tenant2}/${this.downloadLink(attachment, parentId, extras)}`;
  }

  getSummary(
    mode: string = "resource",
    filter1: string = "",
    mode2?: string, filter2?: string,
    showJob = "all"
  ): Observable<JobSummaryDto[]> {
    let url = `customers/summary/visits/${mode}/${filter1 || ""}`;
    if (mode2 && filter1) {
      url = `${url}/${mode2}/${filter2 || ""}`;
    }
    // if (showJob && showJob !== "all") {
    // url = url + "?showJob=" + showJob;
    // }
    return this.http.get<JobSummaryDto[]>(url);
  }

  getAddressLookupList(customer: CustomerLookupDto){
    return customer ? new LookupListEx(customer.addresses.map(add => {
      const addressName = add.name ||
      add.lines[0] ||
      add.lines[2] ||
      add.lines[3];
      return newLookupObject(add.addressId, addressName);
    })) : new LookupListEx([]);
  }
}
