import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { LookupListEx, LookupObjectDto } from "@modules/models";
import { LookupListService, ProductSettingService } from "@services";

interface CountryCode {
  name: string;
  dial_code: string;
  code: string; // ISO country code
}

interface CountryCodeLookup extends LookupObjectDto {
  dialingCode: string;
}

@Component({
  selector: 'abi-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor {
  intlDialCode: string = "";
  phoneNumber: string = "";
  warnings: any[];

  defaultCountryCode: string = "ZA";
  defaultDiallingCode: string = "+27";
  constructor(private lookup: LookupListService, private productSettings: ProductSettingService) { }

  // Only give both numbers if both exist. Dont give the Intl Code only.
  get value(): string {
    const currentValue = this.intlDialCode + this.phoneNumber;
    if(currentValue === this.intlDialCode) return '';
    return this.intlDialCode + this.phoneNumber;
  }

  set value(val: string){
    // Do we need lookup Country Code data?
    if(val?.includes('+')){
      // CODE SMELL: force lookup results set - or wait for request under init to finish...
      this.lookup.lookupList("CodeCountry").subscribe(lst => {
        this.countries = new LookupListEx((lst as LookupListEx<CountryCodeLookup>).values.filter(c => c.active));
        const countryCode = this.countries.values.find(item => val.indexOf(item.dialingCode) === 0);
        this.phoneNumber = val.slice(countryCode.dialingCode.length); // Derived from Value string
        this.intlDialCode = countryCode.dialingCode; // Used in computed Value
        this.country = countryCode.code; // Used for Country Code Selection
        this.setWarnings();
      });
    } else {
      this.phoneNumber = val || "";
      this.country = this.defaultCountryCode; // Set Some Sane default (Product Setting)
      this.intlDialCode = this.defaultDiallingCode; // DEFAULT
      this.setWarnings();
    }
    this.onChange(this.value);
    this.onTouched();
  }

  // Function to call when the rating changes.
  onChange = (phoneNumber: string) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  // EXTERNAL: adapt value to internal representation
  writeValue(phoneNumber: string): void {
    this.value = phoneNumber;
  }

  phoneChanged(event: KeyboardEvent) {
    if(event.key === "Enter") return;// prevent Enter key from triggering form changes
    const value = (event.target as HTMLInputElement).value;
    this.phoneNumber = value;
    this.onChange(this.value);
    this.setWarnings();
  }

  setWarnings(){
    const hasLeadingZero = this.phoneNumber.indexOf('0',0) === 0;
    const hasAlpha = this.phoneNumber.match(/[a-zA-Z]/);
    this.warnings = [
      ...(hasLeadingZero && ['International Phone numbers require that the leading "0" be removed'] || []),
      ...(hasAlpha && ['Alphabetic characters are not allowed in phone numbers'] || [])
    ];
  }

  // countryChanged({item, event}) {
  countryChanged(item) {
    const countryCodeLookup = this.countries.item(item.code);
    this.intlDialCode = countryCodeLookup?.dialingCode || null;
    this.country = countryCodeLookup?.code || null;
    this.onChange(this.value);
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (phoneNumber: string) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @Input() disabled = false;
  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  countryData: CountryCode[];
  country: string = "";
  countries: LookupListEx<CountryCodeLookup>;
  ngOnInit(): void {
    // TODO: DEFINE DEFAULT PHONE COUNTRY
    this.defaultCountryCode = this.productSettings.stringValue("DefaultCountryID") || this.defaultCountryCode;
    this.defaultDiallingCode =  this.productSettings.stringValue("DefaultDiallingCode") || this.defaultDiallingCode;
    this.country = this.country || this.defaultCountryCode;
    this.lookup.lookupList("CodeCountry").subscribe(lst => {
      this.countries = new LookupListEx((lst as LookupListEx<CountryCodeLookup>).values.filter(c => c.active));
    });
  }

  inputDisplay(item: CountryCodeLookup) {
    return `(${item.code}) ${item.dialingCode}`;
  }

  dropdownDisplay(item: CountryCodeLookup) {
    return `(${item.code}) ${item.dialingCode}`;
  }
}
