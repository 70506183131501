import { CustomerContactDto } from "@shared/models";

export interface ContactDto {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobile: string;
  officeHours: string;
  afterHours: string;
  fax: string;
  instantMessageId: string;
  idNumber: string;
  companyName?: string; // this is mapped to Customer.CompanyName when POSTed
  note?: string;// used as CompanyName on ChefsPride
  primary?: boolean;
}

export function isCustomerContact(contact: ContactDto | CustomerContactDto): contact is CustomerContactDto {
  return (contact as CustomerContactDto).contactId !== undefined;
}

export function nameOnly(self: ContactDto | CustomerContactDto): string {
  if (!self) {
    return "";
  }
  return `${self.title || ""} ${self.firstName || ""} ${self.lastName || ""}`.trim();
}

// @deprecated replaced by companyThenName
export function fullName(self: ContactDto | CustomerContactDto): string {
  if (!self) {
    return "";
  }
  if (isCustomerContact(self))// @codesmell this is too specific and only works with 'customer type' contacts
    return self.companyName || ((self.firstName || self.lastName) ? nameOnly(self) :
      "New Contact");
  else
    return nameOnly(self);
}

export function companyThenName(self: ContactDto | CustomerContactDto, defaultName: string = "New Contact"): string {
  if (!self) {
    return "";
  }

  return self.companyName || ((self.firstName || self.lastName) ? nameOnly(self) : defaultName);
}

export function newContact(firstName = "", lastName = ""): ContactDto {
  return {
    title: "",
    firstName,
    lastName,
    emailAddress: "",
    mobile: "",
    officeHours: "",
    afterHours: "",
    fax: "",
    idNumber: "",
    instantMessageId: "",
  };
}
