import { Pipe, PipeTransform } from "@angular/core";
import { ContactDto, companyThenName, fullName, nameOnly } from "@modules/models";
import { ProductSettingService } from "@services";

/**
 * @todo implement default behaviour via setting
 * some want company->name
 * others want name (and show company separately)
 */
 @Pipe({
  name: "contact",
  pure: false
})
export class ContactPipe implements PipeTransform {
  constructor(protected productSetting?: ProductSettingService) {
  }
  transform(contact: ContactDto, format?: string): string {
    if (format === 'nameOnly') return nameOnly(contact);
    if (format === 'companyThenName') return companyThenName(contact);
    // 'fullName' may return 'Company Name' which isn't always desireable
    return fullName(contact);
  }
}

/**
 * Determines the Contact display format via the Product setting 'ContactPipeDefaultFormat'
 **/
@Pipe({
  name: "contactsetting",
  pure: false
})
export class ContactSettingPipe extends ContactPipe implements PipeTransform {
  constructor(protected productSetting: ProductSettingService) {
    super(productSetting);
  }
  transform(contact: ContactDto, format?: string): string {
    if (format === 'nameOnly') return nameOnly(contact);
    // 'fullName' may return 'Company Name' which isn't always desireable
    if(this.productSetting.stringValue('ContactPipeDefaultFormat') === 'nameOnly') return nameOnly(contact);
    return fullName(contact);
  }
}
