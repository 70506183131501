import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { ContractLineDto } from "@app/modules/models";
import { OrderLineDto } from "./OrderLineDto";

export class JobVat {
  public static vatRate = 0.15;
}

export interface JobLineDto {
  lineId: string;
  typeId: string;
  invoice: string;
  receipt: string;
  itemId: string;
  line1: string;
  line2: string;
  structureId: string;
  warehouseId: string;
  quantityDelivered: number;
  quantityOrdered: number;
  requested?: Date;// not always returning in payload
  price: number;
  cost: number;
  paid: number;
  discount: number;
  resourceId: string;
  vatId: string;
  vatRate: number;
  mode: string;
  adhoc: boolean;
  fields: string[]; // = Array(5);
}

export function priceVat(self: JobLineDto): number {
  return self.price * (1.0 + JobVat.vatRate);
}

export function addVat(amnt: number): number {
  return amnt * (1.0 + JobVat.vatRate);
}

export function vatAmount(amnt: number): number {
  return amnt * JobVat.vatRate;
}

export function amount(self: JobLineDto | OrderLineDto | ContractLineDto, inclVat = true): number {
  const qty = ("quantityOrdered" in self) ? self.quantityOrdered : self.quantity;
  const vatRate = /*"vatRate" in self ? 0 :*/ inclVat ? JobVat.vatRate : 0;
  return !!self.price && !!qty ? self.price * qty * (1.0 + vatRate) : 0;
}

export function costPrice(self: JobLineDto) {
  return self.cost * (1 - self.discount) * (1.0 + JobVat.vatRate);
}

export function costAmount(self: JobLineDto) {
  return costPrice(self) * self.quantityOrdered;
}

export function paidPrice(self: JobLineDto) {
  return self.paid * (1.0 + JobVat.vatRate);
}

export function newJobLine(resourceId: string): JobLineDto {
  return {
    lineId: "",
    typeId: "",
    invoice: "",
    receipt: "",
    itemId: "",
    line1: "",
    line2: "",
    structureId: "",
    warehouseId: "",
    quantityDelivered: 0,
    quantityOrdered: 0,
    requested: null,
    price: 0,
    cost: 0,
    paid: 0,
    discount: 0,
    resourceId,
    mode: "",
    adhoc: false,
    fields: Array(5),
    vatId: "",
    vatRate: 0,
  };
}

@Pipe({
  name: "jobline"
})
export class JobLinePipe implements PipeTransform {
  transform(line: JobLineDto, format?: string): string {
    const np = new DecimalPipe("en-ZA");
    let value = 0;
    switch (format) {
      case "amount":
        value = amount(line);
        break;
      case "priceVat":
        value = priceVat(line);
        break;
      case "costPrice":
        value = costPrice(line);
        break;
    }
    return np.transform(value, "1.2-2");
  }
}
