import { LookupObjectDto } from "../LookupObjectDto";
import { StockBinDto } from "../StockMasterDto";

export interface BinMasterDto extends LookupObjectDto {
  warehouseId: string;
  // code, description, order, active
  categories: string[];
  mixed: boolean;
  /**
   * quantity limit for this bin
   */
  quantity: number;
  /**
   * weight limit for this bin
   */
  weight: number;
  /**
   * volume limit for this bin
   */
  volume: number;
  /**
   * area limit in this bin
   */
  area: number;
  /**
   * stack height in this bin
   */
  stackHeight: number;
  /**
   * this bin is used for picking
   */
  pickFace: boolean;
  notes: string;

  // need this to populated for GET/POST/PUT
  stocks?: StockBinDto[];
}


// @TODO: confirm these fields
export interface BinStockDto {
  stockId: string;
  description: string;
  safetyLevel: number;
  eoq: number;
}

// export enum WebLayoutType {
//   USER = 'USER',
//   PUBLIC = 'PUBLIC',
// }
export function newBinMaster(warehouseId: string): BinMasterDto {
  return {
    warehouseId,
    code: "",
    description: "",
    active: true,
    categories: [],
    mixed: true,
    quantity: 0,
    weight: 0,
    volume: 0,
    area: 0,
    stackHeight: 0,
    pickFace: false,
    notes: ""
  };
};
