<div class="input-group">
  <ng-content select="[abiLookup],[abiLookupQuery]"></ng-content>
  <div *ngIf="showButton" class="input-group-append">
    <button class="btn btn-outline-primary px-1" type="button" (click)="showDialog()">
      <i class="fas fa-plus"></i>
    </button>
  </div>
</div>

<ng-template #codeDialog let-ref="dialogRef">
  <h4 mat-dialog-title>{{ listName || innerInput?.abiLookup }}</h4>
  <mat-dialog-content>
    <form role="form" [formGroup]="form">
      <abi-form-field label="Code" labelColClass="col-sm-3" [errors]="formErrors.code">
        <input type="text" formControlName="code" name="code" required>
      </abi-form-field>
      <abi-form-field label="Description" labelColClass="col-sm-3" [errors]="formErrors.description">
        <input type="text" formControlName="description" name="description" required>
      </abi-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-button [mat-dialog-close]="false">Cancel</button>
    <button [disabled]="form?.invalid" type="button" mat-button (click)="saveNewCode(ref)">
      Save</button>
  </mat-dialog-actions>
</ng-template>
