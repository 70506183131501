import { AddressDto, newAddress } from "./AddressDto";

// TODO: Document to better understand the difference between the Address 'types'
export interface CustomerAddressDto extends AddressDto {
  addressId?: string;
  primary?: boolean;

  /*  public toString(): string {
      return this.addressId === "NEW" ? "New Address" : super.toString();
    }*/

}

// export function addressName(self: CustomerAddressDto) {
//   return self.addressId + " ~ " + self.name;
// }

export function newCustomerAddress(): CustomerAddressDto {
  return {
    name: "HOME",
    lines: Array(5).fill(""),
    postalCode: "",
    country: "",
    gpsType: "",
    gpsQuality: "",
    gpsCoordinates: [],
    primary: false,
    addressId: "",
  };
}
