import { Directive, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Disposable, Encoder } from "@modules/common";
import { DataTableComponent, IDataTable, TableColumn } from "@modules/common/components";
import { ListResultDto, SearchDto } from "@modules/models";
import { Observable } from "rxjs";

@Directive({
})
export class DataTableSuper<T> extends Disposable implements OnInit, IDataTable<T>, OnDestroy {
  dataTable: DataTableComponent;

  /**
   * Triggers each time ColsAreInited (and after Cols are set/re-set)
   * which gives us a sure point after which queries can be triggerd
   */
  get colsObs() {
    return this.dataTable.colsObs;
  }

  constructor(name: string, protected route: ActivatedRoute){
    super();
    this.dataTable = new DataTableComponent(name);// injecting is great for services. not for generic classes
  }

  ngOnInit(){
    this.setupTable();
  }

  /**
   * When implementing Tables that need WebLAyout functionality, we must pass the triggerOnParamMapChange=true
   * @param triggerOnParamMapChange usef to trigger table setup on paramMap change
   */
  setupTable(triggerOnParamMapChange = false){
    // SPECIAL NOTE: Experimental implementation of the DataTable Injection component
    this.dataTable.innerSearch = (page: number, size: number, columns: TableColumn[]) => this.innerSearch(page, size, columns);
    this.dataTable.setupColumns = () => this.setupColumns();
    this.dataTable.extraVars = (params) => this.extraVars(params);
    // this.dataTable.processSearchResult = (list: ListResultDto<PickLineDto>) => this.processSearchResult(list);
    this.dataTable.route = this.route;
    this.dataTable.initDataTable(triggerOnParamMapChange);// This is a manual Init here - need to automate this
  }

  setupColumns(){
    // Special NOTE: When assigning the columns, be sure to return new references of each TableColumn def.
    // this is neccessary due to filters and such being directly written to the given columns list
    // this.columns = this.typeColumns[this.typeId];
    throw new Error('Function Not implemented');
  }

  paramMap: ParamMap;
  /**
   * Keep a local copy of the route params
   * Override this to sel local vars from the paramMap
   */
  extraVars(params: ParamMap): void {
    this.paramMap = params;
  }

  get dataList(): ListResultDto<T> {
    return this.dataTable.dataList;
  }

  set dataList(val: ListResultDto<T>) {
    this.dataTable.dataList = val;
  }

  set columns(cols: TableColumn[]){
    this.dataTable.columns = cols;
  }

  get columns(){
    return this.dataTable.columns;// = this.columns;
  }

  get pageName(): string {
    return this.dataTable.pageName;
  }
  set pageName(name: string) {
    this.dataTable.pageName = name;
  }

  innerSearch(page: number, size: number, columns: TableColumn[]): Observable<ListResultDto<T>> {
    throw new Error("Function not implemented");
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataTable.ngOnDestroy();
  }

  getSortField(columns: TableColumn[], defaultSort?: string): string {
    return this.dataTable.getSortField(columns, defaultSort);
  }

  applyFilters(search: SearchDto, columns: TableColumn[]): void {
    this.dataTable.applyFilters(search, columns);
  }

  newSearch(page: number, size: number, columns: TableColumn[] = this.columns, filters: Record<string, string> = {}): SearchDto {
    return this.dataTable.newSearch(page, size, columns, filters);
  }

  public safeEncode(val: string) {
    return Encoder.safeEncode(val);
  }
}
