import { Directive, Input } from "@angular/core";
import { fullName, newContact, ResourceMasterDto } from "@shared/models";
import { Observable, of } from "rxjs";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { ResourceService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiResource]",
})
export class ResourceTypeaheadDirective extends BaseObservableTypeaheadHelper<ResourceMasterDto> {
  @Input() allowAll = false;
  constructor(typeAhead: TypeaheadDirective, private resourceService: ResourceService) {
    super(typeAhead, []);
    this.resourceService.$resources.pipe(this.notDisposed()).subscribe(() => this.loading = false);
  }

  protected filteredList(term: string): Observable<ResourceMasterDto[]> {
    const search = this.regExp(term);
    return this.resourceService.$resources
    .pipe(
      // tap(x => console.log('resources', x)),
      mergeMap(res => {
        if(this.allowAll) {
          const newList = [this.newResource('*', 'ALL'), ...res];
          return of(newList);
        }
        return of(res);
      }),
      map(r => r.filter((item) => item.statusId === "A"
        && search.test(this.getId(item) + this.getName(item))))
    );
  }

  protected list(): Observable<ResourceMasterDto[]> {
    return this.resourceService.$resources.pipe(map(rs => rs.filter(r => r.statusId === "A")));
  }

  protected getId(item: ResourceMasterDto): string {
    return item.code;
  }
  protected getName(item: ResourceMasterDto): string {
    return fullName(item.contact);
  }

  newResource(code = '', firstName = ''): ResourceMasterDto {
    return {
      active: true,
      code,
      contact: {
        ...newContact(firstName),
        contactId: '*'
      },
      address: null,
      statusId: '',
      calendarId: '',
      useCalendar: false,
      jobTitle: '',
      rateId: '',
      supplierAccount: '',
      orderAccount: '',
      warrantyAccount: '',
      export: false,
      vatRegistered: false,
      vatCode: '',
      manager: '',
      controller: '',
      priceList: '',
      // typeRatings: { [typeid: string]: number };
      typeRatings: [],
      postalRatings: [],
      skillRatings: [],
      collections: false
    };
  }


  protected onWriteValue(obj: any): any {
    // console.log('writing object', obj)
    // console.log('had code', this.code)
// let retVal = obj;
    if(!obj && !this._internalValue) // prevent changes from triggering when prev value and new value are null
      return null;

    this._internalValue = obj; // Keep an Internal Representation of the original Dto (for later use)
    // console.log('onWrite', obj)
    if (typeof obj === "string") {
      let id = 0;
      // eslint-disable-next-line no-cond-assign
      if (this.lastResult && (id = this.indexOf(obj)) > -1) {
        // console.log('this.lastResult[id]', this.lastResult[id])
        return this.lastResult[id];

      } else {
        // console.log('start filtering for', obj)
        this.startValue = obj;
        // this.filterAndSaveList(obj).toPromise().then(lst => { });
        this.filterAndSaveList(obj)
          // .pipe(delay(10))
          .pipe(
            take(1),
            // tap(vals => console.log('filterAndSaveList result', vals))
          )
          .subscribe(lst => {
            // console.log('filtered', lst)
            if (lst && lst.length) {
              const nId = this.indexOf(obj);
              // console.log('writing real value from string', lst, this.lastResult[nId])
              if (nId > -1) {
                this.hadResult = !!obj;
                this.typeAhead.onWriteValue(this.lastResult[nId])
                this.typeAhead.doOnChange(this.lastResult[nId]);
              }
            }
          });
      }
    }
    const retVal = obj;
    // console.log('code', this.code)
    // console.log('retVal', retVal)
    // this.value = retVal;
    // must manmually trigger on change when no value is given...(not sure why)
    if(!retVal)
      this.typeAhead.doOnChange(retVal);
    return retVal;
  }

}

