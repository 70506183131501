import { Pipe, PipeTransform } from "@angular/core";
import { CustomerMasterDto } from "@modules/models";

@Pipe({
  name: "customerbase",
  pure: true
})
export class CustomerPipe implements PipeTransform {
  transform(customer: CustomerMasterDto, format?: string): string {
    if (format === 'nameOnly') return customer.name;
    // 'fullName' may return 'Company Name' which isn't always desireable
    return customer.companyName;
  }
}
