import { Injectable } from "@angular/core";
import { ServiceConfig } from "./data.service";

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  constructor(config: ServiceConfig) {  }

  public loBeep() {
    const snd = new Audio(
      // eslint-disable-next-line max-len
      "data:audio/mpeg;base64,SUQzBAAAAAABClRQRTEAAAANAAADU291bmRib3kuZGUAVERSQwAAAAYAAAMyMDA0AFRYWFgAAAAgAAADY29tbWVudABodHRwOi8vd3d3LnNvdW5kYm95LmRlAFRDT04AAAAMAAADU291bmQgQ2xpcABUU1NFAAAADwAAA0xhdmY1OC40NC4xMDAAAAAAAAAAAAAAAP/7UAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEluZm8AAAAPAAAACAAABBQATExMTExMTExMTExMZmZmZmZmZmZmZmZmf39/f39/f39/f39/f5mZmZmZmZmZmZmZmbOzs7Ozs7Ozs7Ozs7PMzMzMzMzMzMzMzMzm5ubm5ubm5ubm5ubm////////////////AAAAAExhdmM1OC45MAAAAAAAAAAAAAAAACQCQAAAAAAAAAQU70SQuAAAAAAAAAAAAAAAAAAAAAD/+xBkAAvwXA6gQAAQ+AAADSAAAAEBFAC6tBGAIAAANIKAAAQAAAhAYZzP81+omaH/99Z+sP//E6ruKIHJ6ApcNOA2irOGgYwGJ7Dv/KYArjs/zxLkgUP/DcTQQMP/2DcL6DJhgsdgsf/7EmQXgAFJJkQGUaAAAAANIMAAAATslVwZFQAAAAA0gwAAACNg1WOEJ0bCc4n5xDvlA3YK4WvnIePOMvjq5wp/9mfiMXoyILbQAI4AAMnnpl7BVpVzReyvs2r9Dppo1C6f/+Nx47//cf/7EGQRgfEtH9pvPOAEAAANIOAAAQQQf3GjpKMwAAA0gAAABC9wshoN94AGaGewvRcDzwhehRzdKZ9BMrf/+KB1//+BM8XH1Vsg7sBAiAAAJExEg5BXyBSuZOkOQPAiKZMwtLISwDFX//sSZBAB8RoLXukvMBwAAA0gAAABAlAZTgeYwAAAADSAAAAEct/kynLkJMtSREUUegaNMvFVqR+oXQO+UHfuV4BgLh1gBEt2d/1/+sKKg4RiCoxzcnyJhyAXu0JTJzxqNvdgeAWFPz8c//sQZBeAAJICzYVoAAAAAA0goAABBOCVShkzgAAAADSDAAAAYbCz/3NRC//5A1VmRAAFGFLEkYopGRAgnoM0OJ6P/UkTgNwvv//dQcopf/9YlShbYAEpzT+/vpc4C4Z5s9jhZBfJvXb/+xJkHIHxHx/RL02gAgAADSDgAAEDuIFXhgFIsAAANIAAAAT/CqKL3v/4ano6FAyPKZhsZ5CWiUB/N4ABJJT3//niMxn/+YN1zQCJQ4lRYBPIuEXxER/kqoKqzAQnPesFga8NASAIP/3/+xBkHg/w4h/TgeFSsgAADSAAAAEB4BdQAwzAAAAANIAAAARin//qF0xBTUUzLjEwMKqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqv/7EmQqCfBZBUkAYBCAAAANIAAAAQE8AN9ABEAgAAA0gAAABKqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqg=="
    );
    snd.play();
  }

  public hiBeep() {
    const snd = new Audio(
      // eslint-disable-next-line max-len
      "data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4LjI5LjEwMAAAAAAAAAAAAAAA/+M4wAAAAAAAAAAAAEluZm8AAAAPAAAABQAAAkAAgICAgICAgICAgICAgICAgICAgKCgoKCgoKCgoKCgoKCgoKCgoKCgwMDAwMDAwMDAwMDAwMDAwMDAwMDg4ODg4ODg4ODg4ODg4ODg4ODg4P//////////////////////////AAAAAExhdmM1OC41NAAAAAAAAAAAAAAAACQEUQAAAAAAAAJAk0uXRQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/+MYxAANQAbGeUEQAAHZYZ3fASqD4P5TKBgocg+Bw/8+CAYBA4XB9/4EBAEP4nB9+UOf/6gfUCAIKyjgQ/Kf//wfswAAAwQA/+MYxAYOqrbdkZGQAMA7DJLCsQxNOij///////////+tv///3RWiZGBEhsf/FO/+LoCSFs1dFVS/g8f/4Mhv0nhqAieHleLy/+MYxAYOOrbMAY2gABf/////////////////usPJ66R0wI4boY9/8jQYg//g2SPx1M0N3Z0kVJLIs///Uw4aMyvHJJYmPBYG/+MYxAgPMALBucAQAoGgaBoFQVBUFQWDv6gZBUFQVBUGgaBr5YSgqCoKhIGg7+IQVBUFQVBoGga//SsFSoKnf/iVTEFNRTMu/+MYxAYAAANIAAAAADEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV"
    );
    snd.play();
  }
}
