import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

export class NavHostBase{
  upstreamLink = "";

  // manually set a new upstream link
  set previousUrl(value: string){
    this.upstreamLink = value;
  }

  fixedMode: boolean = false;
  // set to true to prevent back navigation
  setFixedMode(flag: boolean){
    this.fixedMode = flag;
  }

  constructor(private route: ActivatedRoute, private router: Router, private location: Location){
    this.upstreamLink = `/${this.route.snapshot.params.tenant}`;// default to Tenant home page
  }

  /**
   * Convenience function to help navigate backwards (or upwards, if no previous app navigations exist)
   * @param alternate alternate route to use when no previous app navigations exist
   */
    public back(alternate?: string) {
      const state: any = this.location.getState();
      const id: number = state.navigationId;
      if (id > 1 && !this.fixedMode)
        this.location.back();
      else
        this.router.navigate([alternate || this.upstreamLink]);
    }
}
