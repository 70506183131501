import { codeOnly, LookupObjectDto } from "./LookupObjectDto";

export interface ResourceTypeRatingDto {
  id: string;
  rating: number;
}


export interface ResourceSkillRatingDto {
  id: string;
  rating: number;
  skills: string[];
}

export function setSkillRating(self: ResourceSkillRatingDto, ...skills: (string | LookupObjectDto)[]) {
  self.skills = [...skills.map(s => codeOnly(s) || "*")];
  let i = self.skills.length;
  let del = false;
  while (i--) {
    if (del && self.skills[i] === "*") {
      self.skills[i] = "";
    } else if (self.skills[i] && self.skills[i] !== "*") {
      del = true;
    } else if (i > 0) {
      self.skills.splice(i, 1);
    }
  }
}

export function newResourceSkillRating(): ResourceSkillRatingDto {
  return {
    id: "",
    rating: 0,
    skills: []
  };
}
