import { Pipe, PipeTransform } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";
import { getValueFromPath } from "../utilities/string.utilities";

/**
 * Extended Translate pipe with additional options
 */
@Pipe({
  name: "translate2",
  pure: true
})
export class Translate2Pipe extends TranslatePipe implements PipeTransform{
  transform(query: string, ...args: any[]): any {
    if(args[0] === 'unescape') {
      return this.unescape(super.transform(query, args) as string);
    }
    return super.transform(query, args);
  }

  /**
   * Removes escaped slashes from newline directors
   */
  unescape(str: string){
    return str.replace(/\\[n|r]/g, "\n");
  }
}


@Pipe({
  name: "substitute",
  pure: true
})
export class SubstitutePipe implements PipeTransform{
  transform(query: string, ...args: any[]): any {
    if(args[0]) {
      return getValueFromPath(args[0], query);
    }
    return query;
  }
}
