import { CustomerAddressDto } from "./CustomerAddressDto";

export interface LookupAddressDto extends CustomerAddressDto {
  id?: string;
}

export function isLookupAddress(object: any): object is LookupAddressDto {
  const myInterface = object as LookupAddressDto;
  return myInterface.id !== undefined;
}
