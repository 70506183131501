<div class="row justify-content-center align-items-center login-form">
    <form class="col-12 col-md-8 col-lg-6 mx-3" role="form" [formGroup]="form" (ngSubmit)="requestReset()">
        <div class="card">
            <div class="card-header py-2">
                <h4 class="card-title my-0">{{ (requested ? 'Login.CheckEmail' : 'Login.BeforeSendEmail') | translate }}</h4>
            </div>
            <div class="card-body">
                <div *ngIf="!requested" class="row">
                    <abi-form-field label="Login.EnterEmail" [errors]="formErrors.email" cols="col-12"
                    [horizontal]="false">
                    <input autocomplete="email" type="email" formControlName="email" required />
                    </abi-form-field>

                    <div class="col-12">
                    <button type="submit" [disabled]="!form.valid || requesting" class="btn btn-primary float-right">
                        <i class="far fa-envelope"></i> {{ 'System.Submit' | translate }}</button>
                    </div>
                </div>
                <div *ngIf="requested" class="row">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary float-right" (click)="back()">
                            <i class="fas fa-sign-in-alt"></i> {{ 'System.Back' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
