import { Injectable } from "@angular/core";
import { Observable ,  ReplaySubject ,  Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PublishSubscribe {
  private events = {};

  public subscribe(event: string): Observable<any>;
  public subscribe(event: string, callback: (value: any) => void, error?: (error: any) => void, complete?: () => void): Subscription;
  public subscribe(event: string, callback?: (value: any) => void, error?: (error: any) => void, complete?: () => void) {
    if (!event) {
      throw new Error("Subscription method must get event name.");
    }

    if (this.events[event] === undefined) {
      this.events[event] = new ReplaySubject<any>();
    }

    if (typeof callback !== "function") {
      return this.events[event].asObservable();
    } else {
      return this.events[event].asObservable().subscribe(callback, error, complete);
    }
  }

  publish(event: string, eventObject?: any) {
    if (!event) {
      throw new Error("Publish method must get event name.");
    } else if (!this.events[event]) {
      return;
    }

    this.events[event].next(eventObject);
  }
}
