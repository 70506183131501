<div class="mr-2" ngbDropdown *ngIf="!isLookupList">
    <button class="btn btn-outline-primary" id="showJob" ngbDropdownToggle>{{ objectOptions[value] | translate }}</button>
    <div ngbDropdownMenu aria-labelledby="showJob">
        <a *ngFor="let option of objectOptions | keyvalue" class="dropdown-item" [ngClass]="{'active': option.key === value}" (click)="optionClicked(option.key)">{{ option.value | translate }}</a>
    </div>
</div>

<div ngbDropdown *ngIf="isLookupList">
    <button class="btn btn-outline-primary" id="itemStatus" ngbDropdownToggle>
        {{ lookupOptions.display(value) | translate }}
    </button>
    <div ngbDropdownMenu aria-labelledby="itemStatus">
        <button
            *ngFor="let option of lookupOptions.values"
            class="dropdown-item"
            [ngClass]="{'active': option.code === value}"
            (click)="optionClicked(option.code)"
        >
            {{ option.description | translate }}
        </button>
    </div>
</div>
