import { isPlatformBrowser } from "@angular/common";
import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AppQuery } from "../modules/common/app.store";
import { StorageService } from "../modules/common/services";

@Component({
  template: `
  <div *ngIf="!param && showAll" class="alert alert-warning">
          <abi-back-button></abi-back-button>
  Coming soon...<br/><!--
  {{route.snapshot.paramMap|json}}<br/>
  {{route.snapshot.queryParamMap|json}}<br/>
  {{route.snapshot.toString()}}<br/>-->
  </div>
  <button type="button" class="d-none" (click)="followLink()" #theButton>Click me</button>

  <div *ngIf="!showAll">
  <img class="splash" src="assets/logo.svg" alt="darkred Loading" />
</div>

  `
})

export class PlaceHolderComponent implements OnInit {
  @ViewChild("theButton", {static: true}) button: ElementRef;
  param: string;
  showAll = false;

  constructor(private router: Router, public route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: string,
              private appQuery: AppQuery, private storage: StorageService) {
    this.showAll = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.route.paramMap.subscribe((params: ParamMap) =>
        this.external(params.get("external")));
    }
  }

  private external(param: string) {
    if (this.storage.session.getItem("navigated")) {
      this.storage.session.removeItem("navigated");
      this.router.navigate(["/"]);
    } else {
      this.param = param;
      if (param) {
        this.storage.session.setItem("navigated", param);
        this.button.nativeElement.click();
      }
    }
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
  }

  followLink() {
    if (this.param) {
      switch (this.param) {
        case "spares":
          location.href = "https://" + this.appQuery.tenant + ".iopen.co.za/spares"; // , "_blank");
      }
    }
  }

}
