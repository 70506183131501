export enum MenuStatus {
  ACTIVE = "A",
  INACTIVE = "I",
};

// public class BaseWebMenuDto: ILastModified
// {
//   public string MenuId { get; set; }
//   public string Description { get; set; }
//   public string Status { get; set; }
//   public string Icon { get; set; }
//   public DateTime LastModifiedDate { get; set; }


//   [JsonPropertyName("url")]
//   public JsonElement Raw { get; set; }

//   [JsonIgnore]
//   public string Url
//   {
//     get => Raw.ToString();
//     set => Raw = JsonDocument.Parse(value).RootElement;
//   }
// }
interface BaseWebMenuDto {
  menuId: string;
  description: string;
  status: MenuStatus;
  icon: string;
  lastModifiedDate?: string;
  url: string[];
}

/**
 * Legacy Menu DTO
 * example:
 * {
    "parentId": "SystemAdmin",
    "menuId": "AutoCompleteAdmin~",
    "description": "Autocomplete Values",
    "status": "A",
    "icon": "fas fa-user-cog",
    "lastModifiedDate": "2022-01-27T13:37:21.64+02:00",
    "url": [
        "admin",
        "lists",
        "autocompletevalue"
    ]
}
 */

/**
 * public class WebMenuDto: BaseWebMenuDto
{
  public string ParentId { get; set; }
  public string DisplaySequence { get; set; }
  public WebMenuDto[] Children { get; set; }
  public string[] Roles { get; set; }
}
 */
export interface WebMenuDto extends BaseWebMenuDto {
  parentId: string;// used extensively to reference children
  displaySequence?: number;// used to order the menu items
  children?: WebMenuDto[];// used to create a nested menu
  roles?: string[];// used to restrict access to certain roles
}

/**
 * New Menu DTO (for the Sidebar)
 * Example:
 * {
    "children": [
        {
            "children": [],
            "menuId": "CustomerList",
            "description": "Customers",
            "status": "A",
            "icon": "far fa-list",
            "lastModifiedDate": "2022-01-27T13:37:21.64+02:00",
            "url": [
                "customer",
                "list"
            ]
        },
        {
            "children": [
                {
                    "children": [],
                    "menuId": "CustomerCategoryAdmin",
                    "description": "Customer Categories",
                    "status": "A",
                    "icon": "fas fa-user-cog",
                    "lastModifiedDate": "2022-01-27T13:37:21.64+02:00",
                    "url": [
                        "admin",
                        "lists",
                        "codecustomercategory"
                    ]
                }
            ],
            "menuId": "CustomerAdmin",
            "description": "Setup",
            "status": "A",
            "icon": "fas fa-user-cog",
            "lastModifiedDate": "2022-01-27T13:37:21.64+02:00",
            "url": []
        }
    ],
    "menuId": "CustomerNav",
    "description": "Customers",
    "status": "A",
    "icon": "fas fa-address-card",
    "lastModifiedDate": "2022-01-27T13:37:21.64+02:00",
    "url": []
}
 */
export interface FullMenuDto {
  menuId: string;
  description: string;
  status: MenuStatus;
  // parentId?: string;//Not used when reading the data
  icon: string;
  url: string[];
  children?: FullMenuDto[];
  lastModifiedDate?: string;
  // layouts?: WebLayoutDto[];
  // reports?: FullMenuReport[];
}

export function newWebMenuDto(): WebMenuDto {
  return {
    menuId: "",
    description: "",
    status: MenuStatus.ACTIVE,
    icon: "",
    parentId: "",
    displaySequence: 0,
    url: [],
    children: [],
    roles: [],
  };
}
