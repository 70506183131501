import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroup } from '@angular/forms';
export { MonthlyComponent } from './monthly/monthly.component';
export { WeeklyComponent } from './weekly/weekly.component';
export { YearlyComponent } from './yearly/yearly.component';

@Component({
  selector: 'ngx-repeat',
  templateUrl: './repeat.component.html',
  styleUrls: ['./repeat.component.css'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RepeatComponent), multi: true }]
})
export class RepeatComponent implements OnInit, ControlValueAccessor {
  public form: FormGroup;
  @Input() frequency;
  private propagateChange;
  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      yearly: {},
      monthly: {},
      weekly: {},
      hourly: {},
      daily: {},
      interval: 1,
      frequency: this.frequency || 'Weekly'
    });
   }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => {
      this.onFormChange();
    });
  }

  onOptionChange() {
    this.form.patchValue({
      yearly: {
        interval: 1,
        mode: 'on',
        on: {
          month: 'Jan',
          day: '1'
        },
        onThe: {
          which: 'First',
          day: 'Monday',
          month: 'Jan'
        }
      },
      monthly: {
        interval: 1,
        mode: 'on',
        on: {
          day: 1
        },
        onThe: {
          which: 'First',
          day: 'Monday'
        }
      },
      weekly: {
        interval: 1,
        days: {
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
        }
      },
      hourly: {
        interval: 1
      },
      daily: {
        interval: 1
      }
    });
    this.onFormChange();
  }

  writeValue = (input: any): void => {
    this.form.patchValue({ ...input});
  };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onFormChange = () => {
    const params = {
      ...this.form.value
    };
    const freq = params.frequency.toLowerCase();
    params[freq].interval = this.form.value.interval;
    if (this.propagateChange) {
      this.propagateChange(params);
    }
  };
}
