<div [class]="(disableInput ? 'btn-group ' : 'input-group ') + extraClass">
    <button
        *ngIf="disableInput"
        type="button"
        [disabled]="isDisabled || !canGoPrev()"
        class="btn btn-sm btn-outline-primary"
        (click)="prevDate()"
    >
        <i class="fas fa-chevron-left"></i>
    </button>

    <input
        [type]="disableInput ? 'button' : 'text'"
        [disabled]="isDisabled"
        [class]="disableInput ? 'btn btn-outline-primary ' + getValidity() : ' ' + getValidity()"
        [(ngModel)]="value"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        (click)="disableInput ? datepicker.toggle() : {}"
        container="body"
        [markDisabled]="isDayDisabled"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [dayTemplate]="dt"
        (blur)="onBlur()"
    />
    <ng-template #dt let-date let-focused="focused" let-selected="selected" let-currentMonth="currentMonth" let-disabled="disabled">
        <span
            class="custom-day"
            [class.bg-primary]="selected"
            [class.text-white]="selected"
            [class.text-muted]="isMuted(date, selected, currentMonth, disabled)"
            [class.outside]="isMuted(date, selected, currentMonth, disabled)"
            [class.active]="focused"
        >{{ date.day }}</span>
    </ng-template>
    <button
        *ngIf="disableInput"
        type="button"
        [disabled]="isDisabled || !canGoNext()"
        class="btn btn-sm btn-outline-primary"
        (click)="nextDate()"
    >
        <i class="fas fa-chevron-right"></i>
    </button>
    <div *ngIf="!disableInput" class="input-group-append">
        <button
            type="button"
            [disabled]="isDisabled"
            class="btn btn-primary btn-sm"
            (click)="datepicker.toggle()"
        >
            <i class="fas fa-calendar-alt"></i>
        </button>
    </div>
</div>
