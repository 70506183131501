<span
    *ngIf="displayTooltip"
    [class]="class"
    [tooltipClass]="tooltipClass"
    [ngbTooltip]="displayTooltip"
    [placement]="placement"
    container="body"
    data-testid="tooltip"
    ><ng-content *ngIf="!content; else showContentVar"></ng-content
    ><ng-template #showContentVar>{{ content }}</ng-template></span
>
