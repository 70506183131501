export enum JobDayEnum {
  Days1 = 999,
  Days2,
  Days3,
  Days4,
  Overdue,
  Yesterday,
  Today,
  Tomorrow,
  Tomorrow2,
  Later,
  All,
}
