import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import { MapGeocoder, MapGeocoderResponse } from "@angular/google-maps";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoggingService } from "@core/services";
import { LookupListService } from "@modules/common/services/lookup-list.service";
import { NgbModalRef, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import {
  AddressDto,
  asGoogleText,
  asTextBlock,
  Coordinates,
  coordinates,
  hasCoordinates,
  hasLines,
  LookupObjectDto, mapFromAddress,
  mapFromPlace,
  newAddress,
  PostalCodeDto,
  trim
} from "@shared/models";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { EMPTY, Observable, Observer, of, OperatorFunction } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from "rxjs/operators";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { checkUntilExists } from "@modules/common/utilities/object.utilities";
import { GooglemapapiService, ProductSettingService, WebLayoutService } from "../../services";
import { BaseModal } from "../base-modal";
import { VALIDATION_MESSAGES } from "../form-field/form-field.component";
import { AddressService } from "./address.service";

interface Address {
  address: AddressDto;
  disabled: boolean;
}

const validationMessages = {
  address: {
    required: "Address.Required",
  },
  lines: {
    required: "Address.Required",
  },
  line3: {
    required: "Address.Line3Required.",
  },
  coordinates: {
    required: "Address.Required.",
  },
};

@Component({
  selector: "abi-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
  providers: [
    { provide: VALIDATION_MESSAGES, useValue: validationMessages },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }]
})
export class AddressComponent extends BaseModal<AddressDto> implements OnInit, ControlValueAccessor, Validator, OnChanges {

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  get location() {
    return this.group.controls.coordinates;
  }

  get locationValue() {
    return this.location.value || null;
  }

  public countries: LookupObjectDto[];
  public country: string;
  public goodAddress = false;
  public showWarning = false;

  @Input() public group: FormGroup;
  @Input() public useMaps = true;
  @Input() public showMaps = true;
  @Input() public canUseMaps = true;
  @Output() nameUpdated: EventEmitter<string> = new EventEmitter();

  public formOnly = true;
  private address: AddressDto;
  private onTouched = () => { };
  apiLoaded: boolean = false;
  autocompleteTypes: string[];
  addressRequired: boolean = false;
  constructor(
    layoutService: WebLayoutService,
    fb: FormBuilder,
    private addressService: AddressService,
    private productSettings: ProductSettingService,
    private lookup: LookupListService,
    private renderer: Renderer2,
    private geocoder: MapGeocoder,
    private log: LoggingService,
    private gmapapi: GooglemapapiService,
    @Optional() activeModal?: NgbModalRef,
    @Optional() dialogRef?: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) data?: Address | AddressDto,
  ) {
    super(layoutService, null, dialogRef);
    this.inModal = dialogRef;
    this.autocompleteTypes = this.productSettings.arrayValue("AddressAutocompleteTypes", ["geocode", "establishment"]);
    this.addressRequired = this.productSettings.booleanValue("ContactAddressRequired");
    if(!this.group) this.group = AddressComponent.createFormGroup(fb);
    this.group.get("coordinates").valueChanges.subscribe((value) => {
      if (typeof value === "string" && value) {
        this.lookupAddress(new Coordinates(value.split(",")));
      }
    });

    if (data) {
      if ("disabled" in data) {
        this.address = data.address;
        if (data.disabled)
          this.group.disable();
      } else
        this.address = data;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.canUseMaps) {
      this.setValidation(false);
    }
  }


  defaultCountryCode = "ZA";
  ngOnInit(): void {
    this.defaultCountryCode = this.productSettings.stringValue("DefaultCountryID") || 'ZA';
    this.mapApiLoaded()
    .subscribe(loaded => {
      this.apiLoaded = loaded;// will start init of PlacesRef
      checkUntilExists(() => !!this.placesRef, () => {
        this.updateCountry(this.country || this.defaultCountryCode);
      });
    });

    this.lookup.lookupList("CodeCountry").subscribe(lst => {
      this.countries = lst.values.filter(c => c.active);
    });

    if (this.dialogRef) {
      this.dialogRef.updateSize("600px");
      this.setFormData(this.address);
    }

    this.setValidation(this.addressRequired);
  }

  setValidation(required: boolean) {
    if(required) {
      this.group.get("address").setValidators([Validators.required]);
      this.group.get("address").updateValueAndValidity();
      this.group.get("coordinates").setValidators([Validators.required]);
      this.group.get("coordinates").updateValueAndValidity();
    } else {
      this.group.get("address").clearValidators();
      this.group.get("coordinates").clearValidators();
    }
    this.group.updateValueAndValidity();

  }

  mapApiLoaded(): Observable<boolean> {
    return this.gmapapi.isApiLoaded();
  }

  writeValue(address: AddressDto): void {
    this.address = address;
    this.setFormData(address);
  }

  registerOnChange(fn: any): void {
    this.group.valueChanges.pipe(map(v => {// grab internal form value changes
      this.getFormData(this.address);// apply to local model
      return this.address;// pass local model back to parent form
    })).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if(isDisabled) this.group.disable();
    else this.group.enable();
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.group.valid ? null : { invalidForm: {valid: false, message: "Invalid"}};
  }

  registerOnValidatorChange?(fn: () => void): void {
    //    throw new Error("Method not implemented.");
  }

  private suburbValue(pCode: PostalCodeDto | string): string {
    return typeof pCode === "string" ? pCode : pCode.suburb;
  }

  static createFormGroup(fb: FormBuilder) {
    return fb.group({
      mode: "maps",
      address: "",
      addressText: "",
      name: "",
      line1: "",
      line2: "",
      line3: [""],
      line4: "",
      line5: "",
      postalCode: {value: "", disabled:true},
      country: "",
      coordinates: new FormControl("", {
        validators:[],
        updateOn: "blur",
      }),
      type: "",
    });
  }

  public getFormData(address: AddressDto) {
    const group = this.group.getRawValue();
    address.name = trim(group.name);
    address.lines[0] = trim(group.line1);
    address.lines[1] = trim(group.line2);
    address.lines[2] = this.suburbValue(group.line3);
    address.lines[3] = trim(group.line4);
    address.lines[4] = trim(group.line5);
    address.postalCode = trim(group.postalCode);
    address.country = group.country;
    address.gpsType = group.type;

    if (typeof group.coordinates === "string") {
      address.gpsCoordinates = group.coordinates;
    } else {
      const coors = group.coordinates as google.maps.LatLngLiteral;
      address.gpsCoordinates = coors ? [coors.lat, coors.lng] : [];
    }
    return address;
  }


  private setFormData(address: AddressDto, full = true) {
    if (!address) return;
    const txt = this.group.get("address");
    const coords = coordinates(address);
    this.group.patchValue({
      addressText: asTextBlock(address),
      mode: coords ? "maps" : "manual",
      name: address.name,
      line1: address.lines?.[0] || this.group.get('line1').value,
      line2: address.lines?.[1] || "",
      line3: address.lines?.[2] || "",
      line4: address.lines?.[3] || "",
      line5: address.lines?.[4] || "",
      postalCode: address.postalCode,
      country: address.country,
      coordinates: coords,
      type: address.gpsType,
    });
    if (full) {
      txt.setValue(asGoogleText(address));
      if (!hasCoordinates(address) && hasLines(address) && this.canUseMaps) {

        this.mapApiLoaded()
        .subscribe(loaded => {
          this.apiLoaded = loaded;
          this.geocoder?.geocode({ address: asGoogleText(address) })
          .toPromise()
          .then((response: MapGeocoderResponse) => this.onGeocoderResult(response.results));
        });
      }

      this.group.markAsPristine();
      this.group.markAsUntouched();

      this.nameUpdated.emit(address.toString());
      this.updateCountry(address.country || this.defaultCountryCode);
    }
    if(coords && address.gpsType) {
      this.goodAddress = true;
    }
    this.group.updateValueAndValidity();
  }

  protected configureModal(model: AddressDto) {
    this.switchFields(this.fields);
    this.formOnly = false;
    this.address = model;
    // super.configureModal(model);
  }

  private onGeocoderResult(results: google.maps.GeocoderResult[]) {
    if (results?.length) {
      const locR = results[0].geometry.location;
      const loc = new Coordinates([locR.lat(), locR.lng()]);
      this.location.setValue(loc);
    }
  }

  searchPostalCode: OperatorFunction<string, readonly PostalCodeDto[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => term.length < 3 ? EMPTY : this.addressService.queryPostalCodes(term)
      )
    );

  populatePostalCode(ev: NgbTypeaheadSelectItemEvent) {
    const pCode = ev.item;
    this.group.patchValue({
      line3: pCode.suburb,
      line4: pCode.city,
      line5: pCode.province,
      postalCode: pCode.code,
      country: pCode.country,
    });
    this.goodAddress = false;
    this.serverGeocode();
  }

  formatPostalCode(pCode: PostalCodeDto) {
    return pCode.suburb + " - " + pCode.city + " - " + pCode.code;
  }

  formatPostalCode2(pCode: PostalCodeDto | string): string {
    return typeof pCode === "string" ? pCode : pCode.suburb;
  }

  addressEntered(result: google.maps.places.PlaceResult) {
    if (result.geometry) { // MOST LIKELY GOOD ADDRESS
      this.showWarning = false;
      const tmpAddress = newAddress();
      mapFromPlace(result, tmpAddress);
      this.group.patchValue({
        coordinates: coordinates(tmpAddress),
        type: tmpAddress.gpsType,
      });
      // Verify if there is a PostalCode (the whole system relies on postal codes to work properly)
      if(!tmpAddress.postalCode){
        this.addressService.queryPostalCodes(tmpAddress.lines[2], this.country).subscribe((postalCodes: PostalCodeDto[]) => {
          tmpAddress.postalCode = postalCodes.shift()?.code || "";
          if(!tmpAddress.postalCode) {
            this.showWarning = true;
            // enable form field for manual editing
            this.group.get('postalCode').enable();
          }
          this.setFormData(tmpAddress, false);
          this.goodAddress = true;
        });
      } else {
        // no problems - all details should be fine
        this.setFormData(tmpAddress, false);
        this.goodAddress = true;
        this.showWarning = false;
      }
    } else { // BAD ADDRESS
      //      this.group.get("coordinates").reset(null);
      this.group.patchValue({
        coordinates: "",
        type: "",
      });
      this.goodAddress = false;
      this.showWarning = true;
    }

    this.group.markAsDirty();
  }

  fixAutocomplete($event: Event): void {
    this.renderer.setAttribute($event.target, "autocomplete", "none");
  }

  options: any;
  baseFields = [
    "address_component",
    "adr_address",
    "alt_id",
    "formatted_address",
    "geometry",
    "icon",
    "id",
    "name",
    "place_id",
    "scope",
    "type",
    "vicinity",
  ];

  getMapOptions(country: string): Partial<GooglePlaceDirective["options"]> {
    return {
      fields: this.baseFields,
      componentRestrictions: { country },
      types: this.autocompleteTypes
    };
  }

  updateCountry(country: string) {
    this.country = country || this.defaultCountryCode;
    if  (this.placesRef) {
      this.placesRef.options = new Options(this.getMapOptions(this.country));
      this.placesRef.reset();
    }
  }

  geocode(
    latLng: google.maps.LatLngLiteral
  ): Observable<google.maps.GeocoderResult[]> {
    return new Observable(
      (observer: Observer<google.maps.GeocoderResult[]>) => {
        // Invokes geocode method of Google Maps API geocoding.
        this.geocoder.geocode({ location: latLng }).subscribe((response: MapGeocoderResponse) => {
          if (response.status === google.maps.GeocoderStatus.OK) {
            observer.next(response.results);
            observer.complete();
          } else {
            this.log.logException("Geocoding service: geocoder failed due to: " + response.status);
            observer.error(response.status);
          }
        });
      }
    );
  }

  private lookupAddress(coords: google.maps.LatLngLiteral) {
    this.geocode(coords)
      .toPromise()
      .then((result) => {
        const tmpAddress = newAddress();
        mapFromAddress(result[0], tmpAddress);
        this.group.get("coordinates").reset(coordinates(tmpAddress));
        this.group.get("type").reset(tmpAddress.gpsType);

        this.setFormData(tmpAddress, true);
        this.group.markAsDirty();
        this.goodAddress = true;
        if(!tmpAddress.postalCode) {
          this.showWarning = true;
        } else {
          this.showWarning = false;
        }
      });
  }

  addressMoved(newLocation: any) {
    return this.lookupAddress(newLocation.latLng);
  }

  serverGeocode() {
    if (!this.goodAddress && (this.group.dirty || (this.group.enabled && !this.group.value.coordinates))) {
      const addr = newAddress();
      this.getFormData(addr);
      this.addressService
        .geocode(addr)
        .toPromise()
        .then(() => {
          this.setFormData(addr, true);
          this.group.markAsDirty();
          this.goodAddress = !!addr.gpsCoordinates;
        });
    }
  }

  accepted() {
    return this.getFormData(this.address);
  }

  // public accept(): void {
  //   this.getFormData(this.address);
  //   this.dialogRef.close(this.address);
  // }

  // close() {
  //   this.dialogRef.close(false);
  // }
}
