import { Component, OnInit } from "@angular/core";
import { AppQuery } from "@app/modules/common/app.store";
import { ProductSettingService } from "@app/modules/common/services";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";

const drPhone = "010 590 5980";
const drUrl = "www.darkred.co.za";
@Component({
selector: "abi-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  telephone: string;
  companyUrl: string;
  year = new Date().getFullYear();
  version = __VERSION__;

  constructor(private appQuery: AppQuery, private productSetting: ProductSettingService) {
    this.appQuery.$tenant2.pipe(mergeMap(t =>
      !!t ? this.productSetting.getCompanyInfo() : from([null]))).subscribe(info => {
      this.telephone = info?.telephone || drPhone;
      this.companyUrl = info?.website || drUrl;
    });
  }

  ngOnInit() {
  }

}
