import { Injectable } from "@angular/core";
import { ProductSettingService } from "@modules/common/services";
import { AddressDto, PostalCodeDto } from "@shared/models";
import { combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { DataService, ServiceConfig } from "../../";

@Injectable({
  providedIn: "root"
})
export class AddressService extends DataService {
  gpsTypeMap: { [gpsType: string]: 'poor' | 'partial' };

  postalCodesByCountry: { [country: string]: PostalCodeDto[] } = {};

  constructor(protected config: ServiceConfig, private productSettings: ProductSettingService) {
    super(config);

    combineLatest([this.appQuery.$tenant2.pipe(distinctUntilChanged())])
    .pipe(
      this.notDisposed(),
    )
    .subscribe(() => {
      this.generateGpsTypeMap();
    });
  }

  generateGpsTypeMap(): void {
    const poorGpsTypes = this.productSettings.stringValue("LocationQuality_Poor").split(',').map(i => i.trim());
    const partialGpsTypes = this.productSettings.stringValue("LocationQuality_Partial").split(',').map(i => i.trim());
    this.gpsTypeMap = poorGpsTypes.reduce((pv, cv) => ({...pv, [cv]: 'poor'}), {});
    this.gpsTypeMap = partialGpsTypes.reduce((pv, cv) => ({...pv, [cv]: 'partial'}), this.gpsTypeMap);
  }

  getGpsQuality(address: AddressDto): 'poor' | 'partial' | 'good' {
    return address && this.gpsTypeMap[address.gpsType] || "good";
  }

  queryProvinces(country?: string) {
    let url = "values/list/province";
    if (country) {
      url = url + `/${country}`;
    }
    return this.http.get<PostalCodeDto[]>(url);
  }

  queryCities(country?: string, province?: string) {
    let url = "values/list/city";
    if (country) {
      url = url + `/${country}`;
      if (province) {
        url = url + `/${province}`;
      }
    }
    return this.http.get<PostalCodeDto[]>(url);
  }

  querySuburbs(country?: string, province?: string, city?: string) {
    let url = "values/list/suburb";
    if (country) {
      url = url + `/${country}`;
      if (province) {
        url = url + `/${province}`;
        if (city) {
          url = url + `/${city}`;
        }
      }
    }
    return this.http.get<PostalCodeDto[]>(url);
  }

  // eslint-disable-next-line max-len
  queryPostalCodes(query?: string, country?: string, province?: string, city?: string, suburb?: string, limit?: number): Observable<PostalCodeDto[]> {
    let url = "values/postalcodes";
    if (country) {
      url = url + `/${country}`;
      if (province) {
        url = url + `/${province}`;
        if (city) {
          url = url + `/${city}`;
          // TODO: push for this param to be added? - we seem to have many 'suburb names with the same postlcode'
          if(suburb){
            url = url + `/${suburb}`;
          }
        }
      }
    }

    // if (country && this.postalCodesByCountry[country] && !province && !city && !suburb && !limit) {
    //   return of(this.postalCodesByCountry[country]);
    // }

    // if (query){
    //   url = url + "?query=" + query;// TODO: smartly amend the limit param
    // }
    return this.http.get<PostalCodeDto[]>(url, { params:
      {
        ...(query ? {query} : {}),
        limit: limit ? limit.toString() : "100" }
      }
    // ).pipe(tap(codes => {
    //   if(country && !province && !city && !suburb && !limit){
    //     this.postalCodesByCountry[country] = codes;
    //   }
    // })
  );
  }

  /**
   * Return a single code from a query - must supply full code and will only return first one
   * @param code
   * @returns
   */
  getPostalCode(code: string): Observable<PostalCodeDto> {
    return this.http.get<PostalCodeDto[]>(`values/postalcodes`,
      { params: { query: code, limit: "1" } })
    .pipe(switchMap(codes => {  return codes.length ? of(codes[0]) : of(null); }));
  }

  geocode(address: AddressDto): Observable<AddressDto> {
    return this.http.post<AddressDto>("customers/geocode", address).pipe(
      map(addr => {
        address.gpsCoordinates = addr.gpsCoordinates;
        address.gpsQuality = addr.gpsQuality;
        address.gpsType = addr.gpsType;
        return address;
      }));
  }
}
