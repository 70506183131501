import { Directive, Input, OnInit } from "@angular/core";
import { LookupObjectDto } from "@shared/models";
import { from,  Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LookupListService } from "../services/lookup-list.service";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * This lookup is meant to force a server query each time a term is updated (no internal caching)
 * so some larger result sets would not be fully cacheable as we limit the result sets responses
 * a potential workaround to this issue is to cache the full list on the front- and filter it dynaically?
 * Regarding the standard 'abiLookup' - this one bypasses internal caching
 */
@Directive({
  selector: "input[abiTypeahead][abiLookupQuery]"
})
export class LookupQueryTypeaheadDirective extends BaseObservableTypeaheadHelper<LookupObjectDto> implements OnInit {
  @Input() abiLookupQuery: string;
  @Input() path: string[] = null;

  private _startValue: string;
  constructor(typeAhead: TypeaheadDirective, private lookups: LookupListService) {
    super(typeAhead, ["abiLookupQuery"]);
    this.loading = false;
  }

  ngOnInit(): void {
    if (this._startValue) {
      this.typeAhead.writeValue(this._startValue);
    }
    super.ngOnInit();
  }

  protected onWriteValue(obj: any): any {
    if (!this.abiLookupQuery) {
      this._startValue = obj;
      return obj;
    } else {
      return super.onWriteValue(obj);
    }
  }

  protected getId(item: LookupObjectDto): string {
    return item.code;
  }
  protected getName(item: LookupObjectDto): string {
    return item.description;
  }

  protected filterAndSaveList(term: string): Observable<LookupObjectDto[]> {
    return this.abiLookupQuery ? super.filterAndSaveList(term) : from([]);
  }

  protected filteredList(term: string): Observable<LookupObjectDto[]> {
    return this.lookups.queryList<LookupObjectDto>(this.abiLookupQuery, this.path, term).pipe(map(r => {
      return this.filter ? r.filter(item => this.filter(item, term)) : r;
    }));
  }
}
