import { environment } from "@env/environment";

const productionHostNames = ['darkred.app', 'service.kic.co.za', 'wms.kic.co.za'];

export enum ServerEnvironment {
  LOCALHOST = "localhost",
  PRODUCTION = "production",
  DEV = "dev", // development team only
  DEMO = "demo", // deprecated - demos should be carried out on production env on demo company
  WALLBOARD_DEMO = "wb-demo", // deprecated - demos should be on demo company
  TEST = "test", // internal testing only
  BETA = "beta" // external testing
}

export function getEnv(hostname = ""): ServerEnvironment | string {
  const host = hostname || window.location.hostname;
  if (host !== ServerEnvironment.LOCALHOST) {
    if (productionHostNames.includes(host)) {
      return ServerEnvironment.PRODUCTION;
    }
    // all other env's
    const firstHostPart = host.split(".")[0];
    return firstHostPart;
  }
  return ServerEnvironment.LOCALHOST;
}


export function getSentryConfig(env: ServerEnvironment | string) {
  return {
    release: `darkred@${__VERSION__}`,
    dsn: environment.sentry.dsn,
    maxBreadcrumbs: 10,
    attachStacktrace: true,
    environment: env,

    // integrations: [new Sentry.Replay()],

    ...sentryConfigExtras[env]
  };
}

// Higher Sample rate has performance impact
export const sentryConfigExtras = {
  [ServerEnvironment.LOCALHOST]: {
    tracesSampleRate: 0.0, // ignore localhost
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
  [ServerEnvironment.DEV]: {
    tracesSampleRate: 0.0, // ignore dev
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
  },
  [ServerEnvironment.TEST]: {
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 1.0,
  },
  [ServerEnvironment.BETA]: {
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  },
  [ServerEnvironment.DEMO]: {// same as beta
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  },
  [ServerEnvironment.PRODUCTION]: {
    tracesSampleRate: 0.1, // throttle prod tracing heavily
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
};
