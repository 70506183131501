import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BootstrapModule } from "@modules/common/bootstrap.module";
import { SharedModule } from "@modules/common/common.module";
import { TranslateModule } from "@ngx-translate/core";
import { EndComponent } from "./rrule/components/end/end.component";
import {
  MonthlyComponent,
  RepeatComponent,
  WeeklyComponent,
  YearlyComponent,
} from "./rrule/components/repeat/repeat.component";
import { StartComponent } from "./rrule/components/start/start.component";
import { NgxRruleComponent } from "./rrule/rrule.component";

@NgModule({
  declarations: [
    NgxRruleComponent,
    StartComponent,
    RepeatComponent,
    EndComponent,
    YearlyComponent,
    MonthlyComponent,
    WeeklyComponent,
  ],
  imports: [CommonModule, BootstrapModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [NgxRruleComponent],
})
export class RRuleModule {}
