import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { codeOnly, ModelMasterDto, newModelMaster } from "@shared/models";
import { AuthenticationService, ModelService, ProductSettingService, WebLayoutService } from "../../services";
import { BaseFormDirective } from "../base-form";

@Component({
  selector: "abi-model-master",
  templateUrl: "./model-master.component.html"
})
export class ModelMasterComponent extends BaseFormDirective implements OnInit, OnChanges {
  @Input() inModal = true;
  @Input() showSkills = false;
  @Input() showOptions = false;
  @Input() showStatus = false;
  @Input() categoryOrder = 'first';
  @Input() model: ModelMasterDto;
  @Input() set form(form: FormGroup) {
    this._form = form;
  }
  get form(){
    return this._form;
  }
  _form: FormGroup;

  isNew = true;
  categoryCount: number;

  public validationMessages = {
    modelId: {
      required: "Machine.ModelIDRequired."
    },
    name: {
      required: "Machine.MachineNameRequired."
    }
  };

  get categoryControls(): FormArray {
    return this.form?.get("categories") as FormArray;
  }

  get skillControls(): FormArray {
    return this.form?.get("skills") as FormArray;
  }

  get optionControls(): FormArray {
    return this.form?.get("options") as FormArray;
  }

  static setFormData(model: ModelMasterDto, group: FormGroup) {
    const useCategories = model.categories.every(item => model.skills.includes(item))
    group.setControl("categories", new FormArray(model.categories.map(c => new FormControl({value: c, disabled: group.disabled }))));
    group.setControl("skills", new FormArray(model.skills.map(c => new FormControl({ value: c, disabled: group.disabled}))));
    group.setControl("options", new FormArray(model.options.map(c => new FormControl({ value: c, disabled: group.disabled}))));
    group.patchValue({
      categories: model.categories,
      skills: model.skills,
      modelId: model.code,
      name: model.description,
      base: model.base,
      options: model.options,
      useCategories,
      active: model.active
    });
  }

  static generateForm(fb: FormBuilder): FormGroup {
    return fb.group({
      categories: fb.array([]),
      skills: fb.array([]),
      modelId: ["", Validators.required],
      name: ["", Validators.required],
      base: "",
      useCategories: true,
      options: fb.array([]),
      active: true
    });
  }

  static getFormData(model: ModelMasterDto, formGroup: FormGroup, useCategories = false) {
    const value = formGroup.getRawValue();
    model.active = value.active;
    model.code = value.modelId;
    model.description = value.name;
    model.base = value.base;
    model.categories = value.categories.map(c => codeOnly(c));
    if(value.useCategories || useCategories)
      model.skills = value.categories.map(c => codeOnly(c));
    else
      model.skills = value.skills.map(c => codeOnly(c));
    model.options = value.options.map(c => codeOnly(c));
  }

  constructor(
    protected layoutService: WebLayoutService,
    public productSettings: ProductSettingService,
    formBuilder: FormBuilder,
    private modelService: ModelService,
    @Optional() protected dialogRef?: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
    super(layoutService, formBuilder);
    this.categoryCount = this.productSettings.numericValue("MachineCategoryCount") || 5;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.model && this.form){
      console.log("ModelMasterComponent: ngOnChanges");
      ModelMasterComponent.setFormData(this.model, this.form);
    }
  }

  ngOnInit(){
    if(this.dialogRef) // used a standalone dialog with no parent form
    {
      super.ngOnInit(); // creates the form and setups up validation messages
      this.handleModal(this.data?.model); // use dialog data
    } else {
      if(!this.form)
        this.createForm();  // actually sets up the form validation messages
      this.handleModal(this.model); // form is passed in
    }
  }

  handleModal(modelMaster: ModelMasterDto): any {
    if (!modelMaster) {
      modelMaster = newModelMaster();
    }
    // Ensure that the Max number of Category Fields are always shown
    if (modelMaster.categories.length < this.categoryCount){
      modelMaster.categories = [...modelMaster.categories, ...new Array(this.categoryCount - modelMaster.categories.length).fill("")];
    }
    if (modelMaster.skills.length < this.categoryCount){
      modelMaster.skills = [...modelMaster.skills, ...new Array(this.categoryCount - modelMaster.skills.length).fill("")];
    }
    // Ensure 5 Options always show
    if (modelMaster.options.length < 5){
      modelMaster.options = [...modelMaster.options, ...new Array(5 - modelMaster.options.length).fill("")];
    }
    this.isNew = !modelMaster.code;
    ModelMasterComponent.setFormData(modelMaster, this.form);

    // FORM should exist at this point
    this.form.controls.useCategories.valueChanges.subscribe(val => {
      if (val){
        // set skills to the same values as categories
        this.form.patchValue({
          skills: this.form.value.categories,
        });
      }
    });
    this.form.controls.categories.valueChanges.subscribe(val => {
      if (this.form.value.useCategories){
        // set skills to the same values as categories
        this.form.patchValue({
          skills: val,
        });
      }
    });
  }

  protected createForm() {
    this.form = ModelMasterComponent.generateForm(this.formBuilder);
    super.createForm();
  }

  accept() {
    const model = newModelMaster();
    ModelMasterComponent.getFormData(model, this.form, !this.showSkills);
    this.modelService
      .addModel(model)
      .toPromise()
      .then(m => this.dialogRef.close(model));
  }

  getForm(){
    return this.form;
  }
}
