import { Directive, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { displayValue, LookupList, LookupListEx, LookupObjectDto, newLookupObject } from "@shared/models";
import { from ,  Observable } from "rxjs";
import { LookupListService } from "../services/lookup-list.service";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * Issues with Lookup:
 * 1. when using dynamic content, the prefilter doesnt work
 * 2. when using static content - the typeahead fails
 * @todo update and rework with improved functionality (see above)
 * and integrate functionality that was added to sub-implementations
 */
@Directive({
  selector: "input[abiTypeahead][abiLookup]"
})
export class LookupTypeaheadDirective extends BaseObservableTypeaheadHelper<LookupObjectDto> implements OnInit, OnChanges {
  @Input() name: string = "abiLookup";
  @Input() abiLookup: string | LookupListEx<any> | LookupList;
  @Input() activeOnly = false;// Code Smell: some lookup lists don't filter out the Inactive items - so we need to do it here
  @Input() codeOnly = false;
  @Input() disabled = false;
  @Input() allowAll = false;
  @Input() extraLookups: LookupObjectDto[] = [];
  @Input() sortyByOrder = false;
  @Input() queryPathExtras: string[] = [];
  @Input() limit = 200;
  @Input() nocache = false;
  private code: string = null;
  private _list: LookupList;

  constructor(typeAhead: TypeaheadDirective, private lookups: LookupListService) {
    super(typeAhead, ["abiLookup", "queryPathExtras", 'limit']);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  // @TODO: tidy up and simplify this
  // @TODO: implement an observer pattern to handle more nuanced changes
  // @see https://stackoverflow.com/a/49662611/1741010
  ngOnChanges(changes: SimpleChanges) {
    let changed = false;
    for (const ch of Object.keys(changes)) {
      // Prefilter is a closure that's not easily compared - so we rather ignore it... (as we did before)
      // Moving host compone to 'OnPush' change detection strategy can mitigate this issue - but that is not the right solution either
      // Suggest we re-work the 'prefilter' to something that can be compared
      changed = changed || this.changes.filter(c => c !== 'preFilter').includes(ch);

      // deep compare 'queryPathExtras' array
      // if (ch === 'queryPathExtras') {
      //   changed = changed || JSON.stringify(changes.queryPathExtras.currentValue) !== JSON.stringify(changes.queryPathExtras.previousValue);
      // }
    }
    // Handle Lookup changes (filtering if necessary)
    const lookup = changes.abiLookup;

    if (changed) {
      // console.log("LookupTypeahead - changes", changes, this.abiLookup);

      // We have a bonafide List to use as a Lookup source
      if (this.abiLookup instanceof LookupListEx) {
        this.addExtraItems(this.abiLookup);
        this.preFilterList(this.abiLookup);// CAVEAT: When prefilters are used - typing has no effect as the component is essential re-rendered (nasty)

      // It's a String - so assume we need to Grab it from Lookup API
      } else if (this.abiLookup) {
        // console.log('lookup (or others) changed', this.abiLookup)
        this.getNewLookupList(this.abiLookup)
        .then((lst: LookupList) => {
          // console.log('got new list - runnning prefilter', lst)
          this.preFilterList(lst);
        })
        .catch(e => console.warn('LookupTypeahead - invalid request', e));
      }
    }

    // The Filter Function changed - we must re-run preFilters now
    if(
      this.abiLookup instanceof LookupListEx
      && changes.preFilter
      && changes.preFilter.currentValue !== changes.preFilter.previousValue
    ){
      // console.log('prefilter changed', changes.preFilter.currentValue, changes.preFilter.previousValue)
      this.preFilterList(this.abiLookup);
    }

    if (changes.disabled)
      this.typeAhead.setDisabledState(changes.disabled.currentValue);
  }

  getNewLookupList(lookupString: string): Promise<LookupList>{
    // console.log(this.abiLookup, 'getNewLookupList', lookupString, this.limit, this.queryPathExtras)
    return this.lookups.lookupList(lookupString, this.limit, this.queryPathExtras, !this.nocache).toPromise()
    .then(lst => {
      this.addExtraItems(lst);
      return lst;
    });
  }

  addAllOption(list: LookupListEx<any> | LookupList) {
    if(this.allowAll && list && !list.containsKey('*')) {
      list.prepend(newLookupObject("*", "All"));
    }
  }

  addExtraItems(list: LookupListEx<any> | LookupList) {
    if(this.extraLookups.length){
      this.extraLookups.forEach(el => {
        if(!list.item(el.code))
          list.prepend(el);
      });
    }
    this.addAllOption(list);
  }

  /**
   * Requires a passed in LIST value (remote dont work - [wut, that has nothing to do with it...?!])
   * @todo: write tests for this
   * test: code supplied: item exists in lookup: true, false
   * test: activeOnly: true, false
   * test: prefilter: true, false
   */
  preFilterList(lst: LookupList | LookupListEx<any>){
    this.lastResult = []; // prefilters must trash old results - does not make sense to keep it (if categories changed)
    if (this.preFilter) {
      lst = new LookupList(lst.values.filter(itm => this.preFilter(itm)));
    }

    if(this.activeOnly) {
      lst = new LookupList(lst.values.filter(itm => itm.active));
    }

    this._list = lst;
    this.loading = false;
    this.loaded.emit(this._list.values);
    // preselect availables from list
    const tempCode = this.getCode(this.code);
    if (tempCode && this._list.containsKey(tempCode)) {
      // item exists in lookuplist - write that value
      this.typeAhead.writeValue(this._list.item(tempCode));
    } else {
      // use current value without a lookup result
      this.typeAhead.writeValue(this.codeOnly ? tempCode : this._internalValue);
    }
  }

  protected getId(item: LookupObjectDto): string {
    return item.code;
  }

  protected getName(item: LookupObjectDto): string {
    return item.description;
  }

  /**
   * @todo cleanup and simplify how this needs to work
   */
  protected onWriteValue(obj: any): any {
    // console.log('writing object', obj)
    // console.log('had code', this.code)

    if(!obj && !this._internalValue) // prevent changes from triggering when prev value and new value are null
      return null;

    this._internalValue = obj; // Keep an Internal Representation of the original Dto (for later use)
    // console.log('onWrite', obj)
    if (typeof obj === "string") {
      if (this._list && this._list.containsKey(obj)) {
        this._internalValue = this._list.item(obj); // we got a string - rather use the Dto
        // console.log('updating internal value', this._internalValue)
        this.code = this.getCode(this._internalValue);
        return this.codeOnly ? obj : this._list.item(obj);
      } else {
        // console.log('updating code', this._internalValue)
        this.code = obj;
      }
    } else {
      this.code = this.getCode(obj);
    }
    const retVal = this.codeOnly ? this.getCode(obj) : obj;
    // console.log('code', this.code)
    // console.log(this.abiLookup, 'retVal', retVal)
    // this.value = retVal;
    // must manmually trigger on change when no value is given...(not sure why)
    if(!retVal) {
      // this.typeAhead.writeValue(retVal);
      this.typeAhead.doOnChange(retVal);
    }
    return retVal;
  }

  sortByOrder(a: LookupObjectDto, b: LookupObjectDto){
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  getFilteredList(term: string) {
    const search = this.regExp(term);
    const newList = this._list.values.filter(item => search.test(displayValue(item)) && (!this.filter || this.filter(item, term)));
    return this.sortByOrder ? newList.sort(this.sortByOrder) : newList;
  }

  protected filteredList(term: string): Observable<LookupObjectDto[]> {
    return this._list
      ? from([this.getFilteredList(term)])
      : from([]);
  }

  protected list(): Observable<LookupObjectDto[]> {
    if (this.filter) {
      return from([this._list?.values.filter(itm => this.filter(itm, ""))]);
    } else {
      return from([this._list?.values]);
    }
  }

  protected getCode(item: LookupObjectDto |string): string {
    if (item) {
      if (typeof item === "object") {
        return item.code;
      }
      return item;
    }
    return "";
  }
}
