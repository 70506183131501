import { AfterContentInit, ContentChild, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { CustomerMasterDto } from "@modules/models";
import { CustomerService } from "@services";

/**
 * Customer Context Directive
 * Wrap a section of Template that needs Access to Details of a Customer
 * @example <abi-customer-context [abiCustomerContext]="customerId">
              <ng-template let-customer>
 */
@Directive({
  selector: "[abiCustomerContext]"
})
export class CustomerContextDirective implements AfterContentInit {
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Input() abiCustomerContext: string;
  @Input() customer: CustomerMasterDto;
  constructor(
    private viewContainer: ViewContainerRef,
    private customerService: CustomerService
  ) {}
  ngAfterContentInit() {
    this.customerService.getCustomer(this.abiCustomerContext).subscribe(c => {
      this.customer = c;
      this.render();
    });
  }
  render(){
    this.viewContainer.createEmbeddedView(this.template, {$implicit: this.customer});
    // this.viewContainer.createEmbeddedView(this.templateRef, {$implicit: this.customer});
  }
}

/**
 * Alternate Customer Context Directive
 * Wrap a section of Template that needs Access to Details of a Customer
 * @example <ng-template [abiTemplateCustomerContext]="machine.customerId" let-customer>
 */
@Directive({
  selector: "[abiTemplateCustomerContext]"
})
export class TemplateCustomerContextDirective implements AfterContentInit, OnInit {
  // @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Input() abiTemplateCustomerContext: string;
  @Input() customer: CustomerMasterDto;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private customerService: CustomerService
  ) {}
  ngOnInit(): void {
    // @TODO: optimisations
    // query data here and push to subject
    // subscribe to subject here and trigger render in ngAfterContentInit
  }
  ngAfterContentInit() {
    if(!this.abiTemplateCustomerContext) return;
    this.customerService.getCustomer(this.abiTemplateCustomerContext).subscribe(c => {
      this.customer = c;
      this.render();
    });
  }
  render(){
    this.viewContainer.createEmbeddedView(this.templateRef, {$implicit: this.customer});
  }
}
