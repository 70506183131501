import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MachineMasterDto } from "@shared/models";
import { Observable } from "rxjs";
import { MachineService } from "../services/machine.service";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiLoanMachine]"
})
export class LoanMachineTypeaheadDirective extends BaseObservableTypeaheadHelper<MachineMasterDto> implements OnChanges {
  @Input()
  public abiLoanMachine: string;

  constructor(typeAhead: TypeaheadDirective, private machineService: MachineService) {
    super(typeAhead, ["abiLoanMachine"]);
    this.inputDisplay = "name";
    this.dropDownDisplay = "name";
  }

  protected getId(item: MachineMasterDto): string {
    return item.machineId;
  }

  protected getName(item: MachineMasterDto): string {
    return `${item.modelId} ~ ${item.name}`; // ~ ${item.serialNumber} ~ ${item.warehouseId}`;
  }

  protected filteredList(term: string): Observable<MachineMasterDto[]> {
    return this.machineService.queryLoanMachines(this.abiLoanMachine, term);
  }
}

