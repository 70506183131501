<div
    *ngIf="categories && categoryControls.length"
    [formGroup]="categoryControls"
    class="row"
>
    <ng-template ngFor let-item [ngForOf]="categories" let-i="index">
        <abi-form-field
            *ngIf="!hiddenCategories[i]"
            [label]="item.name"
            cols="col-12"
            [class]="'machine-category'+(i+1)"
            [horizontal]="true"
        >
            <input
                abiTypeahead
                [formControlName]="i"
                [id]="item.propName"
                [abiLookup]="item.items"
                [preFilter]="categoryFilter.bind(this, i)"
                [dropDownDisplay]="lookupDisplay"
                [inputDisplay]="lookupDisplay"
            />
        </abi-form-field>
    </ng-template>
</div>
