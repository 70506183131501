<ng-container [formGroup]="formArray">
    <ng-container *ngFor="let item of formArray.controls; let i = index" [formGroupName]="i">
        <div class="input-group mb-2">
            <input type="text" formControlName="description" placeholder="Description..." class="form-control" />
            <div class="input-group-append w-50">
                <div class="input-group-text text-truncate w-100">
                    <span class="text-truncate d-block w-99 text-left">{{ item.value.file.name }}</span>
                </div>
                <button class="btn btn-outline-primary" (click)="removeIndex(i)"><i class="fas fa-times"></i></button>
            </div>
        </div>
    </ng-container>
</ng-container>

<button [disabled]="disabled" class="btn btn-primary" type="button" (click)="jobFileInput.click()">{{ 'JobNew.AddFiles' | translate }}</button>
<input #jobFileInput type="file" hidden (change)="addJobFiles($event)" multiple noBootstrap [accept]="fileInputAccept">
