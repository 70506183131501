
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormArray, FormBuilder, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AppQuery, LoginStatus } from "@modules/common/app.store";
import { ListResultDto, LookupList } from "@modules/models";
import { FileService, LookupListService, ProductSettingService, SnackBarService, UploadFile } from "@services";
import { combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";

// Mutated sub-set of Sources
type SourceMethod = { code: string, sourceId: string, description: string};


@Component({
  selector: "abi-multi-file-upload",
  templateUrl: "./multi-file-upload.component.html",
  // styleUrls: ["./job-source-select.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiFileUploadComponent),
    multi: true
  }]
})
export class MultiFileUploadComponent implements OnInit, ControlValueAccessor, OnChanges {
  // @Input() sources: LookupList;
  // @Input() granularMode = false;
  // @Input() noTrunc = false;
  // @Input() origins: string[] = [];
  // @Input() sourceFilter: string[] = [];

  fileInputAccept = FileService.FileInputAcceptString;
  public formArray: FormArray;

  // New properties (optional opt-in: gives session pagination saving and loading)
  files: any;
  private _onTouched = () => { };
  private _onChange = (_: any) => { };

  constructor(private formBuilder: FormBuilder, private snackBar: SnackBarService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes.sources && changes.sources.currentValue){
      //   this.buildSources();
      // }
    }

  ngOnInit(): void {
    this.formArray = this.formBuilder.array([]);
  }

  getFileGroup(f: File, descr: string) {
    return this.formBuilder.group({ file: f, description: descr });
  }

  // add and filter out invalid files
  addJobFiles(event) {
    if (event.target.files.length) {
      // fault images is a simple value array
      Array.from(event.target.files)
      .forEach((f: File) => {
        // validation function add an extra layer of safety (with file extension checks)
        if(FileService.validateTypes(f, FileService.AllowedFileUploadType, FileService.InvalidFileExtensions)) {
          // we actually need a form array to store the descriptions and file refs as this is rewflected on the UI
          (this.formArray as FormArray).push(this.getFileGroup(f, "") );
        } else {
          // this should generall neer need to be called as the UI should prevent this (file accept attribute)
          this.snackBar.open(`File ${f.name} is not a valid file type.`, "OK", { duration: 5000 });
        }
      });
    }
  }

  removeIndex( i: number) {
    (this.formArray as FormArray).removeAt(i);
  }

  getFormData() {
    // console.log("getFormData", contact);
    return this.formArray.value;
  }

  writeValue(value: any): void {
    // this.sourceId = value;
    this.files = value;
    // console.log("writeValue", value);
  }

  registerOnChange(fn: any): void {
    this.formArray.valueChanges
    .pipe(
      map((v) => {
        // console.log( this.type, "value changed", v);
        this.files = this.getFormData();
        return this.files;
      })
    )
    .subscribe(fn);
    // this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  disabled = false;
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
