import { Directive, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { JobSummaryUserDto, Mode } from "@shared/models";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * This class prefers 'summaryusers' over standard users list
 * TODO: Rework Users listings and cleanup old mode properties
 */
@Directive({
  selector: "input[abiTypeahead][abiUser]"
})
export class UserTypeaheadDirective extends BaseObservableTypeaheadHelper<JobSummaryUserDto> implements OnInit, OnChanges {
  @Input() abiUser: Mode[];
  @Input() useSummaryUsers: boolean = true;
  @Input() display: "all" | "code" | "name" = "all";
  constructor(typeAhead: TypeaheadDirective, private userService: UserService) {
    super(typeAhead, ["display", "abiUser"]);
    this.filter = this.typeFilter;
    this.userService.usersLoaded.pipe(this.notDisposed()).subscribe((vals) => {
      this.loading = false;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.inputDisplay = this.display;
    this.dropDownDisplay = this.display;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputDisplay = this.display;
    this.dropDownDisplay = this.display;
    super.ngOnChanges(changes);
    this.filter = this.typeFilter;
  }

  typeFilter(user: JobSummaryUserDto, t: string): boolean {
    return this.abiUser ? this.abiUser.includes(user.mode) : true;
  }

  private userToString(self: JobSummaryUserDto): string {
    return self.resource + " ~ " + self.userId;  // (self.mode !== Mode.Resource ? + " ~ " : "") + ;
  }

  protected filteredList(term: string): Observable<JobSummaryUserDto[]> {
    const search = this.regExp(term);
    if(!this.useSummaryUsers)
    return this.userService.getUsers()
      .pipe(
        map(
          (users) => {
            return [
              ...users
              .map(u => this.stringUserToSummaryUser(u))
              .filter(item => this.filter(item, "") && search.test(this.userToString(item)))
            ];
          }
        )
      );

    return from(
      [
        this.userService.users
        .filter(item => this.filter(item, "") && search.test(this.userToString(item)))
      ]
    );
  }

  protected list(): Observable<JobSummaryUserDto[]> {
    if(!this.useSummaryUsers)
    return this.userService.getUsers()
      .pipe(
        map(
          (users) => {
            return [...users.map(u => this.stringUserToSummaryUser(u)).filter(item => this.filter(item, ""))];
          }
        )
      );
    return from([this.userService.users.filter(item => this.filter(item, ""))]);
  }

  private stringUserToSummaryUser(user: string) {
    return ({userId: user, resource: null, mode: null});
  }

  protected getId(item: JobSummaryUserDto): string {
    return item.resource; // modeToString(item);
  }

  protected getName(item: JobSummaryUserDto): string {
    return item.userId;
  }
}

