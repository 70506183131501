export interface OrderLineReturnDto {
  status: string;
  closed: number;
  reasonCode: string;
  reasonText: string;
  machineId: string;
  invoice: string;
  damageCode: string;
  packageCode: string;
  inspected: number;
  deliveryNote: string;
  statusDate: Date;
  confirmedBy: string;
  confirmedNote: string;
}

export function newOrderLineReturn() {
  return {
    status: "",
    closed: 0,
    reasonCode: "",
    reasonText: "",
    machineId: "",
    invoice: "",
    damageCode: "",
    packageCode: "",
    inspected: 0,
    deliveryNote: "",
    statusDate: null,
    confirmedBy: "",
    confirmedNote: "",
  };
}
