import { Directive, Input, OnChanges } from "@angular/core";
import { HistoryOrderLineDto } from "@shared/models";
import { Observable} from "rxjs";
import { SalesOrderService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiOrderLineHistory]"
})
export class OrderLineHistoryTypeaheadDirective extends BaseObservableTypeaheadHelper<HistoryOrderLineDto> implements OnChanges {

  @Input() abiOrderLineHistory: string; // dealerId
  @Input() branchId: string;
  @Input() modelId: string;

  constructor(typeAhead: TypeaheadDirective, private orderService: SalesOrderService) {
    super(typeAhead, ["abiOrderLineHistory", "branchId", "modelId"]);
    this.preLoad = true;
    this.loading = false;
    this.inputDisplay = "code";
  }

  protected getId(item: HistoryOrderLineDto): string {
    return item.invoiceNo;
  }

  protected getName(item: HistoryOrderLineDto): string {
    return item.invoiceNo + " ~ " + item.invoice.format() + " ~ " + item.purchaseOrder;
  }

  protected getFull(item: HistoryOrderLineDto): string {
    return this.getName(item);
  }

  protected filteredList(term: string): Observable<HistoryOrderLineDto[]> {
    return this.orderService.getHistoryLines(this.abiOrderLineHistory, this.branchId, this.modelId);
  }

}

