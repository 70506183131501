<ng-container *ngIf="scanOnly">
    <div class="input-group">
        <input type="text" class="form-control w-50" noBootstrap [value]="serialNumber" [disabled]="true">
        <div class="input-group-append w-50">
            <button [disabled]="!enabled" type="button" class="btn btn-primary w-100 text-truncate" (click)="scan()">
                <i class="fas fa-barcode"></i>&nbsp;Scan Barcode
                <span class="badge badge-success" *ngIf="serialNumber">
                    <i class="fas fa-check fa-fw"></i>
                </span>
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!scanOnly">
    <div class="input-group">
        <ng-content></ng-content>
        <div *ngIf="canAccessCamera" class="input-group-append">
            <button [disabled]="!enabled" type="button" class="btn btn-primary btn-sm" (click)="scan()">
                <i class="fas fa-barcode"></i>
            </button>
        </div>
    </div>
</ng-container>

<ng-template #resolveDialog>
  <h4 mat-dialog-title>Confirm scan</h4>
  <mat-dialog-content>
    <div class="zzzzz" [formGroup]="form">
      <abi-form-field *ngIf="checkScanModel || !scanModel"
        labelColClass="col-sm-4"
        [horizontal]="false"
        label="Confirm Model"
      >
        <div class="col-12">
          <div formControlName="newModel" ngbRadioGroup class="btn-group btn-group-toggle" name="model" >
            <label *ngIf="modelId" ngbButtonLabel class="btn btn-outline-primary" >
              <input ngbButton type="radio" [value]="modelId" />{{ modelId }}
            </label>
            <label *ngIf="scanModel; else unknowns" ngbButtonLabel class="btn btn-outline-primary">
              <input ngbButton type="radio" [value]="scanModel" />{{ scanModel.code }}
            </label>
            <ng-template #unknowns>
              <label ngbButtonLabel *ngFor="let item of unknownModels | keyvalue" class="btn btn-outline-primary">
                <input ngbButton type="radio" [value]="item.value.description" />{{ item.key }}
              </label>
            </ng-template>
          </div>
        </div>
      </abi-form-field>
      <abi-form-field *ngIf="checkScanSerial" labelColClass="col-sm-4" [horizontal]="false" label="Confirm Serial">
        <div class="col-12">
          <div formControlName="newSerial" ngbRadioGroup class="btn-group btn-group-toggle" name="serial">
            <label *ngIf="serialNumber" ngbButtonLabel class="btn btn-outline-primary" >
              <input ngbButton type="radio" [value]="serialNumber" />{{ serialNumber }}
            </label>
            <label ngbButtonLabel *ngFor="let serial of scanSerials" class="btn btn-outline-primary">
              <input ngbButton type="radio" [value]="serial" />{{ serial }}
            </label>
          </div>
        </div>
      </abi-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <!--button cdkFocusInitial type="button" mat-button [mat-dialog-close]="true">
      Ok
    </button-->
    <button type="button" mat-button mat-dialog-close>
        {{ "System.Cancel" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #disclaimerDialog>
    <h4 mat-dialog-title>Attention</h4>
    <mat-dialog-content>
        <p>We have detected that you may not have a device capable of scanning. Would you like to proceed?</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button cdkFocusInitial type="button" mat-button [mat-dialog-close]="true">
        Proceed
      </button>
      <button type="button" mat-button mat-dialog-close>
          {{ "System.Cancel" | translate }}
      </button>
    </mat-dialog-actions>
  </ng-template>
