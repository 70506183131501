<div class="btn-group">
    <ng-content></ng-content>
    <ng-container *ngIf="saveButtonsVisible">
        <button type="reset" class="btn btn-danger" [disabled]="!cancelAllowed" (click)="cancel.emit($event)" [title]="'System.Cancel' | translate">
            <i class="fas fa-times"></i>
        </button>
        <button type="submit" class="btn btn-success" [disabled]="!saveAllowed" (click)="save.emit($event)" [title]="'System.Save' | translate">
            <i class="fas" [ngClass]="{'fa-check': !apiBusy, 'fa-spinner fa-pulse': apiBusy}"></i>
        </button>
    </ng-container>
</div>
