import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";
import { MaskApplierService, MaskPipe } from "ngx-mask";
import { ProductSettingService } from "../services";


@Directive({ selector: "[abiTelephone]" })
export class TelephoneDirective implements OnChanges {
  phoneMask = "(000)000-0000";
  pipe: MaskPipe;
  enableMask = true;

  @Input("abiTelephone") public telephone: string;

  private aLink: any;
  constructor(private el: ElementRef, private renderer: Renderer2, private maskService: MaskApplierService
    ,         productSettings: ProductSettingService) {
    this.renderer.addClass(el.nativeElement, "text-nowrap");
    this.aLink = renderer.createElement("a");
    this.pipe = new MaskPipe(this.maskService);
    this.phoneMask = productSettings.stringValue("PhoneMask") || this.phoneMask;
    this.enableMask = productSettings.numericValue("PhoneMask") === 0;

    renderer.appendChild(el.nativeElement, this.aLink);
  }

  ngOnChanges(changes: SimpleChanges) {

    this.aLink.textContent = this.telephone; // this.pipe.transform(this.telephone, this.phoneMask);
    this.renderer.setAttribute(this.aLink, "href", `tel:${this.telephone}`);
  }

}
