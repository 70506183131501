<div [ngClass]="['sidebar_menu', 'menu-' + sidebarState]" [@onSideNavChange]="sidebarState" (touchstart)="touchStart($event)" (mouseover)="triggerHover($event)" (mouseout)="triggerOut($event)">
    <mat-action-list>
        <button
            *ngIf="!onlyMenu"
            mat-list-item
            class=""
            color="basic"
            [routerLink]="tenant"
            routerLinkActive="router-link-active"
            [routerLinkActiveOptions]="{exact: true}"
        >
            <i matListIcon [@iconAnimation]="sidebarState" class="fas fa-home fa-fw" title="Home"></i>
            <p matLine [@labelAnimation]="sidebarState">{{ "System.Home" | translate }}</p>
        </button>
        <ng-container *ngFor="let menuItem of menuTree; let i = index">

            <ng-container *ngIf="menuItem.children.length; else single_button">

                <!-- FIRST LEVEL EXPANDER -->
                <mat-expansion-panel [disabled]="disablePanels">
                    <mat-expansion-panel-header class="section-height">
                        <i matListIcon [@iconAnimation]="sidebarState" [ngClass]="[ menuItem.image ]" class="fa-fw"></i>
                        <div [@labelAnimation]="sidebarState" class="mat-list-text">{{ menuItem.name }}</div>
                    </mat-expansion-panel-header>
                    <mat-action-list>

                        <mat-accordion #child_accordion>

                            <ng-container *ngFor="let childItem of menuItem.children; let ci = index">

                                <!-- SECOND LEVEL EXPANDER (LAYOUTS) -->
                                <ng-container *ngIf="childItem.children.length; else single_button_caret">

                                    <mat-expansion-panel #child_expander [attr.data-parent]="menuItem.layoutName">
                                        <mat-expansion-panel-header class="section-height">
                                            <i [@iconAnimation]="sidebarState" class="mat-list-icon fas fa-circle"></i>
                                            <span [@labelAnimation]="sidebarState" class="mat-list-text">{{ childItem.name }}</span>
                                        </mat-expansion-panel-header>
                                        <mat-action-list>

                                            <!-- Use LinkLayouts to generate 'child' Routes -->
                                            <ng-container *ngFor="let childChildItem of childItem.children">
                                                <button mat-list-item
                                                    [routerLink]="routerLink(childChildItem)"
                                                    routerLinkActive="router-link-active"
                                                    [routerLinkActiveOptions]="{paths: 'exact', matrixParams: 'exact', queryParams: 'exact'}"
                                                >
                                                    <i class="mat-list-icon fas fa-circle"></i>
                                                    <span class="mat-list-text">{{ childChildItem.name }}</span>
                                                    <!-- <span>{{ childChildItem.description || '' }}</span> -->
                                                </button>
                                            </ng-container>

                                        </mat-action-list>
                                    </mat-expansion-panel>
                                </ng-container>
                                <!-- END SECOND LEVEL EXPANDER -->

                                <ng-template #single_button_caret>
                                    <button mat-list-item
                                        [routerLink]="routerLink(childItem)"
                                        routerLinkActive="router-link-active"
                                        [routerLinkActiveOptions]="{paths: 'exact', matrixParams: 'exact', queryParams: 'exact'}"
                                    >
                                        <i [@iconAnimation]="sidebarState" class="mat-list-icon fas fa-circle"></i>
                                        <span [@labelAnimation]="sidebarState" class="mat-list-text">{{ childItem.name }}</span>
                                    </button>
                                </ng-template>

                            </ng-container>

                        </mat-accordion>

                    </mat-action-list>
                </mat-expansion-panel>
                <!-- END FIRST LEVEL EXPANDER -->
            </ng-container>

            <ng-template #single_button>

                <!-- Add Badge to the FollowpList List -->
                <ng-container *ngIf="menuItem.layoutName === 'FollowupList';else genericLink">
                    <button mat-list-item
                        [routerLink]="routerLink(menuItem)"
                        routerLinkActive="router-link-active"
                        [routerLinkActiveOptions]="{exact: true}"
                        >
                        <i
                            [@iconAnimation]="sidebarState"
                            matListIcon
                            [ngClass]="[ menuItem.image ]"
                            class="fa-fw"
                            [matBadgeHidden]="!followupCount"
                            [matBadge]="followupCount"
                            matBadgePosition="after"
                            matBadgeColor="warn"
                            ></i>
                        <span matLine [@labelAnimation]="sidebarState">{{ menuItem.name }}</span>
                    </button>
                </ng-container>
                <ng-template #genericLink>
                    <button mat-list-item
                        [routerLink]="routerLink(menuItem)"
                        routerLinkActive="router-link-active"
                        [routerLinkActiveOptions]="{exact: true}"
                        >
                        <i
                            [@iconAnimation]="sidebarState"
                            matListIcon
                            [ngClass]="[ menuItem.image ]"
                            class="fa-fw"
                            ></i>
                        <span matLine [@labelAnimation]="sidebarState">{{ menuItem.name }}</span>
                    </button>
                </ng-template>

            </ng-template>

        </ng-container>

        <ng-container *ngIf="isMobile === false && !onlyMenu">
            <mat-divider></mat-divider>
            <button
                id="pin-menu-rails-checkbox"
                mat-list-item
                (click)="toggleRails()"
            >
                <i
                    [@labelAnimation]="sidebarState"
                    matListIcon
                    [ngClass]="{
                        'fa-fw text-base': true,
                        'fas fa-check-square': appQuery.hideRails === false,
                        'far fa-square': appQuery.hideRails === true
                    }"
                ></i>&nbsp;<span [@labelAnimation]="sidebarState">{{ "System.PinSideBar" | translate }}</span>
            </button>
        </ng-container>
    </mat-action-list>

</div>
