import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "input:not([noBootstrap]):not(.form-control-plaintext):not([type=checkbox])" +
    ":not([type=hidden]):not([type=radio]), textarea:not([noBootstrap]), select:not([noBootstrap])"
})
export class BootstrapInputDirective implements AfterViewInit {
  @Input() size: string;

  constructor(private el: ElementRef, private ctrl: NgControl, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    let attr = "form-control";
    if (this.size) {
      attr = attr + "-" + this.size;
    }

    if (!this.el.nativeElement.autocomplete) this.renderer.setAttribute(this.el.nativeElement, "autocomplete", "off");
    this.renderer.addClass(this.el.nativeElement, attr);

    this.ctrl?.statusChanges?.subscribe(data => {
      const nativeEl = this.el.nativeElement;
      // console.log("statusChanges", data, nativeEl.value, nativeEl.type);
      this.updateValidity();
      });
      this.updateValidity();
      }

      updateValidity() {
    // const nativeEl = this.el.nativeElement;
    // if(nativeEl.type === "email"){
    //   console.log("email", nativeEl.value, this.ctrl.errors);
    // }
    if (this.ctrl.invalid) {
      this.renderer.addClass(this.el.nativeElement, "is-invalid");
    } else {
      this.renderer.removeClass(this.el.nativeElement, "is-invalid");
    }
  }
}

/*
@Directive({
  selector: 'form:not([noBootstrap])'
})
export class BootstrapFormDirective {
  constructor(el: ElementRef, renderer: Renderer) {
    renderer.setElementClass(el.nativeElement, 'form-horizontal', true);
  }
}*/
