import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "@env/environment";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { AuthenticationService } from "@services";
import { BehaviorSubject } from "rxjs";

// FOR FUTURE USE
function getTenant(href: string): string {
  return href ? href.split("/", 3).filter(x => x)[0] : "";
}

/**
 * Notification service
 * our basic SgnalR service wrapper
 */
@Injectable({
  providedIn: "root",
})
export class NotificationService implements OnDestroy {
  connection: HubConnection;
  activeHandlers: string[] = [];
  constructor(private authenticationSerivce: AuthenticationService) {

  }

  buildConnection() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.notificationApi}/notifications`, {
        accessTokenFactory: () => this.authenticationSerivce.getAccessToken(),
      })
      .build();
  }

  // each time we 'login' to a new tenant...rebuild the connection with that new token
  async connectWithToken(token: string) {
    if (this.connection && this.connection.state === HubConnectionState.Connected) {
      // stop and start
      return this.connection.stop().then(() => {
        this.$connected.next(false);
        this.buildConnection();
        return this.start();
      });
    } else {
      // build and start
      this.buildConnection();
      return this.start();
    }
  }

  $connected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  async start() {
    return this.connection.start().then(() => {
      console.log('signalr CONNECTED');
      this.$connected.next(true);
      // if(this.postConnectionAction) this.postConnectionAction();
    }).catch((err) => console.error(err));
    console.log('signalr connection started...');
  }

  stop() {
    this.connection.stop().catch((err) => console.error(err));
    console.log('signalr connection stopped...');
  }

  connected(): boolean {
    return this.connection?.state === "Connected";
  }

  disconnected(): boolean {
    return this.connection?.state === "Disconnected";
  }

  getState(): HubConnectionState {
    return this.connection?.state;
  }

  registerHandler(name: string, handler: (...args: any[]) => void) {
    // this.connection.
    if (this.activeHandlers.includes(name)) {
      console.warn(`Duplicate handler ${name} found...ignoring`);
      return;
    } else {
      console.log(`Registering handler ${name}`)
      this.connection.on(name, handler);// Basic Handler Name?
      this.activeHandlers.push(name);// UniqueID?
      if (!this.connected())
        console.warn(`Registering handler ${name} before connection started`);
    }
  }

  unregisterHandler(name: string) {
    console.log(`Un-Registering handler ${name}`);
    this.connection.off(name);
    this.activeHandlers = this.activeHandlers.filter(x => x !== name);// track locally
  }

  ngOnDestroy(): void {
    this.connection.stop();
  }

  send(action: string, tenant: string, entityType: string, entityId: string): Promise<void> {
    console.log(`Sending ${action} for ${entityType} ${entityId}`, this.connection.state);
    if(!action || !tenant || !entityType || !entityId) throw new Error('Invalid parameters');
    if(this.disconnected()) {
      const error = 'SignalR connection is not connected...cannot send message - attempting to reconnect';
      console.error(error);
      // return Promise.reject(error);
      return this.connectWithToken(this.authenticationSerivce.getAccessToken()).then(() => {
        return this.connection.send(action, tenant, entityType, entityId);
      });
    } else if(this.connection.state === HubConnectionState.Connecting) {
      // NOTE: should never enter this state...but if it does, we need to handle it
      const error = 'SignalR connection is connecting...waiting for connection to complete';
      console.log(error);
      return Promise.reject(error);
    } else {
      return this.connection.send(action, tenant, entityType, entityId);
    }
  }
}
