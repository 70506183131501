<ng-container *ngIf="granularSourceMode; else standardSourceMode">
    <div
        ngbRadioGroup
        class="
            btn-group
            btn-group-toggle
            btn-group-vertical
            d-flex
        "
        name="jobSourceOrigin"
        noBootstrap
        [(ngModel)]="sourceOrigin"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onSourceChange($event)"
        #origin="ngModel"
    >
        <label
            *ngFor="let item of methodMap | keyvalue"
            ngbButtonLabel
            class="btn-outline-primary ml-0 w-100"
        >
            <input

                ngbButton
                type="radio"
                [value]="item.key"
            />{{ 'JobNew.Origin_' + item.key | translate }}
        </label>

    </div>

    <ng-container *ngIf="sourceMethods?.length">
        <p class="mb-2 mt-3 p-0">Method</p>
        <div
                ngbRadioGroup
                class="
                    btn-group
                    btn-group-toggle
                    btn-group-vertical
                    d-flex
                "
                name="jobSourceMethod"
                [(ngModel)]="sourceId"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onMethodChange($event)"
            >
            <label
                *ngFor="let sMethod of sourceMethods"
                ngbButtonLabel
                class="btn-outline-primary ml-0 w-100"
            >
                <input
                    ngbButton
                    type="radio"
                    [value]="sMethod.sourceId"
                />{{ 'JobNew.Method_' + sMethod.code | translate }}
            </label>
        </div>
    </ng-container>
</ng-container>
<ng-template #standardSourceMode>
    <div
        ngbRadioGroup
        class="
            btn-group
            btn-group-toggle
            btn-group-vertical
            d-flex
        "
        name="jobSource"
        [(ngModel)]="sourceId"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onMethodChange($event)"
    >
        <label
            *ngFor="let src of sourceList"
            ngbButtonLabel
            class="btn-outline-primary ml-0 w-100"
        >
            <input
                ngbButton
                type="radio"
                [value]="src.code"
            />{{ src.description }}
        </label>
    </div>
</ng-template>

<!-- <pre>
    {{ sourceId | json }}
    {{ methodMap | json }}
    {{ sourceList | json }}
</pre> -->
