import { formatDate } from "@angular/common";
import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { Moment } from "moment";

export type DateUnit = "day" | "week" | "month" | "year";// for moment date calculations

// Add Functions to the Date Class/Prototype
declare global {
  interface DateConstructor {
    minDate(): Date;
    maxDate(): Date;
    maxDate(): Date;
    today(): Date;
    yesterday(): Date;
    parseFormat(value: string, format: string): Date;
  }
  interface Date{
    add(quantity: number, unit: DateUnit): Date;
    subtract(quantity: number, unit: DateUnit): Date;
    startOf(unit: DateUnit): Date;
    endOf(unit: DateUnit): Date;
    format(format?: string): string;
    stripTime(): Date;
  }
}

Date.yesterday = (): Date => moment().add("day", -1).startOf("day").toDate();
Date.today = (): Date => moment().startOf("day").toDate();
Date.minDate = (): Date => moment.utc([1900, 0, 1]).toDate();
Date.maxDate = (): Date => moment.utc([2099, 11, 31]).toDate();
Date.parseFormat = (value: string, format: string): Date => moment(value, format).toDate();

Date.prototype.add = function(quantity: number, unit: DateUnit): Date {
  return moment(this).add(quantity as any, unit as any).toDate();
};
Date.prototype.subtract = function(quantity: number, unit: DateUnit): Date {
  return moment(this).subtract(quantity as any, unit as any).toDate();
};
Date.prototype.startOf = function(unit: DateUnit): Date {
  return moment(this).startOf(unit as any).toDate();
};
Date.prototype.endOf = function(unit: DateUnit): Date {
  return moment(this).endOf(unit as any).toDate();
};
Date.prototype.format = function(format: string = "y-MM-dd"): string {
  try {
    return formatDate(this, format, "en-ZA");
  } catch (e) {
    return null;
  }
};
// Reset Time information from a Date[Time] object
Date.prototype.stripTime = function(): Date {
  return moment(this).startOf("day").toDate();
};


export function isValidDate(date: any): boolean {
  return moment.isDate(date) && !isNaN(date.getTime());
}

// export function

export function convertTimeToDate(time: string | moment.Moment | Date | NgbTimeStruct, baseDate: Date, timeFormat = "HH:mm"): Date {
  let theDate: Date;
  if (!time) {
    theDate = null;
  } else if (typeof time === "string") {
    theDate = moment(moment(baseDate).format("YYYY-MM-DD")+" "+time, "YYYY-MM-DD "+timeFormat).toDate();// might need updates
  } else if (time instanceof Date) {
    theDate = moment(baseDate).set('hour', time.getHours()).set('minute', time.getMinutes()).set('seconds', time.getSeconds()).set('millisecond', 0).toDate();
  } else if (moment.isMoment(time)) {
    theDate = time.toDate();// might need updates
  } else {
    // Tested
    const current = moment(baseDate);
    current.set('hour', time.hour);
    current.set('minute', time.minute);
    current.set('seconds', time.second);
    current.set('millisecond', 0);
    theDate = moment(current).toDate();
  }
  return theDate;
}

// Date Utility Functions
export function convertToDate(date: string | moment.Moment | Date | NgbDateStruct): Date {
  let theDate: Date;
  if (!date) {
    theDate = null;
  } else if (typeof date === "string") {
    theDate = moment(date).toDate();
  } else if (date instanceof Date) {
    theDate = date;
  } else if (moment.isMoment(date)) {
    theDate = date.toDate();
  } else {
    theDate = moment([date.year, date.month - 1, date.day]).toDate();
  }
  return theDate;
}

export function convertToNgbDate(date: any): NgbDateStruct {
  let result: NgbDateStruct;
  if (!date) {
    result = null;
  } else if (moment.isMoment(date)) {
    result = { year: date.year(), month: date.month() + 1, day: date.date() };
  } else if (date instanceof Date) {
    result = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  } else if (typeof date === "string") {
    result = convertToNgbDate(moment(date));
  } else {
    result = date;
  }
  return result;
}

export function convertToNgbTime(date: any): NgbTimeStruct {
  let result: NgbTimeStruct;
  if (!date) {
    result = null;
  } else if (moment.isMoment(date)) {
    result = { hour: date.hour(), minute: date.minute(), second: date.second() };
  } else if (date instanceof Date) {
    result = { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };
  } else if (typeof date === "string") {
    result = convertToNgbTime(moment(date));
  } else {
    result = date;
  }
  return result;
}

export function alignNgbTimeToStep(time: NgbTimeStruct, step: number): NgbTimeStruct {
  const result = { ...time };
  result.minute = Math.floor(result.minute / step) * step;
  result.second = 0;
  return result;
}

/**
 * Get number of minutes (and remaining minutes,etc...) difference between two dates
 */
export function diffMS(startDate: Date, endDate: Date):
  { minutes: number, seconds: number } {
  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);

  const minutes = mStartDate.diff(mEndDate, 'minutes');
  mEndDate.add(minutes, 'minutes');

  const seconds = mStartDate.diff(mEndDate, 'seconds');
  // return { years, months, days, hours, minutes, seconds };
  return { minutes, seconds };
}

/**
 * Get number of Days (and remaining hours,etc..) diff between two dates
 */
export function diffDHMS(startDate: Date, endDate: Date):
  { days: number, hours: number, minutes: number, seconds: number} {
  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);

  const years = mStartDate.diff(mEndDate, 'year');
  // mEndDate.add(years, 'years');

  const months = mStartDate.diff(mEndDate, 'months');
  // mEndDate.add(months, 'months');

  const days = mStartDate.diff(mEndDate, 'days');
  mEndDate.add(days, 'days');

  const hours = mStartDate.diff(mEndDate, 'hours');
  mEndDate.add(hours, 'hours');

  const minutes = mStartDate.diff(mEndDate, 'minutes');
  mEndDate.add(minutes, 'minutes');

  const seconds = mStartDate.diff(mEndDate, 'seconds');

  // console.log(years + ' years ' + months + ' months ' + days + ' days ' + hours + ' hours ' + minutes + ' minutes ' + seconds + ' seconds');

  // return { years, months, days, hours, minutes, seconds };
  return { days, hours, minutes, seconds };
}

export function diffDate(startDate: Date, endDate: Date, unit: DateUnit): number {
  return moment(endDate).diff(moment(startDate), unit);
}

export function getTimeDiff(startTime: NgbTimeStruct, endTime: NgbTimeStruct): {hourDiff: number, minuteDiff: number} {
  const sd = moment.duration((startTime.hour*60)+startTime.minute, 'minutes');
  const ed = moment.duration((endTime.hour*60)+endTime.minute, 'minutes');
  const nd = sd.subtract(ed.minutes(), 'minutes').subtract(ed.hours(), 'hours');
  return {
    hourDiff: Math.abs(nd.hours()),
    minuteDiff: Math.abs(nd.minutes()),
  };
}

export function getFullDayName(days: number, includeWeekend = false){
  if(includeWeekend){
    return dayName(days, 'EEE', []);
  }
  return dayName(days, 'EEE');// defaults to excluding day [0,6] (weekend)
}

export function dayName(days: number, format: string = "EEE", ignoreDays = [6,0]): string {
  return addWorkingDays(Date.today(), days, ignoreDays).format(format);
}
/*
export function today(): Moment {
  return moment().startOf("day");
}
*/
export function sameDate(a: Date, b: Date): boolean {
  return moment(a).startOf("d").isSame(moment(b).startOf("d"));
}
export function sameMonth(a: Date, b: Date): boolean {
  return moment(a).startOf("M").isSame(moment(b).startOf("M"));
}

export function addWorkingDays(date: Date, days: number = 1, ignoreDays = [6,0]): Date {
  const inc = days > 0 ? 1 : -1;
  let next = date;
  while (days) {
    next = next.add(inc, "day");
    // ignore any amount of days
    while (ignoreDays.includes(next.getDay())) {
      next = next.add(inc, "day");
    }
    days -= inc;
  }

  return next;
}

@Pipe({
  name: "date"
})
export class DateExPipe extends DatePipe implements PipeTransform {
  constructor() {
    super("en-ZA");
  }

  transform(value: Moment | Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Moment | Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null
  {
    // Min Dates aren't displayed in pipes
    return moment(value).toDate() <= Date.minDate() ? '' : super.transform(value as any, format || "y-MM-dd", locale);
  }
}

export {};

export function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, "0");
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, "0");
  const direction = timezoneOffset > 0 ? "-" : "+";

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}

  /**
   * Show Time difference between two dates
   * @param first
   * @param second
   * @returns string
   */
   export function getTimeDifference(first: Date, second: Date): string {
     if(!first || !second) return null;
    const timeDiff = getTimeDiff(
      convertToNgbTime(second),
      convertToNgbTime(first)
    );
    const mString = moment(0)
      .utc()
      .minutes(timeDiff.hourDiff * 60 + timeDiff.minuteDiff)
      .format("H:mm");
    return `${moment(first).isAfter(second) ? "-" : ""}${mString}`;
  }


export const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
export function convertObjectToDate(body) {
  if (body === null || body === undefined || !['array', 'object'].includes(typeof body)) {
    return body;
  }

  // if(Array.isArray(body)) {
  //   for (const item of body) {
  //     convertToDate(item);
  //   }
  // }
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIso8601(value)) {
      body[key] = new Date(value);
    } else if (typeof value === "object") {
      convertToDate(value);
    }
  }
}

  export function isIso8601(value) {
    return value !== null && value !== undefined && iso8601.test(value);
  }

/**
 * https://stackoverflow.com/questions/16229494/converting-excel-date-serial-number-to-date-using-javascript
 * @param xlSerial
 * @returns Date
 */
export function xlSerialToJsDate(xlSerial){
  // milliseconds since 1899-12-31T00:00:00Z, corresponds to Excel serial 0.
  const xlSerialOffset = -2209075200000;

  let elapsedDays;
  // each serial up to 60 corresponds to a valid calendar date.
  // serial 60 is 1900-02-29. This date does not exist on the calendar.
  // we choose to interpret serial 60 (as well as 61) both as 1900-03-01
  // so, if the serial is 61 or over, we have to subtract 1.
  if (xlSerial < 61) {
    elapsedDays = xlSerial;
  }
  else {
    elapsedDays = xlSerial - 1;
  }

  // javascript dates ignore leap seconds
  // each day corresponds to a fixed number of milliseconds:
  // 24 hrs * 60 mins * 60 s * 1000 ms
  const millisPerDay = 86400000;

  const jsTimestamp = xlSerialOffset + elapsedDays * millisPerDay;
  return new Date(jsTimestamp);
}

export enum DateRangePosition {
  Name = 0,
  Range = 1,
  Start = 2,
  End = 3,
}
export const DATE_RANGES: [string, number, () => Date, () => Date, string][] = [
  [
    "Report.ChooseDates",
    0,
    () => Date.today().add(-30, "day"),
    () => Date.today(),
    "last30days",
  ],
  [
    "Report.Today",
    1,
    () => Date.today(),
    () => Date.today(),
    "today"
  ],
  [
    "Report.ThisWeek",
    2,
    () => Date.today().startOf("week"),
    () => Date.today(),
    "thisweek",
  ],
  [
    "Report.ThisMonth",
    3,
    () => Date.today().startOf("month"),
    () => Date.today(),
    "thismonth",
  ],
  [
    "Report.ThisYear",
    4,
    () => Date.today().startOf("year"),
    () => Date.today(),
    "thisyear",
  ],
  [
    "Report.WithinWeek",
    -2,
    () => Date.today().add(-6, "day"),
    () => Date.today(),
    "inweek",
  ],
  [
    "Report.WithinMonth",
    -3,
    () => Date.today().add(-1, "month"),
    () => Date.today(),
    "inmonth",
  ],
  [
    "Report.WithinYear",
    -4,
    () => Date.today().add(-1, "year"),
    () => Date.today(),
    "inyear",
  ],
  [
    "Report.Yesterday",
    -11,
    () => Date.today().add(-1, "day"),
    () => Date.today().add(-1, "day"),
    "yesterday",
  ],
  [
    "Report.LastWeek",
    -12,
    () => Date.today().startOf("week").add(-1, "week"),
    () => Date.today().startOf("week").add(-1, "day"),
    "lastweek",
  ],
  [
    "Report.LastMonth",
    -13,
    () => Date.today().startOf("month").add(-1, "month"),
    () => Date.today().startOf("month").add(-1, "day"),
    "lastmonth",
  ],
  [
    "Report.LastYear",
    -14,
    () => Date.today().startOf("year").add(-1, "year"),
    () => Date.today().startOf("year").add(-1, "day"),
    "lastyear",
  ],
];

export function dateSort(a: Date, b: Date): number {
  return b.getTime()-a.getTime();
}

export function combineDateAndTime(date: Date, time: any): Date {
  // console.log('combineDateAndTime', date, time);
  return convertTimeToDate(time, date);
  // return moment(date).set({hours: ngbTime.hour, minutes: ngbTime.minute, seconds: ngbTime.second}).toDate();
}
