import { StockBinDto } from "./StockMasterDto";

export interface StockWarehouseDto {
  warehouseId: string;
  quantityBalance: number;
  lastModifiedDate: Date;
  binNumber: string;
  demand: number;
  onOrder: number;

  // stock take (new)
  defaultBin?: string;
  multipleBins?: boolean;
  cycleCount?: boolean;
  bins?: StockBinDto[];

}


export function newStockWarehouse(): StockWarehouseDto {
  return {
    warehouseId: "",
    quantityBalance: 0,
    lastModifiedDate: new Date(),
    binNumber: "",
    demand: 0,
    onOrder: 0,
  };
}
