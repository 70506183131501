import { Injectable } from "@angular/core";
import { FullMenuDto, LookupObjectDto, MenuStatus, RouteInfo, WebMenuDto } from "@shared/models";
import { UUID } from "angular2-uuid";
import { Observable } from "rxjs";
import { NotificationService } from "@core/services";
import { map } from "rxjs/operators";
import { DataService, ServiceConfig } from "./data.service";
import { LookupListService } from "./lookup-list.service";
import { ProductSettingService } from "./product-setting.service";

export type MenuType = "dash" | "nav" | "master" | "template";
export const MENU_TYPE = {
  DASH: "dash",
  NAV: "nav",
  MASTER: "master",
  TEMPLATE: "template"
};


export interface MasterMenuDto extends LookupObjectDto{
  url: string[];
  icon: string;
}

export class MasterMenu {
  constructor(public code: string, public description: string, public active: boolean, public order: number = 0, public url: string[] = [], public icon: string = "") {

  }

  getWebMenuDto(): WebMenuDto {
    return {
      description: this.description,
      menuId: this.code,
      parentId: "",
      icon: this.icon,
      status: this.active && MenuStatus.ACTIVE || MenuStatus.INACTIVE,
      url: this.url,
      children: [],
      displaySequence: this.order,
      // lastModifiedDate: Date.now(),
      roles: []
    }
  }

  toJSON(): MasterMenuDto {
    return {
      active: this.active,
      code: this.code,
      description: this.description,
      order: this.order,
      url: this.url,
      icon: this.icon
    }
  }

}
@Injectable({
  providedIn: "root",
})
export class MenuService extends DataService {
  constructor(
    config: ServiceConfig,
    private productSetting: ProductSettingService,
    private lookups: LookupListService,
    private notificationService: NotificationService
  ) {
    super(config);
  }

  /**
   * Old Menu API
   */
  getMenu(): Observable<WebMenuDto[]> {
    return this.http.get<WebMenuDto[]>("values/menu");
  }

  updateMenu(menu: WebMenuDto): Observable<WebMenuDto> {
    return this.http.put<WebMenuDto>("values/menu", menu);
  }

  /**
   * New Full Menu API
   */
  getFullMenu(): Observable<FullMenuDto[]> {
    return this.http.get<FullMenuDto[]>("values/fullmenu");
  }

  updateFullMenu(menu: FullMenuDto): Observable<FullMenuDto> {
    return this.http.put<FullMenuDto>("values/fullmenu", menu);
  }

  menuFactory(
    menuId: string = "",
    description: string = "",
    status: MenuStatus = MenuStatus.ACTIVE,
    parentId: string = "",
    icon: string = "",
    url: string[] = [],
  ): WebMenuDto {
    return {
      menuId: menuId || UUID.UUID(),
      description,
      status,
      parentId,
      icon,
      url,
    };
  }

  fullMenuFactory(
    menuId: string = "",
    description: string = "",
    status: MenuStatus = MenuStatus.ACTIVE,
    icon: string = "",
    url: string[] = [],
    children: FullMenuDto[] = []
  ): FullMenuDto {
    return {
      menuId: menuId || UUID.UUID(),
      description,
      status,
      icon,
      url,
      children,
    };
  }

  // MENU ADMIN
  /**
   * new branch = feature/menuAdmin
    values/list/WebMenuMaster - all possible screens in the system - we need to add new items to this as we build new screens
    values/fullmenu/admin / {dash | nav} for all menuitems on dash or nav
    put/{menuId} [WebMenuDto] to UPDATE
    post to create [WebMenuDto]
   */
  getMasterMenuList(): Observable<MasterMenuDto[]> {
    return this.http.get<MasterMenuDto[]>("values/list/WebMenuMaster");
  }
  creatMasterMenuItem(item: MasterMenuDto): Observable<MasterMenuDto> {
    return this.http.post<MasterMenuDto>("values/item/WebMenuMaster", item)
  }
  updateMasterMenuItem(item: MasterMenuDto){
    return this.http.put<MasterMenuDto>(`values/item/WebMenuMaster/${item.code}`, item)
  }
  deleteMasterMenuItem(menuId: string){
    return this.http.delete<MasterMenuDto>(`values/item/WebMenuMaster/${menuId}`);
  }
  masterWebMenu(): Observable<WebMenuDto[]> {
    return this.http.get<MasterMenuDto[]>("values/list/WebMenuMaster").pipe(map(lookupList => {
      return lookupList.map(item => new MasterMenu(item.code, item.description, item.active, item.order, item.url, item.icon).getWebMenuDto())
    }))
  }


  // TEMPLATES
  templatesList(){
    return this.http.get("values/list/menutemplate");
  }
  getTemplateMenu(templateId: string){
    return this.http.get<WebMenuDto[]>(`values/menu/template/${templateId}`);
  }
  createTemplateMenuItem(templateId: string, menu: WebMenuDto){
    return this.http.post<WebMenuDto>(`values/menu/template/${templateId}`, menu);
  }
  updateTemplateMenuItem(templateId: string, menu: WebMenuDto){
    return this.http.put<WebMenuDto>(`values/menu/template/${templateId}/${menu.menuId}`, menu);
  }
  deleteTemplateMenuItem(templateId: string, menuId: string){
    return this.http.delete<WebMenuDto>(`values/menu/template/${templateId}/${menuId}`);
  }

  getAdminMenu(type: MenuType): Observable<WebMenuDto[]> {
    return this.http.get<WebMenuDto[]>(`values/menu/admin`).pipe(map(webMenu => webMenu.filter(item => type === 'dash' ? !item.parentId : !!item.parentId)));
  }


  // FOR DASH AND NAV TYPES (Scoped to Tenant)
  // Note that the 'Parent Id' will determine if it fits into DASH or NAV types
  updateMenuItem(menu: WebMenuDto): Observable<WebMenuDto> {
    return this.http.put<WebMenuDto>(`values/menu/${menu.menuId}`, menu);
  }
  // Note that the 'Parent Id' will determine if it fits into DASH or NAV types
  createMenuItem(menu: WebMenuDto): Observable<WebMenuDto> {
    return this.http.post<WebMenuDto>(`values/menu`, menu);
  }
  deleteMenuItem(menuId: string): Observable<any> {
    return this.http.delete(`values/menu/${menuId}`);
  }


  // FOR NORMAL MENU
  convertFullMenuToRoutes(fullmenu: FullMenuDto[], tenant: string) {
    return fullmenu
    .map((childItem) =>  new RouteInfo(
        childItem.description,
        childItem.url,
        childItem.icon,
        "",// @todo: remove parent if for route info
        childItem.menuId,
        this.convertFullMenuToRoutes(
          childItem.children || [],
          tenant
        ),
        tenant
      )
    );
  }

  buildFullMenutree(fullmenu: FullMenuDto[], tenant): RouteInfo[] {
    return this.convertFullMenuToRoutes(fullmenu, tenant);
  }

  flattenMenu(menu: WebMenuDto[]): WebMenuDto[] {
    return menu.reduce((acc, item) => {
      acc.push(item);
      if (item.children) {
        acc.push(...this.flattenMenu(item.children));
      }
      return acc;
    }, []);
  }


  // FOR ADMIN MENU
  buildAdminMenutree(fullmenu: WebMenuDto[], tenant): RouteInfo[] {
    return this.convertAdminMenuToRoutes(fullmenu, tenant);
  }

  convertAdminMenuToRoutes(fullmenu: WebMenuDto[], tenant: string): RouteInfo[] {
    return fullmenu
    .map((childItem) =>  new RouteInfo(
        childItem.description,
        childItem.url,
        childItem.icon,
        childItem.parentId,
        childItem.menuId,
        this.convertAdminMenuToRoutes(
          childItem.children || [],
          tenant
        ),
        tenant,
        childItem.description,
        +childItem.displaySequence,
        childItem.roles
      )
    ).sort((a, b) => a.displaySequence - b.displaySequence);
  }

}


// /**
//  * Checklist database, it can build a tree structured Json object.
//  * Each node in Json object represents a to-do item or a category.
//  * If a node is a category, it has children items and new items can be added under the category.
//  */
// @Injectable()
// export class ChecklistDatabase {
//   dataChange = new BehaviorSubject<TodoItemNode[]>([]);

//   get data(): TodoItemNode[] {
//     return this.dataChange.value;
//   }

//   constructor() {
//     this.initialize();
//   }

//   initialize() {
//     // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
//     //     file node as children.
//     const data = this.buildFileTree(TREE_DATA, 0);

//     // Notify the change.
//     this.dataChange.next(data);
//   }

//   /**
//    * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
//    * The return value is the list of `TodoItemNode`.
//    */
//   buildFileTree(obj: {[key: string]: any}, level: number): TodoItemNode[] {
//     return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
//       const value = obj[key];
//       const node = new TodoItemNode();
//       node.item = key;

//       if (value != null) {
//         if (typeof value === 'object') {
//           node.children = this.buildFileTree(value, level + 1);
//         } else {
//           node.item = value;
//         }
//       }

//       return accumulator.concat(node);
//     }, []);
//   }

//   /** Add an item to to-do list */
//   insertItem(parent: TodoItemNode, name: string) {
//     if (parent.children) {
//       parent.children.push({item: name} as TodoItemNode);
//       this.dataChange.next(this.data);
//     }
//   }

//   updateItem(node: TodoItemNode, name: string) {
//     node.item = name;
//     this.dataChange.next(this.data);
//   }
// }
