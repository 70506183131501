<button [disabled]="loading || buttonDisabled" type="button" [class]="btnClass" (click)="openModal()">
    <i [class]="faClass"></i><ng-content></ng-content>
    <mat-progress-spinner *ngIf="loading"
        class=""
        [diameter]="24"
        [strokeWidth]="4"
        mode="indeterminate"
    ></mat-progress-spinner>
</button>
<ng-template #chooseFileModal>
    <div class="modal-header">
      <h4 class="modal-title">{{ modalTitle | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <ng-content select="[extraFields]"></ng-content>
        <abi-capture
            [showDescription]="showDescription"
            [maxLength]="maxLength"
            [fileOnly]="fileOnly"
            [imageOnly]="imageOnly"
            [textPlaceholder]="descriptionPlaceholder"
            [requireDescription]="descriptionRequired"
            (fileSelected)="fileSelected($event)"
            [defaultDescription]="defaultDescription"
            [descriptionEditable]="descriptionEditable"
            [validUploadTypes]="validUploadTypes"
            [disabled]="captureDisabled"
            ></abi-capture>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{ 'System.Cancel' | translate }}</button>
    </div>
  </ng-template>
