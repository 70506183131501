import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Component({
  selector: "abi-group-status",
  templateUrl: "./page-status.component.html",
  styleUrls: ["./page-status.component.scss"]
})
export class PageStatusComponent implements OnInit {
  @Input() name = "";
  @Input() show = true;
  @Input() group: AbstractControl;
  @Input() extras: AbstractControl[] = [];

  public colour = "";
  public icon = "";

  getBadge(validity: string): string {
    switch (validity) {
      case "VALID":
        return "badge-success";
      case "INVALID":
        return "badge-danger";
      case "DISABLED":
        return "badge-warning";
    }
    return "";
  }

  getIcon(validity: string): string {
    switch (validity) {
      case "VALID":
        return "fas fa-check fa-fw";
      case "INVALID":
        return "fas fa-times fa-fw";
      case "DISABLED":
        return "fas fa-minus fa-fw";
    }
    return "";
  }

  ngOnInit(): void {
    this.colour = this.getBadge(this.group.status );
    this.icon = this.getIcon(this.group.status );
    this.group.statusChanges.subscribe(validity => {
      setTimeout(() => {
        this.colour = this.getBadge(validity);
        this.icon = this.getIcon(validity);
        }, 1);
    });
  }
}
