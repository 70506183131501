import { Injectable } from "@angular/core";
import { AttachmentService, BaseAttachmentDto, TipOfTheDayDto } from "@shared/models";
import { BehaviorSubject, Observable, zip } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import moment from "moment";
import { DataService, ServiceConfig } from "../";
import { LoginStatus } from "../app.store";
import { Progress } from "./data.service";

@Injectable({
  providedIn: "root"
})
export class TipOfTheDayService extends DataService implements AttachmentService {
  tips: BehaviorSubject<TipOfTheDayDto[]> = new BehaviorSubject([]);
  tip: TipOfTheDayDto;

  constructor(config: ServiceConfig) {
    super(config);

    this.appQuery.menu$.pipe(tap(s => this.tips.next([])), filter(l => !!l.length),
      switchMap(() => this.getTips()), this.notDisposed()).subscribe(tips => {
        this.tips.next(tips);
        if (tips && tips.length) {
          this.tip = tips[0];
        }
      });
  }

  uploadAttachment(parentId: string, file: File, description: string, progress: Progress, generateCheckDigit = false, extras?: any, typeId?: string): Observable<TipOfTheDayDto> {
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    return this.http.post<TipOfTheDayDto>(`values/tip`, formData, {reportProgress: true, observe: "events"}
    ).pipe(this.uploading(progress));
  }

  downloadAttachment(parentId: string, attachmentId: string): Observable<Blob> {
    return this.http.get(`values/tip/${attachmentId}`, { responseType: "blob" });
  }

  deleteAttachment(parentId: string, attachmentId: string): Observable<any> {
    return this.http.delete(`values/tip/${attachmentId}`);
  }

  downloadLink(attachment: TipOfTheDayDto, parentId?: string): string {
    if (parentId) {
      parentId += "/";
    }
    return `values/tip/${attachment.attachmentId}`;
  }

  getTips(): Observable<TipOfTheDayDto[]> {
    return this.http.get<TipOfTheDayDto[]>("values/tip");
  }

  updateTip(tipId: string, newStatus: string): Observable<string> {
    return this.http.put(`values/tip/${tipId}/${newStatus}`, null).pipe(
      map((s: any) => {
        this.tips.value.find(t => t.attachmentId === tipId).status = newStatus;
        return s.status;
      }));
  }

  fullUrl(attachment: BaseAttachmentDto, parentId?: string, extras?: any): string {
    throw new Error("Method not implemented.");
  }
}
