export interface SearchDto {
  pageNumber: number; // = 1;
  pageSize: number; // = 100;
  orderBy: string[];
  filters: { [key: string]: boolean | number | string | string[] }; // = {};
}

export function newSearchDto(): SearchDto {
  return {
    pageNumber: 1,
    pageSize: 100,
    orderBy: [],
    filters: {}
  };
}
