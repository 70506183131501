import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { LookupList } from "@shared/models";
import { LookupListService } from "../services/lookup-list.service";

/**
 * @deprecated use 'lookup-display.directive' instead: set the 'display' input props for name, code or all value display options
 */
@Directive({ selector: "[abiLookupdisplayValue]" })
export class LookupDisplayValueDirective implements OnInit, OnChanges {
  @Input() public listName: string;
  @Input() public empty = "";
  @Input() public targetElement: "textContent" | "value" = "textContent";
  @Input("abiLookupdisplayValue") public value: string;
  private lookupList: LookupList;

  constructor(private el: ElementRef, private lookups: LookupListService) {
  }

  ngOnInit() {
    this.lookups.lookupList(this.listName).subscribe(list => {
      this.lookupList = list;
      this.lookupValue();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listName && changes.listName.currentValue !== changes.listName.previousValue) {
      this.ngOnInit();
    } else {
      this.lookupValue();
    }
  }

  private lookupValue() {
    const text = (this.lookupList ? this.lookupList.displayValue(this.value) : this.value) || this.value || this.empty;
    this.el.nativeElement[this.targetElement] = text;
  }
}
