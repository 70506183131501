import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'abi-tooltip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss']
})
export class ToolTipComponent implements OnInit, OnChanges {
  @Input() class = "badge badge-pill badge-primary";
  @Input() tooltipClass = "";
  @Input() content = "?";
  @Input() tooltip: string | TemplateRef<any> = null;
  @Input() placement: "top" | "buttom" | "left" | "right" = "top";
  @Input() noTranslate: false;
  displayTooltip: string | TemplateRef<any> = null;

  constructor(private translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tooltip?.currentValue){
      this.processTooltip();
    }
  }

  ngOnInit(): void {
    if (this.tooltip) {
      this.processTooltip();
    }
  }

  processTooltip(){
    if (this.noTranslate || this.tooltip instanceof TemplateRef) {
      this.displayTooltip = this.tooltip;
      return;
    }
    this.translate
      .get(this.tooltip)
      .subscribe((translatedTip: string) => {
        this.displayTooltip = translatedTip === this.tooltip ? "" : translatedTip;
        // ensure tooltip remains blank if no valid value exists
      });
  }

}
