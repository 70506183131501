<form [formGroup]="form">
    <div class="form-group row d-flex align-items-sm-center">

        <div class="col-sm-1 offset-sm-2">

    <input type="radio" class="mr-sm-2" formControlName="mode"
        aria-label="Repeat monthly on" value="on" checked (change)="radioChange($event)" >

        </div>
        <div class="col-sm-1">
            {{ "RRule.OnDay" | translate }}
        </div>

        <div class="col-sm-2" formGroupName="on">
            <select aria-label="Repeat monthly on a day" formControlName="day" [attr.disabled]="form.value.mode !== 'on'? '' : null"
                    class="form-control mb-2 mr-sm-2">
                <option *ngFor="let val of range(1, 32)" [value]="val">{{val}}</option>
            </select>
        </div>

    </div>


    <div class="form-group row d-flex align-items-sm-center">
        <div class="col-sm-1 offset-sm-2">

            <input type="radio" class="mb-2 mr-sm-2" formControlName="mode"
                    aria-label="Repeat monthly on" value="on the" checked (change)="radioChange($event)" >
        </div>
        <div class="col-sm-1">
            {{ "RRule.OnThe" | translate }}
        </div>

        <ng-container formGroupName="onThe">

            <div class="col-sm-2">
                <select formControlName="which"
                    aria-label="Repeat monthly on the which" class="form-control mb-2 mr-sm-2" [attr.disabled]="form.value.mode === 'on'? '' : null">
                    <option value="First">First</option>
                    <option value="Second">Second</option>
                    <option value="Third">Third</option>
                    <option value="Fourth">Fourth</option>
                    <option value="Last">Last</option>
                    </select>
            </div>

            <div class="col-sm-2" >

                <select formControlName="day" aria-label="Repeat monthly on the day" class="form-control mb-2 mr-sm-2"  [attr.disabled]="form.value.mode === 'on'? '' : null">
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                    <option value="Day">Day</option>
                    <option value="Weekday">Weekday</option>
                    <option value="Weekend day">Weekend day</option>
                </select>
            </div>

        </ng-container>

      </div>

</form>


