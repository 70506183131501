import { Pipe, PipeTransform } from "@angular/core";
import { sameDate } from "@modules/common";
import { ContractDto, fullName } from "@modules/models";
import { CustomerLookupDto } from "@services";
import { CustomerAddressDto } from "./CustomerAddressDto";
import { CustomerBankDto } from "./CustomerBankDto";
import { CustomerContactDto } from "./CustomerContactDto";
import { CustomerVisitDto } from "./CustomerVisitDto";
import { CustomFieldDto } from "./CustomFieldDto";
import { MachineMasterDto } from "./MachineMasterDto";
import { ModelMasterDto } from "./ModelMasterDto";

type CustomerStatus = "" | "I" | "A";

export interface CustomerMasterDto {
  id: string;
  statusId: CustomerStatus;
  categoryId: string;
  name: string;// company alias?
  registrationNumber: string;
  vatNumber: string;
  ourReference: string;
  note: string;
  companyName: string;
  contacts: CustomerContactDto[];
  addresses: CustomerAddressDto[];
  machines: MachineMasterDto[];
  models: ModelMasterDto[];
  visits: CustomerVisitDto[];
  bankAccounts: CustomerBankDto[];
  inactiveContracts?: boolean;
  overdueContracts?: number;
  contracts?: boolean;
  extra?: {[key: string]: CustomFieldDto};
  consent: string;
  priceList?: string;
}

export function primaryContact(self: CustomerMasterDto): CustomerContactDto {
  return self.contacts?.length ? (self.contacts.find(c => c.primary) || self.contacts[0]) : null;
}

export function primaryAddress(self: CustomerMasterDto | CustomerLookupDto): CustomerAddressDto {
  return self?.addresses?.length ? (self.addresses?.find(c => c.primary) || self.addresses[0]) : null;
}

export function visit(self: CustomerMasterDto, date: Date): CustomerVisitDto {
  return self.visits.find(v => sameDate(date, v.bookedDate));
}

export function newCustomerMaster(): CustomerMasterDto {
  return {
    id: "",
    statusId: "",
    categoryId: "",
    name: "",
    registrationNumber: "",
    vatNumber: "",
    ourReference: "",
    note: "",
    companyName: "",
    contacts: [],
    addresses: [],
    machines: [],
    models: [],
    visits: [],
    bankAccounts: [],
    inactiveContracts: false,
    consent: ""
  };
}

function customerStatus(statusId: "" | "I" | "A") {
  switch(statusId){
    case "":
      return "";
    default:
      return `Customer.Status_${statusId}`;
  }
}

@Pipe({
  name: "customer",
  pure: true
})
export class CustomerMasterPipe implements PipeTransform {
  transform(customer: CustomerMasterDto, format?: string): CustomerAddressDto | CustomerContactDto | string {
    switch (format) {
      case "mobile":
        return primaryContact(customer)?.mobile || '';
      case "fullName":
        return fullName(primaryContact(customer)) || '';
      case "primaryAddress":
        return primaryAddress(customer);
      case "primaryContact":
        return primaryContact(customer);
      case "status":
        return customerStatus(customer.statusId);
    }
    return null;
  }
}
@Pipe({
  name: "customerStatus",
  pure: true
})
export class CustomerStatusPipe implements PipeTransform {
  transform(customerStatusId: CustomerStatus, format?: string): string {
    switch (format) {
      case "status":
      default:
        return customerStatus(customerStatusId);
    }
  }
}
