import { Component, OnInit, Optional } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { LookupListService } from "@modules/common/services/lookup-list.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CodeBankDto, codeOnly, CustomerBankDto, LookupList, LookupObjectDto, trim } from "@shared/models";
import { ProductSettingService, WebLayoutService } from "../../services";
import { BaseModal } from "../base-modal";

@Component({
  selector: "abi-bank",
  templateUrl: "./bank.component.html",
})
export class BankComponent extends BaseModal<CustomerBankDto> implements OnInit {
  static validationMessages = {
  };

  titles: LookupObjectDto[];

  branches: { [bank: string]: LookupList } = {};

  private updateName: (newName: string) => void;

  static optionalEmail(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      return Validators.email(control);
    }
    return null;
  }

  public static createFormGroup(fb: FormBuilder) {
    return fb.group({
      accountId: "",
      description: "",
      bank: "",
      branchCode: "",
      accountNumber: "",
      accountHolder: "",
      accountType: "",
      status: "",
    });
  }

  static setFormData(model: CustomerBankDto, group: FormGroup) {
    group.reset(model);
  }

  static getFormData(account: CustomerBankDto, formGroup: FormGroup) {
    const value = formGroup.value;
    account.accountId = value.accountId;
    account.description = value.description;
    account.bank = codeOnly(value.bank);
    account.branchCode = codeOnly(value.branchCode);
    account.accountNumber = value.accountNumber;
    account.accountHolder = value.accountHolder;
    account.accountType = codeOnly(value.accountType);
    account.status = codeOnly(value.status);
  }

  constructor(
    layoutService: WebLayoutService,
    public productSettings: ProductSettingService,
    public lookup: LookupListService,
    @Optional() activeModal?: NgbActiveModal
  ) {
    super(layoutService, activeModal);

    this.lookup.lookupListEx<CodeBankDto>("CodeBank").subscribe(lst => {
      for (const bank of lst.values) {
        const branches: LookupObjectDto[] = Object.keys(bank.branches).map(b => ({
          code: b,
          description: bank.branches[b],
          order: 0,
          active: true
        }));
        this.branches[bank.code] = new LookupList(branches);
      }
    });
  }

  getBranches(): LookupList {
    const bank = codeOnly(this.group.getRawValue().bank);
    return this.branches[bank];
  }

  setFormData(model: CustomerBankDto, group: FormGroup, updateName: (newName: string) => void = null) {
    this.updateName = updateName;
    BankComponent.setFormData(model, group);
    if (updateName) {
      this.group.valueChanges.pipe(this.notDisposed()).subscribe(c => {
        if (c.bank || c.accountNumber) {
          this.updateName(`${c.bank || ""} ${(c.account || "").slice(-4)}`);
        } else {
          this.updateName("New Account");
        }
      });
    }
  }

  getFormData(bank: CustomerBankDto, group: FormGroup) {
    BankComponent.getFormData(bank, group);
  }

  ngOnInit() {
    /*    this.productSettings
          .lookupList("Title")
          .then(l => (this.titles = l.values));
    */
  }
}
