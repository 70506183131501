import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";

@Component({
  selector: "abi-back-button",
  template: `<button type="button" class="btn btn-link text-muted text-decoration-none" (click)="back()">
    <i class="fas fa-arrow-left"></i></button>`,
  styleUrls: ['./back-button.component.scss'],
})

export class BackButtonComponent {
  @Input() alternativeBack: () => void;
  @Input() pendingChanges: boolean = false;

  constructor(private location: Location, private modal: NgbModal) { }

  back() {
    if(this.pendingChanges) {
      const modal = this.modal.open(CustomDialogComponent, { size: "lg", centered: true, backdrop: "static", keyboard: false, windowClass: "custom-dialog" });
      const instance = modal.componentInstance as CustomDialogComponent;
      instance.title = "Unsaved Changes";
      instance.body = "You have unsaved changes. Are you sure you want to leave this page?";
      instance.buttons = [{
        label: "Leave",
        class: "btn btn-primary",
        action: (modeRef) => {modeRef.close(true)}
      }, {
        label: "Stay",
        class: "btn btn-secondary",
        action: (modRef) => {modRef.close(false)}
      }]
      // instance.message = "You have unsaved changes. Are you sure you want to leave this page?";
      // instance.confirmText = "Yes";
      modal.result.then(res => {
        if(res)
          this.navigate();
      });
    } else {
      this.navigate();
    }
  }

  navigate() {
    if(this.alternativeBack)
      this.alternativeBack();
    else
      this.location.back();
  }
}
