import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JobFollowUpDto } from "@app/shared/models/jobs/JobFollowUpDto";
import {
  AttachmentService, BaseAttachmentDto,
  generateCheckDigit,
  InvoiceDto, JobDto, JobSearchDto, JobSummaryDto, JobValidationDto,
  JobVisitDto,
  ListResultDto, LookupObjectDto,
  MachineCategoryDto, MessageTemplateDto, ModelMasterDto, newContact, newCustomerAddress,
  newCustomerContact, newMachineMaster, ResourceScheduleDto, ScheduleJobDto, SearchDto,
  StockPriceResultDto, UpdateJobDto, UpdateJobResultDto
} from "@shared/models";
import { JobMessageDto } from "@shared/models/jobs/JobMessageDto";
import { UUID } from "angular2-uuid";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { NotificationService } from "@core/services";
import moment from "moment";
import { TableColumn } from "../components";
import { DataService, Progress, ServiceConfig } from "./data.service";
import { LookupListService } from "./lookup-list.service";
import { ProductSettingService } from "./product-setting.service";


export const jobSearchColumns: TableColumn[] = [
  { field: "jobId", header: "Job.JobID", class: "w-25" },
  { field: "statusId", header: "Job.Status", class: "" },
  { field: "modelId", header: "Job.Model", class: "" },
  { field: "loggedDate", header: "Job.Logged", class: "", pipe: [new DatePipe("en-ZA"), "MMM dd"]},
  { field: "closed", header: "Job.Closed", class: ""}
];

export type CodeListName = "CodeJobFault" | "CodeJobDiagnostic" | "CodeJobClose" | "CodeJobComponent" | "CodeJobStatus" | "CodeJobType" | "StockMaster";

@Injectable({
  providedIn: "root"
})
export class JobService extends DataService implements AttachmentService {
  allowedCloseCodes: string[];
  allowedComponentCodes: string[];
  allowedDiagnosticCodes: string[];
  allowedFaultCodes: string[];

  private  machineCategories: MachineCategoryDto[];

  constructor(config: ServiceConfig, private productSetting: ProductSettingService, private lookups: LookupListService, private notificationService: NotificationService) {
    super(config);
  }

  public loadCategories(): Observable<void> {
    return this.lookups.lookupListEx<MachineCategoryDto>("CodeMachineCategory").pipe(map(cats => {
      this.machineCategories = cats.values;
    }));
  }

  /**
   * Example Config:
   * [CODE LIST]: [FILTER WITH VALUE FROM LIST]
   * CodeJobDiagnostic: "CodeJobClose",
   * CodeJobFault: "CodeJobDiagnostic",
   *
   * Translated means: The Close code depends on the previous Diagnostic code, and the Diagnostic code depends on the Fault code
   * etc..
   * and into API calls:
   * values/list/CodeJobFault/{modelid}
   * values/list/CodeJobClose/{diagnostic}
   *
   * So when generating a filter/function - we check which filter we have configuered with which dependency
   * Note that the API will be doing the Filtering for these Codes
   */
  codeGroupConfig: Record<string, any>;
  public loadCodesConfig(): Observable<void> {
    return this.productSetting.getValues("CodeGroup").pipe(map(conf => {
      this.codeGroupConfig = conf;
    }));
  }

  /**
   * The allowed codes are used when Fitering the codes in the UI
   * since they are linked to the Mode's categories - each time the model changes, we need to update the allowed codes
   */
  public updateAllowedCodes(model: ModelMasterDto): boolean {
    if (!model || !this.machineCategories) return false;

    // Filter the categories to only those that are in the model
    const filtered = this.machineCategories.filter(c => model.categories.includes(c.code));
    // Allowed Close Codes derived from the model's categories
    this.allowedCloseCodes = filtered.filter(c => c.close?.length).flatMap(c => c.close);
    // Allowed Component Codes derived from the model's categories
    this.allowedComponentCodes = filtered.filter(c => c.component?.length).flatMap(c => c.component);
    // Allowed Diagnostic Codes derived from the model's categories
    this.allowedDiagnosticCodes = filtered.filter(c => c.diagnostic?.length).flatMap(c => c.diagnostic);
    // Allowed Fault Codes derived from the model's categories
    this.allowedFaultCodes = filtered.filter(c => c.fault?.length).flatMap(c => c.fault);
    return true;
  }

  filterCloseCodes(code: LookupObjectDto) {
    return !this.allowedCloseCodes?.length || this.allowedCloseCodes.includes(code.code) || code.code === "";
  }

  filterComponentCodes(code: LookupObjectDto) {
    return !this.allowedComponentCodes?.length || this.allowedComponentCodes.includes(code.code) || code.code === "";
  }

  filterDiagnosticCodes(code: LookupObjectDto) {
    return !this.allowedDiagnosticCodes?.length || this.allowedDiagnosticCodes.includes(code.code) || code.code === "";
  }

  filterFaultCodes(code: LookupObjectDto) {
    return !this.allowedFaultCodes?.length || this.allowedFaultCodes.includes(code.code) || code.code === "";
  }

  queryDiagnosticCodes(query: string): Observable<LookupObjectDto[]> {
    return this.http.get<LookupObjectDto[]>(`list/diagnosticCode/?query=${query}`);
  }

  getCustomerJobs(customerId: string): Observable<JobSearchDto[]> {
    return this.http.get<JobSearchDto[]>(`jobs/searchByCustomer/${this.safeEncode(customerId)}`);
  }

  getMachineJobs(machineId: string): Observable<JobSearchDto[]> {
    return this.http.get<JobSearchDto[]>(`jobs/searchByMachine/${machineId}`);
  }

  getJob(jobId: string, check?: string): Observable<JobDto> {
    return !!check
      ? this.http.get<JobDto>(`jobs/${jobId}/${this.safeEncode(check)}`)
      : this.http.get<JobDto>(`jobs/${jobId}`);
  }

  newJob(job: JobDto, isPublic: boolean = false): Observable<JobDto> {
    return (isPublic) ? this.http.post<JobDto>("jobs/public", job) : this.http.post<JobDto>("jobs", job);
  }

  refundJob(jobId: string, check: string): Observable<JobDto> {
    return this.http.delete<JobDto>(`payments/job/${jobId}/${this.safeEncode(check)}/refund`);
  }

  updateJobLite(jobId: string, check: string, updateJob: UpdateJobDto): Observable<UpdateJobResultDto> {
    const url = `jobs/${jobId}/${this.safeEncode(check)}`;
    return this.http.put<UpdateJobResultDto>(
      url,
      updateJob,
      { headers: this.getExtraHeaders() }
    );
  }

  getAdumoJwt(amount: number, reference: string): Observable<string> {
    return this.http.get<any>(`payments/token?amount=${amount}&reference=${reference}`).pipe(map(r => r.token));
  }

  getExtraHeaders() {
    return {
      ...(this.notificationService.connection?.connectionId ? { 'Push-Event-Originator-Id': this.notificationService.connection?.connectionId } : {})
    };
  }

  /**
   * @param eventOriginatorId is used by the server to identify the originator of the event
   */
  updateJob(job: JobDto): Observable<JobDto> {
    const headers = this.getExtraHeaders();
    return this.http.put<JobDto>(`jobs/${job.jobId}`, job, { headers });
  }

  getModel(modelId: string): Observable<ModelMasterDto> {
    return this.http.get<ModelMasterDto>(`values/item/ModelMaster/${this.safeEncode(modelId)}`
    );
  }

  getFollowUps(): Observable<JobFollowUpDto[]> {
    return this.http.get<JobFollowUpDto[]>("jobs/followups");
  }

  updateFollowUp(followUp: JobFollowUpDto): Observable<JobFollowUpDto> {
    return this.http.put<JobFollowUpDto>(`jobs/followups/${followUp.jobId}`, followUp,
    { headers: this.getExtraHeaders() });
  }

  createFollowUp(followUp: JobFollowUpDto): Observable<JobFollowUpDto> {
    return this.http.post<JobFollowUpDto>(`jobs/followups/${followUp.jobId}`, followUp,
    { headers: this.getExtraHeaders() });
  }

  createDeliveryNote(jobId: string): Observable<string> {
    return this.http.post<any>(
      `jobs/${jobId}/deliverynote`,
      null,
      { headers: this.getExtraHeaders() }
    ).pipe(map(
      res => {
        return res.deliveryNote;
      }));
  }

  submitJob(jobId: string, statusId: string, journalType: string): Observable<JobDto> {
    return this.http.put<JobDto>(
      `jobs/${jobId}/submit/${statusId}/${journalType}`,
      {},
      { headers: this.getExtraHeaders() }
    );
  }

  queryJobLines(query: SearchDto) {
    return this.http.get<ListResultDto<JobDto>>('jobs/lines/search', { params: this.searchQueryToParams(query)});
  }

  queryJobs(query: SearchDto) {
    return this.http.get<ListResultDto<JobDto>>('jobs/search', { params: this.searchQueryToParams(query)});
  }

  getSummary(mode: string = "resource", filter1: string = "",
             mode2?: string, filter2?: string, showJob = "all"): Observable<JobSummaryDto[]> {
    let url = `jobs/summary/${mode}/${filter1 || ""}`;
    if (mode2 && filter1) {
      url = `${url}/${mode2}/${filter2 || ""}`;
    }
    if (showJob && showJob !== "all") {
      url = url + "?showJob=" + showJob;
    }
    return this.http.get<JobSummaryDto[]>(url);
  }

  getInvoice(invoice: string) {
    return this.http.get<InvoiceDto>(`jobs/invoice/${invoice}`);
  }

  getReport(column1: string, column2: string = "", filter1 = ""): Observable<JobSummaryDto[]> {
    let url = `jobs/report/${column1}`;
    if (column2) {
      url = url + `/${column2}`;

      if (filter) {
        url = url + `/${this.safeEncode(filter1)}`;
      }
    }
    return this.http.get<JobSummaryDto[]>(url);
  }

  getReportSummaryXlsx(column1: string, column2: string = "", filter1 = ""): Observable<Blob> {
    const headers = new HttpHeaders({ Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    let url = `jobs/report/summary/${column1}`;
    if (column2) {
      url = url + `/${column2}`;

      if (filter) {
        url = url + `/${this.safeEncode(filter1)}`;
      }
    }
    return this.http.get(url, { responseType: "blob", headers });
  }

  updateRoutesheet(resourceId: string, bookedDate: Date, jobsIds: { [id: string]: number[] }) {
    return this.http.post(`jobs/updateRoutesheet/${resourceId}/${bookedDate.format()}`, jobsIds);
  }

  /**
   * Upload files against a job, supports public uploads via 'checkDigit'
   * @param jobId string the job to link these files to
   * @param file the actual 'File' to upload
   * @param description a short description
   * @param progress a callback to report file upload progress
   * @param _generateCheckDigit a string to allow public users to upload against a job
   * @returns Observable
   */
  uploadAttachment(jobId: string, file: File, description: string, progress: Progress, _generateCheckDigit = false, extras?: any, typeId?: string):
  Observable<BaseAttachmentDto> {
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    // if(typeId !== "")
    //   formData.append('typeId', typeId);
    const url = `jobs/${jobId}${_generateCheckDigit && `/${generateCheckDigit(jobId)}` || ''}/attachment${typeId && `/${typeId}` || ''}`;
    return this.http.post<BaseAttachmentDto>(
      url,
      formData,
      {
        reportProgress: true,
        observe: "events"
      }
    ).pipe(this.uploading(progress));
  }

  updateJobValidation(jobId: string, check: string, validation: JobValidationDto): Observable<any> {
    return this.http.put(
      `jobs/${jobId}/validate/${this.safeEncode(check)}`,
      validation,
      { headers: this.getExtraHeaders() }
    );
  }

  fullUrl(attachment: BaseAttachmentDto, parentId?: string): string {
    return `${this.appQuery.baseURL}/api/${this.appQuery.tenant2}/${this.downloadLink(attachment, parentId)}`;
  }

  downloadLink(attachment: BaseAttachmentDto, parentId?: string): string {
    if (parentId) {
      parentId += "/";
    }
    return `jobs/${parentId}attachment/${attachment.attachmentId}`;
  }

  downloadAttachment(jobId: string, attachmentId: string): Observable<Blob> {
    return this.http
      .get(`jobs/${jobId}/attachment/${attachmentId}`, { responseType: "blob" });
  }

  deleteAttachment(jobId: string, attachmentId: string): Observable<any> {
    return this.http.delete(`jobs/${jobId}/attachment/${attachmentId}`);
  }

  getScheduleJob(
    jobId: string,
    date: Date
  ): Observable<ResourceScheduleDto[]> {
    return this.http.get<ResourceScheduleDto[]>(`jobs/${jobId}/schedule/${date.format()}`);
  }

  scheduleJob(dto: ScheduleJobDto): Observable<JobDto> {
    return this.http.put<JobDto>(
      `jobs/${dto.jobId}/schedule`,
      dto,
      { headers: this.getExtraHeaders() }
    );
  }

  getFromFacebook(facebookId: string): Observable<JobDto[]> {
    return this.http.get<JobDto[]>(`jobs/facebook/${facebookId}`);
  }

  updateFromFacebook(facebookId: string, dto: JobDto): Observable<JobDto> {
    return this.http.put<JobDto>(`jobs/facebook/${facebookId}/${dto.jobId}`, dto);
  }

  jobFactory(userid = "WebUser"): JobDto {
    const stringValue = (id: string) =>
      this.productSetting.stringValue(id) || "";

    return {
      jobId: UUID.UUID().toString(),
      logged: Date.today(),
      booked: new Date(1900, 0, 1),
      invoice: new Date(1900, 0, 1),
      nextAction: Date.today(),
      userCaptureId: userid,
      statusId: "LOG", // stringValue('DefaultJobStatus'),
      typeId: stringValue("DefaultJobType"),
      sourceId: stringValue("DefaultSource"),
      /* if (!settings.item('ShowJobFaultCodeIDWeb').numericValue) {
        job.faultCodeId : '*',
      } */
      address: newCustomerAddress(),
      contact: newCustomerContact(),
      // job.customer.statusId : stringValue('DefaultCustomerStatus'),
      // job.customer.categoryId : stringValue('DefaultCustomerCategory'), '

      machine: newMachineMaster(stringValue("DefaultMachineStatusID") || "A"),
      dealerContact: newContact(),

      customerId: "",
      closed: 0,
      attachments: [],
      holds: [],
      faultCodeId: "",
      completed: null,
      componentCodeId: "",
      created: Date.today(),
      faultDescription: "",
      customerRef: "",
      diagnosticCodeId: "",
      errors: [],
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      invoiceNumber: "",
      journals: [],
      lines: [],
      mapsOrder: 0,
      noteExternal: "",
      noteInternal: "",
      ourRef: "",
      purchaseOrderNumber: "",
      repairCodeId: "",
      resourceId: "",
      unpaid: 0,
      validations: [],
      visitDate: null,
      visitOrder: 0,
      visits: [],
      paymentDate: null,
      paid: 0,

      /*populate for cookie*/
    };
  }

  getStockPrice(stockId: string, customerId: string, date: Date): Observable<StockPriceResultDto> {
    const url = `jobs/price/${stockId}/${customerId}/${date.format()}`;
    return this.http.get<StockPriceResultDto>(url);
  }

  sendMessage(jobId: string, method: string, sendTo: string, nmbr: string, message: string, templated: boolean = false): Observable<JobMessageDto>
  {
    return this.http.post<JobMessageDto>(
      `jobs/${jobId}/message/${method}`,
      { message, sendTo, number: nmbr, templated },
      { headers: this.getExtraHeaders() }
    );
  }

  getMessageTemplates(): Observable<MessageTemplateDto[]> {
    return this.http.get<MessageTemplateDto[]>('jobs/messagetemplates');
  }

  // Raleigh Specific
  getAvailableTimeSlots(dealerBranchId: string, date: string): any  {
    return this.http.get(`finance/branches/${dealerBranchId}/slots/${date}`);
  }

  getAvailableTimeSlotsByResource(resourceId: string, date: string): any {
    return this.http.get(`resources/${resourceId}/slots/${date}`);
  }

  visitFactory(bookedDate: Date, statusId: string, dealerBranchId: string = null): JobVisitDto {
    return {
      statusId,
      visitId: "",
      booked: bookedDate,
      visited: null,
      resourceId: null,
      dealerBranchId,
    };
  }

  printDeliveryNote(jobId: string, deliveryNoteId: string): Observable<any> {
    return this.http.put(
      `jobs/${jobId}/deliverynote/${deliveryNoteId}`,
      null,
      { headers: this.getExtraHeaders() }
    );
  }
}
