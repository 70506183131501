import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { LookupList } from "@shared/models";
import { LookupListService } from "../services/lookup-list.service";

@Directive({ selector: "[abiLookupdisplay]" })
export class LookupDisplayDirective implements OnInit, OnChanges {
  @Input() public listName: string;
  @Input("abiLookupdisplay") public value: string;
  @Input() display: "all" | "code" | "name" | "description" | "custom" = "name";
  @Input() customProperty: string;

  private lookupList: LookupList;

  constructor(private el: ElementRef, private lookups: LookupListService) {
  }

  ngOnInit() {
    // Query the FULL Lookuplist
    this.lookups.lookupList(this.listName, 0).subscribe(list => {
      this.lookupList = list;
      this.lookupValue();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listName && changes.listName.currentValue !== changes.listName.previousValue) {
      this.ngOnInit();
    } else {
      this.lookupValue();
    }
  }

  getLookupValue() {
    if (this.lookupList) {
      switch (this.display) {
        case "code":
          return this.value;
        case "name":
          return this.lookupList.display(this.value);
        case "custom":
        case "description":
          return this.lookupList.display(this.value, this.customProperty);
        case "all":
        default:
          return this.lookupList.displayValue(this.value);
      }
    }
    return this.value;
  }

  protected lookupValue() {
    this.el.nativeElement.textContent = this.getLookupValue();
  }
}
