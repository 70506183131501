import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { MatSelectionListChange } from "@angular/material/list";
import { MatMenuTrigger } from "@angular/material/menu";
import { PublishSubscribe } from "@modules/common";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, from } from "rxjs";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TableColumn } from "..";
import { TreeNode } from "../column-chooser/column-chooser.component";

@Component({
  selector: "abi-checkbox-dropdown",
  templateUrl: "./checkbox-dropdown.component.html",
  styleUrls: ["./checkbox-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxDropdownComponent),
      multi: true,
    }
  ]
})
export class CheckboxDropdownComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() options: any[];
  @Input() labelProperty: string = "description";
  @Input() valueProperty: string = "code";
  // @Output() toggled: EventEmitter<TableColumn> = new EventEmitter();
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  // nestedColumns: TreeNode[];

  // @Input() value: string | boolean = true;
  // @HostListener('change', ['$event.target.checked'])
  updateValue(value: any) {
    // const valueToUpdate = (value === true) ? this.value: false;
    // this.onChange(valueToUpdate);
    this.onChange(value);
  }

  constructor(private pubsub: PublishSubscribe, private translate: TranslateService, private _renderer: Renderer2, private _elementRef: ElementRef) {}

  onChange = (_: any) => {};

  onTouched = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.columns) {
      // forkJoin(this.columns.map(c => c.header
      //   ? ( c.header.includes(".") ? this.translate.get(c.header) : from([c.header]))
      //   : from([""])))
      // .subscribe((newHeaders: string[]) => {
      //   // Update Column Headers in one go - so that we may filter them next
      //   this.columns.forEach((c, i) => {
      //     c.realHeader = newHeaders[i];
      //   });
      //   // Group and Filter
      //   this.nestedColumns = ([... new Set(this.options.map(c => c.group))] as string[])
      //   .map(r => (
      //     {
      //       name: r,
      //       columns: this.columns.filter(cc => cc.group === r && cc.realHeader && !cc.static)
      //     }
      //   ))
      //   .filter(nest => nest.columns.length);
      // });
    }
  }

  ngOnInit() {
  }

  // Static Columns are never selectable (always visible)
  // visibleColumns(): TableColumn[] {
  //   // return this.columns ? this.columns.filter(c => c.realHeader && !c.static) : [];
  // }

  selected: Set<any> = new Set();
  toggleSelection(option: MatSelectionListChange): void {
    // const tc = option.options[0].value;// as TableColumn;
    // tc.hidden = !option.options[0].selected;
    // this.toggled.emit(tc);
    if(option.options[0].selected) {
      this.selected.add(option.options[0].value);
    } else {
      this.selected.delete(option.options[0].value);
    }
    this.writeValue(Array.from(this.selected.values()));
  }

  closed() {
    this.pubsub.publish("closeSidebar");
  }

  public toggle(): void {
    // this.trigger.toggleMenu();
  }

  label(option: any): string {
    return this.labelProperty ? option[this.labelProperty] : option;
  }

  // buttonLabel(): string {



  // BOILERPLATE
  protected setProperty(key: string, value: any): void {
    this._renderer.setProperty(this._elementRef.nativeElement, key, value);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.setProperty('disabled', isDisabled);
  }

  writeValue(value: any[]) {
    // console.log('CheckboxDropdownComponent writeValue', value);
    if (this.selected.size === 0 && value.length > 0) {
      this.selected = new Set(value);
      // this.addressForm.get('via').setValue(this.value.via);
    } else {
      this.selected = new Set(value);
    }
    this.updateValue(value);
  }
}
