<span mat-dialog-title>{{ tip?.link }}</span>
<mat-dialog-content>
  <ngb-carousel #carousel *ngIf="tips" (slide)="slide($event)">
    <ng-template *ngFor="let tip of tips" ngbSlide [id]="tip.attachmentId">
      <img [src]="downloadLink(tip) | async" [alt]="tip.source">
    </ng-template>
  </ngb-carousel>
  <!--button type="button" class="btn btn-secondary float-left" (click)="toggleTip()">Hide</button-->
</mat-dialog-content>
<mat-dialog-actions>
  <button cdkFocusInitial type="button" mat-button mat-dialog-close (click)="close()" >Close</button>
</mat-dialog-actions>
