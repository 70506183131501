<!-- <div class="row">
    <div class="col-5 pr-0" *ngIf="countries?.values?.length">
        <input
            [inputDisplayFunc]="inputDisplay"
            [dropDownDisplayFunc]="dropdownDisplay"
            [ngModel]="country"
            name="country"
            abiTypeahead
            abiLookup="CodeCountry"
            (selectItem)="countryChanged($event)"
            [codeOnly]="true"
        />
    </div>
    <div class="col-7 pl-1">
        <input
            [ngModel]="phoneNumber"
            (change)="phoneChanged($event.target.value)"
        />
    </div>
</div> -->
<!-- <div class="input-group">
    <div class="input-group-prepend" *ngIf="countries?.values?.length">

        <input
        [inputDisplayFunc]="inputDisplay"
        [dropDownDisplayFunc]="dropdownDisplay"
        [ngModel]="country"
        name="country"
        abiTypeahead
        abiLookup="CodeCountry"
        (selectItem)="countryChanged($event)"
        [codeOnly]="true"
        class="custom-select"
    />
    </div>
    <input
    [ngModel]="phoneNumber"
    (change)="phoneChanged($event.target.value)"
    class="form-control"
/>
</div> -->
<div class="input-group">
    <div class="input-group-prepend" *ngIf="countries?.values?.length">
        <button class="btn btn-primary" type="button" mat-list-item [matMenuTriggerFor]="countryMenu" [disabled]="disabled">({{ country }}) {{ intlDialCode }}</button>
    </div>
    <ng-container >
        <input
        [disabled]="disabled"
        [ngModel]="phoneNumber"
        (keyup)="phoneChanged($event)"
        class="form-control"
    />
    </ng-container>
</div>
<div class="invalid-feedback d-block" *ngIf="warnings?.length">
    <span *ngFor="let msg of warnings" class="d-block">{{
        msg | translate
    }}</span>
</div>
<mat-menu #countryMenu="matMenu">
    <button *ngFor="let item of countries?.values" mat-menu-item (click)="countryChanged(item)" [class.active]="item.code === country">
      ({{ item.code }}) {{ item.dialingCode }} ~ {{ item.description }} </button>
</mat-menu>
