import { Injectable } from "@angular/core";
import { EntityState, QueryEntity } from "@datorama/akita";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/browser";
import {
  CompanyInfoDto,
  ProductSettingDto,
  ZoneDto
} from "@shared/models";
import { BehaviorSubject, Observable, of, Subject, zip } from "rxjs";
import { debounce, distinctUntilChanged, map, switchMap, take, tap } from "rxjs/operators";
import { LoginStatus } from "../app.store";
import { DataService, ServiceConfig } from "./data.service";
import { ProductSettingStore } from "./ProductSettingStore";

export enum LabelStatus {
  Ignore,
  NotZero,
  Zero
}

export type ProductSettingState = EntityState<ProductSettingDto>;

@Injectable({ providedIn: "root" })
export class ProductSettingQuery extends QueryEntity<ProductSettingState, ProductSettingDto> {
  $error = this.selectError();
  constructor(protected store: ProductSettingStore) {
    super(store);
  }
}

@Injectable({
  providedIn: "root"
})
export class ProductSettingService extends DataService {
  $error = this.query.selectError();
  $loading = this.query.selectLoading();
  $lang = new Subject<string>();
  languageLoaded = new BehaviorSubject<boolean>(false);

  getLanguageLoadedObs() {
    return this.languageLoaded;
  }

  constructor(
    protected config: ServiceConfig,
    private store: ProductSettingStore,
    private query: ProductSettingQuery,
    private translate: TranslateService
) {
    super(config);
    this.appQuery.$tenant2.pipe(
      distinctUntilChanged(),
      tap(t => {
        console.log("Tenant changed: ", t, "Reloading product settings");
        this.store.setLoading(true);
      }),
      switchMap(t => zip(of(t), this.getProductSettings()))
    )
    .subscribe(results => {
        if (results && results.length) {
          const settings = results[1];
          const tenant = results[0];
          this.store.remove();

          if (this.local().getItem("rememberMe") === "true") {
            this.local().setItem("tenant", tenant);
          }
          this.store.add(settings);
          const useSidebarMenu = this.booleanValue('UseSidebarMenu'); // grab setting directly and apply to store
          this.appStore.update({ tenant, useSidebarMenu });
          this.store.setError(null);

          Sentry.configureScope((scope) => {
            const currentUser = this.appQuery.username;
            scope.setUser({
              username: currentUser,
              tenant
            });
          });

        } else {
          this.local().removeItem("tenant");
          this.session().removeItem("tenant");
          this.store.setError("Company not found");

          Sentry.configureScope((scope) => {
            const currentUser = this.appQuery.username;
            scope.setUser({
              username: currentUser,
              tenant: null
            });
          });
        }
        const newLang = this.stringValue("Language") || this.appQuery.language; // language from query
        this.languageLoaded.next(false);
        this.$lang.next(newLang || "en");
        this.store.setLoading();
      });

    // Handle Language changes:
    // debouce using lang 'load' and 'use'
    this.$lang
      .pipe(
        debounce(
          val => this.translate.reloadLang(val)
            .pipe(switchMap(lang => this.translate.use(val)))
        )
      )
      .subscribe(val => {
        this.languageLoaded.next(true);
      });
  }

  private getProductSettings(): Observable<ProductSettingDto[]> {
    return this.http.get<ProductSettingDto[]>("values/productSettings");
  }

  public reloadProductSettings() {
    this.store.setLoading(true);
    this.getProductSettings().pipe(take(1)).subscribe(settings => {
      this.store.remove();
      this.store.add(settings);
      this.store.setError(null);
      this.store.setLoading();
    });
  }

  public getSettings(): Observable<ProductSettingDto[]> {
    return this.query.selectAll();
  }

  // SETTINGS CRUD
  public updateSetting(setting: ProductSettingDto): Observable<ProductSettingDto> {
    return this.http.put<ProductSettingDto>(`values/productSettings/${setting.setting}`, setting)
    .pipe(tap(result => this.store.update(setting.setting, setting)));
  }

  public addSetting(setting: ProductSettingDto): Observable<ProductSettingDto> {
    return this.http.post<ProductSettingDto>(`values/productSettings`, setting)
    .pipe(tap(result => this.store.add(result)));
  }

  public deleteSetting(code: string): Observable<any> {
    return this.http.delete(`values/productSettings/${code}`)
    .pipe(tap(result => this.store.remove(code)));
  }

  public tenantChanged(tenant: string) {
    this.store.setLoading(true);
    // Dev Note: these props are not applied simultaneously (so be caseful)
    this.appStore.update({
      tenant2: tenant,// tenant seems to be 'updated' before login status...odd
      roles: [],
      loginStatus: LoginStatus._,
      menu: [],
    });
  }

  public loaded(): boolean {
    return this.query.getCount() > 0;
  }

  canDisplay(setting: string): boolean {
    if (this.query.hasEntity(setting)) {
      const item = this.query.getEntity(setting);
      return item.numericValue >= 0 && item.stringValue !== "";
    }
    return false;
  }

  label(setting: string, ignoreStatus: LabelStatus = LabelStatus.Ignore): string {
    if (this.query.hasEntity(setting)) {
      const item = this.query.getEntity(setting);

      switch (ignoreStatus) {
        case LabelStatus.Ignore:
          if (item.stringValue !== "" && item.active) {
            return item.stringValue;
          }
          break;
        case LabelStatus.NotZero:
          if (item.numericValue >= 0 && item.active && item.stringValue !== "") {
            return item.stringValue;
          }
          break;
        case LabelStatus.Zero:
          if (item.numericValue === 0 && item.active && item.stringValue !== "") {
            return item.stringValue;
          }
          break;
      }
    }
    return "";
  }

  notLabel(setting: string, ignoreStatus: LabelStatus = LabelStatus.Ignore): string {
    if (this.query.hasEntity(setting)) {
      const item = this.query.getEntity(setting);

      switch (ignoreStatus) {
        case LabelStatus.Ignore:
          if (item.stringValue !== "") {
            return "";
          }
          break;
        case LabelStatus.NotZero:
          if (item.numericValue >= 0 && item.active && item.stringValue !== "") {
            return "";
          }
          break;
        case LabelStatus.Zero:
          if (item.numericValue === 0 && item.active && item.stringValue !== "") {
            return "";
          }
          break;
      }
    }
    return " ";
  }

  stringValue(setting: string, defaultString: string = ""): string {
    if (this.query.hasEntity(setting)) {
      return this.query.getEntity(setting).stringValue;
    }
    return defaultString;
  }

  // TODO: merge this into the 'getStringArray' method
  arrayValue(setting: string, defaultValue?: string[]): string[] {
    const stringVal = this.stringValue(setting, "no-entity");
    return stringVal !== "no-entity" && stringVal.split(",").map(e => e.trim()) || defaultValue;
  }

  $stringValue(setting: string): Observable<string> {
    return this.query.select(setting).pipe(map(s => !!s ? s.stringValue : ""));
  }

  numericValue(setting: string): number {
    if (this.query.hasEntity(setting)) {
      return this.query.getEntity(setting).numericValue;
    }
    return 0;
  }

  hasSettings(settings: string): boolean {
    return this.query.getCount(s => s.setting.startsWith(settings)) > 0;
  }

  hasSetting(setting: string): boolean {
    return this.query.hasEntity(setting);
  }

  booleanValue(setting: string): boolean {
    if (this.query.hasEntity(setting)) {
      return this.query.getEntity(setting).active;
    }
    return false;
  }

  getZones(): Observable<ZoneDto[]> {
    return this.http.get<ZoneDto[]>("values/list/CodeZone");
  }

  getText(id: string): Observable<string> {
    return this.http.get<any>(`values/text/${id}`).pipe(map(s => s.text));
  }

  getCounter(counterId: string): Observable<string> {
    return this.http.get(`values/counter/${counterId}`, { responseType: "text"});
  }

  getCompanyInfo(effDate?: Date): Observable<CompanyInfoDto> {
    const dtParam = !!effDate ? "?date=" + effDate.format() : "";
    return this.http.get<CompanyInfoDto>("values/companyInfo" + dtParam);
  }

  getValues(valuesName: string): Record<string, any> {
    return this.http.get(`values/${valuesName}`);
  }

  // some settings are loaded as comma separated values
  getStringArray(key: string, separator: string = ',', defaultValue: string[] = []): string[] {
    const sval = this.stringValue(key, "no-entity");
    return  sval !== "no-entity" && sval.split(separator).map(str => str.trim()) || defaultValue;
  }


  /*
  private stuff()
  {

  this.db.on('ready', function () {

  return this.db.productSettings.count(function (count) {
  if (count > 0) {
  console.log("Already populated");
  } else {
  console.log("Database is empty. Populating from ajax call...");
  // We want framework to continue waiting, so we encapsulate
  // the ajax call in a Dexie.Promise that we return here.
  return new Dexie.Promise(function (resolve, reject) {
  $.ajax(url, {
  type: 'get',
  dataType: 'json',
  error: function (xhr, textStatus) {
  // Rejecting promise to make db.open() fail.
  reject(textStatus);
  },
  success: function (data) {
  // Resolving Promise will launch then() below.
  resolve(data);
  }
  });
  }).then(function (data) {
  console.log("Got ajax response. We'll now add the objects.");
  // By returning the db.transaction() promise, framework will keep
  // waiting for this transaction to commit before resuming other
  // db-operations.
  return db.transaction('rw', db.someTable, function () {
  data.someInitArrayOfObjects.forEach(function (item) {
  console.log("Adding object: " + JSON.stringify(item));
  db.someTable.add(item);
  });
  });
  }).then(function () {
  console.log ("Transaction committed");
  });
  }
  });
  });

  db.open(); // Will resolve when data is fully populated (or fail if error)

  // Following operation will be queued until we're finished populating data:
  db.someTable.each(function (obj) {
  // When we come here, data is fully populated and we can log all objects.
  console.log("Found object: " + JSON.stringify(obj));
  }).then(function () {
  console.log("Finished.");
  }).catch(function (error) {
  // In our each() callback above fails, OR db.open() fails due to any reason,
  // including our ajax call failed, this operation will fail and we will get
  // the error here!
  console.error(error.stack || error);
  // Note that we could also have catched it on db.open() but in this sample,
  // we show it here.
  });
  this.http.get(this.apiUrl + 'values/all/data', this.headers).map(data => {
  const dd = this.db;
  dd.transaction('rw', dd.productSettings, async () => {
  const xxx: { key: string, items: LookupObjectDto[] } = data.json();
  for (const key in xxx) {
  var list = xxx[key];
  this.db.table(key).bulkAdd(list);
  }
  });
  });

  */


}

