import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { environment } from "@env/environment";
import { AppQuery } from "@modules/common/app.store";
import { NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap/carousel/carousel";
import { AttachmentService, BaseAttachmentDto, isImage } from "@shared/models";
import { map } from "rxjs/operators";

export interface AttachmentGalleryData {
  attachments: BaseAttachmentDto[];
  parentId?: string;
  service: AttachmentService;
  /**
   * Will be passed to the Service to generate the download link
   */
  extras?: any,
  currentAttachment?: BaseAttachmentDto
}
@Component({
  selector: "abi-attachment-gallery",
  templateUrl: "./attachment-gallery.component.html",
  styleUrls: ["./attachment-gallery.component.scss"]
})
export class AttachmentGalleryComponent implements OnInit, AfterViewInit {
  @ViewChild("carousel") carousel: NgbCarousel;
  attachments: BaseAttachmentDto[];
  attachment: BaseAttachmentDto;
  loading = true;
  private service: AttachmentService;
  private parentId: string;
  private extras: any;

  constructor(
    private responsive: BreakpointObserver,
    private appQuery: AppQuery,
    @Inject(MAT_DIALOG_DATA) data: AttachmentGalleryData,
    @Optional() private dialogRef: MatDialogRef<AttachmentGalleryComponent>
  ) {
    this.attachments = data.attachments;
    this.attachment = data.currentAttachment || this.attachments[0];
    this.service = data.service;
    this.parentId = data.parentId;
    this.extras = data.extras;
  }
  ngAfterViewInit(): void {
    this.responsive
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(map((bp) => bp.matches)).subscribe((isMobile) => {
      if (isMobile) {
        this.carousel.pause();
        if(this.dialogRef)
          this.dialogRef.updateSize("100%");
      } else {
        this.carousel.cycle();
      }
    });
  }
  ngOnInit(): void {

  }

  slide(ev: NgbSlideEvent) {
    this.attachment = this.attachments.find(t => t.attachmentId === ev.current);
    // this.loading = true;
  }

  downloadLink(attachment: BaseAttachmentDto): string {
    // this.service.fullUrl(attachment, this.parentId, this.extras);
    return `${environment.webApi}/api/${this.appQuery.tenant}/${this.service.downloadLink(
            attachment,
            this.parentId,
            this.extras
          )}`;
  }

  onLoad(loadEvent) {
    this.loading = false;
  }

  isImage = isImage;
}
