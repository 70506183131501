export * from "./sentry";
export * from "./ga4";
export * from "./theme";

// @TODO: move this to a more appropriate location
/**
 * Cleans string array of duplicate values
 * @param arr strings that could contain duplicate values
 * @returns unduplicated strnig arrau
 */
export function deDupe(arr: string[]): string[] {
  const result: string[] = [arr[0]];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== result[i - 1]) {
      result.push(arr[i]);
    }
  }
  return result;
}

/**
 * Generate a string from 'paths' that map to a dictionary 'value' object
 * @param value dictionary that has any key depth and values
 * @param paths string array that contains 'paths' to values contained in the 'value' object
 * @returns string value that joins all 'path' mapped values from value
 */
export function toStringArray(value: any, paths: string[]): string {
  return deDupe(paths.map(m => toString(value, m)))
    .join(" ")
    .trim();
}

/**
 * Get a single 'value' string from a dictionary 'path'
 * @param value dictionary
 * @param path path string (eg: 'parent.child.member' or simply 'member')
 * @returns string value
 */
function toString(value: any, path: string): string {
  return path.split(".").reduce((prev, curr) => prev ? prev[curr] : undefined , value || self);
}


