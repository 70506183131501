import { Injectable } from '@angular/core';
import RRule from "rrule";
import {computeRRule} from './util/computeRRule/toString/computeRRule';
@Injectable({
  providedIn: 'root'
})
export class NgxRruleService {

  constructor() {

  }

  computeRRule(params): RRule {
    return computeRRule(params);
  }
}
