import { CdkTable } from "@angular/cdk/table";
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SnackBarService } from "@services";
import { Observable } from "rxjs";
import { ExportLoaderService, ExportType } from "@modules/common/services/export.service";
import { TableColumn, TableComponent } from "..";

type ExportRange = 'currentpage' | "allrows";

/**
 * Provides a Button and a Dialog to configure your export
 */
@Component({
  selector: 'abi-export-buttons',
  templateUrl: './export-buttons.component.html',
  styleUrls: ['./export-buttons.component.scss']
})
export class ExportButtonsComponent implements OnInit, OnChanges {
  @Input() table: TableComponent;
  @Input() totalResults = 0;
  @Input() exportAllThreshold = 20000;
  @Input() rowsObservable: (() => Promise<any[]>)[];
  @Input() noExportAll = false;
  @Input() extras: Partial<TableColumn>[];// additional columns to include
  @Input() onlyVisible = true;
  @Input() name;

  @ViewChild("exportDialog") exportDialog: TemplateRef<any>;

  exportTooltip = "";
  exportType: ExportType = "excel";
  exportRange: ExportRange = "currentpage";
  exportDialogRef: NgbModalRef;
  exportBusy = false;

  constructor(private translate: TranslateService, private exportService: ExportLoaderService, private modalService: NgbModal,
              private snack: SnackBarService) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.getExportAllTooltip();
  }

  onExportClick(e) {
    e.preventDefault();
    this.exportDialogRef = this.modalService.open(this.exportDialog);
  }

  exportTimeStarted = 0;
  exportTimeRemaining = 0;
  exportProgress = 0;
  onConfirmExportClick(e) {
    e.preventDefault();
    if(this.exportRange !== 'currentpage') {
      this.exportBusy = true;
      this.exportProgress = 0;
      this.exportTimeStarted = Date.now();
      this.exportTimeRemaining = 0;
    }
    const tbl =
      this.table instanceof CdkTable
        ? (this.table as any)._elementRef.nativeElement
        : this.table instanceof ElementRef ? this.table.nativeElement : this.table;

    this.exportService
    .export(
      tbl,
      this.exportRange === 'currentpage' ? null : this.rowsObservable,
      this.extras,
      this.onlyVisible,
      this.exportType,
      this.name,
      (progress) => {
        this.exportTimeRemaining = (Date.now() - this.exportTimeStarted) / progress * (100 - progress);
        this.exportProgress = progress;
        if (progress === 100)
          this.exportBusy = false;
      }
    )
    .then(() => {
      if (this.exportType === 'clipboard') {
        this.snack.success("Copied to Clipboard");
      }
      this.exportBusy = false;
      this.closeDialog();
    });
  }

  get timeRemaining() {
    return new Date(this.exportTimeRemaining);
  }

  closeDialog() {
    this.exportRange = "currentpage";
    this.exportDialogRef.close();
  }

  getExportAllTooltip() {
    const key = 'List.LimitListThreshold'; // Please limit list to less than ::threshold:: rows
    this.translate.get(key).subscribe(val => {
      const translation = val;
      if (val) {
        this.exportTooltip = translation.replace('::threshold::', this.exportAllThreshold + '');
      } else {
        this.exportTooltip = key;
      }
    });
  }
}
