import { HttpErrorResponse, HttpParameterCodec } from "@angular/common/http";

export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

/**
 * Basic Error message extractor for UI display
 */
export function getErrorMessage(e: HttpErrorResponse): string {
  if(e.error?.error_message){
    return e.error.error_message;
  }
  return e.message;
  // return "Unknown Error"
}
