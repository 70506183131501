import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, MatSnackBarVerticalPosition, TextOnlySnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class SnackBarService {
  static defaultDuration = 7000;
  static defaultPosition: MatSnackBarVerticalPosition = "bottom";
  constructor(private snackBar: MatSnackBar, private translate: TranslateService){}

  open(message: string, action?: string, options?: MatSnackBarConfig): Promise<MatSnackBarRef<TextOnlySnackBar>> {
    if(this.snackBar._openedSnackBarRef)
      return Promise.reject("Another snackbar is already open. Please close it first.");

    return new Promise((res, rej) => {
      this.translate.get(message)
      .subscribe(translated => {
        res(this.snackBar.open((translated || message || "").newlineReplace(), action, options));
      });
    });
  }

  openWithTemplate(className: string, template: any, duration?: number, vpos?: MatSnackBarVerticalPosition, panelClass?: string[]){
    return this.snackBar.openFromTemplate(template, SnackBarService.getOptions(className, duration, vpos, panelClass));
  }

  // return this.translate.get(message)
  // .pipe(switchMap(translated => {
  //   return of(this.snackBar.open(translated || message, action, options));
  // }));

  warning(message: string, duration?: number, vpos?: MatSnackBarVerticalPosition, panelClass?: string[]){
    this.open(message, null, SnackBarService.getOptions("warning", duration, vpos, panelClass));
  }

  danger(message: string, duration?: number, vpos?: MatSnackBarVerticalPosition, panelClass?: string[]){
    this.open(message, null, SnackBarService.getOptions("danger", duration, vpos, panelClass));
  }

  success(message: string, duration?: number, vpos?: MatSnackBarVerticalPosition, panelClass?: string[]){
    this.open(message, null, SnackBarService.getOptions("success", duration, vpos, panelClass));
  }

  info(message: string, duration?: number, vpos?: MatSnackBarVerticalPosition, panelClass?: string[]){
    this.open(message, null, SnackBarService.getOptions("info", duration, vpos, panelClass));
  }

  /**
   * @param type: the css prefix for the background color (e.g. "success" or "warning", etc.)
   */
  static getOptions(type: string, duration?: number, vpos?: MatSnackBarVerticalPosition, panelClass?: string[]){
    return {
      duration: duration || SnackBarService.typeDurationMap[type],
      verticalPosition: vpos || SnackBarService.defaultPosition,
      panelClass: ["bg-" + type, SnackBarService.typeTextMap[type], "line-breaker", ...(panelClass || [])],
    };
  }

  static typeDurationMap = {
    warning: SnackBarService.defaultDuration,
    danger: SnackBarService.defaultDuration,
    success: 4000,
    info: SnackBarService.defaultDuration,
  };
  static typeTextMap = {
    warning: "text-dark",
    danger: "text-light",
    success: "text-light",
    info: "text-light",
  };
}
