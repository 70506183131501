

import { Pipe, PipeTransform } from "@angular/core";
import { AddressDto } from "./AddressDto";
import { CustomerContactDto } from "./CustomerContactDto";
import { DeliveryLineDto } from "./DeliveryLineDto";
import { OrderDto, TransferOrderDto, TransferOrderLineDto } from "./OrderDto";
import { OrderLineDto } from "./OrderLineDto";

export interface DeliveryNoteCustomerDto extends AddressDto {

  deliveryNoteId: string;
  customerName: string; // Dealer Address
  printed: boolean;
  customerId: string;
  warehouseId: string;
  telephone: string;
  email: string;

  deliveryLines: DeliveryLineDto[];
  contact?: CustomerContactDto;
}

export function ordered(self: DeliveryNoteCustomerDto | OrderDto): number {
  if ("deliveryLines" in self) {
    return self.deliveryLines.reduce((p: number, l: DeliveryLineDto) => p + l.quantity, 0);
  } else {
    return self.lines.reduce((p: number, l: OrderLineDto) => p + l.quantityOrdered, 0);
  }
}

export function requested(self: TransferOrderDto): number {
    return self.lines.reduce((p: number, l: TransferOrderLineDto) => p + l.quantityRequested, 0);
}
export function shipped(self: TransferOrderDto): number {
    return self.lines.reduce((p: number, l: TransferOrderLineDto) => p + l.quantityShipped, 0);
}
export function received(self: TransferOrderDto): number {
    return self.lines.reduce((p: number, l: TransferOrderLineDto) => p + l.quantityReceived, 0);
}

export function totalDelivered(self: DeliveryNoteCustomerDto): number {
  return self.deliveryLines.reduce((p: number, l: DeliveryLineDto) => p + l.delivered, 0);
}

@Pipe({
  name: "deliveryNoteCustomer"
})
export class DeliveryNoteCustomerPipe implements PipeTransform {
  transform(value: DeliveryNoteCustomerDto, format?: string): number {
    switch (format) {
      case "ordered":
        return ordered(value);
      case "totalDelivered":
        return totalDelivered(value);
    }
    return 0;
  }
}
