import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { DateExPipe } from "@modules/common";
import { CustomerAddressDto } from "../CustomerAddressDto";
import { CustomerBankDto } from "../CustomerBankDto";
import { CustomerContactDto } from "../CustomerContactDto";
import { CustomFieldDto } from "../CustomFieldDto";
import { JobDto, totalAmount } from "../JobDto";
import { JobJournalDto } from "../JobJournalDto";
import { ContractBillingDto } from "./ContractBillingDto";
import { ContractLineDto } from "./ContractLineDto";
import { ContractMachineDto } from "./ContractMachineDto";
import { ContractPaymentDto } from "./ContractPaymentDto";

export interface ContractDto {
  contractId: string;
  customerId: string;
  typeId: string;
  statusId: string;
  paymentStatusId: string;
  start: Date;
  end: Date;
  userCaptureId: string;
  sourceId: string;
  customerReference: string;
  address: CustomerAddressDto;
  postalAddress?: CustomerAddressDto;
  contact: CustomerContactDto;
  bankAccount: CustomerBankDto;
  machines: ContractMachineDto[];
  billing: ContractBillingDto;
  lines: ContractLineDto[];
  payments: ContractPaymentDto[];
  jobs: JobDto[];
  unpaid?: number;
  overdue?: number;
  journals: JobJournalDto[];
  extra?: {[key: string]: CustomFieldDto};
  fields?: {[key: string]: string};
}

@Pipe({
  name: "contract"
})
export class ContractPipe implements PipeTransform {
  transform(contract: ContractDto, format?: string): string | Date {
    const np = new DecimalPipe("en-ZA");
    const dp = new DateExPipe();
    let value = 0;
    switch (format) {
      case "lastInvoice":
        return contract.payments.length
          ? contract.payments.slice(-1)[0].invoiceId
          : "";
      case "premium":
        value = totalAmount(contract);
        break;
      case "unpaidPremium":
        value = contract.unpaid ? -totalAmount(contract) : 0;
        /*case "costPrice":
        value = costPrice(job);*/
        break;
      case "date":
        return contract.payments.length
          ? dp.transform(contract.payments.slice(-1)[0].date)
          : "";
      case "payDate":
        return contract.payments.length
          ? dp.transform(contract.payments.slice(-1)[0].payDate)
          : "";
    }
    return np.transform(value, "1.2-2");
  }
}
