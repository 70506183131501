<abi-calendar-header
    [(view)]="currentView"
    [(viewDate)]="viewDate"
    (viewChange)="onViewChanged($event)"
    (viewDateChange)="onViewDateChanged($event)"
    [disabled]="loading"
>
</abi-calendar-header>

<abi-spinner *ngIf="loading"></abi-spinner>
<div [ngSwitch]="currentView" *ngIf="!loading">
    <mwl-calendar-month-view
        *ngSwitchCase="'month'"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="eventClicked($event)"
        [activeDayIsOpen]="activeDayIsOpen"
        (columnHeaderClicked)="columnHeaderClicked($event)"
        (dayClicked)="dayClicked($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
        *ngSwitchCase="'week'"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="eventClicked($event)"
        (dayHeaderClicked)="dayClicked($event)"
        (hourSegmentClicked)="hourSegmentClicked($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
        *ngSwitchCase="'day'"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="eventClicked($event)"
        (hourSegmentClicked)="hourSegmentClicked($event)"
    >
    </mwl-calendar-day-view>
</div>
