import { HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReportDto } from "@shared/models";
import { ReportDataDto } from "@shared/models/report";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { DataService, ServiceConfig } from "./data.service";


export enum ReportDate {
  Choose = 0,
  Today = 1,
  WithinWeek = -2,
  WithinMonth = -3,
  WithinYear = -4,
  Yesterday = -11,
  LastWeek = -12,
  LastMonth = -13,
  LastYear = -14,
  ThisWeek = -22,
  ThisMonth = -23,
  ThisYear = -24
}

@Injectable({
  providedIn: "root"
})
export class ReportService extends DataService {
  constructor(config: ServiceConfig) {
    super(config);
  }

  getGroups(reportId: string): Observable<ReportDto> {
    return this.http.get<ReportDto>(`reports/groups/${reportId}`);
  }

  getParameters(reportId: string): Observable<ReportDto> {
    return this.http.get<ReportDto>(`reports/parameters/${reportId}`);
  }

  getReports(): Observable<ReportDto[]> {
    return this.http.get<ReportDto[]>("reports")
    .pipe(catchError(err => {
      return of([]);
    }));
  }

  /**
   * Get Report data for specific ReportId (type)
   * Possible Params: daterange, startdate, enddate, col1, col2
   * Extra Params for StockMovements: timeoffset, rollup
   */
  getReport(reportId: string, params: { [key: string]: string }): Observable<ReportDataDto> {
    let hParams = new HttpParams();

    // eslint-disable-next-line guard-for-in
    for (const key in params) {
      const value = params[key];
      if (value) {
        hParams = hParams.append(key, this.safeEncode(value));
      }
    }
    const url = `reports/${reportId}?p=0&${hParams.toString()}`;

    return this.http.get<ReportDataDto>(url).pipe(catchError(err => {
      return of({rows: [], totals: {}, columns: []});
    }));
  }

  updateReport(reportId: string, params: { [key: string]: string }, ids: string[]): Observable<ReportDataDto> {
    let hParams = new HttpParams();

    // eslint-disable-next-line guard-for-in
    for (const key in params) {
      const value = params[key];
      if (value) {
        hParams = hParams.append(key, this.safeEncode(value));
      }
    }
    const url = `reports/${reportId}?p=0&${hParams.toString()}`;

    return this.http.put<ReportDataDto>(url, ids).pipe(catchError(err => {
      return of({rows: [], totals: {}, columns: []});
    }));
  }

  createReport(reportId: string, params: { [key: string]: string }): Observable<string> {
    let hParams = new HttpParams();

    // eslint-disable-next-line guard-for-in
    for (const key in params) {
      const value = params[key];
      if (value) {
        hParams = hParams.append(key, this.safeEncode(value));
      }
    }
    const url = `reports/${reportId}/download?p=0&${hParams.toString()}`;

    return this.http.get(url, {observe: "response"}).pipe(map((r: HttpResponse<any>) => r.headers.get("Location")));
  }

  downloadReport(url: string): Observable<Blob> {
    return this.http.get(url, {observe: "body", responseType: "blob"});
  }
}
