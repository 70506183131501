import { ContactDto } from "./ContactDto";

export interface CustomerContactDto extends ContactDto {
  contactId: string;
  primary?: boolean;
}


export function newCustomerContact(): CustomerContactDto {
  return {
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobile: "",
    officeHours: "",
    afterHours: "",
    fax: "",
    instantMessageId: "",
    contactId: "",
    // primary: false, // manage primary assignment FE side
    companyName: "",
    note: "",
    idNumber: "",
  };
}
