export const environment = {
  production: true,
  webApi: ".",
  notificationApi: ".",
  barcodeScanner: {
    organisationId: '395014',
    engineResourcePath: "/dynam/"
  },
  cod: {
    adumoApi: 'https://apiv2.adumoonline.com/product/payment/v1/initialisevirtual',
    merchantId: '6D687F6F-B463-45FA-92ED-779724A4282B'
  },
  google: {
    mapsApiBaseUrl: 'https://maps.googleapis.com/maps/api/js?libraries=places&key=',
    measurementId: 'G-FM4WJB8NKN'
  },
  sentry: {
    dsn: 'https://e8f68bd21db74c43b0dd139bd15dbd5c@o657158.ingest.sentry.io/5763048'
  },
  floorplanner: {
    api: 'https://floorplanner.com/api/v2'
  }
};

