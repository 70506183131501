import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LookupList } from "@modules/models";

@Component({
  selector: "abi-filter-dropdown",
  templateUrl: "./filter-dropdown.component.html",
  // styleUrls: ["./filter-dropdown.component.scss"],
})
export class FilterDropdownComponent {
  // public isDropdownOpen = false;
  @Input() value: string;
  @Input() options: LookupList | { [key: string]: string };
  @Output() valueChange = new EventEmitter<string>();

  get lookupOptions(){
    return this.options as LookupList;
  }

  get objectOptions(){
    return this.options as { [key: string]: string };
  }

  get isLookupList(): boolean {
    return this.options instanceof LookupList;
  }
  // public toggleDropdown(): void {
  //   this.isDropdownOpen = !this.isDropdownOpen;
  // }

  public optionClicked(value: string): void {
    this.value = value;
    this.valueChange.emit(value);
    // this.toggleDropdown();
  }
}
