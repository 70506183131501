<footer
    class="footer navbar navbar-light navbar-expand bg-light py-0 d-flex flex-column flex-sm-row justify-content-between d-print-none"
>
    <div class="company">
        <a href="http://www.darkred.co.za" target="_blank" rel="noreferrer">
            <img src="assets/logo.svg" class="dr-logo-f" alt="darkred logo" />
        </a>
        <small class="navbar-text text-muted ml-2 copyright"
            >&copy; {{ year }}
        </small>
    </div>
    <small class="navbar-text text-muted version"
        >V{{ version }}
    </small>
    <div class="contact text-muted">
        <a class="text-muted" [href]="'tel:' + telephone"
            ><i class="fas fa-phone-square fa-fw"></i>{{ telephone }}</a
        >&nbsp;|&nbsp;<a
            class="text-muted"
            [href]="'http:' + companyUrl"
            target="_blank"
            rel="noreferrer"
            >{{ companyUrl }}
        </a>
    </div>
</footer>
