<!-- <abi-spinner *ngIf="dataTable?.isLoading" position="relative" [diameter]="32" [stroke]="4" class="mr-2"></abi-spinner> -->
<div class="btn-group" role="group" *ngIf="table">
    <ng-content select="div.prepend-buttons"></ng-content>
    <button
        *ngIf="resetButtonEnabled"
        class="d-none d-sm-inline-block btn btn-outline-secondary"
        (click)="resetTableFilters()"
        data-testid="resetbutton"
        type="button"
        [disabled]="dataTable?.isLoading"
        title="Reset Filters"
    >
        <i class="fas fa-backspace fa-fw mr-2 d-none d-md-inline"></i>{{ "System.ResetFilters" | translate }}
    </button>
    <div class="btn-group" role="group" *ngIf="columnSelectorEnabled">
        <button
            [matMenuTriggerFor]="appMenu"
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            [disabled]="dataTable?.isLoading"
        >{{ 'System.Columns' | translate }}</button>
        <mat-menu #appMenu="matMenu" class="py-0">
            <abi-column-selector [columns]="table.columns" (toggled)="table.triggerColumnSave()"></abi-column-selector>
        </mat-menu>
    </div>

    <div class="btn-group" role="group">
        <button
            [matMenuTriggerFor]="actionMenu"
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            [disabled]="dataTable?.isLoading"
        >{{ 'System.Actions' | translate }}</button>
        <mat-menu #actionMenu="matMenu" class="py-0">
            <mat-action-list>
                <ng-content select="div.prepend-menu-items"></ng-content>
                <abi-export-buttons [table]="table" [totalResults]="dataTable?.totalResults" [rowsObservable]="dataTable?.allData()" [exportAllThreshold]="exportAllThreshold"></abi-export-buttons>
                <mat-divider></mat-divider>
                <!-- could use content type/filters here -->
                <button
                    *ngIf="resetButtonEnabled"
                    type="button"
                    class="d-block d-sm-none"
                    mat-list-item
                    (click)="resetTableFilters()"
                    data-testid="resetbutton"
                    title="Reset Filters"
                >
                    <i matListIcon class="mr-2 fas fa-backspace fa-fw text-primary"></i>{{ "System.ResetFilters" | translate }}
                </button>
                <ng-content select="div.append-menu-items"></ng-content>
            </mat-action-list>
        </mat-menu>
    </div>
</div>
