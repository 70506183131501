<div class="attachments">
    <div class="row">
        <div class="col-6 attachments-title-area">
            <h4 *ngIf="title" data-testid="attachments-title">{{ title | translate }} &nbsp; </h4>
        </div>
        <div class="col-6 attachments-buttons-area pb-2" data-testid="attachments-buttons">
            <div class="row justify-content-end" *ngIf="!isReadOnly">
                <div class="col-2">
                    <abi-spinner *ngIf="uploading || deleting" [indeterminate]="compressing || deleting" [value]="progress" [diameter]="36" [stroke]="4" [position]="'relative'"></abi-spinner>
                </div>
                <div class="col-auto">
                    <!-- standard capture -->
                    <abi-capture *ngIf="!useCustomModal" [disabled]="disabled || (maxCount && attachments?.length >= maxCount)" [defaultDescription]="filename"
                        [hidden]="uploading" (fileSelected)="uploadAttachment($event)"></abi-capture>
                    <!-- custom file button with modal options (and category) -->
                    <abi-file-button
                        *ngIf="useCustomModal"
                        [buttonDisabled]="disabled || uploading"
                        (selected)="uploadAttachment($event, category)"
                        [loading]="false"
                        btnClass="btn btn-primary ml-2"
                        faClass="fas fa-upload fa-fw"
                        descriptionRequired
                        [descriptionPlaceholder]="'Attachment.FileButtonDescriptionPlaceholder' | translate"
                        [defaultDescription]="filename"
                        [maxLength]="getMaxLength()">&nbsp;{{ 'Attachment.Upload' | translate }}
                        <ng-container *ngIf="customCategories.length" extraFields>
                            <div class="row">
                                <abi-form-field label="Category" cols="col-12">
                                    <select class="custom-select" noBootstrap [(ngModel)]="category">
                                        <option *ngFor="let category of customCategories" [ngValue]="category">{{ category }}</option>
                                    </select>
                                </abi-form-field>
                            </div>
                        </ng-container>
                    </abi-file-button>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-hover table-striped table-sm" data-testid="attachments-listing">
            <thead class="thead-light">
                <tr>
                    <th class="w-35" translate="Attachment.Description"></th>
                    <th class="w-35" class="" translate="Attachment.Filename"></th>
                    <th class="w-10" class="">Type</th>
                    <th class="w-10 d-md-table-cell d-none " translate="Attachment.Date"></th>
                    <th class="w-20 d-md-table-cell d-none" translate="Attachment.UploadedBy"></th>
                    <th class="w-10"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let attachment of visibleAttachments" class="attachments-listing-item">
                    <td>
                        <a class="text-truncate d-inline-block w-99" [href]="downloadLink(attachment) | async"
                        [attr.title]="'Attachment.Download' | translate">
                            <!--i class="fas fa-fw fa-download"></i-->{{ attachment.link || attachment.source }}</a>
                        <div class="d-table-cell d-md-none">
                            <small>{{ attachment.date|date }} {{ attachment.by }}</small>
                        </div>
                    </td>
                    <td class="">
                        <span class="text-truncate d-inline-block w-99">
                            {{ attachment.source }}</span>
                        </td>
                    <td class="d-md-table-cell d-none">{{ attachment.typeId }}</td>
                    <td class="d-md-table-cell d-none">{{ attachment.date|date }}</td>
                    <td class="d-md-table-cell d-none">{{ attachment.by }}</td>
                    <td class="p-0 pr-1 text-right align-middle">
                        <div class="btn-group">
                            <button *ngIf="isImage(attachment)" type="button" class="btn btn-primary btn-sm" (click)="openViewer(attachment)"
                                [attr.title]="'Attachment.View' | translate">
                                <i class="far fa-image"></i>
                            </button>

                            <a class="btn btn-primary btn-sm" [href]="downloadLink(attachment) | async"
                                [attr.title]="'Attachment.Download' | translate">
                                <i class="fas fa-fw fa-download"></i></a>

                            <button *ngIf="allowDelete && !isReadOnly && !disabled" type="button" class="btn btn-primary btn-sm" (click)="deleteAttachment(confirm, attachment)"
                                [attr.title]="'Attachment.Delete' | translate">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #confirm let-c="close" let-d="dismiss">
    <h4 mat-dialog-title translate="Attachment.Delete"></h4>
    <mat-dialog-content>
        {{ 'Attachment.DeleteConfirm' | translate }}
    </mat-dialog-content>
    <mat-dialog-actions>
        <button type="button" mat-dialog-close mat-button translate="System.No"></button>
        <button type="button" [mat-dialog-close]="true" mat-button translate="System.Yes"></button>
    </mat-dialog-actions>
</ng-template>
