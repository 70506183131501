<ng-container *ngIf="totalResults">
    <div class="d-flex flex-nowrap">
        <!-- if totalresults == 0 the component will try emitting (pageChange) = 1 -->
        <ngb-pagination
            class=""
            [collectionSize]="totalResults"
            [page]="pageNumber"
            [pageSize]="pageSize"
            [maxSize]="mPages"
            (pageChange)="doPaged($event)"
            rotate="true"
            boundaryLinks="true"
            [disabled]="disabled || dataTable?.isLoading"
        >
        </ngb-pagination>
        <div ngbDropdown class="ml-2">
            <button
                type="button"
                class="btn btn-outline-primary"
                id="pageSize"
                ngbDropdownToggle
                [disabled]="disabled || dataTable?.isLoading"
            >
                {{ pageSize }}
            </button>
            <div ngbDropdownMenu aria-labelledby="pageSize">
                <button
                    *ngFor="let ps of pageSizes"
                    class="dropdown-item"
                    (click)="setPageSize(ps)"
                    type="button"
                >
                    {{ ps }}
                </button>
            </div>
        </div>
        <div class="ml-2 total-results">
            <small class="text-muted"
                >{{ "List.TotalResults" | translate }} : {{ totalResults }}
            </small>
        </div>
    </div>

</ng-container>
