import { Injectable } from "@angular/core";
import { Progress } from "@services";
import JSZip from "jszip";
import { BehaviorSubject, Observable } from "rxjs";
import JSZipUtils from 'jszip-utils';
import { saveAs } from "file-saver";
import { LoggingService } from "@core/services";

export type UploadFile = {
  file: File,
  description: string,
};

export interface FilesToZip {
  fileName: string, // name given to file inside the generated zipFile
  url: string, // the data url to fetch the file from
}

const compressionOptions = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1024,
  useWebWorker: false
};

type UploaderFunc = (file: File, description: string, progFunc: (prog: number) => void) => Observable<any>;

@Injectable({
  providedIn: "root",
})
export class FileService {
  //
  static AllowedFileUploadType: RegExp[] = [/application\/pdf.*/, /audio\/.*/, /image\/.*/, /video\/.*/];
  // facsimile of the above for use in HTML
  static FileInputAcceptString: string = "application/pdf,audio/*,image/*,video/*";
  // apple proprietary files must be blocked - apple internally converts tpo JPG on upload
  static InvalidFileExtensions: string[] = [".heic", ".heif"];
  /**
   * @returns boolean - true = valid, false = invalid
   */
  static validateTypes(file: File, allowedTypes: RegExp[], invalidExt: string[]): boolean {
    return allowedTypes.some((type) => file.type.match(type)) && invalidExt.every(ext => !file.name.includes(ext));
  }

  // ZIPPING
  /**
   * Fetch the content and return the associated promise.
   * @param url the url of the content to fetch.
   * @return the promise containing the data.
   */
  urlToPromise(url: string, fallbackUrl?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if(err) {
          // Image fetch likely failed - lets provide a fallback Image
          if(fallbackUrl) {
            // Grab a know ArrayBuffer and pass it in instead
            fetch(fallbackUrl)
            .then(res => res.arrayBuffer()
              .then(ab => resolve(ab))
            ).catch(ie => reject(ie));
          } else {
            reject(err);
          }
        } else {
          resolve(data);
        }
      });
    });
  }


  saveFile(blob: Blob, fileName: string) {
    saveAs(blob, fileName);
  }

  /**
   * Zips up a collection of files with URL locations and returns the data Blob
   * reccomend you save the Blob using 'this.SaveFile'
   * Using Placehold image service: https://placehold.co/
   */
  zipFiles(
    fileToZip: FilesToZip[],
    progress: (percent: number) => void = (_) => null,
    placeholderImageUrl = ""
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const zip = new JSZip();
      fileToZip.forEach(file => {
        zip.file(
          file.fileName,
          this.urlToPromise(file.url, placeholderImageUrl),
          // this.urlToPromise("https://picsum.photos/200/300"),
          { binary:true }
        );
      });

      if(Object.values(zip.files).length){
        zip.generateAsync(
          {type: "blob"},
          (metadata) => {
            progress(metadata.percent || 0);
          }
        )
        .then((blob: Blob) => {
          resolve(blob);
        },
        (e) => {
          reject('Error fetching one or more files');
        });
      } else {
        reject('No Files in archive');
      }
    });
  }

  // FILE UPLOADS
  public preProcess(file: File): Promise<File | Blob> {
    if (file.type.match(/image\/.*/)) {
      // reverted to using old image compression process - new library seems to have some bugs
      return this.compress(file);
      // return imageCompression(file, compressionOptions);
    } else {
      // never compress normal files
      return Promise.resolve(file);
    }
  }

  /**
   * @deprecated this is no longer maintained
   */
  private compress(file: File): Promise<File | Blob> {
    const maxSize = 1024;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement("canvas");

    const dataURItoBlob = (dataURI: string): File => {
      const bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
          ? atob(dataURI.split(",")[1])
          : unescape(dataURI.split(",")[1]);
      const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new File([ia], file.name, { type: mime, lastModified: file.lastModified }); // Preserve modified datestamp
    };

    const resize = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL("image/jpeg");
      return dataURItoBlob(dataUrl);
    };

    return new Promise((resolve, reject) => {
      try {
        reader.onload = (readerEvent: any) => {
          image.onload = () => resolve(resize());
          image.onerror = (e) => {
            const eTitle = "File Service: Image Loading Error:";
            const message = `${eTitle} ${file.name} ${file.type}`;
            reject(message);
          };
          image.src = readerEvent.target.result;
        };
        reader.onerror = (e) => {
          const eTitle = "File Service: Image Reading Error:";
          const message = `${eTitle} ${file.name} ${file.type}`;
          reject(message);
        };
        reader.readAsDataURL(file);
      } catch (e) {
        const eTitle = "File Service: Compress Error:";
        const message = (e as Error)?.message ? `${eTitle} ${(e as Error)?.message}` : `${eTitle} ${file.name} ${file.type}`;
        reject(message);
      }
    });
  }
}

/**
 * Helper Class to Simplify Uploading of Files/Attachments as a batch process
 */
export class FilesUploader {
  filePromises: ((index: number) => Promise<any>)[] = [];
  aggregateProgress = new BehaviorSubject(0);

  /**
   * The aggregate progress of the upload process
   * @returns BehaviourSubject
   */
  public getProgressSubject(){
    return this.aggregateProgress;
  }

  constructor(private fileService: FileService, private loggingService: LoggingService) {}

  /**
   * Convert and Uploader API function to a promise with all necessary Promise related safety for File uploads
   * @param myUploader uploader function that accepts a File and Progress callback
   * @param file the File to upload - passed into the uploader function
   * @param fileProgress the progress callback
   * @returns Promise
   */
  getFileUploadPromise(myUploader: UploaderFunc , uFile: UploadFile, fileProgress: Progress): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fileService.preProcess(uFile.file)
      .then((newFile: File) => {
        myUploader(newFile, uFile.description, fileProgress)
        .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          // eslint-disable-next-line max-len
          reject(`Failed to Upload File - Attachment: "${uFile.file.name}" (${uFile.file.type} - ${uFile.file.size} bytes - ${uFile.file.lastModified}))`);
        });
      }).catch(err => {
        reject(`File Processing failed - Attachment: "${uFile.file.name}" (${uFile.file.type} - ${uFile.file.size} bytes - ${uFile.file.lastModified}))`);
      });
    });
  }

  /**
   * Add File and it's uploader Promise function to the array of items to process
   * @param file File object
   * @param uploader Function that accepts a File and Progress callback - this must call an api that posts files
   */
  addFile(uFile: UploadFile, uploader: UploaderFunc) {
    const progress = (index: number) => (prog: number) => {
      const arrayProgress = 100 / this.filePromises.length * index;
      this.aggregateProgress.next(arrayProgress + (prog / this.filePromises.length));
    };
    this.filePromises.push((index) => this.getFileUploadPromise(uploader, uFile, progress(index))
      .then(res => {
        this.aggregateProgress.next(100 / this.filePromises.length * (index + 1));
        return res;
      })
      .catch(err => {
          this.loggingService.logException(err);
      })
    );
  }

  /**
   * Helper to Sequentially execute functions that resolve as promises
   * @returns Array of Completed Promises
   */
  async executeSequentially() {
    const results = [];
    for (let index = 0; index < this.filePromises.length; index++) {
      results[results.length] = await this.filePromises[index](index);
    }
    return results;
  }
}
