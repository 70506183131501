<ng-template #existingJobsModalBody>
    <p>{{ "Machine.HasExistingJobs" | translate }} </p>
    <p><strong>{{ "Machine.EditWarning" | translate }}</strong></p>
</ng-template>

<ng-template #editModelReasonBody>
    <p>{{ "Machine.EditReasonBody" | translate }}</p>
    <textarea
        class="form-control"
        [(ngModel)]="editModelReasonText"
        name="editModelReasonText"
        id="editModelReasonText"
        rows="5"
        ></textarea>
</ng-template>
