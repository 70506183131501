import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { ContractLineDto, MetricDto, newContact, newCustomerAddress, newCustomerContact, newMachineMaster } from "@app/modules/models";
import { ContractDto } from "../models/contracts/ContractDto";
import { BaseAttachmentDto } from "./BaseAttachmentDto";
import { ChecklistEntryDto } from "./checklist/ChecklistDto";
import { ContactDto } from "./ContactDto";
import { CustomerAddressDto } from "./CustomerAddressDto";
import { CustomerContactDto } from "./CustomerContactDto";
import { JobErrorDto } from "./JobErrorDto";
import { JobJournalDto } from "./JobJournalDto";
import { amount, JobLineDto, JobVat } from "./JobLineDto";
import { JobEdiDto } from "./jobs/JobEdiDto";
import { JobFollowUpDto } from "./jobs/JobFollowUpDto";
import { JobHoldDto } from "./jobs/JobHoldDto";
import { JobMachineDto } from "./jobs/JobMachineDto";
import { JobMessageDto } from "./jobs/JobMessageDto";
import { JobSearchDto } from "./JobSearchDto";
import { JobValidationDto } from "./JobValidationDto";
import { JobVisitDto } from "./JobVisitDto";
import { MachineMasterDto } from "./MachineMasterDto";

export const JobStatusGrouping: Record<string, string[]> = {
  CAN_ACCEPT: ["QUOTSENT"],
  CAN_CANCEL: ["LOG", "QUOTSENT", "PARTREQ"],
  ACCEPTED: ["PARTREQ", "TECH", "FIN"],
  CANCELLED: ["CXC"]
};

export interface MenuItem {
  label: string;
  edi: string;
  journal: string;
}

/**
 * Get a Job DTO concrete
 * @returns empty but concrete Job Dto
 */
export function newJob(overrides: Partial<JobDto> = {}): JobDto {
  return {
    jobId: "",
    typeId: "",
    statusId: "",
    customerId: "",
    userCaptureId: "",
    sourceId: "",
    logged: new Date(),
    faultCodeId: "",
    faultDescription: "",
    resourceId: "",
    booked: new Date(),
    nextAction: new Date(),
    customerRef: "",
    ourRef: "",
    purchaseOrderNumber: "",
    componentCodeId: ",",
    diagnosticCodeId: "",
    repairCodeId: "",
    noteInternal: "",
    noteExternal: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    completed: new Date(), // this is mapped from CustomDate1 field (internally) - which is the 'closed' date
    closed: 0,
    invoiceNumber: "",
    invoice: new Date(),
    machine: newMachineMaster("A"),
    created: new Date(),
    address: newCustomerAddress(),
    contact: newCustomerContact(),
    dealerContact: newContact(),
    journals: [],
    lines: [],
    errors: [],
    visits: [],
    attachments: [],
    validations: [],
    mapsOrder: 0,
    visitOrder: 0,
    visitDate: new Date(),
    repeatCount: 0,
    unpaid: 0,
    paid: 0,
    paymentDate: new Date(),
    holds: [],
    holdTypeId: "",
    contract: null,
    machines: [],// PROBABLY ON USED FOR front end
    machineCount: 0,
    consent: "",
    edi: [],
    messages: [],
    followUps: [],
    checklist: null,
    ...overrides
  };
}
export interface JobDto {
  jobId: string;
  typeId: string;
  statusId: string;
  customerId: string;
  userCaptureId: string;
  sourceId: string;
  logged: Date;
  faultCodeId: string;
  faultDescription: string;
  resourceId: string;
  booked: Date; // can be from the Booked date selector or the Slot date
  nextAction: Date;
  customerRef: string;
  ourRef: string;
  purchaseOrderNumber: string;
  componentCodeId: string;
  diagnosticCodeId: string;
  repairCodeId: string;
  noteInternal: string;
  noteExternal: string;
  field1: string;
  field2: string;
  field3: string;
  field4: string;
  field5: string;
  completed: Date;
  closed: number;
  invoiceNumber: string;
  invoice: Date;
  machine: MachineMasterDto;
  created: Date;
  address: CustomerAddressDto;
  contact: CustomerContactDto;
  dealerContact: ContactDto;
  journals: JobJournalDto[];
  lines: JobLineDto[];
  errors: JobErrorDto[];
  visits: JobVisitDto[];
  attachments: BaseAttachmentDto[];
  validations: JobValidationDto[];
  mapsOrder: number;
  visitOrder: number;
  visitDate: Date;
  repeatCount?: number;
  unpaid: number;
  paid: number;
  paymentDate: Date;
  holds: JobHoldDto[];
  holdTypeId?: string;

  contract?: ContractDto;
  machines?: JobMachineDto[];
  machineCount?: number;
  consent?: string;
  edi?: JobEdiDto[];
  messages?: JobMessageDto[];
  followUps?: JobFollowUpDto[];
  metrics?: MetricDto[];

  checklist?: ChecklistEntryDto;
}

/**
 * generateCheckDigit - it generates a check digit to append to a job id url for public consumption (.e.g KJ012345/ABC}
 * @param id the JOB Id
 * @returns string of 3 chars long
 */
export function generateCheckDigit(id: string): string
{
  id = id.replace(/\D/g, "");
  const cleanId = (+id * 8683) % 46657;
  const ids = [cleanId % 36, (cleanId / 36) % 36, (cleanId / 36 / 36) % 36];
  return ids.map(i => String.fromCharCode(i + (i >= 10 ? 55 : 48))).join("");
}

export function unpaidVat(self: JobDto): number {
  return self.unpaid * (1.0 + JobVat.vatRate);
}

export function cleanJobId(self: JobDto | JobSearchDto): string {
  const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidRegex.test(self.jobId) ? "Pending" : self.jobId;
}

export function totalAmount(self: JobDto | ContractDto) {
  const lines: (JobLineDto | ContractLineDto)[] = self ? self.lines : null;
  return lines ? lines.reduce((total: number, line) => total + amount(line), 0) : 0;
}

@Pipe({
  name: "job"
})
export class JobPipe implements PipeTransform {
  transform(job: JobDto, format?: string): string {
    const np = new DecimalPipe("en-ZA");
    let value = 0;
    switch (format) {
      case "cleanJobId":
        return cleanJobId(job);
      case "totalAmount":
        value = totalAmount(job);
        break;
      case "days":
        value = Math.floor(((job.completed || Date.today()).valueOf() - job.logged.valueOf()) / (1000 * 3600 * 24));
        return value.toString();
        /*case "costPrice":
        value = costPrice(job);*/
    }
    return np.transform(value, "1.2-2");
  }
}

