import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AddressDto, isAddress } from "@shared/models";

@Directive({ selector: "a[map]" })
export class MapDirective implements OnChanges {
  @Input("map") public coordinates: google.maps.LatLngLiteral | AddressDto;

  constructor(private el: ElementRef, private renderer: Renderer2, private sanitizer: DomSanitizer) {
    this.renderer.setAttribute(this.el.nativeElement, "target", "_blank");
  }

  ngOnChanges(changes: SimpleChanges) {
    let coordinates = "";
    if (isAddress(this.coordinates)) {
      const coords = this.coordinates.gpsCoordinates;
      coordinates = coords ? coords.toString() : "";
    } else if (this.coordinates) {
      coordinates = this.coordinates.lat + ", " + this.coordinates.lng;
    }
    const url = `https://maps.google.com/maps?z=12&T=m&q=loc:${coordinates}`;
    this.renderer.setAttribute(this.el.nativeElement, "href", url);
  }
}
