const favIconMap = new Map<string, string>([
  ["service.kic.co.za", "assets/tenants/kic/favicon.ico"],
  ["wms.kic.co.za", "assets/tenants/kic/favicon.ico"],
]);
const themeMap = new Map<string, string>([
  // ["service.kic.co.za", "styles-kic.css"],
  // ["wms.kic.co.za", "styles-kic.css"],
  ["/kic", "styles-kic.css"],
  ["/wsal", "styles-kic.css"],
  ["/whirlpool", "styles-whirlpool.css"],
  ["/raleigh", "styles-raleigh.css"],
  ["/genric", "styles-genric.css"],
  ["/trojan", "styles-trojan.css"],
  ["/skelcore", "styles-evo-skelcore.css"],
  ["/ozone", "styles-ozone.css"],
  ["/johnson", "styles-johnson.css"],
  ["/matrix", "styles-matrix.css"],
  ["/horizon", "styles-horizon.css"],
  ["/vision", "styles-vision.css"],
  ["/evom-demo/public/catalog", "styles-evo-product-catalog.css"],
  ["/evom/public/catalog", "styles-evo-product-catalog.css"],
]);

let originalFavicon: string;
export function changeFavicon(): void {
  let faviconLink: HTMLLinkElement;
  const hostname = window.location.hostname;
  const links = document.getElementsByTagName("link");
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    if (link.rel === "icon") {
      if (link.href.includes("favicon.ico")) {
        faviconLink = link;
        originalFavicon = link.href || "";
        break;
      }
    }
  }
  if(faviconLink){
    if(favIconMap.has(hostname)){
      faviconLink.href = favIconMap.get(hostname) || "";
    } else {
      faviconLink.href = originalFavicon;
    }
  }
}

let originalHref: string;
/**
 * Set the Tenant theme before the App module is initialised
 * note that this function is duplicated on nav-bar - we should look at a single place to control this
 */
let themedLink: HTMLLinkElement;
export function changeTheme(): void {
  const documentURL = document.URL;
  if (documentURL.includes("/jx") || documentURL.includes("/public")) {
    const links = document.getElementsByTagName("link");
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (link.rel === "stylesheet") {
        if (link.href.includes("styles-app.")) {

          // Give the Teme Link a data-theme attribute if it doesn't have one
          if(!link.getAttributeNode("data-theme")?.value) {
            const dt = document.createAttribute("data-theme");
            dt.value = "default";
            link.setAttributeNode(dt);
          }

          themedLink = link;
          originalHref = link.href || "";
          break;
        }
      }
    }

    if (themedLink) {
      const hasTheme = Array.from(themeMap).some(([key]) => documentURL.includes(key));
      if (hasTheme) {
        const themeRecord = Array.from(themeMap).find(([key]) => documentURL.includes(key));
        applyTheme(themeRecord);
      }
      else {
        applyDefaultTheme();
      }
    }

  } else {
    // non-public pages -> return to default theme
    applyDefaultTheme();
  }
}

function applyTheme(theme: string[]){
  if(themedLink && !themedLink.href.includes(theme[1])){
    themedLink.href = theme[1];
    const dt = themedLink.attributes.getNamedItem("data-theme");
    if(dt)
      dt.value = theme[0];

    console.log("Theme applied", theme[0]);
    return;
  }
  console.log("Theme already applied", theme[0]);
}

function applyDefaultTheme(){
  if(themedLink && themedLink.href !== originalHref){
    themedLink.href = originalHref;
    const dt = themedLink.attributes.getNamedItem("data-theme");
    if(dt)
      dt.value = "default";

    console.log("Default Theme applied");
    return;
  }
  console.log("Default Theme already applied");
}
