import { Directive, Input, OnChanges } from "@angular/core";
import { LookupObjectDto } from "@shared/models";
import { Observable } from "rxjs";
import { WarehouseService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * DealerBranches
 */
@Directive({
  selector: "input[abiTypeahead][abiLoadConfig]"
})
export class LoadConfigTypeaheadDirective extends BaseObservableTypeaheadHelper<LookupObjectDto> implements OnChanges {

  @Input() carrierFilter: string;

  constructor(typeAhead: TypeaheadDirective, private service: WarehouseService) {
    super(typeAhead, [])
    this.loading = false;
  }

  protected getFull(item: LookupObjectDto): string {
    return `${item.code} ~ ${item.description}`;
  }

  protected getId(item: LookupObjectDto): string {
    return item.code;
  }

  protected getName(item: LookupObjectDto): string {
    return item.description
  }

  protected filteredList(term: string): Observable<LookupObjectDto[]> {
    return this.service.getLoadConfigurations(term, this.carrierFilter);
  }
}
