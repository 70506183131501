<div class="input-group">
    <ng-content></ng-content>
    <div class="input-group-append">
        <button
        [matMenuTriggerFor]="appMenu"
        type="button"
        class="btn btn-outline-primary dropdown-toggle w-100"
        [disabled]="false"
    >
    </button>
    <mat-menu #appMenu="matMenu" class="py-0">
        <span (click)="$event.stopPropagation()" class="background">
            <mat-selection-list #colss (selectionChange)="toggleSelection($event)">
                <mat-list-option
                    color="primary"
                    *ngFor="let opt of options"
                    checkboxPosition="before"
                    [selected]="selected.has(valueProperty ? opt[valueProperty] : opt)"
                    [value]="valueProperty ? opt[valueProperty] : opt"
                    class="text-nowrap row-height"
                >
                    {{ label(opt) }}
                </mat-list-option>
            </mat-selection-list>
        </span>
    </mat-menu>
</div>


