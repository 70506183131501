import { Injectable } from "@angular/core";
import { AppQuery } from "@modules/common/app.store";
import * as Sentry from "@sentry/angular-ivy";
import { ServerEnvironment } from "src/util";

@Injectable({
  providedIn: "root"
})
export class LoggingService {
  constructor(private appQuery: AppQuery){}

  // Keep loggin functions limited to non-production to help provide more error context
  consoleServerEnvs = [ServerEnvironment.LOCALHOST, ServerEnvironment.DEV, ServerEnvironment.TEST, ServerEnvironment.BETA]
  public console(message: string): void {
    if(this.consoleServerEnvs.includes(this.appQuery.serverEnvironment as ServerEnvironment))
      console.log(message);
  }

  /**
   * Loggin convenience function to track issues that may come up in production
   * Sentry is initialised for certain Envs
   * @param e system generated exception
   */
  public logException(e: any) {
    Sentry.captureException(e);
    console.error(e?.message || e);
  }
}
