import { Injectable } from "@angular/core";
import {
  BaseAttachmentDto,
  FullModelMasterDto,
  generateCheckDigit,
  ListResultDto,
  LookupListEx,
  MachineCategoryDto,
  MachineMasterDto,
  MachineMasterWithCustomerDto,
  SearchDto,
} from "@shared/models";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import moment from "moment";
import { MachineCategories } from "../components";
import { TypeaheadFilter } from "../directives/base-typeahead-helper";
import { DataService, Progress, ServiceConfig } from "./data.service";
import { LookupListService } from "./lookup-list.service";
import { ProductSettingService } from "./product-setting.service";

@Injectable({
  providedIn: "root",
})
export class MachineService extends DataService {
  hiddenCategories: boolean[] = [];
  localMachineCategories: MachineCategories[]= [];
  machineCategories: Observable<MachineCategories[]>;// = new Observable();
  categoryFilters: Observable<((filters: string[]) => TypeaheadFilter)[]>;// = new Observable();// memoised filters - better performance
  categoryCount: number;
  constructor(protected config: ServiceConfig, private lookups: LookupListService, private settings: ProductSettingService) {
    super(config);
    this.categoryCount = settings.numericValue(`MachineCategoryCount`) || 5;
    this.machineCategories = this.getCategories(Array(this.categoryCount).fill(0).map((v, i) => `category_${i + 1}`))
    .pipe(tap(cats => {
      this.localMachineCategories = cats;
      // console.log('getCategories', cats);
    }));
    this.categoryFilters = this.machineCategories.pipe(map(cats => this.generateCategoryFilters(cats.length)))
    .pipe(tap(cats => {
      // console.log('generateCategoryFilters', cats);
    }));
  }

  queryLoanMachines(
    term: string,
    // warehouseId: string,
    query: string
  ): Observable<MachineMasterDto[]> {
    return this.http.get<MachineMasterDto[]>(
      `machines/loans/${term}?query=${query || ""}` // ${warehouseId || ""}
    );
  }

  queryMachines(query: SearchDto): Observable<ListResultDto<MachineMasterDto>> {
    let url = `machines/search?pageNumber=${query.pageNumber}&pageSize=${
      query.pageSize
    }&orderBy=${query.orderBy[0] || ""}`;

    // eslint-disable-next-line guard-for-in
    for (const key in query.filters) {
      const value = query.filters[key];
      url = url + `&${key}=${value}`;
    }

    return this.http.get<ListResultDto<MachineMasterDto>>(url);
  }

  getMachineWithCustomer(
    machineId: string
  ): Observable<MachineMasterWithCustomerDto> {
    return this.http.get<MachineMasterWithCustomerDto>(
      `machines/${this.safeEncode(machineId)}/withcustomer`
    );
  }

  getMachine(machineId: string, pathExtras?: string): Observable<MachineMasterDto> {
    return this.http.get<MachineMasterDto>(
      `machines/${this.safeEncode(machineId)}${pathExtras || ''}`
    );
  }

  getFullModel(modelId: string): Observable<FullModelMasterDto> {
    return this.http.get<FullModelMasterDto>(
      `machines/model/${modelId}/full`
    );
  }

  newMachine(machine: MachineMasterDto): Observable<MachineMasterDto> {
    return this.http.post<MachineMasterDto>("machines", machine);
  }

  updateMachine(machine: MachineMasterDto): Observable<MachineMasterDto> {
    return this.http.put<MachineMasterDto>(
      `machines/${this.safeEncode(machine.machineId)}`,
      machine
    );
  }

  uploadAttachment(machineId: string, file: File, description: string, progress: Progress, _generateCheckDigit = false, extras?: any, typeId?: string):
  Observable<BaseAttachmentDto> {
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    const url = `machines/${machineId}${_generateCheckDigit ? '/' + generateCheckDigit(machineId) : ''}/attachment${typeId && `/${typeId}` || ''}`;
    return this.http.post<BaseAttachmentDto>(
      url,
      formData,
      {
        reportProgress: true,
        observe: "events"
      }
    )
    .pipe(this.uploading(progress));
  }

  downloadLink(attachment: BaseAttachmentDto, parentId?: string): string {
    if (parentId) {
      parentId += "/";
    }
    return `machines/${parentId}attachment/${attachment.attachmentId}`;
  }

  downloadAttachment(parentId: string, attachmentId: string): Observable<Blob> {
    return this.http
      .get(`machines/${parentId}/attachment/${attachmentId}`, { responseType: "blob" });
  }

  deleteAttachment(parentId: string, attachmentId: string): Observable<any> {
    return this.http.delete(`machines/${parentId}/attachment/${attachmentId}`);
  }

  /**
   * General Categories fetcher - implement this in MachineCategories/SkillCategories...etc.
   * @todo to be implemented as noted above
   */
  getCategories(webCats: string[]): Observable<MachineCategories[]> {
    const catsObs = this.lookups.lookupListEx<MachineCategoryDto>("CodeMachineCategory");
    this.hiddenCategories = [];
    return catsObs
    .pipe(map(cats => {
      // GET ALL ACTIVE CATEGORIES
      const mainCategories = cats.values.filter(c => c.active);
      let lastCat: MachineCategoryDto = null;
      let idx = mainCategories.length - 1;
      // ITERATE BACKWARDS OVER LIST
      while (idx) {
        const cat = mainCategories[idx];
        if (!cat.extras) { // add extras (code)
          cat.extras = [];
        }
        if (!lastCat || lastCat.order !== cat.order || lastCat.description !== cat.description) {
          lastCat = cat;
          lastCat.extras = [lastCat.code];
        } else {
          lastCat.extras.push(cat.code);
          mainCategories.splice(idx, 1);
        }
        idx--;
      }// dont really see the point of this...seem really specific

      const result: MachineCategories[] = [];
      const catNamePrefix = 'Machine.Category';
      for (let i = 0; i < webCats.length; i++) {
        const id = i + 1;
        const cat = webCats.find(c => c.includes("" + id)) || "";
        const name = `${catNamePrefix}${id}`;
        const items = mainCategories.filter(v => v.order === id && v.active);// get current 'order' items
        result.push({
          name,
          // filter, wtf?
          filters: items.filter(ii => !!ii.filter).map(f => f.filter).filter((value, index, self) => self.indexOf(value) === index),
          items: new LookupListEx<MachineCategoryDto>(items),
          visible: !!cat,
        });

        this.hiddenCategories.push(!cat);
      }
      return result;
    }),
  );
  }

  public getMachineCategories() {
    return this.machineCategories;
  }

  public getCategoryFilters() {
    return this.categoryFilters;
  }

  generateCategoryFilters(catCount: number = 5) {
    return Array(catCount).fill(0).map((v, i) => {
      return (currentCategoryFilters: string[]) => (item, term) => this.categoryFilter(i, item, currentCategoryFilters);
    });
  }

  /**
   * Were assuming that the Item will only be in the current level of the category - so lets make sure it is
   * @param level
   * @param item
   * @param categoryColumnFilters
   * @returns
   */
  categoryFilter(level: number, item: MachineCategoryDto, categoryColumnFilters: string[]): boolean {
    const itemInCurrentCategory = this.localMachineCategories[level].items.values.some(lmc => lmc.code === item.code);
    if(!itemInCurrentCategory) return false;
    if (this.localMachineCategories[level].filters && item.parentId) {
      const categoryColumnFilterIndex = (+item.parentId) - 1;
      const pFilter = categoryColumnFilters[categoryColumnFilterIndex];
      if (!pFilter) {
        return true;
      } else {
        return this.localMachineCategories[level].filters.includes(pFilter as string)
        ? (item.filter === pFilter)
        : !item.filter;
      }
    } else {
      return !item.filter;
    }
  }
}
