<ng-content></ng-content>

<h3 *ngIf="layouts?.length" mat-subheader>{{ 'Layout.Saved' | translate }}</h3>
<!-- QUICK -->
<button
    *ngFor="let layout of quickLayouts"
    type="button"
    mat-list-item
    [ngClass]="{ 'router-link-active': layoutId === layout.layoutId}"
    (click)="activateLayout(layout)"
    >
    <i matListIcon [ngClass]="[layout.icon, 'fa-fw text-primary mr-2']"></i>
    <div>
        <div>{{ layout.caption }}</div>
        <!-- <div class="short"><small class="text-muted">{{ layout.description | truncate  }}</small></div> -->
    </div>
</button>

<!-- MENU LAYOUTS -->
<span *ngIf="menuLayouts?.length" (click)="$event.stopPropagation()" class="background">
    <button mat-list-item [matMenuTriggerFor]="layoutMenu">
        <i matListIcon class="fas fa-ellipsis-v fa-fw text-primary mr-2"></i>
        {{ 'Layout.More' | translate }}
    </button>
</span>

<mat-divider></mat-divider>

<!-- CLEAR -->
<a mat-list-item (click)="clearLayout()" [class]="{ disabled: !layoutId }" [disabled]="!layoutId">
    <i matListIcon class="fas fa-undo fa-fw text-primary mr-2"></i>
    {{ 'Layout.Clear' | translate }}
</a>

<!-- SAVE AS -->
<button mat-list-item (click)="saveLayout(saveLayoutRef)">
    <i matListIcon class="far fa-save fa-fw text-primary mr-2"></i>{{ 'Layout.SaveAs' | translate }}</button>

<!-- UPDATE LAYOUT -->
<button mat-list-item (click)="updateLayout(saveLayoutRef)" [class]="{ disabled: !layoutId }" [disabled]="!layoutId">
    <i matListIcon class="fas fa-pen fa-fw text-primary mr-2"></i>{{ 'Layout.Update' | translate }}</button>

<!-- DELETE LAYOUT -->
<button mat-list-item (click)="deleteLayout(deleteLayoutRef)" [class]="{ disabled: !canDeleteLayout(layoutId) }" [disabled]="!canDeleteLayout(layoutId)">
    <i matListIcon class="far fa-trash-alt fa-fw text-primary mr-2"></i>{{ 'Layout.Delete' | translate }}</button>

<!-- LAYOUT MENU -->
<mat-menu #layoutMenu="matMenu">
    <button *ngFor="let layout of menuLayouts"
        mat-menu-item
        type="button"
        (click)="activateLayout(layout)"
        [ngClass]="{ 'router-link-active': layoutId === layout.layoutId }"
    ><i matListIcon [ngClass]="[layout.icon, 'fa-fw text-primary mr-2']"></i>
        {{ layout.caption }}
    </button>
</mat-menu>

<!-- LAYOUT DIALOG -->
<ng-template #saveLayoutRef>
  <h4 mat-dialog-title>{{ (dialogMode === 'update' ? 'Layout.Update' : 'Layout.SaveAs') | translate }} </h4>
  <mat-dialog-content>
    <form role="form" [formGroup]="form">
      <div class="row">
        <abi-form-field label="Layout.Name" [horizontal]="false" cols="col-12">
          <input type="text" formControlName="caption" name="caption" required>
        </abi-form-field>
        <abi-form-field label="Layout.Description" labelColClass="col-sm-3" [horizontal]="false" cols="col-12">
          <textarea rows="4" formControlName="description" name="description" required></textarea>
        </abi-form-field>
        <abi-form-field label="Layout.Icon" labelColClass="col-sm-3" [horizontal]="false" cols="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i [class]="codeOnly(form.value.icon) + ' fa-fw'"></i></span>
            </div>
            <input abiTypeahead abiIcon container="body" type="text" formControlName="icon" name="icon"
              [resultTemplate]="iconTemplate">
          </div>
        </abi-form-field>
        <abi-form-field label="Layout.QuickLink" cols="col-12">
          <div class="form-check pt-2">
            <input noBootstrap type="checkbox" class="form-check-input" formControlName="quickLink">
          </div>
        </abi-form-field>
        <abi-form-field *ngIf="form.get('systemLayout').enabled" label="System Layout" cols="col-12">
          <div class="form-check pt-2">
            <input noBootstrap type="checkbox" class="form-check-input" formControlName="systemLayout">
          </div>
        </abi-form-field>
        <abi-form-field *ngIf="form.get('defaultLayout').enabled" label="Default" cols="col-12">
            <div class="form-check pt-2">
              <input noBootstrap type="checkbox" class="form-check-input" formControlName="defaultLayout">
            </div>
          </abi-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{ 'System.Cancel' | translate }}</button>
    <button *ngIf="dialogMode === 'update'; else saveAsButton" [disabled]="!canUpdateLayout()" cdkFocusInitial mat-button
        (click)="save()">
        {{ 'System.Save' | translate }}</button>
        <ng-template #saveAsButton>
            <button [disabled]="form?.invalid" mat-button (click)="saveAs()">
                {{ 'System.SaveAs' | translate }}</button>
        </ng-template>
  </mat-dialog-actions>
</ng-template>

<ng-template #iconTemplate let-r="result" let-t="term">
    <i [class]="r.code + ' fa-fw'"></i>&nbsp;
    <ngb-highlight [result]="r.description" [term]="t"></ngb-highlight>
</ng-template>


<!-- LAYOUT DIALOG -->
<ng-template #deleteLayoutRef>
    <h4 mat-dialog-title>{{ 'Layout.Delete' | translate }} </h4>
    <mat-dialog-content>
        <!-- This layout -->
        <p>This layout is a System layout and is visible all users.</p>
        <!-- question? -->
        <p>Are you sure you want to Delete this layout?</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close="">{{ 'System.Cancel' | translate }}</button>
      <button ngbAutofocus [disabled]="!canDeleteLayout(layoutId)" mat-button
          mat-dialog-close="delete">
          {{ 'System.Delete' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>
