import { CustomerAddressDto, PickLineDto } from ".";



// public string StatusId { get; set; }
// public string LoadId { get; set; }

// public string CarrierId { get; set; }
// public string Reference { get; set; }
// public string VehicleId { get; set; }
// public string DriverId { get; set; }
// public DateTime? PlanDate { get; set; }

// public DateTime? RequestedDate { get; set; }
export interface PickListDto {
  pickListId: string;
  consolidatedPickListId: string;
  deliveryNoteId: string;
  orderId: string;
  customerId: string;
  // pickDate: Date;
  zoneId: string;
  statusId: string;
  confirmedDate: Date;
  confirmedDate2: Date;
  confirmedDates: Date[];
  deliveredDate: Date;
  orders: number;
  totalLines: number;
  ordered: number;
  delivered: number;
  picked: number;
  lines: PickLineDto[];
  deliveryAddress?: CustomerAddressDto;

  loadId: string;
  carrierId: string;
  reference: string;
  vehicleId: string;
  driverId: string;
  planDate: Date;
  pickDate?: Date;
  shipDate?: Date;
  requestedDate: Date;
}

export function lookupStatus(status: string): string {
  switch (status) {
    case "A":
      return "Available";
    case "C":
      return "Created";
    case "D1":
      return "Delivered (Part)";
    case "D":
    case "D2":
      return "Delivered";
    case "P1":
      return "Printed"; // (Part)";
    case "P":
    case "P2":
      return "Printed";
    default:
      return status;
  }
}

export function pickListStatus(item: PickListDto): string {
  if (!item) {
    return "";
  }
  const statuses = item.statusId.split(" ");
  const status = lookupStatus(statuses[0]);
  return statuses.length === 2 ? status + " " + statuses[1] : status;
}

export function picklistIsPrinted(list: PickListDto){
  return valueIsPrinted(list.statusId);
}

export function valueIsPrinted(val: string){
  return val.substring(0, 1)?.toUpperCase() === 'P';
}

// TODO: Picklist Printed and Shipping Label Printed

