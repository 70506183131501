import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { JobSummaryUserDto, Mode } from "@shared/models";
import { AppQuery } from "../../app.store";
import { UserService } from "../../services";

const SELECT_USER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectUserComponent),
  multi: true
};

@Component({
  selector: "abi-select-user",
  templateUrl: "./select-user.component.html",
  styleUrls: ["./select-user.component.scss"],
  providers: [SELECT_USER_VALUE_ACCESSOR]
})
export class SelectUserComponent implements OnInit, ControlValueAccessor, OnChanges {
  private _isAll: boolean;
  private _lastUser: string;
  private _currentUser: JobSummaryUserDto;
  private _next: any;
  disabled = false;

  private _filter: Mode[];

  @Input() get filter(): Mode[] {
    return this._filter;
  }

  set filter(value: Mode[]) {
    this._filter = value;
  }

  @Input() allowClear: boolean;
  @Input() clearLabel = "All";
  @Input() display = "all";
  @Output() selected: EventEmitter<JobSummaryUserDto> = new EventEmitter();

  private _onTouched = () => { };
  private _onChange = (_: any) => { };

  get currentUser(): JobSummaryUserDto {
    return this._currentUser;
  }

  set currentUser(value: JobSummaryUserDto) {
    this.writeValue(value);
  }

  allUsers(): boolean {
    return (this._isAll && this.allowClear) || this.disabled;
  }

  constructor(public userService: UserService, private appQuery: AppQuery) { }

  ngOnInit() {
    // this.writeValue(this.userService.currentUser);
    this.userService.usersLoaded.subscribe(r => {
      this.ngOnChanges(null);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._next) {
      this.writeValue(this._next);
    }
  }

  selectUser(user: JobSummaryUserDto) {
    this._onChange(user);
    this.selected.emit(user);
  }

  private doWriteValue(user: any): void {
    this._next = user;
    this._lastUser = null;
    const user0 = !this.filter ? this.userService.users[0] : this.userService.users.find(u => this.filter.includes(u.mode));
    let newUser = typeof user === "object" ? user : null;

    if (!newUser) {

      let id = user && typeof user === "object" ? user.userId : user;
      if (!id || id === "_" || id === "*") {
        const ex = this.userService.currentUser;
        id = ex ? ex.userId :
          this.appQuery.username;

        newUser = this.userService.users.find(u => u.userId === id && (!this.filter || this.filter.includes(u.mode))) || user0;
      }
    }

    /*if (this.allowClear && id === "*" && !this._isAll) {
      this.clearResource();
    } else */

    if (!this._currentUser || !newUser || this._currentUser.userId !== newUser.userId || this._currentUser.mode !== newUser.mode) {
      this._currentUser = newUser;
      this.userService.currentUser = this._currentUser;
      this.selectUser(this._currentUser);
    }
  }

  writeValue(user: any): void {
    if (this.userService.users) {
      this.doWriteValue(user);
    } else {
      this._next = user;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  blur() {
    this._onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clearResource() {
    if (this._isAll) {
      this._currentUser = this.userService.users.find(u => u.userId === this._lastUser);
      this.selectUser(this._currentUser);
      this._isAll = false;
      this._lastUser = null;
    } else {
      this._isAll = true;
      this._lastUser = this.userService.currentUser.userId;
      this.selectUser(null);
      this._currentUser = null;
    }
  }


}
