import { Directive, Input } from "@angular/core";
import { WebLayoutDto } from "@shared/models";
import { Observable } from "rxjs";
import { WebLayoutService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiLayout]"
})
export class LayoutTypeaheadDirective extends BaseObservableTypeaheadHelper<WebLayoutDto> {
  @Input() abiLayout: string;

  constructor(typeAhead: TypeaheadDirective, private layoutService: WebLayoutService) {
    super(typeAhead, ["abiLayout"]);
    this.liveResults = false;
  }

  protected getFull(item: WebLayoutDto): string {
    return item.caption;
  }

  protected getId(item: WebLayoutDto): string {
    return item.layoutId;
  }
  protected getName(item: WebLayoutDto): string {
    return item.caption;
  }

  protected filteredList(term: string): Observable<WebLayoutDto[]> {
    return this.layoutService.layouts.selectEntity(this.abiLayout, x => x.layouts);
  }

}

