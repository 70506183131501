import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import { FormArray } from "@angular/forms";

function removeError(control: AbstractControl, error: string) {
  const err = control.errors; // get control errors
  if (err) {
    delete err[error]; // delete your own error
    if (!Object.keys(err).length) { // if no errors left
      control.setErrors(null); // set control errors to null making it VALID
    } else {
      control.setErrors(err); // controls got other errors so set them back
    }
  }
}

// this function adds a single error
function addError(control: AbstractControl, error: string) {
  const errorToSet = {};
  errorToSet[error] = true;
  control.setErrors({...control.errors, ...errorToSet});
}

export function AtLeastOneFieldValidator(fields?: string[]): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    let valid = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (control in group.controls && (!fields || fields.includes(control)) &&
          group.controls[control].valid && group.controls[control].value) {
          valid = true;
        }
      }
    }
    // eslint-disable-next-line guard-for-in
    for (const control in group.controls) {
      const ctrl = group.controls[control];
      if(valid) removeError(ctrl, "atleastone");
      else addError(ctrl, "atleastone");
    }
    return valid ? null : { atleastone: true };
  };
}

export function isDifferentValidator(field1: string, field2: string): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    let valid = false;
    if (group && group.controls) {
      valid = !group.get(field1).value || !group.get(field2).value || group.get(field1).value !== group.get(field2).value;
    }
    // eslint-disable-next-line guard-for-in
    for (const control in group.controls) {
      const ctrl = group.controls[control];
      if (valid) removeError(ctrl, "different");
      else addError(ctrl, "different");
    }
    return valid ? null : { required: true };
  };
}

export function atLeastOneEntryValidator(): ValidatorFn {
  return AtLeastOneEntryValidator;
}

export function AtLeastOneEntryValidator(array: FormArray): { [key: string]: any } {
  const isAtLeastOne = array && array.length;
  return isAtLeastOne ? null : { required: true };
}

export function arrayValidator(name: string, min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && control.value.length < min) {
          return { [name]: true };
      }
      return null;
  };
}
