import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatSelectionListChange } from "@angular/material/list";
import { MatMenuTrigger } from "@angular/material/menu";
import { PublishSubscribe } from "@modules/common";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, from } from "rxjs";
import { TableColumn } from "..";
import { TreeNode } from "../column-chooser/column-chooser.component";

@Component({
  selector: "abi-column-selector",
  templateUrl: "./column-selector.component.html",
  styleUrls: ["./column-selector.component.scss"]
})
export class ColumnSelectorComponent implements OnInit, OnChanges {
  @Input() columns: TableColumn[];
  @Output() toggled: EventEmitter<TableColumn> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  nestedColumns: TreeNode[];

  constructor(private pubsub: PublishSubscribe, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.columns) {
      forkJoin(this.columns.map(c => c.header
        ? ( c.header.includes(".") ? this.translate.get(c.header) : from([c.header]))
        : from([""])))
      .subscribe((newHeaders: string[]) => {
        // Update Column Headers in one go - so that we may filter them next
        this.columns.forEach((c, i) => {
          c.realHeader = newHeaders[i];
        });
        // Group and Filter
        this.nestedColumns = ([... new Set(this.columns.map(c => c.group))] as string[])
        .map(r => (
          {
            name: r,
            columns: this.columns.filter(cc => cc.group === r && cc.realHeader && !cc.static)
          }
        ))
        .filter(nest => nest.columns.length);
      });
    }
  }

  ngOnInit() {
  }

  // Static Columns are never selectable (always visible)
  visibleColumns(): TableColumn[] {
    return this.columns ? this.columns.filter(c => c.realHeader && !c.static) : [];
  }

  toggleColumn(option: MatSelectionListChange): void {
    const tc = option.options[0].value as TableColumn;
    tc.hidden = !option.options[0].selected;
    this.toggled.emit(tc);
  }

  closed() {
    this.pubsub.publish("closeSidebar");
  }

  public toggle(): void {
    this.trigger.toggleMenu();
  }
}
