import { Injectable } from "@angular/core";
import { JobFollowUpDto } from "@app/shared/models/jobs/JobFollowUpDto";
import { DataService, ServiceConfig } from "@modules/common";
import { BehaviorSubject, combineLatest, Observable, of, timer } from "rxjs";
import {
  catchError,
  filter,
  share,
  shareReplay,
  switchMap,
} from "rxjs/operators";
import { AppQuery, LoginStatus } from "../app.store";
import { JobService } from "./job.service";
import { ProductSettingQuery, ProductSettingService } from "./product-setting.service";

/**
 * Followups Data is now shareable for any subscribers
 */
@Injectable({
  providedIn: "root",
})
export class FollowupsService extends DataService {
  followups$: Observable<JobFollowUpDto[]>;
  badgeCount$: Observable<number>;
  externalTrigger: BehaviorSubject<string> = new BehaviorSubject('');

  countIncludeToday = false;
  countIncludeOverdue = false;

  constructor(
    appQuery: AppQuery,
    config: ServiceConfig,
    public jobService: JobService,
    public productSetting: ProductSettingService,
    private query: ProductSettingQuery
  ) {
    super(config);

    this.query.selectLoading().pipe(filter(loading => !loading)).subscribe(() => {
      this.countIncludeToday = this.productSetting.booleanValue("FollowupsCountIncludeToday");
      this.countIncludeOverdue = this.productSetting.booleanValue("FollowupsCountIncludeOverdue");
    });
    const queryInterval = timer(0, 2 * 60 * 1000);
    this.followups$ = combineLatest([appQuery.$loginStatus, appQuery.$tenant2, queryInterval, this.externalTrigger])
    .pipe(
      this.notDisposed(),
      filter(([l, t]) => !!t && LoginStatus.True === l),
      switchMap(() => this.jobService.getFollowUps()),
      catchError((err) => {
        // cannot rely on the above filters for correct queries as some browsers queue up the timed requests, and execute once awake
        return of([]); // handle errors with empty data[]
      }),
      share(),
      shareReplay(1), // a single (most recent) replay is sufficient
    );
    this.badgeCount$ = this.followups$.pipe(switchMap((items: JobFollowUpDto[]) => {
      return of(items.filter((item) => this.filterFollowup(item)).length);
    }));
  }

  public updateFollowups() {
    this.externalTrigger.next("update");
  }

  filterFollowup(dto: JobFollowUpDto): boolean {
    const now = new Date();
    const endOfDay = now.endOf("day");

    // everything before now
    if (this.countIncludeOverdue && +dto.followUpDate < +now) {
      return true;
    }
    // now until end-of-day
    if (this.countIncludeToday && +dto.followUpDate > +now && +dto.followUpDate < +endOfDay) {
      return true;
    }
    // future
    return false;
  }

  getBadgeCountObservable() {
    return this.badgeCount$;
  }

  getFollowupsObservable() {
    return this.followups$;
  }
}
