import { Progress } from "@app/modules/common";
import { Observable } from "rxjs";

export const AttachmentType = {
  ABI: "ABI",
  CAPTURE: "CAP",
  DOCUMENT: "DOC",
  IMAGE: "IMA",
  PDF: "PDF",
  REPORT: "REP",
  SCAN: "SCA",
  SIGNATURE: "SIG",
  VIDEO: "VID",
  VOICE: "VOI",
  OTHER: "OTH",
} as const;

/**
 * For abi-Capture component - includes description that is user editable
 */
export interface FileEvent {
  description: string,
  file: File
}

/**
 * Implement this Interface for all Services that provide Attachment Upload/Download
 */
export abstract class AttachmentService {
  abstract uploadAttachment(parentId: string, file: File, description: string, progress: Progress, _generateCheckDigit: boolean, extras?: any, typeId?: string):
  Observable<BaseAttachmentDto>;
  abstract downloadAttachment(parentId: string, attachmentId: string, extras?: any): Observable<Blob>;
  abstract deleteAttachment(parentId: string, attachmentId: string, extras?: any): Observable<void>;
  abstract downloadLink(attachment: BaseAttachmentDto, parentId?: string, extras?: any): string;
  abstract fullUrl(
    attachment: BaseAttachmentDto,
    parentId?: string,
    extras?: any
  ): string;
}

export interface BaseAttachmentDto {
  /**
   * Unique ID
   */
  attachmentId: string;
  /**
   * Upload User
   */
  by?: string;
  /**
   * Original File-Name
   */
  source: string;
  /**
   * File MIME type
   */
  mimeType: string;
  /**
   * API URL to access file over web
   */
  storage: string;
  /**
   * Description (Link Text)
   */
  link: string;
  /**
   * Date Uploaded
   */
  date: Date;
  /**
   * Last modification Date of the File
   */
  lastModified?: Date;
  /**
   * Use for categorisation
   */
  typeId?: string;
}


export function isImage(attachment: BaseAttachmentDto): boolean {
  return attachment.mimeType?.startsWith("image");
}
