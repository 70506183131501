import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LookupListService } from "@modules/common/services/lookup-list.service";
import { LookupObjectDto } from "@shared/models";
import { WebLayoutService } from "@services";
import { LookupTypeaheadDirective } from "../../directives";
import { BaseFormDirective } from "../base-form";

/**
 * @deprecated Was intended to Add new Codes on the fly, but was not needed in the End
 */
@Component({
  selector: "abi-lookup-edit",
  templateUrl: "./lookup-edit.component.html",
  styleUrls: ["./lookup-edit.component.scss"]
})
export class LookupDialogComponent extends BaseFormDirective {
  public validationMessages = {
  };
  @Input() listName: string;
  @Input() order = 0;
  @Output() newCode: EventEmitter<LookupObjectDto> = new EventEmitter();

  @ContentChild(LookupTypeaheadDirective) innerInput: LookupTypeaheadDirective;

  @ViewChild("codeDialog")
  private codeDialog: TemplateRef<any>;

  public showButton = false;
  constructor(
    protected layoutService: WebLayoutService,
    formBuilder: FormBuilder,
    private dialog: MatDialog,
    private lookups: LookupListService) {
    super(layoutService, formBuilder);
    this.showButton = false; // productSettingService.booleanValue("AllowAdhocLookups");
  }

  protected createForm() {
    this.form = this.formBuilder.group({
      code: ["", Validators.required],
      description: ["", Validators.required],
    });
    super.createForm();
  }

  saveNewCode(ref: MatDialogRef<any, any>) {
    const dto: LookupObjectDto = {
      code: this.form.value.code,
      description: this.form.value.description,
      active: true,
      order: this.order,
    };
    this.lookups.newLookup(this.listName || this.innerInput.abiLookup.toString(), dto).toPromise().then(dd => {
      ref.close();
      this.newCode.emit(dd);
    });
  }

  showDialog() {
    const c = this.innerInput;
    const ref = this.dialog.open(this.codeDialog);
  }
}
