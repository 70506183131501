/**
 * @deprecated seems like this is no longer used or needed
 */
export enum Mode {
  Resource,
  Team,
  Logged,
  // Global,
  Owner,
  Manager,
  Controller,
  Brand,
  Warehouse,
  DealerUser,
  WarehouseUser,
  User,
  Dealer,
  Branch,
  Source,
  Status,
  Salesman
}

export interface JobSummaryUserDto {
  userId: string;
  resource: string;
  mode: Mode;
}

export function modeToString(self: JobSummaryUserDto): string {
    let prefix = Mode[self.mode];
    switch (self.mode) {
      case Mode.WarehouseUser:
      case Mode.DealerUser:
        prefix = self.resource;
        break;
    }
    return prefix;
  }

  /*
  toString() {
    return self.modeToString() + " ~ " + self.userId;  // (self.mode !== Mode.Resource ? + " ~ " : "") + ;
  }*/


export const ALL_MODES: Mode[] = [
  Mode.Resource,
  Mode.Team,
  Mode.Logged,
  Mode.Owner,
  Mode.Manager,
  Mode.Controller,
  Mode.Brand,
  Mode.User
];
