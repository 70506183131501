<ng-container *ngIf="inModal; else inputSection">
    <h4 mat-dialog-title>{{ "Model.Edit" | translate }}</h4>
    <mat-dialog-content>
        <div class="container-fluidz" [formGroup]="form">
            <!-- <ng-container *ngTemplateOutlet="inputSection"></ng-container> -->
            <div class="row">
                <div class="col-6">
                    <abi-machine-categories  [categoryControls]="categoryControls" [allCategories]="true" [useCardDisplay]="false"></abi-machine-categories>
                </div>
                <div class="col-6">

                    <div class="row">
                        <abi-form-field label="Model.ID" [errors]="formErrors.modelId" cols="col-12">
                            <input formControlName="modelId" id="modelId" required [readonly]="!isNew" />
                        </abi-form-field>

                        <abi-form-field label="Model.Name" [errors]="formErrors.name" cols="col-12">
                            <input formControlName="name" id="name" required />
                        </abi-form-field>

                        <abi-form-field label="Model.BaseModel" cols="col-12">
                            <input formControlName="base" id="base" />
                        </abi-form-field>

                        <abi-form-field *ngIf="showStatus" label="Model.Active" inputColClass="col-sm custom-control custom-checkbox d-flex align-items-center" cols="col-12">
                            <input type="checkbox" class="custom-control-input" id="active" name="active" formControlName="active">
                            <label class="custom-control-label" for="active">&nbsp;</label>
                        </abi-form-field>
                    </div>
                </div>
            </div>

        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button cdkFocusInitial type="button" mat-button [disabled]="form.invalid" (click)="accept()">
        {{ "System.Ok" | translate }}</button>
        <button type="button" mat-button mat-dialog-close>
        {{ "System.Cancel" | translate }}</button>
    </mat-dialog-actions>
</ng-container>

<ng-template #inputSection>
    <div [formGroup]="form">

        <ng-container *ngIf="categoryOrder === 'first'">
            <abi-machine-categories  [categoryControls]="categoryControls" [allCategories]="true" [useCardDisplay]="false"></abi-machine-categories>
        </ng-container>

        <div class="row">
            <abi-form-field label="Model.ID" [errors]="formErrors.modelId">
                <input formControlName="modelId" id="modelId" required [readonly]="!isNew" />
            </abi-form-field>

            <abi-form-field label="Model.Name" [errors]="formErrors.name">
                <input formControlName="name" id="name" required />
            </abi-form-field>

            <abi-form-field label="Model.BaseModel">
                <input formControlName="base" id="base" />
            </abi-form-field>

            <abi-form-field *ngIf="showStatus" label="Model.Active" inputColClass="col-sm custom-control custom-checkbox d-flex align-items-center">
                <input type="checkbox" class="custom-control-input" id="active" name="active" formControlName="active">
                <label class="custom-control-label" for="active">&nbsp;</label>
            </abi-form-field>
        </div>
        <ng-container *ngIf="categoryOrder === 'last'">
            <hr />
            <h5 class="mb-3">{{ 'Model.Categories' | translate }}</h5>
            <div class="row">
                <div class="col-6">
                    <abi-machine-categories  [categoryControls]="categoryControls" [allCategories]="true" [useCardDisplay]="false"></abi-machine-categories>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showSkills">
            <hr />
            <h5 class="mb-3">{{ 'Model.Skills' | translate }}</h5>
            <div class="row">
                <abi-form-field label="Model.UseCategories" inputColClass="col-sm custom-control custom-checkbox d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="useCategories" name="useCategories" formControlName="useCategories">
                    <label class="custom-control-label" for="useCategories">&nbsp;</label>
                </abi-form-field>
            </div>
            <div class="row">
                <div class="col-6">
                    <abi-machine-skills [categoryControls]="skillControls" [readonly]="form?.value?.useCategories" [allCategories]="true"></abi-machine-skills>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="showOptions">
            <hr />
            <h5 class="mb-3">{{ 'Model.Options' | translate }}</h5>
            <abi-machine-options [categoryControls]="optionControls"></abi-machine-options>
        </ng-container>
    </div>
</ng-template>
