import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { UserCompanyDto } from "@shared/models";
import { BehaviorSubject, combineLatest, from, Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { DataService, ServiceConfig } from "../";
import { LoginStatus } from "../app.store";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService extends DataService {
  private readonly headers = { headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded") };
  static readonly SYSADMIN = "SYSADMIN";
  public refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  public refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(config: ServiceConfig) {
    super(config);
    this.session()?.removeItem("resourceId");

    const companies$ = this.getCompanies();
    combineLatest([
      this.appQuery.$loginStatus,
      this.appQuery.$tenant2
    ])
    .pipe(
      filter(([l, t]) => l === LoginStatus.True && !t),
      mergeMap(t => companies$)
    )
    .subscribe(companies => {
      this.appStore.update({
        companies: companies || [],
      });
    });
  }

  private getCompanies(): Observable<UserCompanyDto[]> {
    return this.http.get<UserCompanyDto[]>("users/companies");
  }

  public urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += "==";
        break;
      }
      case 3: {
        output += "=";
        break;
      }
      default: {
        throw new Error("Illegal base64url string!");
      }
    }
    return this.b64DecodeUnicode(output);
  }

  // credits for decoder goes to https://github.com/atk
  private b64decode(str: string): string {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";

    str = String(str).replace(/=+$/, "");

    if (str.length % 4 === 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      // eslint-disable-next-line no-cond-assign
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      // eslint-disable-next-line no-bitwise
      ~buffer &&
        (
          // eslint-disable-next-line no-cond-assign
          (bs = bc % 4 ? bs * 64 + buffer : buffer),
          // and if not first of each 4 characters,
          // convert the first 8 bits to one ascii character
          bc++ % 4
        )
        // eslint-disable-next-line no-bitwise
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  private b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      Array.prototype.map
        .call(this.b64decode(str), (c: any) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  public decodeToken(token: string): any {
    if (!token) {
      return null;
    }

    const parts = token.split(".");

    if (parts.length !== 3) {
      throw new Error("The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.");
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error("Cannot decode the token.");
    }

    return JSON.parse(decoded);
  }

  login(username: string, password: string): Observable<boolean> {
    return this.authRequest("/connect/token", {
      grant_type: "password",
      username,
      password,
      acr_values: "tenant:" + this.appQuery.tenant2
    }).pipe(
      map(response => {
        const ok = this.processTokenResponse(response);
        return !!ok;
      }));
    // .catch(err => this.handleError(err, "/connect/token"));
  }

  loginExternal(token: string, provider: string): Observable<boolean> {
    return this.authRequest("/connect/token", {
      grant_type: "external",
      provider,
      external_token: token,
      acr_values: "tenant:" + this.appQuery.tenant2
    }).pipe(
      map(response => {
        const ok = this.processTokenResponse(response);
        return !!ok;
      }));
    // .catch(err => this.handleError(err, "/connect/token"));
  }

  getRefreshToken(): string {
    return (!!this.local().getItem("rememberMe") ? this.local().getItem("refresh_token") :
      this.session().getItem("refresh_token"));
  }

  getAccessToken(): string {
    return this.local().getItem("token") || "";
  }

  doLoggedIn() {
    // eslint-disable-next-line no-console
    // console.info(`AuthService: doLoggedIn`)
    const data = this.decodeToken(this.getAccessToken()) || {};
    const roles = this.getRoles(data);
    this.appStore.update({
      username: data.name,
      loginStatus: LoginStatus.True,
      roles,
    });

    // Configure Sentry Scope data
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: data.name,
        tenant: this.appQuery.tenant2,
      });
    });
  }

  public doLoggedOut(redirect = true) {
    // eslint-disable-next-line no-console
    // console.info(`AuthService: doLoggedOut`)
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("tenant");
    localStorage.removeItem("token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("tenant");
    sessionStorage.removeItem("token");
    this.appStore.update({
      username: "",
      loginStatus: LoginStatus.False,
      roles: [],
      companies: [],
      tenant: "",
    });

    // Unset Scope Data
    Sentry.configureScope(scope => scope.setUser(null));

    if (redirect) {
      // this.router.navigate(["/"]);
      console.log("redirecting to /");
      window.location.href = "/";
    }
  }

  private authRequest(url: string, requestParams: any): Observable<any> {
    const req = new HttpParams({ fromObject: requestParams });
    const res = req.toString();
    return this.http.post(url, res, this.headers);
  }

  public doBigTenantRefresh(): Observable<any> {
    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // – which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1));
    } else {
      this.refreshTokenInProgress = true;

      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      // Call auth.refsreshAccessToken(this is an Observable that will be returned)
      return this.refreshAccessToken().pipe(
        map((token: any) => {
          // When the call to refreshToken completes we reset the refreshTokenInProgress to false
          // for the next time the token needs to be refreshed
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(token);

          return token;
        }),
        catchError((err: any) => {
          this.refreshTokenInProgress = false;
          this.logout();
          return err;
        })
      );
    }
  }

  public bigTokenRefresh(error: any = "", todo: () => Observable<any>): Observable<any> {
    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // – which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => todo()));
    } else {
      this.refreshTokenInProgress = true;

      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      // Call auth.refreshAccessToken(this is an Observable that will be returned)
      return this.refreshAccessToken().pipe(
        switchMap((token: any) => {
          // When the call to refreshToken completes we reset the refreshTokenInProgress to false
          // for the next time the token needs to be refreshed
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(token);

          return todo();
        }),
        catchError((err: any) => {
          this.refreshTokenInProgress = false;
          this.logout();
          return error;
        })
      );
    }
   }

   public refreshAccessToken(newTenant?: string): Observable<any> {
    // this.refreshTokenInProgress = true;
    // this.refreshTokenSubject.next(null);
    // console.log("refresh for " + this.appQuery.tenant2);
    if (this.getRefreshToken()) {/*
      this.appStore.update({
        roles: []
      });*/

      this.local().removeItem("token");
      this.session().removeItem("token");
      return this.authRequest("/connect/token", {
        grant_type: "refresh_token",
        refresh_token: this.getRefreshToken(),
        acr_values: "tenant:" + (newTenant || this.appQuery.tenant2)
      }).pipe(map(response => this.processTokenResponse(response)));
    } else {
      this.doLoggedOut(!!this.getAccessToken());
      return from([]);
    }
  }

  previousUser(): string {
    return this.session().getItem("previousUser") || "";
  }

  loginAsOtherUser(username: string, password: string, previousUser: string): Observable<boolean> {
    this.session().setItem("token-reponse", JSON.stringify(this.lastTokenResponse));
    this.session().setItem("previousUser", previousUser);
    // console.log("loginAsOtherUser: " + username);
    // console.log("token-reponse", JSON.stringify(this.lastTokenResponse));
    // console.log("previousUser", previousUser);
    return this.login(username, password);
  }

  loginAsPreviousUser(): Observable<boolean> {
    this.doLoggedOut(false);
    const previousUser = this.session().getItem("previousUser");
    if (previousUser) {
      this.session().removeItem("previousUser");
      try {
        this.processTokenResponse(JSON.parse(this.session().getItem("token-reponse")));
        this.session().removeItem("token-reponse");
        return of(true);
      } catch (ex) {
        return of(false);
      }
    } else {
      return of(false);
    }
  }

  lastTokenResponse: any = null;
  private processTokenResponse(res: any) {
    if(!res) return false;
    this.lastTokenResponse = res;
    this.local().setItem("token", res.access_token);
    if (!!this.local().getItem("rememberMe")) {
      this.local().setItem("refresh_token", res.refresh_token);
    } else {
      this.session().setItem("refresh_token", res.refresh_token);
    }
    this.doLoggedIn();
    return true;
  }

  logout(redirect = true) {
    this.appStore.update({
      username: "",
      loginStatus: LoginStatus.False,
      roles: [],
      companies: [],
      tenant: "",
    });
    const refreshToken = this.getRefreshToken();
    return this.authRequest("/connect/revocation", {
      token: refreshToken,
      token_type_hint: "refresh_token"
    }).toPromise().then(() => this.doLoggedOut(redirect))
      .catch(ex => {
        this.doLoggedOut(redirect);
      });
  }

  private getRoles(data: any = null): string[] {
    const roles = (data || this.decodeToken(this.getAccessToken())).role || [];
    if (!roles) {
      console.warn("no roles");
      return [];
    }
    return ((!Array.isArray(roles)) ? [roles] : roles)  || [];
  }

  hasRole(role: string, sysadmin: boolean = true): boolean {
    const roles = this.appQuery.roles;
    return roles.includes(role) || (sysadmin && roles.includes(AuthenticationService.SYSADMIN));
  }

  hasRoles(roles: string[], sysadmin: boolean = true): boolean {
    const uRoles = this.appQuery.roles;
    return uRoles.some(r => roles.includes(r)) || (sysadmin && uRoles.includes(AuthenticationService.SYSADMIN));
  }
}
