import { AddressDto, newAddress } from "./AddressDto";

export interface ResourceVisitDto {
  visitId: string;
  date: Date;
  visitDate: Date;
  description: string;
  typeId: string;
  address: AddressDto; // = new AddressDto();
  visitOrder: number;
  mapsOrder: number;
  telephone: string;
  userCaptureId?: string;
}

export function newResourceVisit(): ResourceVisitDto {
  return {
    visitId: "",
    date: null,
    visitDate: null,
    description: "",
    typeId: "",
    address: newAddress(),
    visitOrder: 0,
    mapsOrder: 0,
    telephone: "",
  };
}
