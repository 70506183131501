import { Component, NgZone, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormDirective } from "@modules/common/components";
import {
  AuthenticationService,
  ProductSettingService,
  SnackBarService,
  StorageService,
  WebLayoutService
} from "@modules/common/services";
import { from, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

// import * as Msal from 'msal';
declare let Msal: any;

interface LogLink {
  id: string;
  name: string;
}
@Component({
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"]
})
export class LoginComponent extends BaseFormDirective implements OnInit {
  static logLinks: LogLink[];
  logLinks: Observable<LogLink[]>;

  constructor(
    protected layoutService: WebLayoutService,
    private route: ActivatedRoute,
    private router: Router,
    private productSettings: ProductSettingService,
    private authenticationService: AuthenticationService,
    fb: FormBuilder,
    private snackBar: SnackBarService,
    private storage: StorageService,
    private zone: NgZone
  ) {
    super(layoutService, fb);

    this.showExtras = this.storage.local.getItem("login:extras") === "true";
  }
  validationMessages = {
    username: {
      required: "Login.UsernameRequired"
    },
    password: {
      required: "Login.PasswordRequired"
    }
  };

  sparesLink: Observable<string>;
  error = "";
  busy = false;
  loaded: Observable<boolean>;
  private returnUrl: string;
  showPassword = false;

  showExtras = false;

  ngOnInit() {
    super.ngOnInit();
    if (LoginComponent.logLinks)
      this.logLinks = from([LoginComponent.logLinks]);
    else
      this.logLinks = this.productSettings.$loading.pipe(filter(f => !f)).pipe(
        map(value => {
          const loggs = this.productSettings.stringValue("JobLoggingPublic");
          if (loggs)
            return loggs.split(",").flatMap(i => {
              const e = i.split("|");
              return { id: e[0], name: e[1].replace("~", " ") };
            });
          // else if (this.productSettings.numericValue("JobLoggingPublic")
          return null;
          }),
        tap(lst => (LoginComponent.logLinks = lst))
      );

    this.returnUrl =
      ".." +
      decodeURIComponent(this.route.snapshot.queryParams.returnUrl || "");
  }

  loadScript(id: string, src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve();
      }

      const script = document.createElement("script");
      script.async = true;
      script.src = src;
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }

  protected createForm() {
    this.form = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
      rememberMe: !!this.storage.local.getItem("rememberMe"),
      notifications: false
    });
    super.createForm();
  }

  login() {
    const value = this.form.value;
    this.busy = true;
    if (value.rememberMe) {
      this.storage.local.setItem("rememberMe", "true");
    } else {
      this.storage.local.removeItem("rememberMe");
    }

    this.authenticationService
      .login(value.username, value.password)
      .subscribe(
        response => {
          this.router.navigate([this.returnUrl], {
            replaceUrl: true,
            relativeTo: this.route
          });
        },
        error => {
          switch (error?.error?.error_description) {
            case "invalid_username_or_password":
              this.snackBar.warning("Invalid username or password");
              break;
            default:
              this.snackBar.danger("Unknown Error - Please Try again");
          }
          this.busy = false;
        }
      );
  }

  private externalLogin(token: string, provider: string) {
    this.authenticationService
      .loginExternal(token, provider)
      .subscribe(
        res2 => {
          console.log("Leaving...");
          this.router.navigate([this.returnUrl], {
            replaceUrl: true,
            relativeTo: this.route
          });
        },
        error => {
          switch (error?.error?.error) {
            case "invalid_request":
              this.snackBar.warning("Email not found");
              break;
            default:
              this.snackBar.danger("Unknown Error - Please Try again");
          }
          this.busy = false;
        }
      );
  }

  async signInWithMicrosoft(): Promise<void> {
    this.busy = true;
    try {
      await this.loadScript(
        "MICROSOFT",
        "https://secure.aadcdn.microsoftonline-p.com/lib/1.0.0/js/msal.min.js"
      );

      const msalConfig = {
        auth: {
          clientId: "6b7afe66-400c-4675-95f0-3915db155c67",
          authority: "https://login.microsoftonline.com/common"
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true
        }
      };

      const myMSALObj = new Msal.UserAgentApplication(msalConfig);

      const requestObj = {
        scopes: ["user.read"]
      };

      const loginResponse = await myMSALObj.loginPopup(requestObj);

      console.log(loginResponse);
      this.externalLogin("", "microsoft");
    } catch (err) {
      this.busy = false;
    }
  }

  initGoogleApi(): Promise<{ inner: gapi.auth2.GoogleAuth }> {
    return new Promise((resolve, reject) => {
      gapi.load("auth2", () => {
        const result = gapi.auth2.init({
          scope: "email",
          client_id:
            "208019062240-hi2rh0clfapps6hoio5jlvapp9djdg66.apps.googleusercontent.com"
        });
        resolve({ inner: result });
      });
    });
  }

  facebookLogin(): Promise<fb.StatusResponse> {
    return new Promise((resolve, reject) => {
      FB.login(response => this.zone.run(() => resolve(response)));
    });
  }

  async signInWithGoogle(): Promise<void> {
    this.busy = true;
    try {
      await this.loadScript("GOOGLE", "https://apis.google.com/js/platform.js");

      const auth2 = (await this.initGoogleApi()).inner;

      const response = await auth2.signIn({ scope: "email" });
      const profile = auth2.currentUser.get().getBasicProfile();
      const token = auth2.currentUser.get().getAuthResponse(true).access_token;
      const backendToken = auth2.currentUser.get().getAuthResponse(true)
        .id_token;

      console.log(response);
      this.externalLogin(token, "google");
    } catch (err) {
      this.busy = false;
    }
  }

  updateAfterAutofill(field: string) {
    this.form.get(field).setErrors(null);
  }

  async signInWithFB(): Promise<void> {
    this.busy = true;

    try {
      await this.loadScript("FACEBOOK", `//connect.facebook.net/en_US/sdk.js`);

      FB.init({
        appId: "1513569318789432",
        autoLogAppEvents: true,
        cookie: true,
        xfbml: true,
        version: "v4.0"
      });

      const response = await this.facebookLogin();
      console.log("Facebook");
      if (response.authResponse) {
        const authResponse = response.authResponse;
        FB.api(`/me?fields=name,email,picture,first_name,last_name`, fbUser => {
          // const user: SocialUser = new SocialUser();

          /*user.id = fbUser.id;
                user.name = fbUser.name;
                user.email = fbUser.email;
                user.photoUrl = 'https://graph.facebook.com/' + fbUser.id + '/picture?type=normal';
                user.firstName = fbUser.first_name;
                user.lastName = fbUser.last_name;
                user.authToken = authResponse.accessToken;

                user.facebook = fbUser;

                resolve(user);*/
          console.log(fbUser);
          this.externalLogin(authResponse.accessToken, "facebook");
        });
      } else {
        this.busy = false;
        // reject('User cancelled login or did not fully authorize.');
      }
    } catch (err) {
      this.busy = false;
    }
  }
}
