import {
  animate,
  AnimationMetadata,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const APP_ANIMATIONS: Array<AnimationMetadata> = [
  trigger("flyInOut", [
    state("in", style({ transform: "translateY(0)" })),
    transition("void => *", [
      style({ transform: "translateY(-100%)" }),
      animate(100)
    ]),
    state("out", style({ transform: "translateY(100%)" })),
    transition("* => void", [
      animate(100, style({ transform: "translateY(100%)" }))
    ])
  ]),
  trigger("fadeInOut", [
    transition(":enter", [ // 'void => *', [
      style({ opacity: 0 }),
      animate(500, style({ opacity: 1 }))
    ]),
    transition(":leave", [ // * => void', [
      animate(500, style({ opacity: 0 }))
    ])
  ])

];

export function routerTransition() {
  return slideToLeft();
}

function slideToLeft() {
  return trigger("routerTransition", [
    state("void", style({ position: "fixed", width: "100%" })),
    state("*", style({ position: "fixed", width: "100%" })),
    transition(":enter", [  // before 2.1: transition('void => *', [
      style({ transform: "translateX(100%)" }),
      animate("0.5s ease-in-out", style({ transform: "translateX(0%)" }))
    ]),
    transition(":leave", [  // before 2.1: transition('* => void', [
      style({ transform: "translateX(0%)" }),
      animate("0.5s ease-in-out", style({ transform: "translateX(-100%)" }))
    ])
  ]);
}
