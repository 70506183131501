import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { LookupObjectDto, codeOnly } from "@shared/models";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { CustomerLookupDto, CustomerService } from "../services/index";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiCustomer]"
})
export class CustomerTypeaheadDirective extends BaseObservableTypeaheadHelper<CustomerLookupDto> {
  @Input() abiCustomer: string = 'CUS';
  @Input() codeOnly = false;
  @Output() valueChanged: EventEmitter<CustomerLookupDto> = new EventEmitter();

  constructor(typeAhead: TypeaheadDirective, private customerService: CustomerService) {
    super(typeAhead, ["abiCustomer"]);
    this.loading = false;
  }

  protected getId(item: CustomerLookupDto): string {
    return item.code;
  }

  protected getName(item: CustomerLookupDto): string {
    return item.description;
  }

  protected filteredList(term: string): Observable<CustomerLookupDto[]> {
    return this.customerService.queryCustomers(term, this.abiCustomer);//.pipe(map(r => r.items));
  }

  getCode(item: LookupObjectDto): string {
    return codeOnly(item);
  }

  protected onWriteValue(obj: any): any {
    if(!obj && !this._internalValue) // prevent changes from triggering when prev value and new value are null
      return null;

    this._internalValue = obj; // Keep an Internal Representation of the original Dto (for later use)
    if (typeof obj === "string") {
      let id = 0;
      // eslint-disable-next-line no-cond-assign
      if (this.lastResult && (id = this.indexOf(obj)) > -1) {
        // console.log('this.lastResult[id]', this.lastResult[id])
          this.typeAhead.writeValue(this.lastResult[id]);
          this.valueChanged.emit(this.lastResult[id]);
        return this.lastResult[id];
      } else {
        this.startValue = obj;
        this.filterAndSaveList(obj)
        .pipe(
          take(1),
          // tap(vals => console.log('filterAndSaveList result', vals))
        )
        .subscribe(lst => {
          if (lst && lst.length) {
            const nId = this.indexOf(obj);
            // console.log('got the result from list filters and writing now', lst, this.lastResult[nId])
            if (nId > -1) {
              this.hadResult = !!obj;

              // Apply value internally and trigger selection for label display and dependant callbacks
              this.typeAhead.writeValue(this.lastResult[nId]);
              // this.typeAhead.doOnChange(this.lastResult[nId]);

              this.valueChanged.emit(this.lastResult[nId]);

              // Useful for Getting the full value Loaded on Init
              this.onWriteValue(this.lastResult[nId]);
              // this.typeAhead.doOnChange(this.lastResult[nId]);
              // this.typeAhead.selectItem.emit({ preventDefault: () => {}, item: this.lastResult[nId]});
            }
          }
        });
        return null;
      }
    }
    const retVal = obj;
    if(!retVal)
      this.typeAhead.doOnChange(retVal);

    if(this.codeOnly){
      return this.getCode(retVal);
    }

    this.valueChanged.emit(retVal);
    return retVal;
  }
}
