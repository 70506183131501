<div
    *ngIf="appQuery.shouldShowInfoHeader() | async"
    class="d-print-none col alert-warning py-1 text-center font-italic small my-0"
>{{ 'System.Platform' | translate }}: {{ appQuery.serverEnvironment
    }}<ng-container *ngIf="appQuery.isDemoTenant() | async">
        - {{ 'System.TestDatabase' | translate }}</ng-container
    >
</div>
<div
    *ngIf="networkStatus"
    class="d-print-none col alert-danger py-1 text-center font-italic small my-0"
>{{ networkStatus }}
</div>
