import { Directive, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { BaseAttachmentDto, ListResultDto, SearchDto } from "@modules/models";
import { from, Observable, of, range, Subject, zip } from "rxjs";
import { catchError, concatAll, concatMap, map, skip, take, toArray } from "rxjs/operators";
import { AppInjector } from "@app/app.module";
import { FileService, FilesToZip, WebLayoutService } from "@services";
import { LayoutAware } from "../injectables/layout-aware";
import { convertObjectToDate } from "../utilities/date.utilities";
import { Paged, SortOrder, TableColumn } from ".";

// Experimental Interface for Classes that use the DataTable Directive
export declare interface IDataTable<T>{
  /** must point to your DataTable instance dataList */
  get dataList(): ListResultDto<T>;
  /** must point to your DataTable instance dataList */
  set dataList(val: ListResultDto<T>);
  /** initialise you data list */
  /** must point to your DataTable instance columns */
  get columns(): TableColumn[];
  /** must point to your DataTable instance columns */
  set columns(cols: TableColumn[]);
  /** must assign columns with what you need */
  setupColumns(): void;
  /** return api query here */
  innerSearch(
    page: number,
    size: number,
    columns: TableColumn[]
  ): Observable<ListResultDto<T>>
}

export declare interface AttachmentExport {
    /**
     * Generates a ZIP file with all Visit attachments Named sequentially and triggers download
     * Fetches ALL Rows (not current displayed Data)
     */
    generateAttachmentFileName(rowData: any, attachment: BaseAttachmentDto): string;
    getFullUrl(attachment: BaseAttachmentDto, rowData: any): string;
    generateZipFileName(chunkNamePart: string): string;
    getFilesToZip(): Promise<FilesToZip[]>;
}

/**
 * Common class for DataTable style components (Example: JobsList, ResourceList, StockList, etc...)
 * NOTE: We are naughtily extending this class in most places - it's designed to be injected
 */
export class DataTableComponent extends LayoutAware implements AttachmentExport {
  route: ActivatedRoute;
  /**
   * Used when displaying the Export Dialog and for the Pagination component
   */
  totalResults = 0;
  /**
   * default empty declaration of table columns
   */
  columns: TableColumn[] = [];
  /**
   * local result set of queried data from the 'search' process
   */
  dataList: ListResultDto<any>;
  /**
   * Used for to apply page props to the search queries
   */
  paged: Paged;
  /**
   * Local observable that uses the values given by the Pagination component
   */
  dataO: Subject<ListResultDto<any>> = new Subject();
  pagedS: Subject<Paged> = new Subject();
  colsObs: Subject<TableColumn[]> = new Subject();
  /**
   * Size of each page of data to be queried
   * can be used to tweak performance for certain queries
   */
  allDataPageSize = 1000;
  fileService: FileService;
  constructor(pageName: string = "GenericDataTable"){
    super(pageName, AppInjector.get(WebLayoutService));
    this.fileService = AppInjector.get(FileService);
  }

  triggerOnParamMapChange = true;
  /**
   * Must be called in the extending Sub-Class's OnInit hook
   */
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  initDataTable(triggerOnParamMapChange = true): void {
    const routeParams = this.route.snapshot.paramMap;
    this.setVars(routeParams);// set initial Params (if any
    this.triggerOnParamMapChange = triggerOnParamMapChange;
    this.setupObservables(this.route);// get triggers in place for Searching (including Column Observables)
    // Columns Should be triggered after Layouts are loaded
    this.fetchLayouts();
  }

  /**
   * Sets up the Param and Paged observable subscriptions consistently
   * @param route
   */
  setupObservables(route: ActivatedRoute){
    /**
     * First time initialisation of Params and Paged
     * PaginationSubject, ParamMap and Columns are all required to trigger this first search
     */
    zip(this.pagedS, route.paramMap, this.colsObs)
    .pipe(take(1))
    .pipe(this.notDisposed())
    .subscribe(([paged, params, cols]) => {
      this.paged = paged;
      this.search(false, this.columns);
    });

    // Re-triggers of Columns Initialisation
    // this might be that new columns are loaded or that the layout has changed (incl. filters)
    this.colsObs
    .pipe(skip(1))
    .pipe(this.notDisposed())
    .subscribe(pages => {
      this.search(false, this.columns);
    });

    // Re-triggers of Params/Paged observables will search again
    this.pagedS
    .pipe(skip(1))
    .pipe(this.notDisposed())
    .subscribe(pages => {
      this.paged = pages;
      this.search(false, this.columns);
    });

    // Re-Triggers if params change
    // GUARD FOR: if we navigate away (without immediate disposal) this search will re-trigger unneccessarily
    // note that some routing methods use params - this may cuase a re-trigger (erroneously)
    if(this.triggerOnParamMapChange)
    route.paramMap
    .pipe(this.notDisposed())
    .pipe(skip(1))
    .subscribe(p => {

      // Non-Layout Change - trigger search here
      if((!this.layoutId && !p.get('layoutId')) || (this.layoutId && p.get('layoutId') && p.get('layoutId') === this.layoutId)) {
        this.nonLayoutParamChange(p);
      }

      // Layout Change - search is triggered in the LayoutLoaded callback
      if(this.layoutChanged(this.layoutId, p.get('layoutId'))){
        this.setVars(p);
        // Layout change will trigger the LayoutLoaded callback
        this.setLayout(this.layouts.find(l => l.layoutId === this.layoutId));// layout change will trigger the Search via column changes
      }
    });
  }

  nonLayoutParamChange(p: ParamMap){
    // Layout is set and didnt change
    this.setVars(p);
    // If layout is set-- we use the layoutsLoaded callback to trigger search (for all other param changes we use this search)
    this.search(false, this.columns);
  }


  /**
   * Get Sort field using generic rules
   * @param columns
   * @returns
   */
  getSortField(columns: TableColumn[], defaultSort: string = ""): string {
    let sortField = defaultSort;
    const sortCol = columns.find(c => c.sortOrder > SortOrder.None);
    if (sortCol) {
      sortField =
        (sortCol.sortOrder === SortOrder.Descending ? "!" : "") +
        (sortCol.columnId || sortCol.field);
    }
    return sortField;
  }

  newSearch(pageNumber: number = 1, pageSize: number = 10, columns: TableColumn[] = this.columns, filters = {}): SearchDto{
    return {
      pageNumber,
      pageSize,
      orderBy: [this.getSortField(columns)],
      filters,
    };
  }

  /**
   * Each Column's filter value is applied to the SearchDto in place
   */
  applyFilters(search: SearchDto, columns: TableColumn[]): void {
    for (const prop of columns) {
      if (prop.filter) {
        search.filters[prop.columnId || prop.field] = prop.filter;
      }
    }
  }

  /**
   * Overriden function will assemble the query and trigger, which returns a ResultListDto
   * @param page number
   * @param size number
   * @param columns columns may have filters, these can be used in the query
   */
  innerSearch(
    page: number,
    size: number,
    columns: TableColumn[]
  ): Observable<ListResultDto<any>> {
    throw new Error('Implement this method');
  }

  /**
   * Queries a full list of Results for Exporting
   * Note: Divides list queries into chunks of 1000 rows each to prevent server timeout per query
   * @returns Observable list of DTOs for Exporting inside an Excel file
   */
  allData(): (() => Promise<any[]>)[] {
    // note that the 'totalResults' response property must match the actual number of possible results (without paging)
    const queryCount = Math.ceil(this.totalResults / this.allDataPageSize);
    // return range(1, queryCount)
    // .pipe(
    //   concatMap(
    //     pageNumber => this.innerSearch(pageNumber, this.allDataPageSize, this.columns).pipe(map((r) => from(r.items)))
    //   ),
    //   concatAll(),
    //   toArray()
    // );
    return Array.from({length: queryCount}, (_, i) => i + 1)
    .map(pageNumber => () => this.innerSearch(pageNumber, this.allDataPageSize, this.columns).toPromise().then(r => r.items));
  }

  isLoading = false;
  /**
   * Search function called by OnInit, Table Filter changes, Page Changes
   * If a pagination component is present, it will OnInit trigger the Paged Subject first
   */
  search(
    resetPageNumber: boolean,
    columns: TableColumn[]
  ): Promise<void> {
    this.isLoading = true;
    if (!this.paged || !columns || columns.length === 0) {
      return Promise.resolve(null);
    }
    if (resetPageNumber) {
      this.paged.pageNumber = 1;
    }
    return this.innerSearch(this.paged.pageNumber, this.paged.pageSize, columns)
    .pipe(catchError((err, obs) => {
      // console.error(err.message || err.statusText)
      return of({ items: [], totalResults: 0, message: `${this.pageName}: DataTableError - ${err.message || err.statusText}`});
    }))
    .toPromise()
      .then((list) => {
        this.processSearchResult(list);
      }).catch((e) => {
        this.dataList = null;
        this.processError(e);
      }).finally(() => {
        this.isLoading = false;
      });
  }

  convertErrorToString(e: any): string {
    return e?.error?.message || e?.message || e;
  }

  processError(e: any){
    console.error(e);
    this.applyResult({ totalResults: 0, items: [], errors: [this.convertErrorToString(e)] });
  }

  processSearchResult(list: ListResultDto<any>) {
    this.applyResult(list);
  }

  message: string = "";
  applyResult(list: ListResultDto<any>){
    this.dataList = list;
    this.dataO.next(list);
    this.totalResults = this.dataList.totalResults;
    this.message = list.message || "";
  }

  /**
   * Set your local variables here from the Params
   * must set the layout id with a super.setVars() call
   * @param params
   */
  protected setVars(params: ParamMap) {
    // setTimeout(() => {
    this.layoutId = params.get("layoutId");
    this.extraVars(params);
  }

  extraVars(params: ParamMap) {
    // throw new Error('Implement this Function');
  }

  /**
   * Set additional/custom layout properties on the Layout Data object
   * Example: data.mode = this.showJob || "open";
   * @param data
   */
  layoutExtras(data: any) {
    throw new Error('Implement this Function');
  }

  triggerRefresh() {
    this.setupColumns();
  }

  /**
   * Set your columns variable with custom logic
   * or optionally set your columns variable directly if it's static
   */
  setupColumns(){
    // throw new Error('Implement this Function');
  }

  getPageName() {
    return this.pageName;
  }


  // UTILITIES
  colsIncludesField(cols: TableColumn[], fieldOrColumnId: string) {
    return cols.findIndex(col => col.field === fieldOrColumnId) > -1;
  }

  // Experimental Filter Reset Method
  public resetFilters(){
    // table.resetFilters();// remove filter values - this is already done in the TableControls component...
    this.setupColumns();// generate new columns reference (causes table column re-init and search query)
  }

  /**
   * Useful when controlling a table from a parent component (ie: no auto-query etc...)
   * the filters need to be loaded and applied as needed to the parent component
   */
  saveExternalFilters(filters: Record<string, string>){
    sessionStorage.setItem(`${this.pageName}_external`, JSON.stringify(filters));
  }

  loadExternalFilters(): Record<string, string>{
    const parsedObject = JSON.parse(sessionStorage.getItem(`${this.pageName}_external`));
    convertObjectToDate(parsedObject);
    return parsedObject;
  }


  // ATTACHMENT EXPORT
  generateAttachmentFileName(dataRow: any, attachment: BaseAttachmentDto): string {
    throw new Error('Implement this Function');
  }
  getFullUrl(attachment: BaseAttachmentDto, dataRow: any): string {
    throw new Error('Implement this Function');
  }
  generateZipFileName(chunkNamePart: string): string {
    throw new Error('Implement this Function');
  }
  getFilesToZip(): Promise<FilesToZip[]> {
    throw new Error('Implement this Function');
  }

  isZipping = false;
  zipProgress = 0;
  customZipMessage = '';
  /**
   * Generates a ZIP file with all Visit attachments Named sequentially and triggers download
   * Fetches ALL Rows (not current displayed Data)
   */
  doDownloadImages() {
    this.zipProgress = 0;
    this.customZipMessage = '';
    this.isZipping = true;

    // Fetch Data and Generate List of Files with Detailed File Names
    this.getFilesToZip().then(filesToZip => {
      // console.log('Files to Zip:', filesToZip.length);
      // console.log('Files to Zip:', filesToZip);
      // return;
      // Customise message to show how Many Chunks there are
      // Split Files into 1k chunks and Name Zip Files Sequencially
      const chunkSize = 1000;
      const chunkedList = [...Array(Math.ceil(filesToZip.length / chunkSize))]
      .map((_, i) => filesToZip.slice(i*chunkSize, (i+1)*chunkSize));

      const blobPromises = chunkedList.map(
        (chunk, index) =>
          () => {
            // Display Chunking Message is neccessary
            if(chunkedList.length > 1 && (index) < chunkedList.length)
            this.customZipMessage = `Downloading Chunk ${index+1} of ${chunkedList.length} (${chunkSize} files each)`;
            return this.fileListToBlob(chunk)
            .then(blob => {
              // eslint-disable-next-line max-len
              const chunkNamePart = filesToZip.length > chunkSize ? `(${(chunkSize*index)+1}-${((chunkSize*(index+1)))-(chunkSize-chunk.length)})` : '';
              this.fileService.saveFile(blob, this.generateZipFileName(chunkNamePart));
            });
          }
      );

      this.executeSequentially(blobPromises)
      .finally(() => {
          this.isZipping = false;
          this.zipProgress = 0;
          this.customZipMessage = '';
      });
    });
  }

  /**
   * Helper to Sequentially execute functions that resolve as promises
   * @returns Array of Completed Promises
   */
  async executeSequentially(promiseFactories: (() => Promise<any>)[] = []) {
    const results = [];
    for (const pf of promiseFactories) {
      results[results.length] = await pf();
    }
    return results;
  }

  placeHolderImageUrl = "https://placehold.co/100x100.jpg?text=Placeholder";
  // Use given File List to Download and Generate single Zip file
  fileListToBlob(filesToZip: FilesToZip[]): Promise<Blob> {

    return this.fileService.zipFiles(
      filesToZip,
      (val) => {
        this.zipProgress = val;
      },
      this.placeHolderImageUrl
    );
  }

  setupRoles() {
    // throw new Error('Implement this Function');
  }
}

/**
 * Extendable DataTable class
 * @deprecated prefer composition over inheritance
 */
@Directive({})
export class DataTableDirective extends DataTableComponent implements OnInit, OnDestroy{

  constructor(pageName: string){
    super(pageName);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.setupRoles();
    this.initDataTable();
  }
}
