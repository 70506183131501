import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "abi-json-view",
  templateUrl: "./json-view.component.html",
  styleUrls: ["./json-view.component.scss"]
})
export class JsonViewComponent {
  @Input() data: any;

  constructor(@Inject(MAT_DIALOG_DATA) dialogData?: any) {
    this.data = dialogData;
  }
}

