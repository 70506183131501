import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormDirective } from "@modules/common/components";
import { UserService, WebLayoutService } from "@modules/common/services";

@Component({
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent extends BaseFormDirective implements OnInit {
  readonly passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  validationMessages = {
    email: {
      required: "Contact.InvalidEmail",
      email: "Contact.InvalidEmail."
    },
    newPassword: {
      required: "Login.PasswordRequired.",
      pattern: "Login.PasswordRules"
    },
    confirmNewPassword: {
      required: "Login.PasswordRequired",
      match: "Login.PasswordMatch"
    }
  };

  code: string;
  userid: string;

  constructor(protected layoutService: WebLayoutService, private route: ActivatedRoute, private router: Router, private userService: UserService, fb: FormBuilder) {
    super(layoutService, fb);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(map => {
      this.code = map.get("code");
      this.userid = map.get("userid");
    });
    super.ngOnInit();
  }

  public resetPassword() {
    const value = this.form.getRawValue();
    this.userService
      .resetPassword(this.code, value.username, value.newPassword)
      .subscribe(
        _response => {
          this.router.navigate(["/"], { replaceUrl: true });
        }
      );
  }

  protected createForm() {
    this.form = this.formBuilder.group({
      username: {value: this.userid, disabled: true},
      newPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]]  ,
      confirmNewPassword: ["", [Validators.required, this.userService.passwordsMatch()]]
    });
    super.createForm();
  }
}
