import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "@env/environment";
import { AppQuery } from "@modules/common/app.store";
import { TipOfTheDayService } from "@modules/common/services";
import { NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap/carousel/carousel";
import { TipOfTheDayDto } from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "abi-tip-of-the-day",
  templateUrl: "./tip-of-the-day.component.html",
  styleUrls: ["./tip-of-the-day.component.scss"]
})
export class TipOfTheDayComponent implements OnInit {
  tip: TipOfTheDayDto;

  @ViewChild("carousel") carousel: NgbCarousel;

  constructor(private tipOfTheDayService: TipOfTheDayService,
              private appQuery: AppQuery, @Inject(MAT_DIALOG_DATA) public tips: TipOfTheDayDto[]) {

    this.tips = tips.filter(t => t.status !== "Hidden");
    this.tip = this.tips.find(t => !t.status) || this.tips[0];
  }

  ngOnInit() {
  }

  downloadLink(attachment: TipOfTheDayDto): Observable<string> {
    return this.appQuery.$tenant.pipe(map(tenant => `${environment.webApi}/api/${tenant}/values/tip/${attachment.attachmentId}`));
  }

  slide(ev: NgbSlideEvent) {
    this.tipOfTheDayService.updateTip(this.tip.attachmentId, "Read").toPromise().then(_ => { });
    this.tip = this.tips.find(t => t.attachmentId === ev.current);
  }

  toggleTip() {/*
    if (this.tip) {
      this.tipOfTheDayService.updateTip(this.tip.attachmentId).toPromise().then(x => {
        this.tip.status = x;
        //this.settings["Show" + this.tip.attachmentId] = x;
        this.carousel.next();
      });
    }*/
  }

  close() {
    this.tipOfTheDayService.updateTip(this.tip.attachmentId, "Read").toPromise().then(_ => { });
  }


}
