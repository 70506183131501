import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { OnChanges, SimpleChanges } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { from, merge, Observable, of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { Disposable } from "..";
import { TypeaheadDirective } from "./typeahead.directive";

export type preFilterContext = {
  preFilter: (item: any, thisArg: any) => boolean;
  thisArg: any;
};

export type TypeaheadFilter = (item: any, term: string) => boolean;
/**
 * BaseObservableTypeaheadHelper
 * @TODO this is a real candidate for a refactor
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseObservableTypeaheadHelper<T>
  extends Disposable
  implements OnChanges, OnInit
{
  focus = new Subject<string>();
  click = new Subject<string>();
  startValue: any;
  hadText = false;
  hadResult = false;

  @Input() container: "" | "body" = "body";
  @Input() inputDisplay: "all" | "code" | "name" | "description" = "all";
  @Input() inputDisplayFunc: (item: T) => string;
  @Input() dropDownDisplay: "all" | "code" | "name" | "description" = "all";
  @Input() dropDownDisplayFunc: (item: T) => string;
  @Input() filter: TypeaheadFilter;// what's the differnece between this and preFilter?
  @Input() preFilter: (item: T) => boolean | preFilterContext;
  @Output() loaded: EventEmitter<T[]> = new EventEmitter();
  @Input() preLoad = false;

  /*@HostBinding("disabled")*/ loading = true;
  liveResults = true;

  public lastResult: T[];
  inputs = new Subject<string>();
  initialResult: T;
  _internalValue: any; // keep the raw lookup object reference here

  constructor(
    protected typeAhead: TypeaheadDirective,
    protected changes: string[]
  ) {
    super();

    this.changes.push(...["filter", "preFilter"]);
    this.typeAhead.resultFormatter = this.dropDownText.bind(this);
    this.typeAhead.inputFormatter = this.inputText.bind(this);
    this.typeAhead.onWriteValue = (obj) => this.onWriteValue(obj);
    this.typeAhead.abiTypeahead = this.search;
    this.typeAhead.focusFirst = true;
    this.typeAhead.container = this.container;
  }

  ngOnInit(): void {
    this.postConstructor();
    if (this.preLoad) {
      this.filterAndSaveList("")
        .toPromise()
        .then((x) => {});
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let changed = false;
    for (const ch of Object.keys(changes)) {
      changed = changed || this.changes.includes(ch);
    }

    if (changed) {
      this.lastResult = null;
      if (this.preLoad) {
        this.filterAndSaveList("")
          .toPromise()
          .then((x) => {});
      }
    }
  }

  protected regExp(term: string): RegExp {
    term = term.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    return new RegExp("\\b" + term, "i");
  }

  protected abstract getId(item: T): string;
  protected abstract getName(item: T): string;

  protected getFull(item: T): string {
    return `${this.getId(item)} ~ ${this.getName(item)}`;
  }

  protected inputText(inputValue: T | string): string {
    const item = this._internalValue || inputValue; // always try using the internal Object first (instead of a possible string only value)
    if (!item) return "";
    if (typeof item !== "string") {
      if(this.inputDisplayFunc) return this.inputDisplayFunc(item);
      switch (this.inputDisplay) {
        case "code":
          return this.getId(item);
        case "name":
        case "description":
          return this.getName(item);
        }
        return this.getFull(item);
      } else {
        return item || "";
      }
  }

  protected dropDownText(item: T): string {
    if (!item) return "";
    if (typeof item !== "string") {
      if(this.dropDownDisplayFunc) return this.dropDownDisplayFunc(item);
      switch (this.dropDownDisplay) {
        case "code":
          return this.getId(item);
        case "name":
        case "description":
          return this.getName(item);
      }
      return this.getFull(item);
    } else {
      return item || "";
    }
  }

  postConstructor(): void {
    this.typeAhead.onWriteValue = (obj) => this.onWriteValue(obj);
    this.typeAhead.autocomplete = "off";
    this.typeAhead.editable = false;
    this.typeAhead.showHint = false;
    // this.typeAhead.container = "body";
  }

  @HostListener("click", ["$event"])
  handleClick(event): void {
    this.click.next(""); // event.target.value
  }

  @HostListener("focus", ["$event"])
  handleFocus(event): void {
    this.focus.next(""); // event.target.value
  }

  protected abstract filteredList(term: string): Observable<T[]>;

  protected filterAndSaveList(term: string): Observable<T[]> {
    // console.log('filterAndSaveList', term)
    this.loading = true;

    return this.filteredList(term)
    .pipe(
      catchError(err => {
        Sentry.captureException(
          new Error(`BaseTypeAheadedHelper - filteredList threw Error: ${err?.message || err || "Unknown"} - term: ${term}`)
        ); // help diagnose why Lookup List requests are failing
        return []; // catch errors for list queries as this prevents the Lookup component from crashing
      }),
      tap((results) => {
        if (results && results.length) {
          this.lastResult = results;

          // console.log('filterAndSaveList: filteredList', term, results, this.initialResult)
          // save the initalResult (starting value)
          if (term && results.length === 1 && !this.initialResult) {
            // we have an initial result - we want to cache this
            this.initialResult = results[0];
          }

          this.loaded.emit(results);
          // code Smell: this code is basically unreachable (at the moment)
          if (results && results.length && !!this.startValue) {
            const nId = this.indexOf(this.startValue);
            if (nId > -1) {
              // console.log('longshot writing value', results);

              this.typeAhead.writeValue(this.lastResult[nId]);
              // this.typeAhead.doOnChange(this.lastResult[nId]); // We really never want to trigger a change event here - as the underlying 'code' should be the same
              this.startValue = null;
            }
          }
          this.loading = false;
        } else {
          // console.log('filterAndSaveList: no results', term, results, this.initialResult)
          // If Result return empty even without Terms, then there are other params causing the empty list and we should just wipe the current value
          if(!term && this.initialResult) {
            this.typeAhead.writeValue(null);// This will clear the input field
          }
        }
      }),
      map(results => {
          if (term === "" && results.length > 1 && this.initialResult) {
            // ensure inital result is in results list
            results = results.some(
              (item) => this.getId(item) === this.getId(this.initialResult)
            )
              ? results
              : [this.initialResult, ...results];
          }
          return results;
      })
    );
  }

  protected filterSavedList(term: string): Observable<T[]> {
    // console.log('filterSavedList', term, this.lastResult, this.liveResults)
    if (this.lastResult && !this.liveResults) {
      const search = this.regExp(term);
      return this.list().pipe(
        map((l) => l.filter((i) => search.test(this.getFull(i))))
      );
    }
    return this.filterAndSaveList(term);
  }

  protected list(): Observable<T[]> {
    const typea = this.typeAhead as any;
    return this.lastResult
      ? from([this.lastResult])
      : this.filterAndSaveList("");
  }

  protected onWriteValue(obj: any): any {
    // console.log('onWriteValue', obj)
    let retVal = obj;
    // this._internalValue = obj; // Keep an Internal Representation of the original Dto (for later use)
    // const iteration = new Date();
    // if object
    if (obj && typeof obj === "object") return obj;
    if(obj && typeof obj === "string") {
      let id = 0;
      // eslint-disable-next-line no-cond-assign
      if (this.lastResult && (id = this.indexOf(obj)) > -1) {
        retVal = this.lastResult[id];

      } else {
        // console.log('start filtering for', obj)
        this.startValue = obj;
        this.filterAndSaveList(obj)
          .pipe(
            take(1),
            // tap(vals => console.log('filterAndSaveList result', vals))
          )
          .subscribe(lst => {
            // console.log('filtered', lst)
            if (lst && lst.length) {
              const nId = this.indexOf(obj);
              // console.log('writing real value from string', lst, this.lastResult[nId])
              if (nId > -1) {
                this.hadResult = !!obj;
                this.typeAhead.writeValue(this.lastResult[nId]);
              }
            }
          });
      }
    }

    if (!retVal) {
     this.lastResult = null;
     this.initialResult = null;
    }
    this.hadResult = !!retVal;
    return retVal;
  }

  protected indexOf(id: string): number {
    return this.lastResult
      ? this.lastResult.findIndex((l) => this.getId(l) === id)
      : -1;
  }

  search = (text: Observable<string>): Observable<T[]> => {
    const debText = text.pipe(debounceTime(200), distinctUntilChanged());
    const clickedAndPopupClosed = this.click.pipe(
      filter(() => !this.typeAhead.isPopupOpen())
    );
    const trigger = merge(clickedAndPopupClosed, this.focus).pipe(debounceTime(200));
    return merge(debText, trigger).pipe(
      switchMap((term) => {
        if (this.hadText && !term && !this.hadResult) this.lastResult = null;
        this.hadText = !!term;
        return term ? this.filterSavedList(term) : this.filterAndSaveList(""); // if the term is blank: always get the full list
        // It's the responsibility of each implementation to clear the 'lastResult' when a 'param/input change' is made
        // Then we can use the below clause to optimise the display (prevent re-fetching the list)
        // return term ? this.filterSavedList(term) : !this.hadText && this.lastResult ? of(this.lastResult) : this.filterAndSaveList(""); // if the term is blank: always get the full list
      })
    );
  };
}
