import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";

@Component({
  selector: 'app-unlock-machine-modal',
  templateUrl: './unlock-machine-modal.component.html',
  styleUrls: ['./unlock-machine-modal.component.scss']
})
export class UnlockMachineModalComponent implements AfterViewInit, OnInit {
  @Output() unlockMachine = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  @ViewChild("existingJobsModalBody") existingJobsModalBody;
  @ViewChild("editModelReasonBody") editModelReasonBody;

  constructor(private modal: NgbModal) { }
  ngAfterViewInit(): void {
    this.launchExistingJobsModal();
    // throw new Error("Method not implemented.");
  }

  ngOnInit() {
  }

  launchExistingJobsModal() {
    const modalRef = this.modal.open(CustomDialogComponent);
    const modalComponent = modalRef.componentInstance as CustomDialogComponent;
    modalComponent.title = "Machine.ExistingJobs";
    modalComponent.body = this.existingJobsModalBody;
    modalComponent.buttons = [
      {
        label: "Machine.LeaveAsIs",
        class: "btn btn-success",
        disabled: () => false,
        action: (modal) => {
          modal.close();
        },
      },
      {
        label: "Machine.EditMachine",
        class: "btn btn-warning",
        disabled: () => false,
        action: (modal) => {
          modal.close();
          this.launchModelEditReasonModal();
        },
      }
    ];
  }

  editModelReasonText = "";
  launchModelEditReasonModal() {
    this.editModelReasonText = "";
    const modalRef = this.modal.open(CustomDialogComponent);
    const modalComponent = modalRef.componentInstance as CustomDialogComponent;
    modalComponent.title = "Machine.EditReason";
    modalComponent.body = this.editModelReasonBody;
    modalComponent.buttons = [
      {
        label: "System.Cancel",
        class: "btn btn-text",
        disabled: () => false,
        action: (modal) => {
          modal.close();
        },
      },
      {
        label: "Machine.SaveReason",
        class: "btn btn-primary",
        disabled: () => !this.editModelReasonText,
        action: (modal) => {
          modal.close();
          this.unlockMachine.emit(this.editModelReasonText);
        },
      }
    ];
  }
}
