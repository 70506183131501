<ng-container *ngIf="inModal; else inputSection">
    <h4 mat-dialog-title>
        <span *ngIf="group.enabled">{{ "System.Edit" | translate }} </span
        >{{ "Address.Address" | translate }}
    </h4>
    <mat-dialog-content>
        <ng-container *ngTemplateOutlet="inputSection"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            type="button"
            cdkFocusInitial
            mat-button
            [disabled]="group.disabled || group.invalid"
            (click)="accept()"
        >
            {{ "System.Ok" | translate }}
        </button>
        <button type="button" mat-button (click)="cancel()">
            {{ "System.Cancel" | translate }}
        </button>
    </mat-dialog-actions>
</ng-container>

<ng-template #inputSection>
    <button
        *ngIf="canUseMaps"
        type="button"
        [class.active]="useMaps"
        class="btn btn-outline-primary mr-auto"
        (click)="useMaps = !useMaps"
        data-cy="address-use-maps"
    >
        <i class="fas fa-map-pin"></i> {{ "Address.UseMaps" | translate }}
    </button>
    <button
        type="button"
        class="btn btn-primary float-right"
        [disabled]="group.disabled"
        (click)="serverGeocode()"
    >
        <i class="fas fa-map fa-fw"></i> {{ "Address.Lookup" | translate }}
    </button>

    <div *ngIf="showWarning" class="alert alert-warning mt-2">Selected Address is not Accurate. Please Re-Enter or Drag 'n Drop the Map Pin to desired location.</div>

    <div [formGroup]="group" class="mt-2">
        <div *ngIf="useMaps && canUseMaps" class="row">
            <abi-form-field
                label="Address.Line1"
                cols="col-12"
                labelColClass="col-md-2"
            >
                <input formControlName="line1" maxlength="64" />
            </abi-form-field>

            <abi-form-field
                label="Address.Address"
                cols="col-12"
                labelColClass="col-md-2"
            >
                <div class="input-group">
                    <ng-container *ngIf="apiLoaded; else placeholder">
                        <input
                            ngx-google-places-autocomplete
                            #placesRef="ngx-places"
                            abiProductHint="Address.Search"
                            (onAddressChange)="addressEntered($event)"
                            formControlName="address"
                            type="text"
                        />
                    </ng-container>
                    <ng-template #placeholder>
                        <input
                            formControlName="address"
                            type="text"
                            [placeholder]="'Address.Search' | translate"
                        />
                    </ng-template>
                    <div
                        class="input-group-append"
                        *ngIf="countries?.length > 1"
                    >
                        <button
                            class="btn btn-primary"
                            [disabled]="group.disabled"
                            type="button"
                            mat-list-item
                            [matMenuTriggerFor]="countryMenu"
                        >
                            {{ country }}
                        </button>
                    </div>
                </div>
                <mat-menu #countryMenu="matMenu">
                    <button
                        *ngFor="let item of countries"
                        mat-menu-item
                        (click)="updateCountry(item.code)"
                    >
                        {{ item.code + " ~ " + item.description }}
                    </button>
                </mat-menu>
            </abi-form-field>
        </div>
        <div *ngIf="!useMaps || !canUseMaps" class="row">
            <abi-form-field label="Address.Line1">
                <input formControlName="line1" maxlength="64" />
            </abi-form-field>
            <div class="col-md-12 d-none d-md-block"></div>
            <abi-form-field label="Address.Line2">
                <input formControlName="line2" maxlength="64" />
            </abi-form-field>
            <abi-form-field label="Address.Line3">
                <input
                    id="line3"
                    formControlName="line3"
                    [ngbTypeahead]="searchPostalCode"
                    editable="false"
                    focusFirst="true"
                    [resultFormatter]="formatPostalCode"
                    [inputFormatter]="formatPostalCode2"
                    container="body"
                    (selectItem)="populatePostalCode($event)"
                    maxlength="64"
                />
            </abi-form-field>

            <abi-form-field
                label="Address.Line4"
                [horizontal]="formOnly"
                labelColClass="col-sm-4"
            >
                <input formControlName="line4" readonly maxlength="64" />
            </abi-form-field>
            <abi-form-field label="Address.Line5" [horizontal]="formOnly">
                <input formControlName="line5" readonly maxlength="64" />
            </abi-form-field>
            <abi-form-field
                label="Address.PostalCode"
                [horizontal]="formOnly"
                labelColClass="col-sm-4"
            >
                <input formControlName="postalCode" />
            </abi-form-field>
            <abi-form-field label="Address.Country" [horizontal]="formOnly">
                <input formControlName="country" readonly />
            </abi-form-field>
        </div>
        <div class="border rounded mb-2">
            <ng-container *ngIf="showMaps && apiLoaded">
                <div class="w-100 map-height">
                    <google-map
                        *ngIf="locationValue"
                        [center]="locationValue"
                        class="w-100 map-height"
                        [height]="null"
                        [width]="null"
                        [draggable]=""
                >
                        <map-marker
                            *ngIf="locationValue"
                            [position]="locationValue"
                            [options]="{draggable: group?.enabled}"
                            (mapDragend)="addressMoved($event)"
                        ></map-marker>
                    </google-map>
                </div>
            </ng-container>
        </div>
        <div *ngIf="canUseMaps" class="row">
            <abi-form-field label="Address.GpsCoordinates">
                <input type="text" formControlName="coordinates" readonly />
                <!--[textMask]="{mask: mask, guide:false }"-->
            </abi-form-field>

            <abi-form-field>
                <p class="form-control-static pre-break">
                    {{ group.value.name }}<br/>
                    {{ group?.controls["addressText"].value }}
                </p>
            </abi-form-field>
        </div>
    </div>
</ng-template>
