import { cloneDeep, clone } from 'lodash';

/**
 * Given an Object and a path string, we search the object using the string (optionally with dot notation)
 * @param obj Object to deep find
 * @param path the dot notation path to search with
 * @returns resulting value
 */
export function deepFind(obj: Object, path: string): any {
  const paths = path.split('.');
  let current = obj;
  let i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}


export function checkUntilExists(ref: () => boolean, cb: () => void){
  if(ref()) {
    cb();
  } else {
    setTimeout(() => {
      checkUntilExists(ref, cb);
    }, 100);
  }
}

export function deepClone(obj: any) {
  return clone(obj, true);
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj);
  }

  if (obj instanceof Array) {
    const copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepClone(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    const copy: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepClone(obj[key]);
      }
    }
    return copy;
  }
}


/**
 * Crop signature canvas to only contain the signature and no whitespace.
 * @see https://github.com/szimek/signature_pad/issues/49#issuecomment-260976909
 * @since 1.0.0
 */
export function cropSignatureCanvas(canvas) {

  // First duplicate the canvas to not alter the original
  const croppedCanvas = document.createElement('canvas');
  const croppedCtx = croppedCanvas.getContext('2d');

  croppedCanvas.width  = canvas.width;
  croppedCanvas.height = canvas.height;
  croppedCtx.drawImage(canvas, 0, 0);

  // Next do the actual cropping
  let w         = croppedCanvas.width;
  let h         = croppedCanvas.height;
  const pix       = {x:[], y:[]};
  const imageData = croppedCtx.getImageData(0,0,croppedCanvas.width,croppedCanvas.height);
  let x;
  let y;
  let index;

  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4;
      if (imageData.data[index+3] > 0) {
        pix.x.push(x);
        pix.y.push(y);
      }
    }
  }
  pix.x.sort(function(a,b){return a-b;});
  pix.y.sort(function(a,b){return a-b;});
  const n = pix.x.length-1;

  w = pix.x[n] - pix.x[0];
  h = pix.y[n] - pix.y[0];
  const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

  croppedCanvas.width = w;
  croppedCanvas.height = h;
  croppedCtx.putImageData(cut, 0, 0);

  return croppedCanvas.toDataURL();
}

// https://github.com/szimek/signature_pad/issues/49#issuecomment-867566006
// function getCroppedCanvasImage(canvas: HTMLCanvasElement) {

// 	const originalCtx = canvas.getContext('2d');

// 	const originalWidth = canvas.width;
// 	const originalHeight = canvas.height;
// 	const imageData = originalCtx.getImageData(0,0, originalWidth, originalHeight);

// 	const minX = originalWidth + 1, maxX = -1, minY = originalHeight + 1, maxY = -1, x = 0, y = 0, currentPixelColorValueIndex;

// 	for (y = 0; y < originalHeight; y++) {
// 		for (x = 0; x < originalWidth; x++) {
// 			currentPixelColorValueIndex = (y * originalWidth + x) * 4;
// 			let currentPixelAlphaValue = imageData.data[currentPixelColorValueIndex + 3];
// 			if (currentPixelAlphaValue > 0) {
// 				if (minX > x) minX = x;
// 				if (maxX < x) maxX = x;
// 				if (minY > y) minY = y;
// 				if (maxY < y) maxY = y;
// 			}
// 		}
// 	}

// 	let croppedWidth = maxX - minX;
// 	let croppedHeight = maxY - minY;
// 	if (croppedWidth < 0 || croppedHeight < 0) return null;
// 	let cuttedImageData = originalCtx.getImageData(minX, minY, croppedWidth, croppedHeight);

// 	let croppedCanvas = document.createElement('canvas');
//   let croppedCtx    = croppedCanvas.getContext('2d');

// 	croppedCanvas.width = croppedWidth;
// 	croppedCanvas.height = croppedHeight;
// 	croppedCtx.putImageData(cuttedImageData, 0, 0);

// 	return croppedCanvas.toDataURL();
// }
