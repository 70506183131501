import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableAkitaProdMode } from "@datorama/akita";
import { environment } from "@env/environment";
import * as Sentry from "@sentry/angular-ivy";
import { BehaviorSubject } from "rxjs";
import { AppModule } from "./app/app.module";
import { ADD_TO_HOMESCREEN } from "./app/modules/common";
import { changeFavicon, changeTheme, getEnv, getSentryConfig, ServerEnvironment } from "./util/index";

const addToHome = new BehaviorSubject<any>(null);


declare global {
  interface Window {
    gtag: any;
  }
}

/**
 * Only activate for Prod and Test (and staging when that's available)
 */
function runSentry() {
  const serverEnv = getEnv();
  if (![ServerEnvironment.LOCALHOST.toString()].includes(serverEnv)){
    Sentry.init(getSentryConfig(serverEnv));
  }
}

function runGA(){
  const serverEnv = getEnv();
  if (![ServerEnvironment.LOCALHOST.toString()].includes(serverEnv)){
    window.gtag('js', new Date());
    window.gtag('config', 'G-FM4WJB8NKN');
  }
}


function addToHomeScreen(event: any) {
  if (window.location.href.includes("/public/")) {
    console.log("A2HS: Block for public");
    event.preventDefault();
  } else {
    console.log("A2HS: Prompt");
    // event.prompt();
    addToHome.next(event);
  }
  // addToHome.next(event);
  // only listen for 1 iteration
  // window.removeEventListener("beforeinstallprompt", addToHomeScreen);
}
window.addEventListener("beforeinstallprompt", addToHomeScreen);

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  runGA();
  runSentry();
  changeTheme();
  changeFavicon();
  platformBrowserDynamic([
    { provide: ADD_TO_HOMESCREEN, useValue: addToHome }
  ])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
