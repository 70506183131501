import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BaseFormDirective } from "@modules/common/components";
import { UserService, WebLayoutService } from "@modules/common/services";

@Component({
  templateUrl: "forgot-password.component.html",
  styleUrls: ["forgot-password.component.scss"]
})
export class ForgotPasswordComponent extends BaseFormDirective implements OnInit {
  validationMessages = {
    email: {
      required: "Contact.InvalidEmail",
      email: "Contact.InvalidEmail"
    }
  };

  requested = false;
  requesting = false;

  constructor(
    protected layoutService: WebLayoutService,
    private userService: UserService, fb: FormBuilder) {
    super(layoutService, fb);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected createForm() {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    super.createForm();
  }

  requestReset() {
    const value = this.form.value;
    this.requesting = true;
    this.userService
    .requestResetPassword(value.email).subscribe(
      response => {
        this.requested = true;
        this.requesting = false;
        }
      );
  }

  back(){
    window?.history?.back();
  }
}
