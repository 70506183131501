import { Injectable } from "@angular/core";
import { EntityStore, StoreConfig } from "@datorama/akita";
import { ProductSettingDto } from "@shared/models";
import { ProductSettingState } from "./product-setting.service";
@Injectable({ providedIn: "root" })
@StoreConfig({ name: "productsetting", idKey: "setting" })
export class ProductSettingStore extends EntityStore<ProductSettingState, ProductSettingDto> {
  constructor() {
    super();
  }
}
