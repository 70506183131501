import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "abi-anchor",
  template: `<a
      *ngIf="clickable; else nonClickable"
      href=""
      (click)="onClick($event)"
      [class]="class"
      data-testid="anchor"
      >{{ content }}</a
    >
    <ng-template #nonClickable>{{ content }}</ng-template>`,
  styles: [],
})
export class AnchorComponent implements OnInit {
  @Input() clickable = false;
  @Input() content = "";
  @Input() class = "";
  @Output() clickedEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClick(event) {
    event.preventDefault();
    this.clickedEvent.emit();
  }
}
