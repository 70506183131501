import { Injectable } from "@angular/core";
import {
  LookupList,
  LookupListEx,
  LookupObjectDto
} from "@shared/models";
import { Observable } from "rxjs";
import { map, shareReplay, take, tap } from "rxjs/operators";
import { DataService, ServiceConfig } from "./data.service";
import { ListDataService } from "./list-data.service";

@Injectable({
  providedIn: "root",
})
export class LookupListService extends ListDataService {
  private lookupLists: { [name: string]: Observable<LookupList> } = {}; // listdata service

  constructor(protected config: ServiceConfig, dataService: DataService) {
    super(config, dataService);
    this.appQuery.$tenant2.subscribe(results => {
      // console.log("Tenant Changed", results, 'lookups reset');
      this.resetLookups();
    });
  }

  private getLookupList(listname: string, limit: number = null, path?: string[]): Observable<LookupListEx<LookupObjectDto>> {
    const pathMap = !!path && path.length ? "/" + path.map(p => this.safeEncode(p)).join("/") : "";
    const url = `values/list/${listname.toLowerCase()}${pathMap}`;
    const params = {
      // CODE SMELL: limit maybe 'undefined', but we only check for null
      ...(limit !== null ? { limit: limit.toString() } : {})// params must always be string typed
    };
    return this.http.get<LookupObjectDto[]>(url, { params })
    .pipe(
      map(res => new LookupListEx<LookupObjectDto>(res)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  /**
   * For use with LookupListEx (Extended Lookup List)
   * @param listname standard list names
   * @returns the Extended/Typed lookup list observable instances
   */
  lookupListEx<T extends LookupObjectDto>(listname: string): Observable<LookupListEx<T>> {
   return this.lookupList(listname) as Observable<LookupListEx<T>>;
  }

  /**
   * LookupList
   * @param listname LookupName
   * @param limit 0=No Limit, [number]=number of rows, null=server default (200)
   * @returns Observable LookupList
   * @todo add chache expiry params for often updated lists
   */
  lookupList(listname: string, limit: number = null, path: string[] = [], cache = true): Observable<LookupList> {
    const internalName = this.generateInternalListName(listname, limit, path);
    if (!this.lookupLists[internalName] && cache) {
      this.lookupLists[internalName] = this.getLookupList(listname.toLowerCase(), limit, path);
    }
    else if(!cache) {
      return this.getLookupList(listname.toLowerCase(), limit, path);
    }
    return this.lookupLists[internalName];
  }

  generateInternalListName(listname: string, limit: number = null, path: string[]): string {
    const pathMap = !!path && path.length ? "/" + path.map(p => this.safeEncode(p)).join("/") : "";
    return `${listname.toLowerCase()}${pathMap}${limit !== null ? `-${limit}` : ""}`;
  }

  /**
   * A Basic Direct Lookup Query
   * no caches are used
   * @deprecated find similar instances and replace with queryLookupList
   */
  queryLookupList(listname: string, path: string[], query: string): Observable<LookupList> {
    const url2 = !!path ? "/" + path.map(p => this.safeEncode(p)).join("/") : "";
    const url = `values/list/${listname.toLowerCase()}${url2}?query=${query}`;
    return this.http.get<LookupObjectDto[]>(url).pipe(
      map(res => new LookupListEx<LookupObjectDto>(res)));
  }

  /**
   * Add New Lookup Object to List
   */
  newLookup(listName: string, lookup: LookupObjectDto): Observable<LookupObjectDto> {
    const lListName = listName.toLowerCase();
    const url = `values/list/${lListName}`;
    return this.http.post<LookupObjectDto>(url, lookup).pipe(
      tap(dto => {
        const list = this.lookupLists[lListName];
        list?.subscribe(l => l.add(dto));
      }));
  }

  /**
   * Update Lookup
   */
  updateLookupCode(listName: string, codeId: string, lookup: LookupObjectDto): Observable<LookupObjectDto> {
    const lListName = listName.toLowerCase();
    const url = `values/list/${lListName}/${this.safeEncode(codeId)}`;
    return this.http.put<LookupObjectDto>(url, lookup).pipe(
      tap(dto => {
        const list = this.lookupLists[lListName];
        list?.pipe(take(1)).subscribe(l => l.update(codeId, lookup));
      }));
    }

  /**
   * Removes a Lookup Item from a List
   * @param listName Lookup List Name
   * @param codeId Lookup Code
   * @returns Observable
   * @todo check that API exists and works as expected
   */
  removeLookup(listName: string, codeId: string) {
    const lListName = listName.toLowerCase();
    const url = `values/list/${lListName}/${this.safeEncode(codeId)}`;
    return this.http.delete(url).pipe(
      tap(dto => {
        const list = this.lookupLists[lListName];
        list?.pipe(take(1)).subscribe(l => l.remove(codeId));
      }));
  }

  /**
   * Clear local Lookup Cache
   */
  resetLookups() {
    this.lookupLists = {};
  }
}
