import { Directive, Input, OnInit } from "@angular/core";
import { displayValue, LookupList, LookupListEx, LookupObjectDto, ZoneDto } from "@shared/models";
import { from, Observable } from "rxjs";
import { ProductSettingService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * @deprecated - this isnt used anywhere and should be removed (but probably just needs an update)
 */
@Directive({
  selector: "input[abiTypeahead][abiZone]"
})
export class ZoneTypeaheadDirective extends BaseObservableTypeaheadHelper<ZoneDto> implements OnInit {
  private code: string = null;
  private _list: ZoneDto[];

  constructor(typeAhead: TypeaheadDirective, private productSettingService: ProductSettingService) {
    super(typeAhead, []);
    this.dropDownDisplay = "all";
    this.inputDisplay = "all";
  }

  ngOnInit() {
    this.productSettingService.getZones().toPromise().then(lst => {
      this._list = lst;
      const allZone: ZoneDto = {
        code: "ALL",
        description: "All Zones",
        // carrier: "",
        // leadTimeUnit: "",
        // leadTimeValue: 0,
        leadTime: 0,
        order: 0,
        active: true,
      };
      this._list.splice(0, 0, allZone);
      this.loading = false;
      if (this.code && this._list.some(z => z.code === this.code)) {
        this.typeAhead.writeValue(this._list.find(z => z.code === this.code));
      }
    });
  }

  protected getId(item: ZoneDto): string {
    return item.code;
  }
  protected getName(item: ZoneDto): string {
    return item.description;
  }

  protected filteredList(term: string): Observable<ZoneDto[]> {
    const search = this.regExp(term);
    return this._list
      ? from([this._list.filter(item => search.test(item.code) && (!this.filter || this.filter(item, term)))])
      : from([]);
  }

  protected list(): Observable<ZoneDto[]> {
    if (this.filter) {
      return from([this._list.filter(itm => this.filter(itm, ""))]);
    } else {
      return from([this._list]);
    }
  }

}
