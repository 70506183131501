import { Component, forwardRef, HostBinding, HostListener, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "button[abiToggle]",
  templateUrl: "./toggle.component.html",
  styleUrls: ["./toggle.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    },
  ]
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() theme = "primary";
  @Input() baseClass = "btn btn-outline-";
  @Input() faClass = "fa-fw";
  @Input() btnClass = "";
  @HostBinding() type = "button";
  @Input() showDisabled = true;
  @HostBinding("disabled") @Input() disabled: boolean;
  @HostBinding("class")
  get buttonClass() {
    return this.btnClass + " " + this.baseClass + this.theme;
  };
  @HostBinding("class.active")
  @Input("abiToggle") checked: boolean;
  @HostListener("click")
  click(){
    this.checked = !this.checked;
    this.onChange(this.checked);
  }

  get icon(): string {
    if (!this.showDisabled && this.disabled) {
      return "";
    }
    return this.checked ? "far fa-check-square" : "far fa-square";
  }


  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /*
    toggle(event: MouseEvent) {
      //this.click.emit(event);
      //event.cancelBubble = true;
    }

    doBlur(event: FocusEvent) {
      this.blur.emit(event);
    }
  */

    private onTouched = () => { };
    private onChange = (_: any) => { };
}
