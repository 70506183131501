import { AddressDto } from "./AddressDto";
import { LookupObjectDto } from "./LookupObjectDto";
import { BinMasterDto } from "./wms/BinMasterDto";

export function newWarehouse(): WarehouseMasterDto {
  return {
    code: "",
    description: "",
    bins: [],
    isDefault: false,
    active: true,
  };
}
export interface WarehouseMasterDto extends LookupObjectDto {
  isDefault: boolean;
  transitWarehouseId?: string;
  address?: AddressDto;
  field1?: string;
  field2?: string;
  field3?: string;
  field4?: string;
  field5?: string;
  bins?: BinMasterDto[];
}

export interface CycleCountDto extends LookupObjectDto {
  periodFlag: boolean;
  period: string;
  quantityFlag: boolean;
  quantityUnder: number;
  daysOfSalesFlag: boolean;
  daysOfSales: number;
  demandFlag: boolean;
  demandUnder: number;
}

// public class CodeVehicleDto: LookupObjectDto
// {
//   public string Notes { get; set; }
//   public string CarrierId { get; set; }
// }
export interface CodeVehicleDto extends LookupObjectDto {
  notes: string;
  carrierId: string;
}
