import { Directive, Input, OnChanges } from "@angular/core";
import { OrderDto, SearchDto } from "@shared/models";
import { Observable, of  } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { SalesOrderService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiOrder]"
})
export class OrderTypeaheadDirective extends BaseObservableTypeaheadHelper<OrderDto> implements OnChanges, OnChanges {
  @Input() typeId: "purchaseorders" | "salesorders" = "purchaseorders";
  @Input() allowAll = false;

  constructor(typeAhead: TypeaheadDirective, private orderService: SalesOrderService) {
    super(typeAhead, ['allowAll']);
    this.loading = false;
  }

  protected getId(item: OrderDto): string {
    return item.orderId;
  }

  protected getName(item: OrderDto): string {
    return item.orderId;
  }

  protected getFull(item: OrderDto): string {
    return `${item.orderId} - ${item.orderDate.format()} - ${item.deliveryId}`;
  }

  // TODO: fix this uglyness
  protected filteredList(term: string): Observable<OrderDto[]> {
    // const sortCol = columns.find((c) => c.sortOrder > SortOrder.None);
    const salesOrderSearch: SearchDto = {
      pageNumber: 1,
      pageSize: 200,
      orderBy: [
        "orderid"
      ],
      filters: {
        orderid: term,
      },
    };

    if(!this.allowAll){
      salesOrderSearch.filters['closed'] = "0";// show open orders only - or showAll
    }
    // for (const prop of columns) {
    //   if (prop.filter) {
    //     salesOrderSearch.filters[prop.columnId || prop.field] = prop.filter;
    //   }
    // }

    return this.orderService.queryOrders(this.typeId, salesOrderSearch)
    .pipe(
      mergeMap(res => {
        return of(res.items);
      }),
      mergeMap(orders => {
        return this.preFilter && of(orders.filter(o => this.preFilter(o))) || of(orders);
      })
    );
  }

  newDealer(code = '', description = '') {
    return {
      dealerId: '*',
      baseCalendarId: '',
      code,
      description,
      order: 0,
      active: true
    };
  }

}
