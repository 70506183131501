import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { canAccessCamera as hasMediaEnabled, Disposable } from "@modules/common";
import { AppQuery, LoginStatus } from "@modules/common/app.store";
import { ModelMasterDto } from "@shared/models";
import { map } from "rxjs/operators";
import { ModelService, ProductSettingService } from "../../services";

export interface ScanResult {
  serialNumber?: string;
  modelId?: string | ModelMasterDto;
}

/**
 * Model Scanner Implementation
 */
@Component({
  selector: "abi-scan",
  templateUrl: "./scan.component.html",
  styleUrls: ["./scan.component.scss"],
})
export class ScanComponent extends Disposable implements OnInit {
  @Input() count: number = 2;
  @Input() checkModel: boolean;
  @Input() enabled = true;
  @Input() scanOnly = false; // only allow scanning via barcore reader - no manual entry
  @Input() serialNumber: string;
  @Input() modelId: string;
  @Output() scanned: EventEmitter<ScanResult> = new EventEmitter();

  private confirmRef: any;

  @ViewChild("resolveDialog") resolveDialog: any;
  @ViewChild("disclaimerDialog") disclaimerDialog: any;
  canAccessCamera = true; // canAccessCamera();
  scanSerials: string[] = [];
  scanModel: ModelMasterDto;

  form: FormGroup;

  checkScanSerial = false;
  checkScanModel = false;
  unknownModels: {[id: string]: ModelMasterDto} = {};
  isMobile: boolean;

  constructor(
    private dialog: MatDialog,
    private modelService: ModelService,
    private productSetting: ProductSettingService,

    private formBuilder: FormBuilder,
    private appQuery: AppQuery,
    private snackBar: MatSnackBar,
    private responsive: BreakpointObserver,
    ) {
      super();
      this.responsive
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .pipe(map((bp) => bp.matches)).subscribe(v => this.isMobile = v);
  }

  get canScan(): boolean {
    return this.enabled && hasMediaEnabled();
  }

  ngOnInit(): void {
    this.canAccessCamera = !!this.productSetting.stringValue("DynamsoftKey")
      && (this.appQuery.loginStatus === LoginStatus.True || this.productSetting.booleanValue("PublicBarcodeScanner"));
    this.form = this.formBuilder.group ({
      newSerial: "",
      newModel: ""
    });
    this.form.valueChanges.pipe(this.notDisposed()).subscribe(() => this.checkClose());
  }

  private async validateModel(code: string): Promise<ModelMasterDto> {
    const models = await this.modelService.queryModels(null, code).toPromise();
    const good = models.find((m) => m.base.toLowerCase().trim() === code.toUpperCase().trim()); // ??
    if (models.length && !good)
    {
      this.unknownModels[code] = {...models[0], description: code};
    }
    return good;
  }

  scan() {
    if (!this.canScan) {
      this.snackBar.open(
        "This browser is not supported for live barcode scanning. On iOS, please use Safari. On a computer, please use Chrome.",
        null,
        {
          duration: 5000,
          verticalPosition: "top",
          panelClass: ["bg-warning", "text-dark"]
        });
      return;
    }
    else if (this.canScan && !this.isMobile) {
      // it's possible it's a desktop of larger device that cannot be carried to the Appliance...
      this.dialog.open(this.disclaimerDialog)
        .afterClosed()
        .subscribe(res => {
          if(res) this.openScannerDialog();
        });
      return;
    }
    this.openScannerDialog();
  }

  async openScannerDialog() {
    this.scanSerials = [];
    this.scanModel = null;
    this.unknownModels = {};
    this.form.reset({
      newSerial: "",
      newModel: ""
    });
    this.checkScanSerial = false;
    this.checkScanModel = false;

    const { UserModule } = await import(
      /* webpackChunkName: 'user' */ "../../../user/user.module"
    );
    const comp = UserModule.components.barcodeScanner;

    const scanData = {
      count: this.count,
      validate: this.checkModel ? this.validateModel.bind(this) : null,
    };
    const ref = this.dialog.open(comp, { data: scanData });
    ref.afterClosed().subscribe((codes: any[]) => {
      this.scanSerials = [];

      if (!codes)
        return;

      for (const code of codes) {
        if (typeof code === "string") {
          if (code !== this.serialNumber)
            this.scanSerials.push(code);
        }
        else this.scanModel = code;
      }
      console.log(this.scanSerials, this.scanModel);
      if (this.scanSerials.length === 1 && !this.serialNumber)
        this.form.patchValue({newSerial: this.scanSerials[0]});

      this.checkScanModel =
        this.modelId &&
        this.scanModel &&
        this.scanModel.code !== this.modelId;

      this.checkScanSerial = this.scanSerials.length > 1 || (!!this.scanSerials.length && !!this.serialNumber);

      if (this.checkScanModel || this.checkScanSerial) {
        this.confirmRef = this.dialog.open(this.resolveDialog);
        this.confirmRef.afterClosed()
          .subscribe((ok) => {
            if (ok)
              this.scanned.emit({
                serialNumber: this.form.value.newSerial || this.serialNumber,
                modelId: this.form.value.newModel || this.modelId,
              });
        });
      }
      else
        this.scanned.emit({
          serialNumber: this.form.value.newSerial || this.serialNumber,
          modelId: this.scanModel || this.modelId,
        });
    });
  }

  checkClose() {
    if (this.form.value.newModel && this.form.value.newSerial) {
      this.confirmRef.close(true);
    }
  }
}
