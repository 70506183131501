<div class="row text-center">
    <div class="col-md-4 text-left-md">
        <div class="btn-group">
            <button
                type="button"
                [disabled]="disabled"
                class="btn btn-primary"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)"
            >
                Previous
            </button>
            <button
                type="button"
                [disabled]="disabled"
                class="btn btn-outline-secondary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)"
            >
                Today
            </button>
            <button
                type="button"
                [disabled]="disabled"
                class="btn btn-primary"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)"
            >
                Next
            </button>
        </div>
    </div>
    <div class="col-md-4">
        <h3>{{ viewDate | calendarDate: view + "ViewTitle":locale }}</h3>
    </div>
    <div class="col-md-4 text-right-md">
        <div class="btn-group">
            <button
                type="button"
                [disabled]="disabled"
                class="btn btn-primary"
                (click)="viewChange.emit(CalendarView.Month)"
                [class.active]="view === CalendarView.Month"
            >
                Month
            </button>
            <button
                type="button"
                [disabled]="disabled"
                class="btn btn-primary"
                (click)="viewChange.emit(CalendarView.Week)"
                [class.active]="view === CalendarView.Week"
            >
                Week
            </button>
            <button
                type="button"
                [disabled]="disabled"
                class="btn btn-primary"
                (click)="viewChange.emit(CalendarView.Day)"
                [class.active]="view === CalendarView.Day"
            >
                Day
            </button>
        </div>
    </div>
</div>
<br />
