<ng-container *ngIf="inModal; else inputSection">
  <div class="modal-header">
    <h4 class="modal-title">
      <span *ngIf="group.enabled">Edit </span>{{ 'Bank.Bank' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <ng-container *ngTemplateOutlet="inputSection"></ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" [disabled]="group.invalid" (click)="accept()">
      <i class="fas fa-check fa-fw"></i>{{ 'System.Ok' | translate }}</button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
      <i class="fas fa-times fa-fw"></i>{{ 'System.Cancel' | translate }}</button>
  </div>
</ng-container>

<ng-template #inputSection>
  <div [formGroup]="group" class="row">
    <!--abi-form-field label="Title">
      <select formControlName="title" class="custom-select">
        <option *ngFor="let ttl of titles" [value]="ttl.code">{{ ttl.description }}</option>
      </select>
    </abi-form-field-->
    <abi-form-field label="Bank.Bank" [errors]="formErrors.bank">
      <input formControlName="bank" abiTypeahead abiLookup="CodeBank" inputDisplay="name" dropDownDisplay="name" />
    </abi-form-field>
    <abi-form-field label="Bank.BranchCode" [errors]="formErrors.branchCode">
      <input formControlName="branchCode" abiTypeahead [abiLookup]="getBranches()" />
    </abi-form-field>
    <abi-form-field label="Bank.AccountNumber"
      [errors]="formErrors.accountNumber">
      <input formControlName="accountNumber" />
    </abi-form-field>
    <abi-form-field label="Bank.AccountType" [errors]="formErrors.accountType">
      <input formControlName="accountType" abiTypeahead abiLookup="CodeBankType" inputDisplay="name"
        dropDownDisplay="name" />
    </abi-form-field>
  </div>
</ng-template>
