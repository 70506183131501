<form *ngIf="form" [formGroup]="form">
    <div class="px-3">
        <div class="form-group row">
            <div class="col-sm-2 text-sm-right">
                <label for="rrule-frequency" class="col-form-label">
                    <strong>
                        {{ "RRule.RepeatEvery" | translate }}
                    </strong>
                </label>
            </div>
            <div class="col-sm-6">
                <div class="row mb-3">
                    <div class="col-4">
                        <input
                            id="rrule-interval"
                            aria-label="Repeat interval"
                            class="form-control"
                            formControlName="interval"
                        />
                    </div>
                    <div class="col-8">
                        <select
                            id="rrule-frequency"
                            formControlName="frequency"
                            (change)="onOptionChange()"
                            class="form-control mt-md-0 mt-sm-2"
                        >
                            <option
                                value="Yearly"
                            >
                                Year
                            </option>
                            <option
                                value="Monthly"
                            >
                                Month
                            </option>
                            <option
                                value="Weekly"
                            >
                                Week
                            </option>
                            <option
                                value="Daily"
                            >
                                Day
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <ngx-yearly
                *ngIf="form.value.frequency === 'Yearly'"
                formControlName="yearly"
            ></ngx-yearly>
            <ngx-monthly
                *ngIf="form.value.frequency === 'Monthly'"
                formControlName="monthly"
            ></ngx-monthly>
            <ngx-weekly
                *ngIf="form.value.frequency === 'Weekly'"
                formControlName="weekly"
            ></ngx-weekly>
        </div>
    </div>

    <hr/>
</form>
