import { Component } from "@angular/core";
import { StorageService } from "@modules/common/services";

@Component({
  selector: "abi-reset",
  templateUrl: "./reset.component.html",
  styleUrls: ["./reset.component.scss"]
})
export class ResetComponent {

  constructor(private storage: StorageService) { }

  reset() {
    this.storage.local.clear();
    this.storage.session.clear();
    document.location.href = "/";
  }

}
