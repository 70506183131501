import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest } from "rxjs";

@Directive({ selector: "[hasTranslation]" })
export class HasTranslationDirective {
  private createdView = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private translateService: TranslateService
  ) {}

  @Input() set hasTranslation(keyOrKeys: string | string[]) {
    if (typeof keyOrKeys === "string") {
      this.translateService.get(keyOrKeys)
      .subscribe(value => {
        this.showContent(!!value);
      });
    } else {
      combineLatest(keyOrKeys.filter(val => !!val)
        .map(key => this.translateService.get(key)))
        .subscribe((vals) => {
          this.showContent(vals.some(val => !!val));
        });
    }
  }

  showContent(flag: boolean) {
    if (flag) {
      // Add template to DOM
      if (!this.createdView)
        this.viewContainer.createEmbeddedView(this.templateRef);
      this.createdView = true;
    } else {
      // Remove template from DOM
      this.createdView = false;
      this.viewContainer.clear();
    }
  }
}
