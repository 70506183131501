<div class="">
    <div *ngIf="!file" class="d-flex justify-content-end w-100">
        <label *ngIf="!fileOnly" [class.disabled]="disabled" class="btn btn-primary btn-file">
            <i class="fas fa-camera fa-fw"></i> {{ 'Attachment.SelectImage' | translate }}
            <input [disabled]="disabled" type="file" accept="image/*" capture="environment" class="d-none" (change)="fileChange($event)" [ngModel]="file" data-testid="capture-camera"/>
        </label>
        <label [class.disabled]="disabled" class="btn btn-primary btn-file ml-2">
            <i class="fas fa-file fa-fw"></i> {{ 'Attachment.SelectFile' | translate }}
            <input *ngIf="!imageOnly" [disabled]="disabled" type="file" class="d-none" (change)="fileChange($event)" [ngModel]="file" data-testid="capture-file-any"/>
            <input *ngIf="imageOnly" accept="image/*" [disabled]="disabled" type="file" class="d-none" (change)="fileChange($event)" [ngModel]="file" data-testid="capture-file-image"/>
        </label>
    </div>
</div>

<div *ngIf="fileMessage" class="mt-2 alert alert-warning">{{ fileMessage }}</div>

<ng-container *ngIf="file">
    <div class="row">
        <ng-container *ngIf="showFileName">
            <abi-form-field label="Attachment.Filename" cols="col-12" data-testid="capture-file-name" [noFormGroup]="true">
                <input type="text" class="form-control-plaintext" data-testid="capture-file-name-input" [ngModel]="file.name" readonly>
            </abi-form-field>
        </ng-container>
        <abi-form-field label="" cols="col-12" labelColClass="col-12" inputColClass="col-12" data-testid="capture-description" [noFormGroup]="true">
            <div class="input-group py-0">
                <input *ngIf="showDescription" type="text" [disabled]="!descriptionEditable" [abiProductHint]="textPlaceholder" [(ngModel)]="description" [maxlength]="maxLength" />
                <div class="input-group-append">
                    <button type="button" class="btn btn-danger" (click)="cancel()" data-testid="capture-cancel">
                        <i class="fas fa-times fa-fw"></i>
                    </button>
                    <button type="button" class="btn btn-success" (click)="accept()" [disabled]="requireDescription && !description" data-testid="capture-accept">
                        <i class="fas fa-check fa-fw"></i>
                    </button>
                </div>
            </div>
        </abi-form-field>
    </div>
</ng-container>
