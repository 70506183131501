import { trim } from "@modules/models";

// TODO: Determine list of Journal Types (this may be dynamic)
// type JobJournalTypeTypes = "New" | "Accepted" | "Declined";

// export const JobJournalTypes: Record<string, JobJournalTypeTypes> = {
//   NEW: "New",
//   ACCEPTED: "Accepted",
//   DECLINED: "Declined"
// };

export interface JobJournalDto {
  typeId: string;
  text: string;
  from: string;
  to: string;
  created: Date;
  by: string;
  /**
   * This is a flag to indicate that this is a new journal entry that has not been saved yet.
   */
  isNew?: boolean;
}

/**
 * Standard Journal Entry - used in Job, Order, Order Line, etc.
 * @param typeId JOURNAL TYPE ID - can be anything
 * @param text DESCRIPTION OF THE JOURNAL
 * @param from FROM VALUE
 * @param to TO VALUE
 * @param created autofill - leave empty
 * @param by autofill - leave empty
 * @returns
 */
export function newJobJournal(typeId: string, text = "", from = "", to = "", created?: Date, by?: string) {
  return {
    typeId,
    text: trim(text),
    from,
    to,
    created,
    by,
  };
}
