import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoggingService } from "@core/services";

export type FileCapture = {
  description: string,
  file: File,
  typeId?: string
};

const defaultUploadTypes = ["application/pdf", "audio/", "image/", "video/"];
const invalidExtensions = [".heic", ".heif"];// apple proprietary files must be blocked

@Component({
  selector: "abi-capture",
  templateUrl: "./capture.component.html",
  styleUrls: ["./capture.component.scss"],
})
export class CaptureComponent {
  @Input() imageOnly: boolean = false;
  @Input() fileOnly: boolean = false;
  @Input() defaultDescription: string = "";
  @Input() descriptionEditable: boolean = true;
  @Input() requireDescription: boolean = false;
  @Input() textPlaceholder: string = "Attachment.Description";
  @Input() disabled = false;
  @Input() showFileName = true;
  @Input() showDescription = true;
  @Input() maxLength = 64;
  @Input() validUploadTypes = defaultUploadTypes;
  @Input() invalidExtensions = invalidExtensions;
  @Output() fileSelected: EventEmitter<FileCapture> = new EventEmitter();

  file: File = null;
  description: string = "";
  fileMessage: string = "";

  constructor(private loggingService: LoggingService) {}

  fileChange(event) {
    this.fileMessage = "";
    const fileList: FileList = event.target.files;
    if(!fileList.length) {
      this.cancel();
    }
    const firstSelectedFile = fileList[0];// only select the first file (only allow single files at a time)
    if(!this.validUploadTypes?.length) this.validUploadTypes = defaultUploadTypes;
    if (
      !this.validUploadTypes.some((t) => firstSelectedFile.type.includes(t)) || this.invalidExtensions.some(ext => firstSelectedFile.name.includes(ext))
    ) {
      this.fileMessage = `Invalid File/Type selected: ${firstSelectedFile.type || 'Unknown'}`;
      this.loggingService.logException(this.fileMessage);
      this.cancel();
    } else {
      this.file = firstSelectedFile;
      this.description = this.defaultDescription;
    }
  }

  cancel(): void {
    this.file = null;
    this.description = "";
  }

  accept(): void {
    // make a copy of the file and pass it back up the the stack
    const file = new File([this.file], this.file.name, { type: this.file.type, lastModified: this.file.lastModified });
    this.fileSelected.emit({ description: this.description, file });
    // clear local file refs for UX
    this.file = null;
    this.description = this.defaultDescription;
  }
}
