import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AppQuery } from "../app.store";

@Directive({ selector: "[hasClaim]" })
export class HasClaimDirective {
  private createdView = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private appQuery: AppQuery
  ) {}

  @Input() set hasClaim(claimType: string | string[]) {
    if (this.appQuery.hasClaim(claimType)) {
      // Add template to DOM
      if (!this.createdView)
        this.viewContainer.createEmbeddedView(this.templateRef);
      this.createdView = true;
    } else {
      // Remove template from DOM
      this.createdView = false;
      this.viewContainer.clear();
    }
  }
}
