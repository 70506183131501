<form [formGroup]="form">
    <div class="px-3">
        <div class="form-group row mb-0">
            <div class="col-sm-2 text-sm-right">
                <label for="mode" class="col-form-label">
                    <strong>
                        {{ "RRule.End" | translate }}
                    </strong>
                </label>
            </div>
            <div class="col-sm-2">
                <select formControlName="mode" class="form-control">
                    <!-- <option value="Never">Never</option> -->
                    <option value="After">After</option>
                    <option value="On date">On date</option>
                </select>
            </div>

            <div class="col-sm-4">
                <div *ngIf="form.value.mode === 'After'">
                    <div class="row d-flex align-items-center">
                        <div class="col-sm-6">
                            <input
                                formControlName="after"
                                aria-label="End after"
                                class="form-control"
                            />
                        </div>
                        <label class="col-sm">occurrences</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div
                            class="input-group"
                            *ngIf="form.value.mode === 'On date'"
                        >
                            <input
                                class="form-control"
                                formControlName="endAt"
                                placeholder="yyyy-mm-dd"
                                ngbDatepicker
                                #endAt="ngbDatepicker"
                                (click)="endAt.toggle()"
                                container="body"
                                [minDate]="minDate"
                            />
                            <div class="input-group-append">
                                <button
                                    type="button"
                                    class="btn btn-outline-primary"
                                    (click)="endAt.toggle()"
                                >
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
