import { Component, Input, OnChanges, SimpleChanges, } from "@angular/core";
import { FormArray } from "@angular/forms";
import { LookupListService } from "@modules/common/services/lookup-list.service";
import { LookupListEx, MachineCategoryDto } from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface MachineOptions {
  name: string;
  filters: string[];
  items: LookupListEx<MachineCategoryDto>;
  visible: boolean;
}

/**
 * This is virtually a carbon copy of 'machine-categories.component.ts'
 */
@Component({
  selector: "abi-machine-options",
  templateUrl: "./machine-options.component.html",
  styleUrls: ["./machine-options.component.scss"],
})
export class MachineOptionsComponent implements OnChanges {
  @Input() categoryControls: FormArray;
  @Input() allCategories = false;
  @Input() readonly = false;
  categories: MachineOptions[];
  hiddenCategories: boolean[] = [];

  constructor(private lookups: LookupListService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCategories(
      ['MachineOption1ID', 'MachineOption2ID', 'MachineOption3ID', 'MachineOption4ID', 'MachineOption5ID']
    )
    .subscribe(c => this.categories = c);
  }

  categoryFilter(level: number, item: MachineCategoryDto): boolean {
    if (this.categories[level].filters && item.parentId) {
      const pFilter = this.categoryControls.value[(+item.parentId) - 1];
      if (!pFilter) {
        return true;
      } else {
        return this.categories[level].filters.includes(pFilter.code)
          ? (item.filter === pFilter.code)
          : !item.filter;
      }
    } else {
      return !item.filter;
    }
  }

  private getCategories(webCats: string[]): Observable<MachineOptions[]> {
    const catsObs = this.lookups.lookupListEx<MachineCategoryDto>("CodeMachineOption");
    return catsObs.pipe(map(cats => {
      const mainCategories = cats.values.filter(c => c.active);
      let lastCat: MachineCategoryDto = null;
      let idx = mainCategories.length - 1;
      while (idx) {
        const cat = mainCategories[idx];
        if (!cat.extras) {
          cat.extras = [];
        }
        if (!lastCat || lastCat.order !== cat.order || lastCat.description !== cat.description) {
          lastCat = cat;
          lastCat.extras = [lastCat.code];
        } else {
          lastCat.extras.push(cat.code);
          mainCategories.splice(idx, 1);
        }
        idx--;
      }

      const result: MachineOptions[] = [];

      for (let i = 0; i < this.categoryControls.length; i++) {
        const cat = webCats.find(c => c.includes("" + (i + 1))) || "";
        const id = i + 1;
        const name = `Machine.Option${id}`; // TODO: add translations
        const items = mainCategories.filter(v => v.order === id && v.active);
        result.push({
          name,
          filters: items.filter(ii => !!ii.filter).map(f => f.filter).filter((value, index, self) => self.indexOf(value) === index),
          items: new LookupListEx<MachineCategoryDto>(items),
          visible: !!cat,
        });

        this.hiddenCategories.push(!cat);
        if (this.categoryControls.parent.enabled) {
          if (!cat) {
            this.categoryControls.at(i).disable();
          } else {
            this.categoryControls.at(i).enable();
          }
        }
      }
      return result;
    }));
  }
}
