import { CdkPortal, DomPortalOutlet } from "@angular/cdk/portal";
import {
  AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, Injector, Input, OnDestroy, OnInit,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "abi-page-actions",
  template: `
    <ng-template cdkPortal>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: []
})
export class PageActionsComponent implements OnInit, AfterViewInit, OnDestroy {
  private portalHost: DomPortalOutlet;
  @Input() container = "#page-actions-container";
  @ViewChild(CdkPortal) portal: CdkPortal;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    // Create a portalHost from a DOM element
    this.portalHost = new DomPortalOutlet(
      document.querySelector(this.container),
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    // Attach portal to host
    this.portalHost.attach(this.portal);
  }

  ngOnDestroy(): void {
    this.portalHost.detach();
  }
}
