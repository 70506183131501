import { Injectable } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import { KeyGetter } from "@services";
import { Observable, from, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { DataService, ServiceConfig } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class GooglemapapiService extends DataService {
  apiLoaded: Observable<boolean>;

  constructor(config: ServiceConfig, keys: KeyGetter) {
    super(config);
    const loader = new Loader({
      apiKey: KeyGetter.mapskey,
      version: "weekly",
      libraries: ["core", "places", "maps", "geocoding", "routes", "marker"],
    });
    this.apiLoaded = of(false)
    .pipe(switchMap(() => of(this.getMaps())))// get a fresh ref
    .pipe(switchMap(apiRef => {
      return !apiRef ? from(loader.load().then(() => true)) : of(true);
    }))
    .pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  getMaps(){
    return window.google?.maps;
  }

  isApiLoaded(): Observable<boolean> {
    return this.apiLoaded;
  }
}
