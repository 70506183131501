<ng-container *ngIf="inModal; else inputSection">
    <h4 mat-dialog-title>
        <span *ngIf="group.enabled">{{ 'System.Edit' | translate }} </span>{{ 'Contact.Contact' | translate }}
    </h4>
    <mat-dialog-content>
        <ng-container *ngTemplateOutlet="inputSection"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button type="button" cdkFocusInitial mat-button [disabled]="group.disabled || group.invalid" (click)="accept()">
        {{ 'System.Ok' | translate }}</button>
        <button type="button" mat-button (click)="close()">
        {{ 'System.Cancel' | translate }}</button>
    </mat-dialog-actions>
</ng-container>

<ng-template #inputSection>
    <div [formGroup]="group" class="row">
        <div class="col-md-6">
            <div class="row">
                <abi-form-field label="Contact.Title" cols="col-md-12">
                    <input formControlName="title" abiTypeahead abiLookup="Title" inputDisplay="name" dropDownDisplay="name"/>
                </abi-form-field>

                <ng-container formGroupName="names">
                    <abi-form-field label="Contact.FirstName" path=".." cols="col-md-12">
                        <input formControlName="firstName" />
                    </abi-form-field>

                    <abi-form-field label="Contact.LastName" path=".." cols="col-md-12">
                        <input formControlName="lastName" />
                    </abi-form-field>

                    <abi-form-field *ngIf="showField('companyName')" label="Contact.Company" path=".." cols="col-md-12">
                        <input formControlName="companyName" />
                    </abi-form-field>

                    <abi-form-field *ngIf="showField('note', false)" label="Contact.Note" path=".." cols="col-md-12">
                        <input formControlName="note" />
                    </abi-form-field>
                </ng-container>

                <abi-form-field *ngIf="showField('instantMessageId')" label="Contact.InstantMessageID" path=".." cols="col-md-12">
                    <input formControlName="instantMessageId" />
                </abi-form-field>

                <abi-form-field *ngIf="showField('idNumber')" label="Contact.IDNumber" cols="col-md-12">
                    <input formControlName="idNumber" type="text" />
                </abi-form-field>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <abi-form-field label="Contact.EmailAddress" cols="col-md-12" [errors]="formErrors?.emailAddress">
                    <input formControlName="emailAddress" type="email" />
                </abi-form-field>

                <ng-container formGroupName="numbers">
                    <abi-form-field label="Contact.Mobile" path=".." cols="col-md-12">
                        <abi-phone-input *ngIf="withCountrySelector" formControlName="mobile"></abi-phone-input>
                        <input *ngIf="!withCountrySelector" type="text" [mask]="phoneMask" formControlName="mobile" />
                    </abi-form-field>

                    <abi-form-field *ngIf="showField('officeHours')" label="Contact.OfficeHours" path=".." cols="col-md-12">
                        <abi-phone-input *ngIf="withCountrySelector" formControlName="officeHours"></abi-phone-input>
                        <input *ngIf="!withCountrySelector" type="text" [mask]="phoneMask" formControlName="officeHours" />
                    </abi-form-field>

                    <abi-form-field *ngIf="showField('afterHours')" label="Contact.AfterHours" path=".." cols="col-md-12">
                        <abi-phone-input *ngIf="withCountrySelector" formControlName="afterHours"></abi-phone-input>
                        <input *ngIf="!withCountrySelector" type="text" [mask]="phoneMask" formControlName="afterHours" />
                    </abi-form-field>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
