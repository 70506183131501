type JobHoldStatus = "New" | "Accept" | "Decline";

export const JobHoldTypes: Record<string, JobHoldStatus> = {
  NEW: "New",
  ACCEPTED: "Accept",
  DECLINED: "Decline"
};

export interface JobHoldDto {
  holdId: string;// main id
  status: JobHoldStatus | "";// status
  typeId: string;// type id - this might control what special features the hold has
  clearedUserId: string;// user id that cleared the hold
  cleared?: Date;
  notes?: string;// notes

  // new fields
  // signatureRequired?: boolean;// is a signature required to clear the hold
  contactId?: string;// contact id
  gpsCoordinates?: number[];// gps location (todo: check structure)
  attachmentId?: string;// attachment id

  permissions?: Record<string, number>;// permissions
}
