import { Directive, Input } from "@angular/core";
import { ResourceMasterDto, StockMasterDto } from "@shared/models";
import { Observable } from "rxjs";
import { map, skip, take, tap } from "rxjs/operators";
import { StockService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

export type StockMode = "all" | "fixed" | "adhoc";

@Directive({
  selector: "input[abiTypeahead][abiStock]",
})
export class StockTypeaheadDirective extends BaseObservableTypeaheadHelper<StockMasterDto> {
  @Input() abiStock: string;
  @Input() warehouseId: string;
  @Input() mode: StockMode = "all";
  @Input() modelId = "";
  @Input() showQuantity = true;
  @Input() forceTriggerOnUpdate = false;// ensures that the validator runs when the value is updated (internally)

  constructor(
    typeAhead: TypeaheadDirective,
    private stockService: StockService
  ) {
    super(typeAhead, ["abiStock", "mode", "modelId", "warehouseId"]);
    this.loading = false;
  }

  protected getId(item: StockMasterDto): string {
    return item.code;
  }

  /**
   * Generates a string with both the Description and Stock quantity value if available
   * @param item Stock item
   * @returns string
   */
  protected getName(item: StockMasterDto): string {
    const qty = this.showQuantity && (this.abiStock === "STK" || this.abiStock === "MDL")
      ? item.hasOwnProperty("quantity")  && Number.isInteger(item.quantity) ? ` [Qty: ${item.quantity}]` : ""
      : "";
    return `${item.description}${qty}`;
  }

  protected filteredList(term: string): Observable<StockMasterDto[]> {
    let filter: (line: StockMasterDto) => boolean = (line) => true;

    if (this.mode === "fixed") {
      filter = (i) => !!i.eanCode;
    } else if (this.mode === "adhoc") {
      filter = (i) => !i.eanCode;
    }

    return this.stockService
      .getStock(
        this.modelId,
        this.warehouseId,
        this.abiStock,
        this.preLoad ? "" : term,
        filter
      )
      .pipe(map((r) => r.values));
  }

  // NOTE: override upstream method to trigger 'onChange' - this should probably be standardised
  protected onWriteValue(obj: any): any {
    let retVal = obj;
    // this._internalValue = obj; // Keep an Internal Representation of the original Dto (for later use)
    // const iteration = new Date();
    // if object
    if (obj && typeof obj === "object") {
      if(this.forceTriggerOnUpdate)
        this.typeAhead.doOnChange(obj);// note: THIS UPDATES THE VALUE OF THE INPUT - ALLOWING ATTACHED VALIDATORS TO TRIGGER
      return obj;
    }
    if(obj && typeof obj === "string") {
      let id = 0;
      // eslint-disable-next-line no-cond-assign
      if (this.lastResult && (id = this.indexOf(obj)) > -1) {
        retVal = this.lastResult[id];

      } else {
        // console.log('start filtering for', obj)
        this.startValue = obj;
        this.filterAndSaveList(obj)
          .pipe(
            take(1),
            // tap(vals => console.log('filterAndSaveList result', vals))
          )
          .subscribe(lst => {
            // console.log('filtered', lst)
            if (lst && lst.length) {
              const nId = this.indexOf(obj);
              // console.log('writing real value from string', lst, this.lastResult[nId])
              if (nId > -1) {
                this.hadResult = !!obj;
                this.typeAhead.writeValue(this.lastResult[nId]);
              }
            }
          });
      }
    }

    if (!retVal) {
     this.lastResult = null;
    }
    this.hadResult = !!retVal;
    return retVal;
  }



}
