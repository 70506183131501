<form class="d-flex">
    <div class="form-group hidden">
        <div class="input-group">
            <input
                name="datepicker"
                class="form-control"
                ngbDatepicker
                #datepicker="ngbDatepicker"
                [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="displayMonths"
                [dayTemplate]="t"
                [outsideDays]="outsideDays"
                [startDate]="fromDate!"
                tabindex="-1"
                container="body"
                [footerTemplate]="footerTemplate"
                noBootstrap
            />
            <ng-template #t let-date let-focused="focused" let-selected="selected" let-currentMonth="currentMonth" let-disabled="disabled">
                <span
                    class="custom-day"
                    [class.focused]="focused"
                    [class.outside]="isMuted(date, selected, currentMonth, disabled)"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null"
                >
                    {{ date.day }}
                </span>
            </ng-template>
        </div>
    </div>
    <div class="form-group mb-0 w-100">
        <div class="input-group flex-nowrap">

            <input
                #dpFromDate
                class="form-control"
                name="dpFromDate"
                [value]="inputValue"
                noBootstrap
                readonly="true"
            />
            <div class="input-group-append">
                <button
                    class="btn btn-outline-primary calendar"
                    (click)="datepicker.toggle()"
                    type="button"
                >
                    <i class="fas fa-calendar-alt fa-sm"></i>
                </button>
            </div>
        </div>
    </div>
</form>
<ng-template #footerTemplate>
    <hr class="my-0" />
    <div class="form-group px-2 pt-2">
        <select class="form-control mb-2" noBootstrap (change)="presetSelected($event.target.value);datepicker.close()">
            <option *ngFor="let preset of dateRanges;index as i" [value]="i" [selected]="isRangeSelected(i)">{{ preset[0] | translate }}</option>
        </select>
        <button
            class="btn btn-outline-primary btn-sm"
            (click)="clear()"
        >
            <i class="fas fa-backspace fa-fw mr-2 d-none d-md-inline"></i>Clear
        </button>
        <button
            class="btn btn-primary btn-sm float-right"
            (click)="datepicker.close()"
        >
            Close
        </button>
    </div>
</ng-template>
