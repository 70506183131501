import { JobJournalDto } from "./JobJournalDto";
import { OrderLineDeliveryDto } from "./OrderLineDeliveryDto";
import { OrderLineReturnDto } from "./OrderLineReturnDto";

export const OrderLineStatus = {
  REJECTED: "Rejected",
  APPROVED: "Approved",
  COLLECTED: "Collected",
};

export interface OrderLineDto {
  lineId: string;
  typeId: string;
  invoiceNumber: string;
  structureId: string;
  itemId: string;
  line1: string;
  line2: string;
  requested: Date;
  shipping: Date;
  quantityOrdered: number;
  quantityPicked?: number;
  quantityDelivered: number;
  price: number;
  discountPercentage: number;
  vatId: string;
  vatRate: number;
  warehouseId: string;
  deliveries: OrderLineDeliveryDto[];
  returns: OrderLineReturnDto;
  attachmentCount?: number;
  journals?: JobJournalDto[];
  serialnumbers?: string[]; // some orders may have scanned Serials against them
  priorityId?: number;
}

export function newOrderLine(): OrderLineDto {
  return {
    lineId: "",
    typeId: "",
    invoiceNumber: "",
    structureId: "",
    itemId: "",
    line1: "",
    line2: "",
    requested: null,
    shipping: null,
    quantityOrdered: 0,
    quantityDelivered: 0,
    price: 0,
    discountPercentage: 0,
    vatId: "",
    vatRate: 0,
    warehouseId: "",
    deliveries: [],
    returns: null,
    journals: []
  };
}

export function quantityPicked(self: OrderLineDto): number {
  return self.deliveries && self.deliveries.length ? self.deliveries.reduce(
    (p: number, d: OrderLineDeliveryDto) => p + d.allocated, 0) : 0;
}

/*
get selected() {
  return self.quantityOrdered > 0;
}

set selected(value: boolean) {
  self.quantityOrdered = value ? 1 : 0;
}
*/
