import { EventEmitter, Input, Output } from "@angular/core";
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FileCapture } from "..";

@Component({
  selector: 'abi-file-button',
  templateUrl: './file-button.component.html',
  styleUrls: ['./file-button.component.scss']
})
export class FileButtonComponent {
  @Input() fileOnly: boolean = false;
  @Input() imageOnly: boolean = false;
  @Input() modalTitle = "File Upload";
  @Input() faClass = "fa-fw";
  @Input() btnClass = "";
  @Input() loading = false;
  @Input() defaultDescription = "";
  @Input() descriptionPlaceholder = "";
  @Input() descriptionEditable: boolean = true;
  @Input() buttonDisabled = false;
  @Input() showDescription = true;
  @Input() captureDisabled = false;
  @Input() descriptionRequired = false;
  @Input() maxLength = 64;
  @Input() validUploadTypes;

  @ViewChild('chooseFileModal') chooseFileModal: TemplateRef<NgbModal>;
  @Output() selected = new EventEmitter();
  dialog: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  openModal(){
    this.dialog = this.modalService.open(this.chooseFileModal);
  }

  closeDialog() {
    this.dialog.close();
  }

  fileSelected(fileCap: FileCapture) {
    this.selected.emit(fileCap);
    this.closeDialog();
  }
}
