import { Directive, Input, OnChanges } from "@angular/core";
import { DealerBranchDto } from "@shared/models";
import { Observable, of  } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { DealerService, newBranch } from "../services";
import { BaseObservableTypeaheadHelper, preFilterContext } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * This lists DealerBranches (not Dealers)
 */
@Directive({
  selector: "input[abiTypeahead][abiDealer]"
})
export class DealerTypeaheadDirective extends BaseObservableTypeaheadHelper<DealerBranchDto> implements OnChanges {
  @Input() allowAll = false;
  @Input() showAllBranches = false;// by default the branches are linked to the Users Access permeissions
  @Input() filterByDealerId: string;

  constructor(typeAhead: TypeaheadDirective, private dealerService: DealerService) {
    super(typeAhead, []);
    this.loading = false;
  }

  protected getId(item: DealerBranchDto): string {
    return item.code;
  }

  protected getName(item: DealerBranchDto): string {
    return item.description;
  }

  protected getFull(item: DealerBranchDto): string {
    return `${item.dealerId} ~ ${item.description}`;
  }

  getList(term: string){
    if(this.filterByDealerId) {
      return this.dealerService.get(this.filterByDealerId)
      .pipe(switchMap(dealer => {
        const result = dealer.branches;
        return of(result);
      }));
    } else {
      return this.dealerService.queryBranches(term, this.showAllBranches);
    }
  }

  protected filteredList(term: string): Observable<DealerBranchDto[]> {
    return this.getList(term).pipe(mergeMap(res => {
      if(this.allowAll) {
        const newList = [newBranch('*', 'All'), ...res];
        return of(newList);
      }

      if (this.preFilter) {
        res = res.filter(itm => this.preFilter.hasOwnProperty('thisArg') ? (this.preFilter as unknown as preFilterContext).preFilter(itm, (this.preFilter as unknown as preFilterContext).thisArg) : this.preFilter(itm));
      }

      return of(res);
    }));
  }
}
