import { Injectable } from "@angular/core";
import { ListResultDto, ResponseDto, SearchDto, SurveyDto } from "@shared/models";
import { Observable } from "rxjs";
import { DataService, ServiceConfig } from "../";

@Injectable({
  providedIn: "root"
})
export class SurveyService extends DataService {
  constructor(config: ServiceConfig) {
    super(config);
  }

  getSurvey(surveyId: string): Observable<SurveyDto> {
    return this.http.get<SurveyDto>(`surveys/survey/${surveyId}`);
  }

  updateResponse(response: ResponseDto): Observable<ResponseDto> {
    return this.http.put<ResponseDto>(`surveys/${response.responseId}`, response);
  }

  getResponse(responseId: string): Observable<ResponseDto> {
    return this.http.get<ResponseDto>(`surveys/${responseId}`);
  }

  queryResponses(query: SearchDto) {
    return this.http.get<ListResultDto<ResponseDto>>('surveys/search', {params: this.searchQueryToParams(query)});
  }
}
