export interface UserMetricSummaryDto {
  id: string;
  name: string;
  value: number;
  target: boolean;
  trend: boolean;
}

export const dummyMetric = (): UserMetricSummaryDto => {
  const result: UserMetricSummaryDto = {
    name: "-",
    value: -99999,
    id: "",
    target: null,
    trend: undefined,
  };
  return result;
};
