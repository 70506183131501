<form [formGroup]="form">
    <div class="form-group row d-flex align-items-sm-center">
        <div class="col-sm-1 offset-sm-2">
            <input
                type="radio"
                aria-label="Repeat yearly on"
                value="on"
                formControlName="mode"
            />
        </div>

        <div class="col-sm-1">
            {{ "RRule.On " | translate }}
        </div>

        <ng-container formGroupName="on">
            <div class="col-sm-2">
                <select
                    formControlName="month"
                    aria-label="Repeat yearly on month"
                    class="form-control"
                    [attr.disabled]="form.value.mode !== 'on' ? '' : null"
                >
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="Jun">Jun</option>
                    <option value="Jul">Jul</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                </select>
            </div>

            <div class="col-sm-2">
                <select
                    formControlName="day"
                    aria-label="Repeat yearly on a day"
                    class="form-control"
                    [attr.disabled]="form.value.mode !== 'on' ? '' : null"
                >
                    <option
                        *ngFor="let val of range(1, 32)"
                        [value]="val.toString()"
                    >
                        {{ val }}
                    </option>
                </select>
            </div>
        </ng-container>
    </div>

    <div class="form-group row d-flex align-items-sm-center">
        <div class="col-sm-1 offset-sm-2">
            <input type="radio" value="on the" formControlName="mode" />
        </div>
        <div class="col-sm-1">
            {{ "RRule.OnThe" | translate }}
        </div>

        <ng-container formGroupName="onThe">
            <div class="col-sm-2">
                <select
                    formControlName="which"
                    aria-label="Repeat yearly on the which"
                    class="form-control"
                    [attr.disabled]="form.value.mode === 'on' ? '' : null"
                >
                    <option value="First">First</option>
                    <option value="Second">Second</option>
                    <option value="Third">Third</option>
                    <option value="Fourth">Fourth</option>
                    <option value="Last">Last</option>
                </select>
            </div>

            <div class="col-sm-2">
                <select
                    formControlName="day"
                    aria-label="Repeat yearly on the day"
                    class="form-control"
                    [attr.disabled]="form.value.mode === 'on' ? '' : null"
                >
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                    <option value="Day">Day</option>
                    <option value="Weekday">Weekday</option>
                    <option value="Weekend day">Weekend day</option>
                </select>
            </div>

            <div class="col-sm-1">
                {{ "RRule.Of" }}
            </div>

            <div class="col-sm-2">
                <select
                    formControlName="month"
                    aria-label="Repeat yearly on the month"
                    class="form-control"
                    [attr.disabled]="form.value.mode === 'on' ? '' : null"
                >
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="Jun">Jun</option>
                    <option value="Jul">Jul</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                </select>
            </div>
        </ng-container>
    </div>
</form>
