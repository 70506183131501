import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BaseAttachmentDto,
  ListResultDto,
  LookupListEx,
  SearchDto,
  StockMasterDto,
  StockSummaryResultDto,
  StockTransactionDto,
  StockTransactionResultDto,
  warehouse,
} from "@shared/models";
import { Observable, of } from "rxjs";
import { catchError, delay, map } from "rxjs/operators";
import { StockSupplierDto, newSupplierStock } from "@shared/models/StockSupplierDto";
import { DataService, Progress, ServiceConfig } from "./data.service";
import { UploadFile } from "./file.service";

export interface StockData {
  price: number;
  mode: string;
}

export interface StockSerialDto {
  stockId: string;
  serialId: string;
  warehouseId: string;
  binId: string;
  statusId: string;
  description: string;
  receivedDate: Date;
  supplierId: string;
  purchaseOrderId: string;
  purchOrderLine: string;
  customerId: string;
  addressId: string;
  customerName: string;
  orderId: string;
  orderLine: string;
  invoiceId: string;
  price: number;
  customerDeliveryDate: Date;
  warrantyExpireDate: Date;
  location: string;
  ownership: string;
  transactions: StockTransactionDto[];
}

@Injectable({
  providedIn: "root",
})
export class StockService extends DataService {
  constructor(protected config: ServiceConfig) {
    super(config);
  }

  getPrice(stockId: string, jobId: string): Observable<StockData> {
    const url = `warehouses/stock/${this.safeEncode(stockId)}/price/${jobId}`;
    return this.http.get<StockData>(url);
  }

  getStockMaster(stockId: string): Observable<StockMasterDto> {
    return this.http.get<StockMasterDto>(
      `warehouses/stock/${this.safeEncode(stockId)}/details`
    );
  }

  createStockMaster(stock: StockMasterDto): Observable<StockMasterDto> {
    return this.http.post<StockMasterDto>("values/item/StockMaster", stock);
  }

  // updateStockMaster(stock: StockMasterDto): Observable<StockMasterDto> {
  //   return this.http.put<StockMasterDto>(`values/item/StockMaster/${stock.code}`, stock)
  // }

  /**
   * Get individual stock transations (per serial?)
   * Possible Stock/Movement parameters
   * StockID, OrderID, LineID, DeliveryNoteID, Reference
   */
  searchStockTransations(search: SearchDto): Observable<ListResultDto<StockTransactionDto>> {
    const params = new HttpParams().appendAll(search.filters);
    return this.http.get<StockTransactionResultDto>(`warehouses/transactions?${params.toString()}`);
  }

  /**
   * Search StockTransactions
   */
  getStockTransactions(
    stockId: string,
    query: SearchDto
  ): Observable<StockTransactionResultDto> {
    const url = `warehouses/stock/${this.safeEncode(
      stockId
    )}/transactions`;
    return this.http.get<StockTransactionResultDto>(url, { params: this.searchQueryToParams(query)});
  }

  getStockTypeTransactions(
    typeId: string,
    pageNumber = 1,
    pageSize = 100,
    warehouseId = ""
  ): Observable<StockTransactionResultDto> {
    const params = new HttpParams()
      .append("pageNumber", pageNumber.toString())
      .append("pageSize", pageSize.toString())
      .append("warehouseId", warehouseId);
    const url = `warehouses/stock/transactions/${this.safeEncode(
      typeId
    )}?${params.toString()}`;
    return this.http.get<StockTransactionResultDto>(url);
  }

  getStockExtras(
    stockId: string,
    extra: string,
    pageNumber = 1,
    pageSize = 100
  ): Observable<StockSummaryResultDto> {
    const url = `warehouses/stock/${this.safeEncode(
      stockId
    )}/${extra}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<StockSummaryResultDto>(url);
  }

  updateStockMaster(stock: StockMasterDto): Observable<StockMasterDto> {
    return this.http.put<StockMasterDto>(
      `warehouses/stock/${this.safeEncode(stock.code)}`,
      stock
    );
  }

  stockTransactions(
    action: string,
    transactions: StockTransactionDto[]
  ): Observable<any> {
    return this.http
      .post<any>(`warehouses/stock/transactions/${action}`, transactions)
      .pipe(map((x) => x.documentId));
  }

  getTransactions(document: string): Observable<StockTransactionDto[]> {
    return this.http.get<any>(`warehouses/stock/transaction/${document}`);
  }

  getStock(
    modelId: string,
    warehouseId: string,
    typeId: string,
    query: string = "",
    filter: (sm: StockMasterDto) => boolean = null
  ): Observable<LookupListEx<StockMasterDto>> {
    /*    warehouseId = !!warehouseId ? warehouseId + "/" : ""
    const url = !modelId // || true
      ? `warehouses/${warehouseId}stock/${typeId}?query=${query}`
      : `warehouses/${warehouseId}stock/model/${this.safeEncode(modelId)}/${typeId}?query=${query}`;
*/
    const url = `values/list/StockMaster/${typeId}/${
      warehouseId || "_"
    }/${this.safeEncode(modelId || "")}?query=${encodeURIComponent(query)}`;
    return this.http
      .get<StockMasterDto[]>(url)
      .pipe(
        catchError(e => []), // Prevent Lookup component crash
        map((res) => this.extractLookupData<StockMasterDto>(res, filter))
      );
  }

  queryStock(query: SearchDto): Observable<ListResultDto<StockMasterDto>> {
    const params: any = { ...query.filters };
    if (params.code) params.code = encodeURIComponent(params.code);
    params.pageNumber = query.pageNumber;
    params.pageSize = query.pageSize;
    params.orderBy = query.orderBy[0] || "";

    return this.http.get<ListResultDto<StockMasterDto>>("warehouses/stock", {
      params,
    });
  }

  queryStockSerial(stockId: string, serial: string) {
    const url = `warehouses/stock/${this.safeEncode(stockId)}/serial/${this.safeEncode(serial)}`;
    return this.http.get<StockSerialDto>(url);
  }


  //#region STOCK SUPPLIER
  queryStockSupplier(query: SearchDto): Observable<ListResultDto<StockSupplierDto>> {
    const url = `inventory/stocksupplier`;
    return this.http.get<ListResultDto<StockSupplierDto>>(url, { params: this.searchQueryToParams(query)});
  }

  saveStockSupplier(stockSupplier: StockSupplierDto): Observable<StockSupplierDto> {
    return this.http.post<StockSupplierDto>(`inventory/stocksupplier`, stockSupplier);
  }

  updateStockSupplier(stockSupplier: StockSupplierDto): Observable<StockSupplierDto> {
    return this.http.put<StockSupplierDto>(`inventory/stocksupplier`, stockSupplier);
  }

  deleteStockSupplier(stockSupplier: Partial<StockSupplierDto>): Observable<any> {
    return this.http.delete(`inventory/stocksupplier/${stockSupplier.stockId}/${stockSupplier.supplierId}`);
  }
  //#endregion

  uploadStockTransactions(sheet: string, cell: string, uploadFile: UploadFile, progress: Progress) {
    const formData = new FormData();
    formData.append(uploadFile.description, uploadFile.file, uploadFile.file.name);
    const url = `warehouses/stock/transactions/xlsx/${sheet}/${cell}`;
    return this.http
      .post<BaseAttachmentDto>(url, formData, {reportProgress: true, observe: "events"}).pipe(this.uploading(progress));
  }
}
