<form [formGroup]="form" class="">
    <div class="px-3">
        <div class="form-group row mb-0">
            <div class="col-sm-2 text-sm-right">
                <label for="rrule-start" class="col-form-label">
                    <strong>
                        {{ "RRule.Start" | translate }}
                    </strong>
                </label>
            </div>

            <div class="input-group col-6 col-sm-2">
                <input
                    type="text"
                    class="form-control"
                    id="rrule-start"
                    formControlName="startDate"
                    placeholder="Start Date"
                    name="startDate"
                    ngbDatepicker
                    #startDp="ngbDatepicker"
                    (click)="startDp.toggle()"
                    container="body"
                />
                <div class="input-group-append d-block">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="startDp.toggle()"
                    >
                        <i class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <hr/>
</form>
