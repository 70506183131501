import { deepClone } from "./object.utilities";

// Find element in array or return last element
export function findOrLast<T>(array: T[], predicate: (T: any) => boolean) {
  const result = array.find(predicate);
  return result != null ? result : array[array.length - 1];
}

// Find element's index in array or return last element's index
export function indexOrLast<T>(array: T[], predicate: (T: any) => boolean) {
  const result = array.find(predicate);
  return result != null ? array.indexOf(result) : array.length - 1;
}

// Find element's index in array or return first element's index
export function indexOrFirst<T>(array: T[], predicate: (T: any) => boolean) {
  const result = array.find(predicate);
  return result != null ? array.indexOf(result) : 0;
}

// Groups any array into 'count' defined size Chunks
export function groupArray<T>(count: number, arr: T[]): T[][] {
  const groups = Math.ceil(arr.length / count);
  const array = Array(groups).fill([]);
  array.forEach((a, i) => {
    array[i] = arr.slice(i*count, (i*count)+count);
  });
  return array;
}

// deep copy function for arrays and objects
export function copy(aObject) {
  return deepClone(aObject);
}
