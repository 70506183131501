<div
    *ngIf="categories && categoryControls.length"
    [formGroup]="categoryControls"
    class="row"
>
    <ng-template ngFor let-item [ngForOf]="categories" let-i="index">
        <abi-form-field
            *ngIf="!hiddenCategories[i]"
            [label]="item.name"
            [class]="'order-md-' + (i * 2 + 1)"
        >
            <input
                abiTypeahead
                [formControlName]="i"
                [id]="item.propName || item.name"
                [abiLookup]="item.items"
                [preFilter]="categoryFilter.bind(this, i)"
            />
        </abi-form-field>
    </ng-template>
    <ng-content></ng-content>
</div>
