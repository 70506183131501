import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { DateExPipe } from "@modules/common";
import { NoZeroPipe } from "@modules/common/pipes/no-zero.pipe";
import { BaseAttachmentDto } from "./BaseAttachmentDto";
import { CustomerAddressDto } from "./CustomerAddressDto";
import { CustomerContactDto } from "./CustomerContactDto";
import { CustomerMasterDto } from "./CustomerMasterDto";
import { ordered, received, requested, shipped } from "./DeliveryNoteCustomerDto";
import { JobJournalDto } from "./JobJournalDto";
import { amount } from "./JobLineDto";
import { OrderLineAttachmentDto } from "./OrderLineAttachmentDto";
import { OrderLineDeliveryDto } from "./OrderLineDeliveryDto";
import { OrderLineDto } from "./OrderLineDto";
import { JobEdiDto } from "./jobs/JobEdiDto";

export const OrderStatus = {
  OPEN: "OPEN",
  SUBMITTED: "SUB",
  CONFIRMED: "CONF",
  COLLECTED: "COLL",
  CLOSED: "FIN",
  REJECTED: "REJ",
}

export const OrderConstants = {
  INBOUND_COLLECTION: "INBOUND-COL",
  INBOUND_DELIVERY: "INBOUND-DEL",
  INBOUND_ASSESSMENT: "INBOUND-ASS",
  INBOUND_FAILEDCOLLECTION: "INBOUND-FCOL",
  INBOUND_FAILEDDELIVERY: "INBOUND-FDEL",
  OUTBOUND_CREDITNOTE: "OUTBOUND-CRE",
  OUTBOUND_COLLECTION: "OUTBOUND-COL",
  OUTBOUND_DELIVERY: "OUTBOUND-DEL",
};

export interface OrderJournalDto extends JobJournalDto {
  orderId: string;
}
export interface OrderDto {
  orderId: string;
  typeId: string;// Common values: Purch/Sales Order: Inv | Crd
  statusId: string;// Common values: Purch/Sales Order: ORD
  invoiceId: string;
  deliveryId: string;
  userCaptureId: string;
  sourceId: string;
  orderDate: Date;
  nextAction: Date;
  customerReferenceNumber: string;
  ourReferenceNumber: string;
  purchaseOrderNumber: string;
  attachmentcount?: number;
  invoiceNumber: string;
  invoiceDate: Date;
  consolidatedPickListGenerated?: string;
  pickListGenerated?: string;
  deliveryNoteGenerated?: string;
  invoiceGenerated?: string;
  noteInternal: string;
  noteExternal: string;
  field1: string;
  field2: string;
  field3: string;
  field4: string;
  field5: string;
  closed: number;
  lines: OrderLineDto[]; // = [];
  requested?: number;

  deliveryCustomer: CustomerMasterDto;
  invoiceCustomer: CustomerMasterDto;
  deliveryAddress: CustomerAddressDto;
  invoiceAddress: CustomerAddressDto;
  contact: CustomerContactDto;
  attachments: BaseAttachmentDto[];
  lineAttachments: OrderLineAttachmentDto[];
  journals?: OrderJournalDto[];
}

function distinct(items: string[]): string[] {
  return Array.from(new Set(items));
}

function ids(
  self: OrderDto,
  mapper: (del: OrderLineDeliveryDto) => string
): string {
  const items = distinct(
    self.lines
      .map((l) => l.deliveries.map(mapper))
      .reduce((p, n) => p.concat(n), [])
  );
  return items.filter((s) => s).join(",") || "";
}
// TODO: confirm field for this data point
export function picked(self: OrderDto): number {
  return self.lines.reduce(
    (p: number, l: OrderLineDto) => p + l.quantityPicked || 0,
    0
  );
}
export function delivered(self: OrderDto): number {
  return self.lines.reduce(
    (p: number, l: OrderLineDto) => p + l.quantityDelivered,
    0
  );
}

export function orderedAmount(self: OrderDto): number {
  return self.lines.reduce((p: number, l: OrderLineDto) => p + amount(l), 0);
}

export function approved(self: OrderDto): boolean {
  return (
    self &&
    self.lines.length &&
    self.lines.every(
      (l) =>
        l.returns.status === "Approved" ||
        l.returns.status === "Rejected" ||
        l.returns.status === "Collected"// is no longer a valid status -- but a computed value from Movement journals
    )
  );
}

export function haveDeliveries(self: OrderDto): boolean {
  return self.lines.some((l) => !!l.deliveries.length);
}

export function picklistIds(self: OrderDto): string {
  return ids(self, (d) => d.pickListId);
}

export function deliveryIds(self: OrderDto): string {
  return ids(self, (d) => d.deliveryNoteId);
}

export function hasDeliveryNote(self: OrderDto) {
  return (
    self.deliveryNoteGenerated &&
    self.deliveryNoteGenerated !== "A" &&
    self.deliveryNoteGenerated !== "N"
  );
}

export function lineCount(self: OrderDto): number {
  return self.lines.length;
}

export function approvedCount(self: OrderDto): number {
  return self.lines.filter((l) => l.returns && l.returns.status === "Approved")
    .length;
}

export function rejectedCount(self: OrderDto): number {
  return self.lines.filter((l) => l.returns && l.returns.status === "Rejected")
    .length;
}

export function warehouse(self: OrderDto): string {
  return self.lines.length ? self.lines[0].warehouseId : "";
}

// no longer computes correctly
export function deliveredCount(self: OrderDto): number {
  return self.lines.filter((l) => l.returns && l.returns.status === "Collected")
    .length;
}


export type OrderType = 'salesorders' | 'purchaseorders' | 'transferorders' | 'credits';
export const OrderTypes: {[O in OrderType]: O} = {
  salesorders: 'salesorders',
  purchaseorders: 'purchaseorders',
  transferorders: 'transferorders',
  credits: 'credits'
};

export const typeTableNames = {
  purchaseorders: 'PurchaseOrderList',
  salesorders: 'SalesOrderList',
  transferorders: 'TransferOrderList',
  credits: 'CreditsList',
};
// @TODO: Use Translatable keys here
export const typeNames = {
  purchaseorders: 'Purchase Order',
  salesorders: 'Sales Order',
  transferorders: 'Transfer Order',
  credits: 'Credits',
};
export const typeProcessNames = {
  purchaseorders: 'Receipt',
  salesorders: 'Issue',
  transferorders: 'Transfer',
  credits: 'Credit',
};

export interface OrderTypeDto {
  orderId: string;
}

export interface TransferOrderLineDto {
  orderId?: string;
  lineId: string;
  typeId: string;
  structureId?: string;
  itemId: string;
  line1: string;
  line2?: string;
  // these must be duplicated on the new lise from prev line data
  transferDate?: Date;// ship date - date whe it must be shipped... (to be earlier usually)
  requestedDate: Date;// must be there at that date
  quantityRequested: number;
  quantityShipped?: number;
  quantityReceived?: number;
  cost: number;

  deliveries?: any[];// todo define dto for this
}

export interface TransferOrderDto {
  orderId: string;
  typeId: string;
  statusId?: string;
  closed?: number;
  allowPartialDeliveries?: number;
  warehouseFrom: string;
  warehouseTransit: string;
  warehouseTo: string;
  userCaptureId?: string;
  sourceId?: string;
  orderDate: Date;
  ourReferenceNumber: string;
  pickListGenerated?: string;
  deliveryNoteGenerated?: string;
  receiptGenerated?: string;
  pickListCurrentId?: string;
  deliveryNoteCurrentId?: string;
  receiptCurrentId?: string;
  noteInternal?: string;
  noteExternal?: string;
  lines?: TransferOrderLineDto[];
  edi?: JobEdiDto[];
}

export function newTransferOrder(): TransferOrderDto {
  return {
    orderId: "",
    typeId: "",
    statusId: "",
    userCaptureId: "",
    sourceId: "",
    orderDate: null,
    ourReferenceNumber: "",
    pickListGenerated: "",
    deliveryNoteGenerated: "",
    noteInternal: "",
    noteExternal: "",
    closed: 0,
    lines: [],
    warehouseFrom: "",
    warehouseTransit: "",
    warehouseTo: "",
  };
}

export function newTransferOrderLine(): TransferOrderLineDto {
  return {
    cost: 0.0,
    itemId: "",
    line1: "",
    lineId: "",
    quantityRequested: 0,
    requestedDate: null,
    typeId: "",
    deliveries: [],
  };
}

export function newOrder(): OrderDto {
  return {
    orderId: "",
    typeId: "",
    statusId: "",
    invoiceId: "",
    deliveryId: "",
    userCaptureId: "",
    sourceId: "",
    orderDate: null,
    nextAction: null,
    customerReferenceNumber: "",
    ourReferenceNumber: "",
    purchaseOrderNumber: "",
    invoiceNumber: "",
    invoiceDate: null,
    pickListGenerated: "",
    deliveryNoteGenerated: "",
    invoiceGenerated: "",
    noteInternal: "",
    noteExternal: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    closed: 0,
    lines: [],
    requested: 0,
    deliveryCustomer: null,
    invoiceCustomer: null,
    deliveryAddress: null,
    invoiceAddress: null,
    contact: null,
    attachments: [],
    lineAttachments: [],
  };
}

@Pipe({
  name: "transferorder"
})
export class TransferOrderPipe implements PipeTransform {
  transform(order: TransferOrderDto, format?: string): string | boolean | number {
    const np = new DecimalPipe("en-ZA");
    const nz = new NoZeroPipe();
    // let value = 0;
    switch (format) {
      case "requested":
        return requested(order);
      case "shipped":
        return shipped(order);
      case "received":
        return received(order);
    }
    // return np.transform(value, "1.2-2");
  }
}

@Pipe({
  name: "order"
})
export class OrderPipe implements PipeTransform {
  transform(order: OrderDto, format?: string): string | boolean | number {
    const np = new DecimalPipe("en-ZA");
    const nz = new NoZeroPipe();
    let value = 0;
    switch (format) {
      case "ordered":
        return ordered(order);
      case "delivered":
        return delivered(order);
      case "picked":
        return picked(order);
      case "orderedAmount":
        value = orderedAmount(order);
        break;

      case "lineCount":
        return nz.transform(lineCount(order));
      case "approvedCount":
        return nz.transform(approvedCount(order));
      case "rejectedCount":
        return nz.transform(rejectedCount(order));
      case "deliveredCount":
        return nz.transform(deliveredCount(order));

      case "hasDeliveryNote":
        return hasDeliveryNote(order);
      case "warehouse":
        return warehouse(order);
      case "haveDeliveries":
        return haveDeliveries(order);
      case "approved":
        return approved(order);
      case "picklistIds":
        return picklistIds(order);
      case "deliveryIds":
        return deliveryIds(order);
      case "invoiceCustomer":
        return (
          order.invoiceCustomer.id + " ~ " + order.invoiceCustomer.name
        );
      case "invoiceAddress":
        return (
          order.invoiceAddress.addressId + " ~ " + order.invoiceAddress.name
        );
      case "deliveryCustomer":
        return (
          order.deliveryCustomer?.id +
          " ~ " +
          order.deliveryCustomer?.name
        );
      case "deliveryAddress":
        return (
          order.deliveryAddress?.addressId +
          " ~ " +
          order.deliveryAddress?.name
        );
    }
    return np.transform(value, "1.2-2");
  }
}

export type PrintedStatus = 'printed' | 'notprinted' | 'all';

function getJournalByType(journals: JobJournalDto[], typeId: string) {
  return journals.filter((j) => j.typeId === typeId).shift() || null;
}

// we used the 'to' field for date formats
function getJournalDate(journals: JobJournalDto[], typeId: string) {
  return getJournalByType(journals, typeId)?.to || "";
}
function getJournalBy(journals: JobJournalDto[], typeId: string) {
  return getJournalByType(journals, typeId)?.by || "";
}
function getJournalNote(journals: JobJournalDto[], typeId: string) {
  return getJournalByType(journals, typeId)?.text || "";
}

@Pipe({
  name: "orderline",
})
export class OrderLinePipe implements PipeTransform {
  transform(
    orderLine: OrderLineDto,
    format?: string,
    dateFormat: string = "y-MM-dd"
  ): string | boolean | number {
    const np = new DecimalPipe("en-ZA");
    const nz = new NoZeroPipe();
    switch (format) {
      case "inboundCollectionDate":
        return new DateExPipe().transform(
          getJournalDate(orderLine.journals, OrderConstants.INBOUND_COLLECTION),
          dateFormat
        );
      case "outboundCollectionDate":
        return new DateExPipe().transform(
          getJournalDate(orderLine.journals, OrderConstants.OUTBOUND_COLLECTION),
          dateFormat
        );

      case "inboundDeliveryDate":
        return new DateExPipe().transform(
          getJournalDate(
            orderLine.journals,
            OrderConstants.INBOUND_DELIVERY
          ),
          dateFormat
        );
      case "outboundDeliveryDate":
        return new DateExPipe().transform(
          getJournalDate(
            orderLine.journals,
            OrderConstants.OUTBOUND_DELIVERY
          ),
          dateFormat
        );

      case "inboundCollectionBy":
        return getJournalBy(
          orderLine.journals,
          OrderConstants.INBOUND_COLLECTION
        );
      case "outboundCollectionBy":
        return getJournalBy(
          orderLine.journals,
          OrderConstants.OUTBOUND_COLLECTION
        );
      case "inboundDeliveryBy":
        return getJournalBy(
          orderLine.journals,
          OrderConstants.INBOUND_DELIVERY
        );
      case "outboundDeliveryBy":
        return getJournalBy(
          orderLine.journals,
          OrderConstants.OUTBOUND_DELIVERY
        );

      case "inboundCollectionNote":
        return getJournalNote(
          orderLine.journals,
          OrderConstants.INBOUND_COLLECTION
        );
      case "outboundCollectionBy":
        return getJournalNote(
          orderLine.journals,
          OrderConstants.OUTBOUND_COLLECTION
        );
      case "inboundDeliveryBy":
        return getJournalNote(
          orderLine.journals,
          OrderConstants.INBOUND_DELIVERY
        );
      case "outboundDeliveryBy":
        return getJournalNote(
          orderLine.journals,
          OrderConstants.OUTBOUND_DELIVERY
        );
      default:
        return null;
    }
  }
}
