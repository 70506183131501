import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "abi-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }
  ]
})
export class NumberInputComponent implements ControlValueAccessor, OnInit {
  @Input() min: number;
  @Input() max: number;
  @Input() name: string;
  @Input() id: string;
  @Input() decrementIconClass: string = 'fas fa-minus';
  @Input() incrementIconClass: string = 'fas fa-plus';
  @Input() buttonSizeClass: string = '';


  currVal: number;
  isDisabled: boolean;
  private onChanged = (_: any) => { };
  onTouched = () => { };

  constructor() { }

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.currVal = obj;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  textInput(value: any): void {
    this.currVal = +value;
    this.onChanged(this.currVal);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  isMinValue(): boolean {
    return this.min != null && this.currVal <= this.min;
  }

  isMaxValue(): boolean {
    return this.min != null && this.currVal >= this.max;
  }

  updateValue(delta: number) {
    this.currVal += delta;
    this.onChanged(this.currVal);
    this.onTouched();
  }


}
