<!-- <div class="input-group"> -->
    <input
    list="timeSuggestions"
    [disabled]="isDisabled"
    [class]="'form-control '+getValidity()"
    type="time"
    [(ngModel)]="value"
    noBootstrap
    placeholder="Time"
    step="600"
    pattern="[0-9]{2}:[0-9]{2}"/>
    <!-- <input class="form-control hour-input" type="text" [(ngModel)]="hour" [min]="0" [max]="23" mask="H9" [leadZeroDateTime]="true" [showTemplate]="true" [validation]="true" [showMaskTyped]="true"/>
    <input class="form-control separator" [disabled]="true" type="text" value=":"/>
    <input class="form-control minute-input" type="text" [(ngModel)]="minute" [min]="0" [max]="59" mask="m9" [leadZeroDateTime]="true" [showTemplate]="true" [validation]="true" [showMaskTyped]="true"/> -->
<!-- </div> -->
<!-- {{ value }} -->
<!-- {{ showError }} -->
<!-- {{ hour }} -->
<!-- {{ minute }} -->
<!-- {{ invalid }} -->
<datalist id="timeSuggestions">
    <option *ngFor="let time of times" [value]="time.label">{{ time.label }}</option>
</datalist>
