import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges, Output, QueryList,
  SimpleChanges
} from "@angular/core";
import { NgbNav, NgbNavChangeEvent, NgbNavLink } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "abi-nav-dropdown",
  templateUrl: "./nav-dropdown.component.html",
  styleUrls: ["./nav-dropdown.component.scss"]
})
export class NavDropdownComponent implements AfterContentInit, OnChanges {
  @Output() navChange: EventEmitter<NgbNavChangeEvent<any>> = new EventEmitter();
  @Input() skipFirst: boolean;
  @Input() skipLast = false;
  @ContentChild(NgbNav) nav: NgbNav;
  @ContentChildren(NgbNavLink, {descendants: true}) dropDownTabs: QueryList<NgbNavLink>;

  public navItems: NgbNavLink[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dropDownTabs) {
      this.loadTabs(this.dropDownTabs);
    }
  }

  activeText: string = "-";
  ngAfterContentInit(): void {
    this.nav.activeIdChange.subscribe(id => {
      if(this.navItems.length === 0) return;
      const item = this.navItems.find(t => t.navItem.id === id);
      this.activeText = item?.elRef.nativeElement.innerHTML ?? "-";
    });

    this.dropDownTabs.changes.subscribe(tabs => {
        this.loadTabs(tabs);
    });

    setTimeout(() => {
      this.loadTabs(this.dropDownTabs);
    }, 1);// wait for render tick - then process tabs data
  }

  private loadTabs(tabs: QueryList<NgbNavLink>) {
    const navItems = tabs.toArray(); // .filter(t => !t.navItem.disabled);
    if (this.skipFirst) navItems.splice(0, 1);
    if (this.skipLast) navItems.splice(navItems.length - 1, 1);
    this.navItems = navItems;

    if (this.navItems?.length) {
      const item = this.navItems.find(t => t.navItem.id === this.nav.activeId);
      this.activeText = item?.elRef.nativeElement.innerHTML ?? "-";
    } else {
      this.activeText = "-";
    }
  }

  activeNavIndex(): number {
    return this.navItems.findIndex(t => t.navItem.id === this.nav.activeId);
  }

  canGoNext(): boolean {
    return this.activeNavIndex() < this.navItems.length - 1;
  }

  canGoPrev(): boolean {
    return this.activeNavIndex() > 0;
  }

  nextPage(): void {
    if (this.navItems.length) {
      if (this.canGoNext()) {
        const nextId = this.navItems[this.activeNavIndex() + 1].navItem.id;
        this.navChange.emit({activeId: this.nav.activeId, nextId, preventDefault: () => {}});
        this.nav.select(nextId);
      }
    }
  }

  prevPage(): void {
    if (this.navItems.length) {
      if (this.canGoPrev()) {
        const nextId = this.navItems[this.activeNavIndex() - 1].navItem.id;
        this.navChange.emit({activeId: this.nav.activeId, nextId, preventDefault: () => {}});
        this.nav.select(nextId);
      }
    }
  }

  selectNavItem(link: NgbNavLink): void {
    this.navChange.emit({activeId: this.nav.activeId, nextId: link.navItem.id, preventDefault: () => {}});
    this.nav.select(link.navItem.id);
  }
}
