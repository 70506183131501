import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class Disposable implements OnDestroy {
  protected disposed: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.disposed.next();
    this.disposed.complete();
  }

  notDisposed<T>() {
    return takeUntil<T>(this.disposed);
  }
}

