import { Directive, Input, OnInit, SimpleChanges } from "@angular/core";
import { from, Observable } from "rxjs";
import { UserService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiSimpleUser]"
})
export class SimpleUserTypeaheadDirective extends BaseObservableTypeaheadHelper<string> implements OnInit {
  private _users: string[];

  constructor(typeAhead: TypeaheadDirective, private userService: UserService) {
    super(typeAhead, []);
  }

  ngOnInit(): void {
    this.userService.getUsers().toPromise().then(u => {
      this._users = u;
      this.loading = false;
    });
    super.ngOnInit();
  }

  protected filteredList(term: string): Observable<string[]> {
    const search = this.regExp(term);
    if (this._users) {
      return from([this._users.filter(item => search.test(item)).slice(0, 50)]);
    } else {
      return from([]);
    }
  }

  protected getId(item: string): string {
    return item;
  }
  protected getName(item: string): string {
    return item;
  }
}

