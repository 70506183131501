import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

export const SideBarState = {
  OPEN: "open",
  CLOSE: "close",
  CLOSE_MOBILE: "close-mobile",
  OPEN_MOBILE: "open-mobile"
};
@Injectable({
  providedIn: "root"
})
export class SidebarService {
  private sidebarState = SideBarState.CLOSE;
  private sidebarStateChanged$ = new BehaviorSubject<string>(this.sidebarState);
  public sidebarStateObservable$ = this.sidebarStateChanged$.asObservable();
  private autoClose = false;
  private autoClose$ = new BehaviorSubject<boolean>(this.autoClose);
  public autoCloseObservable$ = this.autoClose$.asObservable();
  private isMobile = false;

  isOpen = false;

  constructor(
    private responsive: BreakpointObserver,
    ) {

    this.responsive
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .pipe(map((bp) => bp.matches))
      .subscribe(isMobile => {
        this.isMobile = isMobile;
        // console.log('is mobile', isMobile);
        this.setState(this.getNextStateString());
      });
  }

  setState(newState) {
    if(this.sidebarState === newState) return;// PREVENT DUPLICATE STATE TRIGGERS
    this.sidebarState = newState;
    this.sidebarStateChanged$.next(this.sidebarState);
  }

  getNextStateString(){
    return this.isOpen ? this.getOpenStateString() : this.getCloseStateString();
  }

  getOpenStateString() {
    return this.isMobile ? SideBarState.OPEN_MOBILE : SideBarState.OPEN;
  }
  getCloseStateString() {
    return this.isMobile ? SideBarState.CLOSE_MOBILE : SideBarState.CLOSE;
  }

  open() {
    this.isOpen = true;
    this.setState(this.getOpenStateString());
  }

  close() {
    this.isOpen = false;
    this.setState(this.getCloseStateString());
  }

  toggle() {
    const newState = this.sidebarState === this.getOpenStateString() ? this.getCloseStateString() : this.getOpenStateString();
    this.isOpen = !this.isOpen;
    this.setState(newState);
  }

  disableAutoClose() {
    this.autoClose$.next(false);
  }

  enableAutoClose() {
    this.autoClose$.next(true);
  }
}

/**
 * States: Desktop-Open, Desktop-Closed, Mobile-Open, Mobile-Closed
 * Desktop-Open: 'side' + 'open'
 * Desktop-Closed: 'side' + 'close'
 * Mobile-Open: 'over' + 'open'
 * Mobile-Close: 'over' + 'close'
 */
