<div class="input-group flex-nowrap">
  <div class="input-group-prepend">
    <button class="btn btn-outline-secondary px-1" [ngClass]="buttonSizeClass" type="button" (click)="updateValue(-1)" [disabled]="isDisabled || isMinValue()">
      <i [ngClass]="decrementIconClass"></i>
    </button>
  </div>
  <input type="number" [(ngModel)]="currVal" class="text-right" [min]="min" [max]="max" [disabled]="isDisabled"
    (blur)="onTouched()" (input)="textInput($event.target.value)" [name]="name" [id]="id" />
  <div class="input-group-append">
    <button class="btn btn-outline-secondary px-1" [ngClass]="buttonSizeClass" type="button" (click)="updateValue(1)" [disabled]="isDisabled  || isMaxValue()">
      <i [ngClass]="incrementIconClass"></i>
    </button>
  </div>
</div>
