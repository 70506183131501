import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AppQuery, LoginStatus } from "@modules/common/app.store";
import { ListResultDto, LookupList } from "@modules/models";
import { LookupListService, ProductSettingService } from "@services";
import { combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

// Mutated sub-set of Sources
type SourceMethod = { code: string, sourceId: string, description: string};


@Component({
  selector: "abi-job-source-select",
  templateUrl: "./job-source-select.component.html",
  // styleUrls: ["./job-source-select.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JobSourceSelectComponent),
    multi: true
  }]
})
export class JobSourceSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
  // @Input() sources: LookupList;
  // @Input() granularMode = false;
  // @Input() noTrunc = false;
  // @Input() origins: string[] = [];
  // @Input() sourceFilter: string[] = [];

  public sourceOrigin: string = "";
  public methodMap: { [key: string]: SourceMethod[] } = {};
  public sourceList: any[] = [];

  // New properties (optional opt-in: gives session pagination saving and loading)
  sourceId: string;
  private _onTouched = () => { };
  private _onChange = (_: any) => { };

  constructor(private changeRef: ChangeDetectorRef, private productSetting: ProductSettingService, private appQuery: AppQuery, private lookups: LookupListService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes.sources && changes.sources.currentValue){
      //   this.buildSources();
      // }
    }

    ngOnInit(): void {
      // this.buildSources();
      this.loadLookups().subscribe(() => {
        this.buildSources();
      });
  }


  origins = [];
  granularSourceMode = false;
  availableSources: LookupList = null;
  sourceFilter: string[] = [];
  noTrunc = false;
  private loadLookups() {
    const defaultSourceFilter = ['WEB'];
    this.sourceFilter = defaultSourceFilter;
    const customSourceFilter = this.productSetting.booleanValue("JobSourceFilter");
    if(customSourceFilter) {
      this.sourceFilter = this.productSetting.stringValue("JobSourceFilter").split(',');
    }
    this.noTrunc = this.productSetting.booleanValue("JobSourceNoTruncate");

    // Sources:
    // Split into Origin and Method lists
    // Given List of ORIGINS, filter out the ones that are not active AND not in the list of ORIGINS
    this.granularSourceMode = this.productSetting.booleanValue("JobSourceGranularMode");
    this.origins =  this.productSetting.arrayValue("JobSourceGranularMode", ["CON", "AGENT", "TECH", "DEAL", "OTHER"]);// grouping keys
    // this.methodMap = {};
    // const OriginList =
    return combineLatest([this.appQuery.$loginStatus, this.lookups.lookupList("CodeJobSource")])
    // .pipe(this.notDisposed())
    .pipe(
      tap(
        ([loginStatus, list]) => {
          // Only hand out ones relevant to the Active and Login Context
          this.availableSources = new LookupList(list.values.filter(source => source.active && (source.order >= 100 || (loginStatus === LoginStatus.True && source.order > 0))));

          console.log(this.availableSources);
        }
      )
    );

    // AddressComponent.setFormData(this.customer.addresses[1], this.addressForm);
    // ContactComponent.setFormData(this.customer.contacts[1], this.contactForm);
    // MachineMasterComponent.setFormData(this.customer.machines[0], this.machineForm);
  }

  buildSources() {
    this.methodMap = {};
    // Granular (Multi-Select) Mode
    if(this.granularSourceMode) {
      this.availableSources.values.filter(li => li.active).forEach((li) => {
        if(this.origins.some(o => li.code.startsWith(o))) {
          // origin
          const origin = this.origins.find(o => li.code.startsWith(o));
          if(!this.methodMap[origin]){
            this.methodMap[origin] = [];
          }
          const methodCode = li.code.substring(origin.length);
          const method = {
            sourceId: li.code,
            code: methodCode,
            description: `JobNew.Method_${methodCode}`
          }
          this.methodMap[origin].push(method);// push 'METHOD' part
        }
      });
    }

    // Old Mode
    this.sourceList = this.availableSources.values
    // 'order' 100 and above are intended for 'Public' side
    // 'order' 1 and above are intended for 'Private' side
    .filter((f) => this.sourceFilter.some(sf => f.code.endsWith(sf)))
    .map((s) => {
      const descs = s.description.split(" "); // .toLowerCase();
      const desc = descs
        .slice(0, Math.max(1, descs.length - 1))
        .join(" "); // .toLowerCase();
      return {
        code: s.code,
        description: this.noTrunc ? s.description : desc,
      };
    });
  }

  sourceMethods = [];
  /**
   * Pre-select Method based on Source Origin
   * @param sourceOrigin example: "CON", "DEAL", "TECH", "AGENT"
   */
  onSourceChange(sourceOrigin: string){
    if(sourceOrigin){
      this.sourceMethods = this.getSourceMethods(sourceOrigin);
    } else {
      this.sourceMethods = [];
    }
    this._onChange(null);
    this._onTouched();
  }

  onMethodChange(sourceId: string){
    this._onChange(sourceId);
  }

  getSourceMethods(sourceOrigin: string): SourceMethod[] {
    return sourceOrigin && this.methodMap && Object.keys(this.methodMap).length && sourceOrigin ? this.methodMap[sourceOrigin] : [];
  }

  writeValue(value: any): void {
    this.sourceId = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  // setDisabledState?(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  // }

}
