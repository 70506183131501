import { CdkTable } from "@angular/cdk/table";
import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input
} from "@angular/core";
import { Observable } from "rxjs";
import { TableColumn, TableComponent } from "../components/ability-table/ability-table.component";
import { DataToArray, ExportLoaderService, ExportType } from "../services/export.service";

@Directive({
  selector: "button[abiExport],a[abiExport]",
  exportAs: "abiExport"
})
export class ExportDirective {
  @Input("abiExport") table: TableComponent | ElementRef | CdkTable<any> | HTMLElement | DataToArray;
  @Input() extras: Partial<TableColumn>[];
  @Input() rows: (() => Promise<any[]>)[];
  @Input() onlyVisible = true;
  @Input() tableName = "table";
  @Input() exportType: ExportType = "excel";
  @HostBinding("disabled")
  @HostBinding("class.disabled")
  disabled: boolean;

  constructor(private exportService: ExportLoaderService) {}

  @HostListener("click") click() {
    this.disabled = true;
    const tbl =
      this.table instanceof CdkTable
        ? (this.table as any)._elementRef.nativeElement
        : this.table instanceof ElementRef ? this.table.nativeElement : this.table;
    this.exportService.export(tbl, this.rows, this.extras, this.onlyVisible, this.exportType, this.tableName)
    .finally(() => this.disabled = false);
  }
}
