import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "abi-save-buttons",
  templateUrl: "./save-buttons.component.html",
  styleUrls: ["./save-buttons.component.scss"]
})
export class SaveButtonsComponent {
  @Input() saveButtonsVisible: boolean = false;
  @Input() cancelAllowed: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Input() saveAllowed: boolean = false;
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Input() apiBusy: boolean = false;
  constructor() {}
}
