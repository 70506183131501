import {getDateParts, isValidDate} from "../../common";

/**
 * Generates a UTC date as per RRule requirement (chrome doesnt care and shows it as non-utc aparrently)
 * @param attr
 * @returns
 */
const computeEnd = (attr) => {
  const end: any = {};

  if (attr) {
    const { mode, after } = attr;
    const date =  attr.onDate.date;
    if (mode === 'After') {
      end.count = after;
    }

    if (mode === 'On date' && isValidDate(date)) {
      const dateParts = getDateParts(date);
      end.until = new Date(Date.UTC(dateParts.year, dateParts.month - 1, dateParts.day, 0, 0, 0, 0));
    }
  }

  return end;
};

export default computeEnd;
