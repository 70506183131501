<button mat-list-item (click)="trigger.openMenu()">
  <i matListIcon class="fas fa-columns fa-fw text-primary mr-1"></i>{{ 'System.Columns' | translate }}
  <!--span class="w-100 text-right">
    <i class="fas fa-caret-down fa-lg text-primary"></i>
  </span-->
</button>
<div [matMenuTriggerFor]="appMenu" #trigger="matMenuTrigger"></div>
<mat-menu #appMenu="matMenu" (closed)="closed()" class="py-0">
  <span (click)="$event.stopPropagation()" class="background">

<!--    <mat-tree #colst [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        {{node.name}}
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.name">
          <i [class]="'fas ' + (treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right')"></i>
        </button>
        {{node.name}}
      </mat-tree-node>
    </mat-tree>-->
    <mat-accordion *ngIf="nestedColumns?.length > 1; else flatList" displayMode="flat">
      <mat-expansion-panel *ngFor="let node of nestedColumns">
        <mat-expansion-panel-header class="section-height">
        {{ node.name | translate }}
        </mat-expansion-panel-header>
        <mat-selection-list #colss (selectionChange)="toggleColumn($event)" class="py-0">
          <mat-list-option color="primary" *ngFor="let col of node.columns" checkboxPosition="before" [selected]="!col.hidden"
            [value]="col" class="text-nowrap row-height"> <!--visibleColumns()-->
            {{ col.realHeader }}
          </mat-list-option>
        </mat-selection-list>
      </mat-expansion-panel>
    </mat-accordion>
    <ng-template #flatList>
      <mat-selection-list #colss (selectionChange)="toggleColumn($event)">
        <mat-list-option color="primary" *ngFor="let col of visibleColumns()" checkboxPosition="before" [selected]="!col.hidden"
          [value]="col" class="text-nowrap row-height">
          {{ col.realHeader }}
        </mat-list-option>
      </mat-selection-list>
    </ng-template>
  </span>
</mat-menu>
