export interface JobVisitDto  {
  visitId: string;
  booked: Date;
  visited: Date;
  statusId: string;
  resourceId: string;
  // Use for JobAtBranch (setting)
  dealerBranchId: string;
}

export function newJobVisit() {
  return {
    visitId: null,
    booked: null,
    visited: null,
    statusId: null,
    resourceId: null,
    // Use for JobAtBranch (setting)
    dealerBranchId: null
  };
}
