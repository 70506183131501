export interface JobSummaryDto {
  id: string;
  overdue: number;
  yesterday: number;
  bookedDay0: number;
  bookedDay1: number;
  bookedDay2: number;
  later: number;
  daysAverage: number;
  days1: number;
  days2: number;
  days3: number;
  days4: number;
  totalJobs: number;
}

export function hasDue(self: JobSummaryDto): number {
  return self.overdue || self.yesterday || self.bookedDay0 || self.bookedDay1 || self.bookedDay2 || self.later;
}

export function hasOpen(self: JobSummaryDto): number {
  return self.days1 || self.days2 || self.days3 || self.days4 || self.daysAverage;
}

