import { KeyedCollection } from "./keyed.collection";
import { displayValue, LookupObjectDto } from "./LookupObjectDto";

/**
 * Wrap a LookupObject Collection in a utility class
 */
export class LookupListEx<T extends LookupObjectDto> extends KeyedCollection<T> {
  constructor(items: T[]) {
    super(items, item => item.code);
  }

  /**
   * Converts an array of LookupObject derivatives to a LookupListEx with currect meta type
   * @param items array of LookupObject derivatives
   * @returns LookupObjectEx
   */
  static fromLookupObject<T extends LookupObjectDto>(items: any): LookupListEx<T>  {
    return new LookupListEx<T>(items);
  }

  /**
   * Display [property = 'description'] for [CODE]
   * @todo: display 'code' as fallback as we do with displayValue
   */
  display(code: string, property: string = 'description'): string {
    code = (code || "").trim();
    if (super.containsKey(code)) {
      return super.item(code)[property];
    }
    return "";
  }

  /**
   * Display [CODE] ~ [DESCRIPTION] for [CODE]
   */
  displayValue(code: string): string {
    if (super.containsKey(code)) {
      return displayValue(super.item(code));
    }
    return code;
  }

  update(code: string, item: T) {
    if (super.containsKey(code)) {
      super.update(code, item);
    }
    return item;
  }
}

export class LookupList extends LookupListEx<LookupObjectDto> {

}
