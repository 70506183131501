export interface ResourceScheduleDto {
  resourceId: string;
  postalCode: number;
  jobType: number;
  skills: number;
  maximum: number;
  allocated: number;
  leave: number;
  resourceZoneId: string;
  restriction: number;
}

export function allocPercent(self: ResourceScheduleDto): number {
  if (self.maximum === 0) {
    return 100;
  }
  return Math.round(self.allocated / self.maximum * 100);
}

export function rating(self: ResourceScheduleDto): number {
  return self.postalCode * self.jobType * self.skills;
}
