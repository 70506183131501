export interface ItemKeys { [index: string]: number} ;

export class KeyedCollection<T> {

  private itemKeys: ItemKeys = {};

  getKeys(): ItemKeys {
    return this.itemKeys;
  }

  constructor(private items: T[], protected key: (item: T) => string) {
    this.updateKeys();
  }

  private updateKeys(){
    this.items.forEach((item, index) => this.itemKeys[this.key(item)] = index);
  }

  // Append (optionally update existing item)
  public add(item: T) {
    // console.log('add', item);
    if (!this.containsKey(this.key(item))) {
      this.items.push(item);
      this.itemKeys[this.key(item)] = this.items.length - 1;
    } else {
      this.update(this.key(item), item);
    }
  }

  public update(key: string, item: T) {
    this.items[this.itemKeys[key]] = item;
  }

  public containsKey(key: string): boolean {
    return (this.itemKeys) ? key in this.itemKeys : false;
  }

  public count(): number {
    return this.items.length;
  }

  public item(key: string): T {
    // console.log('accessed', key);
    return this.items[this.itemKeys[key]];
  }

  public get values(): T[] {
    return this.items;
  }

  public clear() {
    this.items = [];
    this.itemKeys = {};
  }

  public remove(key: string) {
    if (this.containsKey(key)){
      this.items.splice(this.itemKeys[key], 1);
      delete this.itemKeys[key];
    }
  }

  public prepend(item: T) {
    this.items.splice(0, 0, item);
    this.updateKeys();// all indices changed
  }
}

export interface CacheInfo {expiry: number, lastAccess: number};

export class CachedCollection<T>  extends KeyedCollection<T> {
  private cacheInfo: Map<string, CacheInfo> = new Map<string, CacheInfo>();

  constructor(items: T[], key: (item: T) => string, private lifeSpan: number = 0, private cacheSize: number = 1000) {
    super(items, key);
    items.forEach(item => this.cacheInfo.set(key(item), this.generateCacheInfo()));
  }

  private generateCacheInfo(){
    return {expiry: this.lifeSpan && Date.now() + this.lifeSpan || null, lastAccess: Date.now()};
  }

  public add(item: T) {
    if(this.cacheSize && this.count() >= this.cacheSize){
      const oldest = Array.from(this.cacheInfo.entries()).sort((a, b) => a[1].lastAccess - b[1].lastAccess)[0];
      this.remove(oldest[0]);
      // console.log('removed', oldest[0]);
    }
    super.add(item);
    this.cacheInfo.set(this.key(item), this.generateCacheInfo());
    console.dir(super.values)
  }

  public update(key, item: T) {
    super.update(key, item);
    this.cacheInfo.set(key, this.generateCacheInfo());
  }

  public remove(key: string) {
    super.remove(key);
    this.cacheInfo.delete(key);
  }

  public item(key: string): T {
    const item = super.item(key);
    if(item && this.cacheInfo.get(key).expiry && Date.now() > this.cacheInfo.get(key).expiry){
      this.remove(key);
      // console.log('expired', key);
      return null;
    }
    if(item){
      // console.log('accessed', key);
      this.cacheInfo.get(key).lastAccess = Date.now();
      return item;
    }
    return null;
  }

  public clear() {
    super.clear();
    this.cacheInfo.clear();
  }

  public prepend(item: T) {
    super.prepend(item);
    this.cacheInfo.set(this.key(item), this.generateCacheInfo());
  }
}
