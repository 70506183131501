import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Disposable, newGuid } from "@modules/common";
import { AppQuery } from "@modules/common/app.store";
import { codeOnly, LookupObjectDto, WebLayoutDto, WebLayoutType } from "@shared/models";
import { TableColumn } from "..";
import { AuthenticationService, SnackBarService, WebLayoutService } from "../../services";

export type LayoutExtras = (layout: any) => void;

@Component({
  selector: "abi-web-layout",
  templateUrl: "./web-layout.component.html",
  styleUrls: ["./web-layout.component.scss"]
})
export class WebLayoutComponent extends Disposable implements OnInit, OnChanges {
  @Input() pageId: string;
  @Input() columns: TableColumn[];
  @Input() layoutId: string;
  @Input() layouts: WebLayoutDto[] = [];
  @Input() keepMatrixKeys: string[] = [];
  @Input() extras: LayoutExtras;
  @Input() navigateOnChange: boolean = true;
  @Output() layout: EventEmitter<WebLayoutDto> = new EventEmitter();
  form: FormGroup;
  quickLayouts: WebLayoutDto[];
  menuLayouts: WebLayoutDto[];
  dialogMode: 'saveas' | 'update' = 'saveas';

  get isSysAdmin() {
    return this.appQuery.hasRole(AuthenticationService.SYSADMIN);
  }

  constructor(
    private layoutService: WebLayoutService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private appQuery: AppQuery,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: SnackBarService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      typeId: "",
      layoutId: "",
      caption: ["", Validators.required],
      quickLink: false,
      systemLayout: false,
      defaultLayout: false,
      icon: "fas fa-file",
      description: ["", Validators.required],
    });
    if (!this.isSysAdmin) {
      this.form.get("systemLayout").disable();
    }

    this.form.get("defaultLayout").valueChanges.subscribe(v => {
      if (v) {
        // Set the Caption Name to the Default Layout Name
        this.form.get("caption").setValue(this.layoutService.defaultLayoutName);
        this.form.get("caption").disable();
      } else {
        this.form.get("caption").enable();
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    const layouts = changes["layouts"];
    if(layouts && layouts.currentValue !== layouts.previousValue){
      this.splitLayouts();
    }
  }

  canUpdateLayout() {
    return this.form.value.typeId === WebLayoutType.PUBLIC ? this.isSysAdmin && this.form?.valid : this.form?.valid;
  }

  private splitLayouts() {
    this.quickLayouts = this.layouts.filter(l => l.quickLink === "1");
    this.menuLayouts = this.layouts.filter(l => l.quickLink !== "1");
  }

  private arrayToHash(columns: TableColumn[]) {
    const result = Object.assign({}, ...columns/*.filter(col => col.hidden || col.sortOrder || col.filter)*/.map(column => ({
      [column.columnId || column.field]: {
        sortOrder: column.sortOrder, hidden: column.hidden, filter: column.filter
      }
    })));
    return result;
  }

  /**
   * Removes any session storage for the current page and navigates to the current page (without a layoutId)
   */
  clearLayout() {
    sessionStorage.removeItem(this.pageId);// reset to default columns
    this.layout.emit(null);// setup columns - reinits cols - will cause col filters to update -- @TODO: this is a hack - need to fix
    if(this.navigateOnChange)
      this.router.navigate(["./", { ...this.keepMatrixParams()}], { relativeTo: this.activeRoute });// CODE SMELL: not clever enough to navigate properly
  }

  populateLayoutForm(){
    if (this.dialogMode === 'update' && this.layoutId) {
      const layout = this.layouts.find(l => l.layoutId === this.layoutId);
      this.form.patchValue(
        {
          typeId: layout.typeId,
          layoutId: !this.isSysAdmin && layout.typeId === WebLayoutType.PUBLIC ? "" : layout.layoutId,
          caption: layout.caption,
          quickLink: layout.quickLink === "1",
          icon: layout.icon,
          description: layout.description,
          systemLayout: layout.typeId === WebLayoutType.PUBLIC,
          defaultLayout: layout.caption === this.layoutService.defaultLayoutName
        });
    }
  }

  getFormData(): WebLayoutDto {
    const data = this.arrayToHash(this.columns || []);
    if (this.extras) {
      this.extras(data);
    }
    const fData = this.form.value;
    return {
      layoutId: fData.layoutId,
      typeId: fData.systemLayout ? WebLayoutType.PUBLIC : WebLayoutType.USER, // SYSTEM=PUBLIC ... USER
      caption: fData.defaultLayout ? this.layoutService.defaultLayoutName : fData.caption,
      description: fData.description,
      quickLink: !!fData.quickLink ? "1" : "0",
      icon: codeOnly(fData.icon),
      data
    };
  }


  activateLayout(layout: WebLayoutDto) {
    this.layout.emit(layout);
    // this.layoutId = layout.layoutId;
    if(this.navigateOnChange)
      this.router.navigate(["./", { ...this.keepMatrixParams(), layoutId: layout.layoutId }], { relativeTo: this.activeRoute });
  }

  isDuplicatedCaption(newLayout: WebLayoutDto, checkGuid: boolean = false): boolean {
    return this.layouts.some(layout => layout.caption === newLayout.caption && (checkGuid ? layout.layoutId !== newLayout.layoutId : true));
  }

  // Update... will only update an existing layout (if applicable)
  updateLayout(dialog: any) {
    this.dialogMode = 'update';
    this.populateLayoutForm();
    this.dialog.open(dialog, { width: "450px" });
  }

  // Save As... will always create a new Layout
  saveLayout(dialog: any) {
    this.dialogMode = 'saveas';
    this.populateLayoutForm();
    this.dialog.open(dialog, { width: "450px" });
  }

  save(){
    const updatedLayout = this.getFormData();
      if (this.isDuplicatedCaption(updatedLayout, true)) {
        this.snackBar.warning("Layout name already exists. Please enter a different name.");
        return;
      }
      this.layoutService.updateLayout(this.pageId, updatedLayout).toPromise()
      .then(dto => {
        const id = this.layouts.findIndex(l => l.layoutId === dto.layoutId);
        this.layouts[id] = dto;
        this.splitLayouts();
        this.dialog.closeAll();
      });
  }

  saveAs(){
    const newLayout = this.getFormData();
    if (this.isDuplicatedCaption(newLayout)) {
      this.snackBar.warning("Layout name already exists. Please enter a different name.");
      return;
    }
    newLayout.layoutId = newGuid();
    this.layoutService.createLayout(this.pageId, newLayout).toPromise()
    .then(dto => {
      this.layouts.push(dto);
      this.splitLayouts();
      this.layout.emit(dto);
      if(this.navigateOnChange)
        this.router.navigate(['./', { layoutId: dto.layoutId }], { relativeTo: this.activeRoute});
      this.dialog.closeAll();
    });
  }

  codeOnly(icon: LookupObjectDto | string): string {
    return codeOnly(icon);
  }

  deleteLayout(dialog: TemplateRef<MatDialog>) {
    const layout = this.layouts.find(l => l.layoutId === this.layoutId);
    if(layout.typeId === WebLayoutType.USER) {
      this.doDeleteLayout(this.layoutId);
    } else if( layout.typeId === WebLayoutType.PUBLIC && this.isSysAdmin  ) {
      this.dialog.open(dialog, { width: '400px' }).afterClosed().subscribe(res => {
        if(res) {
          // service call and confirm
          this.doDeleteLayout(this.layoutId);
        }
      });
    }
  }

  doDeleteLayout(layoutId: string){
    this.layoutService.deleteLayout(this.pageId, layoutId).subscribe(val => {
      this.layouts.splice(this.layouts.findIndex(l => l.layoutId === layoutId), 1);
      this.splitLayouts();
      this.clearLayout();
    });
  }

  canDeleteLayout(layoutId: string){
    const layout = this.layouts?.find(l => l.layoutId === this.layoutId);
    if(layout && (layout.typeId === WebLayoutType.USER || (layout.typeId === WebLayoutType.PUBLIC && this.isSysAdmin ) )) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Some Pages use Matrix params for navigation, sometimes we need to preserve these params when navigating to a new layout
   */
  keepMatrixParams() {
    return  Object.keys(this.activeRoute.snapshot.params)
    .filter(k => this.keepMatrixKeys.some(keep => keep === k))
    .reduce((acc, key) => ({ ...acc, [key]: this.activeRoute.snapshot.params[key] }), {});
  }

  routerLink(layout: WebLayoutDto) {
    return ["./", { ...this.keepMatrixParams(), layoutId: layout.layoutId }];
  }
}
