import { DOCUMENT } from "@angular/common";
import {
  ApplicationRef,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  Inject,
  Injector,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  ViewContainerRef
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbTypeahead, NgbTypeaheadConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgbTypeaheadWindow } from "@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Live } from "./live";

const ABI_TYPEAHEAD_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TypeaheadDirective),
  multi: true
};

@Directive({
  selector: "input[abiTypeahead]",
  exportAs: "abiTypeahead",
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    // "(blur)": "handleBlur()",
    // "(keydown)": "handleKeyDown($event)",
    // list: "off" + Math.random() * 1000,
    autocomplete: "off", // + Math.random() * 1000,
    autocapitalize: "off",
    autocorrect: "off",
    spellcheck: "false",
    role: "combobox",
    "[class.open]": "isPopupOpen()",
    class: "custom-select",
    "aria-multiline": "false",
    "[attr.aria-autocomplete]": "showHint ? 'both' : 'list'",
    "[attr.aria-activedescendant]": "activeDescendant",
    "[attr.aria-owns]": "isPopupOpen() ? popupId : null",
    "[attr.aria-expanded]": "isPopupOpen()"
  },
  providers: [ABI_TYPEAHEAD_VALUE_ACCESSOR]
})
export class TypeaheadDirective extends NgbTypeahead implements OnInit {
  @Input() codeOnly = false;
  @Input() codeValueProperty: string = "code";

  // @Input()
  get abiTypeahead(): (text: Observable<string>) => Observable<readonly any[]> {
    return this.ngbTypeahead;
  }

  set abiTypeahead(value: (text: Observable<string>) => Observable<readonly any[]>) {
    this.ngbTypeahead = value;
    this._ngbTypeahead = value;
  }

  /*hacks to get into privates*/
  private get _windowRef2(): NgbTypeaheadWindow {
    return (this as any)._windowRef.instance;
  }

  constructor(
    _elementRef2: ElementRef,
    _viewContainerRef: ViewContainerRef,
    _renderer: Renderer2,
    _injector: Injector,
    config: NgbTypeaheadConfig,
    ngZone: NgZone,
    live: Live,
    @Inject(DOCUMENT) document: any,
    changeDetector: ChangeDetectorRef,
    _applicationRef: ApplicationRef
  ) {
    super(
      _elementRef2,
      _viewContainerRef,
      _renderer,
      _injector,
      config,
      ngZone,
      live as any,
      document,
      ngZone,
      changeDetector,
      _applicationRef
    );

    /*hacks to get override private methods*/
    const pt = TypeaheadDirective.prototype as any;
    const pt2 = NgbTypeahead.prototype as any;

    let vc = (this as any)._valueChanges as Observable<string>;
    vc = vc.pipe(tap(value => {
      if (!!value && !this.focusFirst)
        this.focusFirst = true;
      else if (!value && !!this.focusFirst)
        this.focusFirst = false;
    }));
    (this as any)._valueChanges = vc;
    pt._openPopup = function() {
      // get called when search term changes
      this._haveResult = !!this._inputValueBackup;
      // this.focusFirst = false;
      pt2._openPopup.call(this);
      this._findSelected = true;
    };

    pt._showHint = function() {
      // get called after each nav / change in the dropdown
      // pt2._showHint.call(this);
      const win: NgbTypeaheadWindow = this._windowRef2;
      const term = win.term.toLowerCase();
      if (
        win.results &&
        this._findSelected &&
        (win.activeIdx === 0 ||
          win.activeIdx > win.results.length ||
          !win
            .formatter(win.results[win.activeIdx])
            .toLowerCase()
            .includes(term))
      ) {
        this._findSelected = false;
        const id = win.results.findIndex(r =>
          win
            .formatter(r)
            .toLowerCase()
            .includes(term)
        );
        this._haveResult = id >= 0;
        setTimeout(() => {
          win.markActive(Math.max(id, 0));
          if (this.activeDescendant) {
            const elem = document.getElementById(this.activeDescendant);
            if (elem) {
              elem.parentElement.scrollTop = elem.offsetTop;
            }
          }
        }, 50);
      } else {
        setTimeout(() => {
          const elem = document.getElementById(this.activeDescendant);
          if (elem && elem.offsetTop < elem.parentElement.scrollTop) {
            elem.parentElement.scrollTop = elem.offsetTop;
          } else if (
            elem &&
            elem.offsetTop >
              elem.parentElement.scrollTop +
                elem.parentElement.clientHeight -
                elem.clientHeight
          ) {
            elem.parentElement.scrollTop =
              elem.offsetTop -
              elem.parentElement.clientHeight +
              elem.clientHeight;
          }
        }, 50);
      }
    };

    pt._selectResult = function(result: any) {
      pt2._selectResult.call(this, result);
      this._haveResult = true;
    };
  }

  private _ngbTypeahead: (text: Observable<string>) => Observable<readonly any[]>;

  onWriteValue: (value: any) => any;

  private _findSelected = false;
  private _haveResult = true;
  private _onChange2 = (_: any) => {};

  doOnChange(value: any) {
    // console.log('doOnchange', value)
    value = this.codeOnly ? this.getCode(value) : value;
    this._onChange2(value);
  }
  registerOnChange(fn: (value: any) => any): void {
    this._onChange2 = fn;
    super.registerOnChange(this.doOnChange.bind(this));
  }

  ngOnInit() {
    this.ngbTypeahead = this._ngbTypeahead;
    super.ngOnInit();
  }

  handleKeyDown(event: any) {
    if (!this.isPopupOpen()) {
      return;
    }
    this._haveResult = false;
    super.handleKeyDown(event);
  }

  handleBlur() {
    if (!this._haveResult) {
      this.writeValue(undefined);
    }
    this._haveResult = true;
    super.handleBlur();
  }

  writeValue(obj: any) {
    if (this.onWriteValue) {
      obj = this.onWriteValue(obj);
    }
    super.writeValue(obj);
  }

  protected getCode(item: any): string {
    if (item) {
      if (typeof item === "object") {
        return item[this.codeValueProperty];
      }
      return item;
    }
    return "";
  }
}
