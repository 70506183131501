import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

/**
 * Hiding zeros
 */
@Pipe({
  name: "nozero"
})
export class NoZeroPipe implements PipeTransform {
  transform(value: number | string, format?: string): string | number {
    if (format && value) {
      const dp = new DecimalPipe("en-ZA");
      return dp.transform(value, format);
    }
    if(typeof value === 'string') {
      const newValue = Number.parseFloat(value);
      return !!newValue ? newValue.toString() : "";
    }
    return !!value ? value : "";
  }
}

// Borrowed from: https://stackoverflow.com/questions/45540552/how-to-round-up-or-down-a-number-using-decimalpipe-in-angular
export enum Direction {
  UP = 'up',
  DOWN = 'down'
};
@Pipe({name: 'toFixed'})
export class ToFixedPipe implements PipeTransform {
  /**
   *
   * @param value - some number
   * @param digits - number of digits after the decimal point
   * @param dir - round up or down (floor/ceil)
   */
  transform(value: number, digits: number = 0, dir: Direction = Direction.DOWN): number {
    const round = dir === Direction.DOWN ? Math.floor : Math.ceil;
    return round(value * (10 ** digits)) / (10 ** digits);
  }
}
