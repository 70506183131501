import { Component, HostBinding, HostListener, Input } from "@angular/core";

@Component({
  selector: "button[abiPrint]",
  templateUrl: "./print.component.html",
  styleUrls: ["./print.component.scss"]
})
export class PrintComponent {
  @Input() class: string;
  @Input() iconClass = "fas fa-print";
  @Input() theme = "primary";
  @HostBinding() type = "button";
  @Input() showDisabled = true;
  @HostBinding("disabled") @Input() disabled: boolean;
  @Input() beforePrint: () => Promise<boolean>;
  @Input("abiPrint") label = "Print";
  @HostBinding("class")
  get buttonClass() {
    return "d-print-none btn btn-" + this.theme + " " + this.class;
  }
  @HostListener("click", ["$event.target"])
  print(event: MouseEvent) {
    if (this.beforePrint) {
      this.beforePrint().then(x => this.doPrint());
    } else {
      this.doPrint();
    }
  }

  private isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
  }

  private doPrint() {
    if (this.isIE()) {
      const objPrintPreview =
        "<object id=\"printPrev\" width=\"0\" height=\"0\" classid=\"CLSID:8856F961-340A-11D0-A96B-00C04FD705A2\"></object>";
      document.body.insertAdjacentHTML("beforeend", objPrintPreview);
      const printPrev = window["printPrev"];
      try {
        printPrev.ExecWB(7, 2);
        printPrev.outerHTML = "";
        return;
      } catch {
        console.warn(
          "IE Security changes required: 'Initialise and script ActiveX controls not marked as safe for scripting' to Prompt or Enable"
        );
      }
    }
    window.print();
  }
}
