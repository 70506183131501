
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

/**
 * Examples:
 * trackEvent('Job Logging', 'search_customer', 'public');
 *
 * Category: Job Logging
 * Action Types:
 * - search_customer
 * - select_customer
 * - new_customer
 * - select_existing_contact
 * - select_existing_address
 * - select_existing_machine
 * - add_new_model
 * - confirm_job
 * - schedule_job
 * Label Types: public, internal
 */
export function trackEvent(category: string, action: string, label?: string, value?: number) {
    if (typeof gtag === 'function') {
        gtag('event', action, {
            event_category: category,
            event_label: label,
            value
        });
    }
}

