import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { scriptLoader } from "@modules/common/utilities/script.utilities";

export interface SirvMedia {
  src: string;
  type?: 'zoom';
  options?: string;
};

const exampleSirvMedia: SirvMedia[] = [
  {
    src: "https://demo.sirv.com/example.spin",
  },
  {
    src: "https://demo.sirv.com/image.jpg",
    type: "zoom",
  },
  {
    src: "https://demo.sirv.com/video.mp4",
  }
];

declare global {
  interface Window {
    Sirv: any;
  }
}

const apiExists = (win: Window) => {
  return 'Sirv' in win && win.Sirv.on;
};

@Component({
  selector: 'abi-sirv-media-viewer',
  templateUrl: './sirv-media-viewer.component.html',
  styleUrls: ['./sirv-media-viewer.component.scss']
})
export class SirvMediaViewerComponent implements OnInit, OnDestroy {
  @Input() carousel: SirvMedia[];
  @Input() viewFilters: (viewerId: string, items: SirvMedia[]) => SirvMedia[];
  @Output() loaded: EventEmitter<boolean> = new EventEmitter();
  @Output() ready: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    // this.carousel = exampleSirvMedia;
  }

  ngOnDestroy(): void {
    if(apiExists(window))
    window.Sirv.stop();
    // console.log('...stop sirv');
  }

  ngOnInit(): void {
    const attrs = {}; // script load attibutes
    console.log('starting SMV Script load...', window.Sirv);
    scriptLoader(
      "https://scripts.sirv.com/sirvjs/v3/sirv.js",
      attrs,
      undefined,
      apiExists
    )
    .then((win) => {
      // console.log('SMV script loaded', win.Sirv);
      if(!('Sirv' in window)) throw new Error('failed loading sirv');

      this.loaded.emit(true);

      // ensure existing filters are cleared
      window.Sirv.viewer.filters.removeAll();

      if(this.viewFilters) // add filters if neccesary
      window.Sirv.viewer.filters.add(this.viewFilters);

      // clear & add event handlers
      win.Sirv.off('viewer:ready');
      win.Sirv.on('viewer:ready', () => {
        this.ready.emit(true);
        // console.log('SMV:ready');
      });
      // win.Sirv.start(); - dont invoke the fullscreen bug

    }).catch(e => {
      // try again
      window.location.reload();// CODE SMELL: this usually rectifies and component troubles
    });
  }

  hasView(){
    return this.carousel.some(media => media.src.includes('.view'));
  }

  getView(){
    return this.carousel.find(media => media.src.includes('.view'));
  }
}
