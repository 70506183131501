import { Component, Input } from "@angular/core";
import { AppQuery } from "@modules/common/app.store";

@Component({
  selector: "abi-platform-info-header",
  templateUrl: "./platform-info-header.component.html",
  styleUrls: ["./platform-info-header.component.scss"],
})
export class PlatformInfoHeaderComponent {
  constructor(public appQuery: AppQuery) {}
  @Input() networkStatus: string;
}
