<ng-container *ngIf="inModal; else inputSection">
    <h4 mat-dialog-title>{{ !isNew ? ('System.Edit' | translate) : ('System.New' | translate) }} {{ 'Stock.Stock' | translate }}</h4>
    <mat-dialog-content>
        <ng-container *ngTemplateOutlet="inputSection"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button cdkFocusInitial type="button" mat-button [disabled]="group.disabled || group.invalid" (click)="accept()">
            {{ 'System.Ok' | translate }}</button>
        <button type="button" mat-button (click)="cancel()">
            {{ 'System.Cancel' | translate }}</button>
    </mat-dialog-actions>
</ng-container>

<ng-template #inputSection>
<div [formGroup]="group">
        <div role="model-info" class="model-info row">
            <abi-form-field label="Stock.StockID" [errors]="formErrors.modelId" class="stock-stockid" cols="col-12">
                <div class="input-group">
                    <input formControlName="stockId" container="body" codeOnly="true">
                </div>
            </abi-form-field>
            <abi-form-field label="Stock.Description" class="stock-description" cols="col-12">
                <input formControlName="description" id="stockDescription" maxlength="64" />
            </abi-form-field>
            <abi-form-field label="Stock.Price" class="stock-price" cols="col-12">
                <input formControlName="price" id="stockPrice" type="number" />
            </abi-form-field>
        </div>
        <!-- <div role="model-details">
            <div class="row">

            </div>
        </div> -->
    <ng-content></ng-content>
</div>
</ng-template>

<ng-template #inputField let-type="type" let-name="name" let-id="id" let-lookup="lookup" let-readonly="readonly">
    <ng-container [formGroup]="group">
        <ng-container *ngIf="type==='text'">
            <input type="text" [formControlName]="name" [id]="id" [readonly]="readonly"/>
        </ng-container>
        <ng-container *ngIf="type==='checkbox'">
            <div class="row">
                <div class="custom-control custom-checkbox input-group">
                    <input class="custom-control-input" type="checkbox" [formControlName]="name" value="yes" [readonly]="readonly"/>
                    <label class="custom-control-label" [for]="name">&nbsp;</label>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="type==='lookup'">
            <input [formControlName]="name" abiTypeahead [abiLookup]="lookup" [id]="id" [codeOnly]="true" [disabled]="readonly"/>
        </ng-container>
    </ng-container>
</ng-template>
