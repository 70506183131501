import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from "@angular/core";

import { ProductSettingService } from "../services";

@Directive({ selector: "[abiProductLabel]" })
export class LabelDirective implements OnChanges {
  @Input() public label: string;
  @Input("abiProductLabel") public setting: string;
  @Input() public extra: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private productSettings: ProductSettingService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    let sett = this.productSettings.label(this.setting);
    if (!!sett && !!this.extra)
      sett = sett + this.extra;
    const value = sett || this.label;
    this.el.nativeElement.textContent = value;
  }
}
