<h4 mat-dialog-title class="">{{ attachment?.link }}<br/><small>{{ attachment?.source }}</small></h4>
<mat-dialog-content>
    <ngb-carousel
        #carousel
        *ngIf="attachments"
        (slide)="slide($event)"
        [showNavigationArrows]="attachments?.length > 1"
        [showNavigationIndicators]="attachments?.length > 1"
        [activeId]="attachment.attachmentId"
    >
        <ng-template
            *ngFor="let attachment of attachments"
            ngbSlide
            [id]="attachment.attachmentId"
        >
        <ng-container *ngIf="isImage(attachment); else document">
            <abi-spinner *ngIf="loading"></abi-spinner>
            <img
                [src]="downloadLink(attachment)"
                [alt]="attachment.source"
                (load)="onLoad($event)"
                class="img-size"
            />
        </ng-container>
        <ng-template #document>
            <a [href]="downloadLink(attachment)" title="Download" class="text-primary img-size d-flex justify-content-center align-items-center">
                <i class="fas fa-file-download fa-10x"></i>
            </a>
        </ng-template>
        </ng-template>
    </ngb-carousel>
</mat-dialog-content>
<mat-dialog-actions>
    <button cdkFocusInitial type="button" mat-button mat-dialog-close>
        Close
    </button>
</mat-dialog-actions>
