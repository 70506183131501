import { Injectable } from '@angular/core';
import { CustomerVisitDto, CustomerVisitSetupDto, ListResultDto, SearchDto, newVisitSetup } from "@modules/models";
import { DataService, ServiceConfig } from "..";

@Injectable({
  providedIn: 'root'
})
export class CallCycleService extends DataService {

  static newVisit(): CustomerVisitDto {
    return {
      ...newVisitSetup(),
      actualStartTime: new Date(),
      actualStartLocation: "",
      actualEndTime: null,
      actualEndLocation: "",
      statusId: "",
      checkUserId: "",
      // positionActual: 0,
      // positionMap: 0
    };
  }

  constructor(config: ServiceConfig) { super(config); }

  // ADMIN (Setup Calendar)
  // Create Visit & Update Visit
  createCustomerVisitAdmin(cv: CustomerVisitSetupDto) {
    return this.http.post(`customers/${cv.customerId}`, cv);
  }

  updateCustomerVisitAdmin(cv: CustomerVisitSetupDto) {
    return this.http.put(`customer/${cv.customerId}`, cv);
  }

  // Users
  // Update Visit with Actual Times & Locations
  updateCustomerVisit(cv: CustomerVisitDto) {
    return this.http.put(`customer/${cv.customerId}`, cv);
  }


  // // ADMIN List
  // // values/list/checklist
  // queryChecklists(query: SearchDto): Observable<ListResultDto<any>> {
  //   const params: any = {...query.filters};
  //   params.pageNumber = query.pageNumber;
  //   params.pageSize = query.pageSize;
  //   params.orderBy = query.orderBy[0] || "";

  //   return this.http.get<ListResultDto<any>>('values/search/checklist', {params} );
  // }

  // // ADMIN Detail
  // // jobs/checklist POST
  // // jobs/checlist/{checklistid} PUT
  // // jobs/checlist/{checklistid} GET
  // createNewChecklist(checklist: ChecklistDto): Observable<ChecklistDto> {
  //   return this.http.post<ChecklistDto>('jobs/checklist', checklist);
  // }

  // updateChecklist(checklist: ChecklistDto): Observable<ChecklistDto> {
  //   return this.http.put<ChecklistDto>(`jobs/checklist/${checklist.code}`, checklist);
  // }

  // getChecklist(checklistId: string): Observable<ChecklistDto> {
  //   return this.http.get<ChecklistDto>(`values/item/checklist/${checklistId}`);
  // }
}
