import { Component, Input, OnInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AppQuery, LoginStatus } from "@app/modules/common/app.store";
import {
  AuthenticationService,
  FollowupsService,
  ProductSettingService,
} from "@app/modules/common/services";
import { SidebarService } from "@modules/sidebar/sidebar.service";
import { UserCompanyDto } from "@shared/models";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "abi-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() menu: MatSidenav;
  currentCompany: UserCompanyDto;
  companies: UserCompanyDto[];
  companyLogo: string;
  brandInactive: Observable<boolean>;
  hasFollowupsMenu = false;
  username: Observable<string>;
  tenant: string;
  companyName: string;
  followupCount = 0;
  showUserMenu = false;
  hasMenu = false;
  useSidebarMenu = false;

  constructor(
    private appQuery: AppQuery,
    private productSetting: ProductSettingService,
    public authService: AuthenticationService,
    public followupService: FollowupsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sidebarService: SidebarService
  ) {
    this.username = this.appQuery.$username;
    this.brandInactive = this.appQuery.$loginStatus.pipe(
      map((v) => v !== LoginStatus.True)
    );

    combineLatest([this.appQuery.$tenant2, this.appQuery.$companies]).subscribe(
      ([tenant, companies]) => {
        this.tenant = tenant;
        this.loadCompanies(companies, tenant);
      }
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute.root),
      )
      .subscribe(({ firstChild }) => {
        if (!!firstChild && !!firstChild.component && firstChild.component["name"] === "LoginComponent") {
          this.showUserMenu = false;
        }else {
          this.showUserMenu = true;
        }
      });

    this.productSetting.$loading.subscribe((s) => {
      this.companyName =
        this.productSetting.stringValue("CompanyName") || "darkred";
    });

    combineLatest([
      appQuery.$tenant2,
      appQuery.$loginStatus,
    ])
    .pipe(filter(([tenant, loginStatus]) => loginStatus === LoginStatus.True))
    .subscribe(([tenant]) => {
      this.tenant = tenant;
    });
    this.appQuery.useSidebarMenu$.subscribe(useSidebarMenu => {
      this.useSidebarMenu = useSidebarMenu;
    });
    this.appQuery.menu$.subscribe(webMenu => {
      this.hasMenu = !!webMenu.length;
    });
  }

  ngOnInit() {
    this.appQuery.menu$
      .pipe(
        switchMap((list) =>
          list.some((item) => item.menuId === "FollowupList")
            ? of(true)
            : of(false)
        ),
        tap((val) => {
          this.hasFollowupsMenu = val;
        }),
        filter((hasFollowups) => !!hasFollowups),
        switchMap(() => this.followupService.getFollowupsObservable())
      ).subscribe(list => {

      });
    this.followupService.getBadgeCountObservable().subscribe(count => {
      this.followupCount = count;
    });
  }

  toggleSidebar(){
    this.sidebarService.toggle();
  }

  isDemo(company: UserCompanyDto) {
    return company && !!company.id && company.id.indexOf("-") !== -1;
  }

  public logout() {
    this.authService.logout().then(() => {});
    return false;
  }

  public login() {
      document.location.assign(this.tenant + "/login");
  }

  private loadCompanies(companies: UserCompanyDto[], tenant: string) {
    this.companies = [
      ...companies.filter((c) => !c.id.includes("-demo")),
      ...companies.filter((c) => c.id.includes("-demo")),
    ];
    this.currentCompany = {
      id: tenant,
      icon: tenant.split("-", 1)[0],
      name: tenant || "darkred",
      code: ""
    };
    if (tenant && companies.length) {
      this.currentCompany = companies.find((c) => c.id === tenant);
    }

    this.companyLogo =
      this.currentCompany && !!this.currentCompany.icon
        ? `assets/logos-big/${this.currentCompany.icon}.svg`
        : "assets/logo.svg";

    if (!this.currentCompany || !this.currentCompany.id) {
      this.companyName = "darkred";
    }
  }

  previousUser(): string {
    return this.authService.previousUser();
  }

  loginAsPreviousUser(): void {
    this.authService.loginAsPreviousUser().toPromise().then(() => {
      this.router.navigate(["/"],
      {
        state: { forgetPreviousNavHistory: true }
      });
    });
  }
}
