import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoggingService } from "@core/services";
import { environment } from "@env/environment";
import { FileService, UploadFile } from "@modules/common/services";
import { AttachmentService, BaseAttachmentDto, isImage } from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppQuery } from "../../app.store";
import { AttachmentGalleryComponent } from "../attachment-gallery/attachment-gallery.component";

export type Promised = () => Promise<boolean>;
export type ParentId = () => string;

@Component({
  selector: "abi-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
})
export class AttachmentsComponent implements OnChanges, OnInit {
  @Input() useCustomModal = false;
  @Input() customCategories = ["DeliveryNote"];
  @Input() maxCount = 0;
  @Input() disabled = false;
  @Input() attachments: BaseAttachmentDto[] = [];
  @Input() parentId: string | ParentId;
  /** Default Description */
  @Input() filename: string = "";
  @Input() title = "";
  @Input() service: AttachmentService;
  @Input() isReadOnly: boolean;
  @Input() useCheckDigit = false;
  @Input() allowDelete = true;
  @Input() hiddenTypes = [];
  @Output() uploaded = new EventEmitter<BaseAttachmentDto>();
  @Output() deleted = new EventEmitter<BaseAttachmentDto>();
  @Output() busy = new EventEmitter<boolean>();

  category: string;
  description: string;
  uploading = false;
  deleting = false;
  compressing = false;
  progress: number;
  // localAttachments: BaseAttachmentDto[];
  @Input() canUpload: Promised = () => Promise.resolve(true);

  constructor(
    private dialog: MatDialog,
    private appQuery: AppQuery,
    private fileService: FileService,
    private log: LoggingService
  ) {}

  _localAttachments: BaseAttachmentDto[] = [];


  get visibleAttachments() {
    return this._localAttachments.filter((att) => (att.typeId && this.hiddenTypes.length) ? !this.hiddenTypes.includes(att.typeId) : true);
  }
  get localAttachments() {
    return this._localAttachments;
  }
  set localAttachments(value: BaseAttachmentDto[]) {
    this._localAttachments = value;
  }

  ngOnInit(): void {
    this.localAttachments = [...this.attachments];
    if(this.customCategories.length){
      this.category = this.customCategories[0];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.attachments){
      // apply a copy of attachments to local list
      this.localAttachments = [...changes.attachments.currentValue];
    }
  }

  getParentId(): string {
    return typeof this.parentId === "function"
      ? this.parentId()
      : this.parentId;
  }

  uploadProgress(value: number): void {
    this.progress = value;
  }

  uploadAttachment(result: UploadFile, category?: string) {
    this.progress = 0;
    this.uploading = true;
    this.compressing = true;
    this.busy.emit(this.uploading);

    // @todo: at some point we need to switch over to no longer merging the category into the description
    const fileDescription = category ? `${result.description}-{${category}}` : result.description;

    this.canUpload()
      .then((ok) => {
        if (ok) {
          return this.fileService.preProcess(result.file);
        } else return null;
      })
      .then((file: File) => {
        this.compressing = false;
        if (file) {
          this.service
            .uploadAttachment(
              this.getParentId(),
              file,
              fileDescription || "",
              this.uploadProgress.bind(this),
              this.useCheckDigit,
              {},
              category
            )
            .toPromise()
            .then(
              (att) => {
                this.uploading = false;
                this.busy.emit(this.uploading);
                if (att) {
                  this.localAttachments.push(att);
                  this.uploaded.emit(att);
                }
              },
              (err) => {
                this.log.logException(err);
                this.uploading = false;
                this.busy.emit(this.uploading);
              }
            );
        } else {
          this.uploading = false;
          this.busy.emit(this.uploading);
        }
      })
      .catch((err) => {
        this.compressing = false;
        this.uploading = false;
        this.busy.emit(this.uploading);
        this.log.logException(err);
      });
  }

  openViewer(attachment: BaseAttachmentDto, attachments: BaseAttachmentDto[] = this.localAttachments) {
    this.dialog.open(AttachmentGalleryComponent, {
      data: {
        attachments,
        currentAttachment: attachment,
        service: this.service,
        parentId: this.getParentId(),
      },
    });

    return false;
  }

  deleteAttachment(confirm: any, attachment: BaseAttachmentDto) {
    const dialogRef = this.dialog.open(confirm);
    dialogRef.afterClosed().subscribe((ok) => {
      if (!ok) {
        return;
      }
      this.deleting = true;
      this.busy.emit(this.deleting);
      this.service
        .deleteAttachment(this.getParentId(), attachment.attachmentId)
        .toPromise()
        .then(
          (blob) => {
            const id = this.localAttachments.indexOf(attachment);
            this.localAttachments.splice(id, 1);
            this.deleting = false;
            this.busy.emit(this.deleting);
            this.deleted.emit(attachment);
          },
          (err) => {
            this.log.logException(err);
          }
        );
    });
    return false;
  }

  downloadLink(attachment: BaseAttachmentDto): Observable<string> {
    return this.appQuery.$tenant.pipe(
      map(
        (tenant) =>
          `${environment.webApi}/api/${tenant}/${this.service.downloadLink(
            attachment,
            this.getParentId()
          )}`
      )
    );
  }

  getMaxLength(){
    return 64 - (this.category ? this.category.length + 3 : 0)
  }


  isImage = isImage;
}
