import loadScript from 'load-script2';
let scriptPromise: Promise<any> | null = null;
export const scriptLoader = (scriptSrc: string, attrs?: object, parentNode?: HTMLElement, apiExists?: (any) => boolean): Promise<any> => {
  // if(!scriptPromise)
  scriptPromise = new Promise((resolve, reject) => {
    if (apiExists && apiExists(window as any)) {
      console.log('scriptLoader:apiExists', scriptSrc);
      resolve((window as any));
    } else {
      console.log('scriptLoader:loadScript', scriptSrc);
      loadScript(scriptSrc, attrs, parentNode)
      .then(() => {
        resolve((window as any));
      })
      .catch((err) => {
        reject(err);
      });
    }
  });
  return scriptPromise;
};
