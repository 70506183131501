import { AsyncPipe } from "@angular/common";
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform
} from "@angular/core";
import { LookupList } from "@shared/models";
import { map, tap } from "rxjs/operators";
import { LookupListService } from "../services/lookup-list.service";

/**
 * Pipe to display a lookup value
 * @todo: implement 'display' params same as LookupDisplayDirective
 */
@Pipe({
  name: "lookupvalue",
  pure: false
})
export class LookupValuePipe implements OnDestroy, PipeTransform {
  private innerPipe: AsyncPipe;
  private lookupList: LookupList;
  private lastList: string;
  isRequesting = false;

  constructor(
    private lookups: LookupListService,
    ref: ChangeDetectorRef
  ) {
    this.innerPipe = new AsyncPipe(ref);
  }

  transform(value: string, listName: string, full = true): any {
    if (this.lastList !== listName && !this.isRequesting) {
      this.isRequesting = true;
      return this.innerPipe.transform(
        this.lookups.lookupList(listName, 0)
        .pipe(tap(list => {
          this.lookupList = list;
          this.lastList = listName;
          this.isRequesting = false;
        }))
        .pipe(map(list => {
          return full ? list.displayValue(value) : list.display(value);
        }))
      );
    } else if (!this.isRequesting) {
      return full ? this.lookupList.displayValue(value) : this.lookupList.display(value);
    }
    return null;
  }

  ngOnDestroy(): void {
    this.innerPipe.ngOnDestroy();
  }
}
