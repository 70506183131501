import { Directive, Input, OnChanges } from "@angular/core";
import { DeliveryNoteDto, newSearchDto, OrderDto, OrderType, OrderTypes, SearchDto } from "@shared/models";
import { Observable, of  } from "rxjs";
import { SalesOrderService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiPrintReceipt]"
})
export class PrintReceiptsDirective extends BaseObservableTypeaheadHelper<DeliveryNoteDto> implements OnChanges, OnChanges {
  @Input() warehouseId: string;
  @Input() orderId: string;
  @Input() typeId: OrderType = OrderTypes.purchaseorders;
  @Input() supplierId: string;

  constructor(typeAhead: TypeaheadDirective, private orderService: SalesOrderService) {
    super(typeAhead, []);
    this.loading = false;
  }

  protected getId(item: DeliveryNoteDto): string {
    return item.deliveryNoteId;
  }

  protected getName(item: DeliveryNoteDto): string {
    return item.deliveryNoteId;
  }

  protected getFull(item: DeliveryNoteDto): string {
    return `${item.deliveryNoteId} - ${item.customerId} - ${item.deliveredDate} - ${item.lines.length}`;
  }

  // TODO: fix this uglyness
  protected filteredList(term: string): Observable<DeliveryNoteDto[]> {

    const search = newSearchDto();
    if(this.warehouseId)search.filters.warehouseId = this.warehouseId;
    if(this.orderId)search.filters.orderId = this.orderId;
    if(this.supplierId)search.filters.supplierId = this.supplierId;
    return null;
    // return this.orderService.queryPrintReceipts(this.typeId, false, search);
    // .pipe(
    //   mergeMap(res => {
    //     return of(res.items);
    //   }),
    //   mergeMap(orders => {
    //     return this.preFilter && of(orders.filter(o => this.preFilter(o))) || of(orders);
    //   })
    // );
  }

}
