import { Pipe, PipeTransform } from "@angular/core";
import { DeliveryLineDto } from "./DeliveryLineDto";
import { DeliveryNoteCustomerDto } from "./DeliveryNoteCustomerDto";
import { WarehouseMasterDto } from "./WarehouseMasterDto";

export interface DeliveryNoteDto {
  pickListId: string;
  zoneId: string;
  deliveryNoteId: string;
  statusId: string;// same as the Order Status Id (not sure why)
  customerId: string;
  orderId: string;
  lines: DeliveryLineDto[];
  customers: DeliveryNoteCustomerDto[];
  balances: { [key: string]: number };
  deliveredDate: Date;
  totalLines: number;
  orders: number;
  ordered: number;
  delivered: number;
  picked: number;
  selected: boolean;
  warehouse?: WarehouseMasterDto;
}

export const printedConverter = (row: DeliveryNoteDto) => isPrinted(row) ? 'Yes': 'No';

export function isPrinted(self: DeliveryNoteDto): boolean{
  // return getNoteStatus(self.statusId) === "Printed";
  return self.statusId === "PRINTED";
}

export function getNoteStatus(statusId: string): string {
  switch (statusId) {
    case "A":
      return "Available";
    case "C":
      return "Created";
    case "D1":
      return "Delivered (Part)";
    case "D":
    case "D2":
      return "Delivered";
    case "P1":
      return "Printed"; // (Part)";
    case "P":
    case "P2":
      return "Printed";
    default:
      return statusId;
  }
}

@Pipe({
  name: "deliveryNote"
})
export class DeliveryNotePipe implements PipeTransform {
  transform(note: DeliveryNoteDto, format?: string): string {
    return getNoteStatus(note.statusId);
  }
}
