import { Directive, Input, OnChanges, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { newSearchDto } from "@modules/models";
import { UserService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * For Standard duty use
 */
@Directive({
  selector: "input[abiTypeahead][abiUserList]"
})
export class UserListTypeaheadDirective extends BaseObservableTypeaheadHelper<string> implements OnInit, OnChanges {
  @Input() roleFilter: string = "";
  constructor(typeAhead: TypeaheadDirective, private userService: UserService) {
    super(typeAhead, []);
  }

  protected filteredList(term: string): Observable<string[]> {
    const query = newSearchDto();
    query.orderBy = ['userId'];
    query.filters['userId'] = term;
    query.filters['role'] = this.roleFilter;
    return  this.userService.searchUsers(query);
    // .pipe(switchMap(vals => {
    //   return of(vals.filter(user => user.includes(term)));
    // }));
  }

  protected getId(item: string): string {
    return item;
  }
  protected getName(item: string): string {
    return item;
  }
}

