import { Pipe, PipeTransform } from "@angular/core";
import { BaseAttachmentDto } from "./BaseAttachmentDto";
import { CustomerAddressDto } from "./CustomerAddressDto";
import { CustomerContactDto } from "./CustomerContactDto";
import { MachineJournalDto } from "./equipment/MachineJournalDto";
import { ModelMasterDto } from "./ModelMasterDto";

export interface MachineMasterDto {
  machineId: string;
  customerId: string;
  statusId: string;
  locationId?: string;
  name: string;
  serialNumber: string;
  dateOfPurchase: Date;
  owner: string;
  dealerBranchId: string;
  readOnly: boolean;
  categories: string[];
  skills: string[];
  fields: string[]; // = Array(5);
  attachments: BaseAttachmentDto[];
  modelId: string;
  options: string[]; // = Array(5);

  model: ModelMasterDto;
  warehouseId?: string;
  journals?: MachineJournalDto[];

  // meta
  created?: string;
}

export interface MachineMasterWithCustomerDto extends MachineMasterDto {
  address: CustomerAddressDto;
  contact: CustomerContactDto;
}

export interface MachineMasterWithStockDto extends MachineMasterDto {
  stockItems: {[warehouse: string]: number};
}


// public toString = (): string => this.machineId === "NEW" ? "New Appliance" : `${this.modelId} ${this.name}`;

export function getCategory(self: MachineMasterDto, level: number): string {
  return self.model.categories[level - 1] || "";
}

export function branch(self: MachineMasterDto) {
  return self ? self.dealerBranchId : "";
}

export function newMachineMaster(statusId: string): MachineMasterDto {
  return {
    machineId: "NEW",
    customerId: "",
    statusId,
    name: "",
    serialNumber: "",
    dateOfPurchase: null,
    dealerBranchId: "",
    categories: [],
    skills: [],
    fields: Array(5),
    attachments: [],
    modelId: "",
    owner: "Cu",
    readOnly: false,
    options: Array(5),
    locationId: "Cu",
    model: null,
  };
}

@Pipe({
  name: "machine",
  pure: false
})
export class MachineMasterPipe implements PipeTransform {
  transform(machine: MachineMasterDto, format?: string): string  {
    return branch(machine);
  }
}
