import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { PostalCodeDto } from "@modules/models";
import { tap } from "rxjs/operators";
import { AddressService } from "../components/address/address.service";
import { replaceStringTemplate } from "../utilities/string.utilities";

/**
 * @deprecated does nt work nicely.... infinite requests on a production build..?
 * lookup pipes on non-cached data is not a good idea
 */
@Pipe({ name: 'postalcode', pure: false })
export class PostalCodePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  private currentEntity: PostalCodeDto;

  isBusyRequesting= false;
  requestFailed= false;

  constructor(private addressService: AddressService,
    ref: ChangeDetectorRef) {
    this.asyncPipe = new AsyncPipe(ref);
  }

  ngOnDestroy() {
     this.asyncPipe.ngOnDestroy();
  }
  getDefaultEntity(key: string): PostalCodeDto {
    return {city: '', code: key, country: '', province: '', suburb: '' }
  };
  getEntity(key: string): PostalCodeDto {
    if(key && !this.isBusyRequesting){
      if(this.currentEntity && this.currentEntity.code === key) {
        // console.log('returning cached customer', this.currentCustomer);
        return this.currentEntity;
      }
      if(this.requestFailed) {
        // console.log('returning default customer', this.currentCustomer);
        return this.getDefaultEntity(key);
      }
      this.isBusyRequesting = true;
      if(!this.asyncPipe) return this.getDefaultEntity(key);
      return this.asyncPipe
      .transform(
        this.addressService.getPostalCode(key)
        // this.customerService.getCustomer(key)
        .pipe(
          tap(c => {
            // console.log('got getPostalCode', c);
            this.currentEntity=c;
            this.requestFailed=!c;
            this.isBusyRequesting = false;
          })
        )
      );
    }
    // return this.asyncPipe.transform(of(newCustomerMaster()).pipe(tap(c => {
    //   this.currentCustomer=null;
    //   // this.isBusyRequesting = false;
    // })));
    return this.getDefaultEntity(key);
  }

  transform(key: string, format: string = '{code} ~ {suburb}'): any {
    const entity = this.getEntity(key);
    // console.log('PostalCodePipe.transform', key, entity);
    return replaceStringTemplate(format, entity);
  }
}
