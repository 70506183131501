import { Directive, Input, OnChanges } from "@angular/core";
import { codeOnly, PostalCodeDto } from "@shared/models";
import { EMPTY, Observable, of  } from "rxjs";
import { AddressService } from "../components/address/address.service";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiPostalCode]"
})
export class PostalCodeTypeaheadDirective extends BaseObservableTypeaheadHelper<PostalCodeDto> implements OnChanges {
  @Input() country = "";// country prefilter
  @Input() province = "";// country prefilter
  @Input() city = "";// country prefilter

  constructor(typeAhead: TypeaheadDirective, private addressService: AddressService) {
    super(typeAhead, []);
    this.loading = false;
  }

  protected getId(item: PostalCodeDto): string {
    return item.code;
  }

  protected getName(item: PostalCodeDto): string {
    return item.code;
  }

  protected getFull(item: PostalCodeDto): string {
    return `${item.code} ~ ${item.suburb}`;
  }

  // prevent large requests using term limits, but this is confusing to the user, should actually just limt the results...
  protected filteredList(term: string): Observable<PostalCodeDto[]> {
    // console.log("PostalCodeTypeaheadDirective.filteredList", term, this.country, this.province, this.city);
    if (!this.country) return EMPTY;
    return this.addressService.queryPostalCodes(term, codeOnly(this.country), this.province || null, this.city || null, null, 100);
  }
}
