import { Pipe, PipeTransform } from "@angular/core";
import { AddressDto, asGoogleText, asTextBlock, firstLine, line34 } from "@shared/models";

@Pipe({
  name: "address",
  pure: false
})
export class AddressPipe implements PipeTransform {

  transform(value: AddressDto, format?: string): string {
    if(!value) return "";
    switch (format) {
      case "firstLine":
        return !!value ? firstLine(value) : "";
      case "line34":
        return !!value ? line34(value) : "";
      case "asGoogleText":
        return asGoogleText(value);
    }
    return !!value && value.lines ? asTextBlock(value) : "";
  }

}
