<form [formGroup]="form">
    <ngx-start *ngIf="hideStart === false" formControlName="start"></ngx-start>
    <ngx-repeat formControlName="repeat" [frequency]="frequency"></ngx-repeat>
    <ngx-end *ngIf="hideEnd === false" formControlName="end"></ngx-end>
    <hr />
    <div class="row mb-0">
        <div class="col-sm-2 text-right">
            <strong> Result </strong>
        </div>
        <div class="col-sm-10">
            <span>{{ getRruleString() }}</span>
        </div>
    </div>
</form>
