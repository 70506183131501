import { AfterViewInit, Directive, Inject, Input, OnDestroy, Optional, QueryList, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgbActiveModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FieldSettings } from "@shared/models/FieldSettings";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CUSTOM_LAYOUT_ID, WebLayoutService } from "../services/weblayout.service";
import { Disposable } from "../";
import { FormFieldComponent } from "./form-field/form-field.component";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FieldList {
  @ViewChildren(FormFieldComponent)
  fields: QueryList<FormFieldComponent>;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseModal<TModel> extends Disposable implements OnDestroy, AfterViewInit {
  model: TModel;
  fieldSettings: FieldSettings;

  @Input() inModal?;
  @Input() optimiseFieldLayout = true;
  @Input() formErrors: { [control: string]: string[] };
  @Input() group: FormGroup;
  @ViewChildren(FormFieldComponent) fields: QueryList<FormFieldComponent>;
  @ViewChildren(FieldList) nestedFields: QueryList<FieldList>;

  constructor(
    private layoutService: WebLayoutService,
    @Optional() protected activeModal?: NgbActiveModal,
    @Optional() protected dialogRef?: MatDialogRef<any>
  ) {
      super();
  }

  ngAfterViewInit() {
    if (this.inModal && this.optimiseFieldLayout) {
      this.fields.changes.pipe(this.notDisposed()).subscribe(f =>
        setTimeout(() => this.switchFields(f), 1)
      );
      setTimeout(() => this.switchFields(this.fields), 50);

      this.nestedFields.changes.pipe(this.notDisposed()).subscribe(f =>
        this.switchNestedFields(f));

      this.nestedFields.forEach(f => this.switchNestedFields(f));
    }
  }

  protected switchFields(fields: QueryList<FormFieldComponent>) {
    fields.forEach(element => element.horizontal = !this.inModal);
  }

  protected switchNestedFields(container: FieldList) {
    if (container && container.fields) {
      container.fields.changes.pipe(this.notDisposed()).subscribe(f =>
        setTimeout(() => this.switchFields(f), 1)
      );
    }
  }

  // abstract setFormData(model: TModel, group: FormGroup);
  abstract getFormData(model: TModel, group: FormGroup): TModel | void;

  protected configureModal(model: TModel) {
    // this.setFormData(model, this.group);
  }

  handleModal(group: FormGroup, errors: { [control: string]: string[] }, model?: TModel) {
    this.group = group;
    this.formErrors = errors;
    this.inModal = true;
    this.model = model;
    if (model) {
      setTimeout(() => this.configureModal(model), 1);
    }
  }

  accept() {
    const result = this.accepted();
    if (this.activeModal) {
      this.activeModal.close(result || this.model);
    } else {
      this.dialogRef.close(result || this.model);
    }
  }

  accepted() {
    return this.getFormData(this.model, this.group);
  }

  cancel() {
    this.cancelled(this.model);
    if (this.activeModal) {
      this.activeModal.dismiss();
    } else {
      this.dialogRef.close();
    }
  }

  cancelled(model: TModel) {
   // this.setFormData(model, this.group);
  }

  canSubmit() {
    return this.group && this.group.valid;
  }

  get layoutName(): string {
    return 'BaseModal';
  }

  // Same function  as found in BaseFormDirective
  getFieldSettings(): Observable<FieldSettings> {
    return this.layoutService.getPageLayouts(this.layoutName)
    .pipe(
      catchError(e => {
        console.warn(`${this.layoutName}: invalid request`, e);
        return [];
      }),
      map(layouts => {
        if (layouts.length) {
          const webLayout = layouts.find(item => item.layoutId === CUSTOM_LAYOUT_ID);
          this.fieldSettings = webLayout?.data;
          return this.fieldSettings;
        }
        return {};
      }
    ));
  }

}
