export interface ResourcePostalCodeRatingDto {
  country: string;
  code: string;
  zoneId: string;
  rating: number;
}


export function newResourcePostalCodeRating(): ResourcePostalCodeRatingDto {
  return {
    country: "",
    code: "",
    zoneId: "",
    rating: 0
  };
}
