import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Workday } from "@modules/models";
import { computeRRule } from "./util/computeRRule/fromString/computeRRule";
import { formatDate, getDateParts } from "./util/common";
import { NgxRruleService } from "./rrule.service";

@Component({
  selector: "ngx-rrule",
  templateUrl: "./rrule.component.html",
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxRruleComponent),
      multi: true,
    },
  ],
})
export class NgxRruleComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() hideStart = false;
  @Input() hideEnd = false;
  @Input() startAt: Date;
  @Input() endAt;
  @Input() frequency;
  @Input() tz;
  @Input() workdays: Workday[];
  public form: FormGroup;
  private propagateChange;
  private ruleText: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private service: NgxRruleService
  ) {}

  ngOnInit() {
    const params: any = {
      start: {},
      repeat: {},
      end: {
        // mode: "Never",
        mode: "On date",
        onDate: {
          date: getDateParts(new Date()),
        },
      },
    };

    if (this.endAt) {
      params.end = {
        mode: "On date",
        onDate: {
          date: getDateParts(this.endAt),
        },
      };
      // console.log('setting end date', this.endAt);
    }

    if (this.startAt) {
      params.start = {
        onDate: {
          date: getDateParts(this.startAt),
        },
      };
      // console.log('setting start date', this.startAt);
    }

    this.form = this.formBuilder.group(params);
    this.form.valueChanges.subscribe(() =>
      setTimeout(() => {
        this.onFormChange();
      }, 1)
    );
  }

  writeValue = (input: any): void => {
    const config: any = {};
    const configureFrequency = () =>
      config.repeat ? config.repeat[0] : "Weekly";
    const configureYearly = () => config.yearly || "on";
    const configureMonthly = () => config.monthly || "on";
    const configureEnd = () => (config.end ? config.end[0] : "On date");
    const configureHideStart = () =>
      typeof config.hideStart === "undefined" ? true : config.hideStart;
    const configureWeeklyDays = () => {
      // So you have workdays for a calendar . 1 = sun, ... 7 = sat
      // With the default calendar we use ISO 8601: 'weekday' is 1=Mon ... 7=Sun
      const baseWeeklyDays = {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      };
      if(this.workdays) {
        return Object.keys(baseWeeklyDays)
        .map(
          (kDay, index) => ({key: kDay, value: this.workdays.some(i => i.dayOfWeek === index+1)})
        )
        .reduce((pv, cv) => ({ ...pv, [cv.key]: cv.value }), {});
      }
      return baseWeeklyDays;
    };

    const init_data = {
      start: {
        onDate: {
          date:  formatDate(this.startAt),
          options: {},
        },
      },
      repeat: {
        frequency: configureFrequency(),
        yearly: {
          mode: configureYearly(),
          on: {
            month: "Jan",
            day: 1,
          },
          onThe: {
            month: "Jan",
            day: "Monday",
            which: "First",
          },
          options: {
            // modes: config.yearly,
          },
        },
        monthly: {
          mode: configureMonthly(),
          interval: 1,
          on: {
            day: 1,
          },
          onThe: {
            day: "Monday",
            which: "First",
          },
          options: {
            // modes: config.monthly,
          },
        },
        weekly: {
          interval: 1,
          days: configureWeeklyDays(),
          options: {
            // weekStartsOnSunday: config.weekStartsOnSunday,
          },
        },
        daily: {
          interval: 1,
        },
        hourly: {
          interval: 1,
        },
        options: {
          // frequency: config.repeat,
        },
      },
      end: {
        mode: configureEnd(),
        after: 1,
        onDate: {
          date: formatDate(new Date()),
          options: {
            // weekStartsOnSunday: config.weekStartsOnSunday,
            // calendarComponent,
          },
        },
        options: {
          modes: config.end,
        },
      },
      options: {
        hideStart: configureHideStart(),
        hideEnd: config.hideEnd,
        hideError: config.hideError,
        weekStartsOnSunday: config.weekStartsOnSunday,
      },
      error: null,
    };
    const data = computeRRule(init_data, input);
    this.form.patchValue({...data});
  };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  onFormChange = () => {
    let rRule;
    try {
      const params = this.form.value;
      // console.log('on form change', params)
      if (this.hideStart && !this.startAt) {
        params.start = null;
      }
      if (this.hideEnd && !this.endAt) {
        params.end = null;
      }
      rRule = this.service.computeRRule({
        ...params,
        options: { tz: this.tz },
      });
      this.ruleText = rRule.toText().trim();
    } catch (err) {
      console.error(err);
    }
    if (this.propagateChange) {
      this.propagateChange({
        raw: this.form.value,
        rRule,
      });
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.onFormChange();
    }, 10);
  }

  getRruleString() {
    return this.ruleText;
  }
}
