import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AppQuery } from "@modules/common/app.store";
import { StockMasterDto } from "@modules/models";
import { ProductSettingService, StockService, WebLayoutService } from "@services";
import { FieldSettings } from "@shared/models/FieldSettings";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { BaseModal } from "../base-modal";

@Component({
  selector: "abi-stock-master",
  templateUrl: "./stock-master.component.html",
  styleUrls: ["./stock-master.component.scss"],
})
export class StockMasterComponent extends BaseModal<StockMasterDto> implements OnInit, OnChanges {
  static validationMessages = {
    stockId: {
      required: "Stock.StockIDRequired"
    },
    // dateOfPurchase: {
    //   required: "Machine.DateOfPurchaseRequired"
    // }
  };

  @Input() stock: StockMasterDto = null;
  @Input() horizontal = true;
  @Input() context: "ReverseLogistics" = "ReverseLogistics";

  static createFormGroup(fb: FormBuilder, extra: { [key: string]: any } = {}) {
    const result = fb.group({
      stockId: ["", { validators: Validators.required }],
      description: ["", { validators: Validators.required }],
      price: 0,
    });
    return result;
  }

  static setFormData(
    stock: StockMasterDto,
    group: FormGroup,
    productSettings: ProductSettingService
  ) {
    group.reset({ ...stock });
  }

  static getFormData(stock: StockMasterDto, formGroup: FormGroup) {
    const value = formGroup.getRawValue();

    stock.code = value.stockId;
    stock.description = value.description;
    stock.price = +value.price;
  }

  constructor(
    layoutService: WebLayoutService,
    public productSettings: ProductSettingService,
    private dialog: MatDialog,
    private appQuery: AppQuery,
    private fb: FormBuilder,
    private stockService: StockService,
    @Optional() dialogRef?: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
    super(layoutService, null, dialogRef);
  }

  // handleModal(group: FormGroup, dto: StockMasterDto): any {
  //   if (!dto) {
  //     dto = newStockMaster();
  //   }

  //   // this.isNew = !modelMaster.code;
  //   StockMasterComponent.setFormData(sto, this.group, this.productSettings);

  //   this.form.controls.useCategories.valueChanges.subscribe(val => {
  //     if (val){
  //       // set skills to the same values as categories
  //       this.form.patchValue({
  //         skills: this.form.value.categories,
  //       });
  //     }
  //   });
  //   this.form.controls.categories.valueChanges.subscribe(val => {
  //     if (this.form.value.useCategories){
  //       // set skills to the same values as categories
  //       this.form.patchValue({
  //         skills: val,
  //       });
  //     }
  //   });
  // }

  // protected createForm() {
  //   this.form = ModelMasterComponent.generateForm(this.formBuilder)
  //   super.createForm();
  // }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes.group && this.fieldSettings){
      this.applyValidation(this.group, this.fieldSettings);
    }
  }

  ngOnInit() {


    if(this.dialogRef) // used a standalone dialog with no parent form
    {
      // super.ngOnInit(); // creates the form and setups up validation messages
      this.group = StockMasterComponent.createFormGroup(this.fb);
      this.handleModal(this.group, {}, this.data?.stock); // use dialog data
    } else {
      // super.createForm();  // actually sets up the form validation messages
      // form SHOULD be set via input props
      this.handleModal(this.group, {}, this.stock); // form is passed in
    }

    this.getFieldSettings()
    .subscribe((fs: FieldSettings) => {
      if (this.group && fs) // group will almost always be null until it's passed into the Component as a prop (circular dependency)
        this.applyValidation(this.group, fs);
    });
    if (this.dialogRef) {
      this.dialogRef.updateSize("600px");
    }
  }

  /**
   * Use Given FieldSettings to apply custom Validation settings to certain form fields
   * Supported fields are: modelId, serialNumber, dateOfPurchase, dealerBranchId
   */
   applyValidation(group: FormGroup, fieldSettings: FieldSettings){
    // // modelId
    // if(fieldSettings.modelId?.validates !== undefined) {
    //   const useModelIdValidation = fieldSettings.modelId.validates;
    //   group.get('modelId').setValidators(useModelIdValidation ? Validators.required : []);
    //   group.get('modelId').updateValueAndValidity();
    // }
    // // serialNumber
    // if(fieldSettings.serialNumber?.validates !== undefined) {
    //   const useSerialNumberValidation = fieldSettings.serialNumber.validates;
    //   group.get('serialNumber').setValidators(useSerialNumberValidation ? Validators.required : null);
    //   group.get('serialNumber').updateValueAndValidity();
    // }
    // // dateOfPurchase
    // if(fieldSettings.dateOfPurchase?.validates !== undefined) {
    //   const useDateOfPurchaseValidation = fieldSettings.dateOfPurchase.validates;
    //   group.get('dateOfPurchase').setValidators(useDateOfPurchaseValidation ? Validators.required : null);
    //   group.get('dateOfPurchase').updateValueAndValidity();
    // }
    // // dealerBranchId
    // if(fieldSettings.dealerBranchId?.validates !== undefined) {
    //   const useDealerBranchIdValidation = fieldSettings.dealerBranchId.validates;
    //   group.get('dealerBranchId').setValidators(useDealerBranchIdValidation ? Validators.required : null);
    //   group.get('dealerBranchId').updateValueAndValidity();
    // }

    group.updateValueAndValidity();
  }

  showField(field: string, defaultVisible: boolean = true) {
    if (this.fieldSettings?.[field]?.disabled === undefined) return defaultVisible; // default
    return this.fieldSettings?.[field]?.disabled === false ? true : false;
  }

  isNew = true;
  protected configureModal(stock: StockMasterDto) {
    if ("disabled" in this.data) {
      if (this.data.disabled)
        this.group.disable();
    }
    this.isNew = !stock.code;
    this.setFormData(stock, this.group);
  }

  // save Stock Master
  accept(): void {
    const res = this.accepted() as StockMasterDto;
    const process = this.isNew ? this.stockService.createStockMaster(res) : this.stockService.updateStockMaster(res);
    process.pipe(catchError(e => {
      return of(null);
    }))
    .subscribe(r => {
      if(r) super.accept(); // close dialog
    });
  }

  setFormData(dto: StockMasterDto, group: FormGroup) {
    StockMasterComponent.setFormData(dto, group, this.productSettings);
  }

  getFormData(dto: StockMasterDto, group: FormGroup) {
    StockMasterComponent.getFormData(dto, group);
    return dto;
  }

}
