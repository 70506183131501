import { Component, Input } from "@angular/core";

@Component({
  selector: "abi-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent {
  @Input() indeterminate = true;
  @Input() value: number;
  @Input() position = "absolute";
  @Input() diameter = 50;
  @Input() stroke = 5;

  getTop(){
    switch(this.position){
      case 'absolute':
        return 'calc(50% - '+this.diameter*0.5+'px)';
      case 'fixed':
        return '50vh';
      default:
        return 0;
    }
  }

  getLeft(){
    switch(this.position){
      case 'absolute':
        return 'calc(50% - '+this.diameter*0.5+'px)';
      case 'fixed':
        return '50vw';
      default:
        return 0;
    }
  }
}
