import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IKeyboardShortcutListener } from "@modules/common/services/libraries/listener.library";
import { KeyboardShortcutService } from "@services";
import { Subscription } from "rxjs";

/**
 * A legend Modal that displays all active keyboard shortcuts
 */
@Component({
  selector: "keyboard-shortcut-legend",
  templateUrl: "./keyboard-shortcut-legend.component.html",
  styleUrls: ["./keyboard-shortcut-legend.component.css"],
})
export class KeyboardShortcutLegendComponent implements OnInit, OnDestroy {
  helpVisible = false;
  @Input() title = "Keyboard Shortcuts:";
  subscription: Subscription;

  hotkeys: IKeyboardShortcutListener[];

  constructor(private kbShortcutService: KeyboardShortcutService) {}

  public ngOnInit(): void {
    this.subscription = this.kbShortcutService.legendVisible.subscribe(
      (isOpen) => {
        if (isOpen !== false) {
          this.hotkeys = this.kbShortcutService.listeners.filter(
            (hotkey) => hotkey.description
          );
        }

        if (isOpen === false) {
          this.helpVisible = false;
        } else {
          this.toggleCheatSheet();
        }
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public toggleCheatSheet(): void {
    this.helpVisible = !this.helpVisible;
  }
}
