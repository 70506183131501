<div class="row justify-content-center align-items-center login-form">
    <form class="col-md-6 col-lg-4 mx-3" role="form" [formGroup]="form" (ngSubmit)="resetPassword()">
        <div class="card">
            <div class="card-header py-2">
                <h4 class="card-title my-0" translate="Login.ResetPassword"></h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <abi-form-field label="User ID" [errors]="formErrors.userid" cols="col-12" [horizontal]="false">
                        <input autocomplete="username" formControlName="username" required />
                    </abi-form-field>
                    <abi-form-field label="Options.New" [errors]="formErrors.newPassword" [horizontal]="false" cols="col-12">
                        <input type="password" formControlName="newPassword" name="newPassword" autocomplete="new-password" required>
                    </abi-form-field>
                    <abi-form-field label="Options.Confirm" [errors]="formErrors.confirmNewPassword" [horizontal]="false" cols="col-12">
                        <input type="password" formControlName="confirmNewPassword" name="confirmNewPassword" autocomplete="new-password-confirm" required>
                    </abi-form-field>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" [disabled]="!form.valid" class="btn btn-primary">
                    <i class="fas fa-redo"></i> {{ 'System.Submit' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
</div>
