<div
    *ngIf="!busy; else loading"
    class="row justify-content-center align-items-center login-form"
>
    <form
        class="col-12 col-md-8 col-lg-6 mx-3"
        role="form"
        [formGroup]="form"
        (ngSubmit)="login()"
        *ngIf="!busy"
    >
        <div class="card">
            <div class="card-header py-2">
                <h4 class="card-title my-0" translate="Login.Login"></h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <abi-form-field
                        label="Login.Username"
                        [errors]="formErrors.username"
                        cols="col-md-12"
                        labelColClass="col-md-3"
                        [horizontal]="false"
                    >
                        <input
                            autofocus="true"
                            type="text"
                            formControlName="username"
                            id="username"
                            name="username"
                            required
                            (cdkAutofill)="updateAfterAutofill('username')"
                            autocomplete="username"
                            data-cy="login-username"
                        />
                    </abi-form-field>
                    <abi-form-field
                        label="Login.Password"
                        [errors]="formErrors.password"
                        cols="col-md-12"
                        labelColClass="col-md-3"
                        [horizontal]="false"
                    >
                        <div class="input-group">
                            <input
                                [type]="showPassword ? 'text' : 'password'"
                                formControlName="password"
                                id="password"
                                name="password"
                                required
                                (cdkAutofill)="updateAfterAutofill('password')"
                                autocomplete="current-password"
                                data-cy="login-password"
                            />
                            <div class="input-group-append">
                                <button
                                    class="btn btn-outline-secondary"
                                    [class.active]="showPassword"
                                    type="button"
                                    [attr.title]="
                                        'Login.ShowPassword' | translate
                                    "
                                    (click)="showPassword = !showPassword"
                                    data-cy="login-show-password"
                                >
                                    <i
                                        [class]="
                                            showPassword
                                                ? 'fas fa-eye-slash fa-fw'
                                                : 'fas fa-eye fa-fw'
                                        "
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </abi-form-field>
                    <div class="col-12 pt-3">
                        <button
                            type="submit"
                            [disabled]="!form.valid"
                            class="btn btn-primary col-12"
                            data-cy="login-submit"
                        >
                            <i class="fas fa-sign-in-alt"></i>&nbsp;{{
                                "Login.Login" | translate
                            }}
                        </button>
                    </div>
                    <abi-form-field
                        cols="col-12 pt-3 col-md-6"
                        labelColClass="col-3"
                        inputColClass="custom-control custom-checkbox"
                    >
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            formControlName="rememberMe"
                            data-cy="login-remember-me"
                        />
                        <label class="custom-control-label" for="rememberMe">{{
                            "Login.RememberMe" | translate
                        }}</label>
                    </abi-form-field>
                    <div class="col-12 pt-3 col-md-6">
                        <a
                            class="float-md-right"
                            routerLink="/forgotpassword"
                            translate="Login.ForgotPassword"
                            data-cy="login-forgot-password"
                        ></a>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="showExtras">
            <h4 class="text-center w-100 my-3" translate="or"></h4>
            <div class="card">
                <div class="card-header py-2">
                    <h4 class="card-title my-0" translate="Third Party"></h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 text-center">
                            <button
                                type="button"
                                class="mt-2 btn sb facebook"
                                (click)="signInWithFB()"
                            >
                                <div class="float-left">
                                    <i class="fab fa-facebook"></i>
                                </div>
                                Sign in with Facebook
                            </button>
                            <button
                                type="button"
                                class="mt-2 btn sb google"
                                (click)="signInWithGoogle()"
                            >
                                <div class="float-left">
                                    <i class="fab fa-google"></i>
                                </div>
                                Sign in with Google
                            </button>
                            <button
                                type="button"
                                class="mt-2 btn sb microsoft"
                                (click)="signInWithMicrosoft()"
                            >
                                <div class="float-left">
                                    <i class="fab fa-microsoft"></i>
                                </div>
                                Sign in with Microsoft
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
</div>
<ng-template #loading>
    <abi-spinner></abi-spinner>
</ng-template>
