import { Component } from "@angular/core";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AppInjector } from "@app/app.module";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
@Component({
  selector: 'abi-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteDialogComponent {
  static openModal(modal: NgbModal, dialogConfig?: NgbModalOptions, title?: string, message?: string): NgbModalRef {
    // const modal = AppInjector.get(NgbModal); -- NOTE: always a source of issues (with Unit Tests)
    const modalRef = modal.open(CustomDialogComponent, dialogConfig || {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    });
    const modalComponent = modalRef.componentInstance as CustomDialogComponent;
    modalComponent.title = title || "Confirm Delete";
    modalComponent.body = message || "Are you sure you want to delete this item?";
    modalComponent.buttons = [
      {
        label: "System.Cancel",
        class: "btn btn-secondary",
        disabled: () => false,
        action: (m) => {
          m.close();
        },
      },
      {
        label: "System.Delete",
        class: "btn btn-danger",
        disabled: () => false,
        action: (m) => {
          m.close(true);
        },
      }
    ];
    return modalRef;
  }
}
