import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ServiceConfig } from "..";

interface GeolocationOptions {
  enableHighAccuracy: boolean;
  timeout: number;
  maximumAge: number;
}

@Injectable({
  providedIn: "root",
})
export class GeolocationService {
  location$: Subject<GeolocationPosition | GeolocationPositionError> =
    new Subject<GeolocationPosition | GeolocationPositionError>();
  shouldLoop = true;
  intervalMs = 30000; // default to high intervals - can be changed based on context
  wasStarted = false;

  static options: GeolocationOptions = {
    enableHighAccuracy: true,
    timeout: 15000, // 15 seconds - trigger error if no location is found in this time
    maximumAge: 3000, // 3 seconds - to help improve acurracy without requiring too much GPS device occupancy
  };

  success(pos: GeolocationPosition) {
    // const crd = pos.coords;
    // console.log("Your current position is:");
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);

    this.location$.next(pos);
    if (this.shouldLoop)
      setTimeout(() => {
        this.getNewPos();
      }, this.intervalMs);
  }

  error(err: GeolocationPositionError) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    this.location$.next(err);
    if (this.shouldLoop)
      setTimeout(() => {
        this.getNewPos();
      }, this.intervalMs);
  }

  constructor(config: ServiceConfig) {}

  set interval(interval: number) {
    this.intervalMs = interval;
  }

  get locationObs() {
    return this.location$;
  }

  set loop(flag: boolean) {
    this.shouldLoop = flag;
  }

  // allow longer timeout for when starting loop -15s
  startLocationLoop(timeout: number = 15000) {
    if(this.wasStarted) return;
    this.wasStarted = true;
    this.getNewPos(timeout);
  }

  stopLocationLoop() {
    this.shouldLoop = false;
    this.wasStarted = false;
  }

  // subsequent positions aquisitions must result faster -10s
  private getNewPos(timeout: number = 10000) {
    navigator.geolocation.getCurrentPosition(
      (val) => this.success(val),
      (val) => this.error(val),
      { ...GeolocationService.options, timeout }
    );
  }

  static isError(obj: GeolocationPositionError) {
    return !!obj.code;
  }

  static getErrorMessage(obj: GeolocationPositionError) {
    return obj.message;
  }

  /**
   * @todo: note 100% sure which version is correct: 'degreesToRadians' or 'deg2rad' (ie: BODMAS rules or brackets?)
   * */
  static degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  /**
   * See: https://stackoverflow.com/a/365853/1741010
   * @param lat1
   * @param lon1
   * @param lat2
   * @param lon2
   * @returns number KM distance value
   */
  static distanceInKmBetweenEarthCoordinates(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const earthRadiusKm = 6371;

    const dLat = GeolocationService.degreesToRadians(lat2 - lat1);
    const dLon = GeolocationService.degreesToRadians(lon2 - lon1);

    lat1 = GeolocationService.degreesToRadians(lat1);
    lat2 = GeolocationService.degreesToRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

  static getDistanceFromLatLonInKm(loc1: google.maps.LatLngLiteral, loc2: google.maps.LatLngLiteral) {
    if (loc1 === null || loc2 === null) {
      return -999999;
    }
    const R = 6371; // Radius of the earth in km
    const dLat = GeolocationService.deg2rad(loc2.lat - loc1.lat); // deg2rad below
    const dLon = GeolocationService.deg2rad(loc2.lng - loc1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(GeolocationService.deg2rad(loc1.lat)) *
      Math.cos(GeolocationService.deg2rad(loc2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = Math.abs(R * c); // Distance in km
    return d;
  }

  static PiDiv180 = 0.0174532925199433;// Optimise calculation with constant
  static deg2rad(deg: number): number {
    // return deg * (Math.PI / 180);
    return deg * GeolocationService.PiDiv180;
  }

 /**
  *
  * @param start
  * @param end
  * @returns number in KM
  */
static getDistance(start: number[] | string, end: number[] | string): number {
   if (!start || !end) return null; // error
   const startLocation =
     typeof start === "string" ? GeolocationService.getLatLongFromString(start) : start;
   const endLocation =
     typeof end === "string" ? GeolocationService.getLatLongFromString(end) : end;
   return GeolocationService.distanceInKmBetweenEarthCoordinates(
     startLocation[0],
     startLocation[1],
     endLocation[0],
     endLocation[1]
   );
 }


static getLocationString(location: GeolocationPosition) {
  return `${location.coords.latitude},${location.coords.longitude}`;
}

static getLatLongFromString(location: string): number[] {
  return location.split(",").map((i) => parseFloat(i));
}
}
