import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { scriptLoader } from "@modules/common/utilities/script.utilities";

export interface RoomplannerAsset {
  architectural: boolean
  bbox_max: number[],
  bbox_min: number[],
  brand: string,
  color: string,
  combinations: undefined,
  currency: null,
  description: string,
  fixture: boolean,
  flags: any[],
  group_id: string,
  height: number,
  id: string,
  level: number,
  light: undefined,
  materials: undefined,
  meta: undefined,
  meta_type: undefined,
  name: string,
  price: null,
  rollable: false,
  scaleable: true,
  sku: string,
  status: string,
  supplierSize: null,
  supplier_id: number,
  supplier_name: string,
  surface_height: number,
  tags: number[]
  thumb: {
    thumb2D: string,
    thumb3D: string,
    thumbBW: string,
    big3d: string
  },
  url: string,
  url2d: string,
  url3d: string,
  urlBw: string,
  width: number,
  z_height: number
  // architectural: false
  // bbox_max: (3) [19, 11.75, 160.5, keys: ƒ, map: ƒ, filter: ƒ, slice: ƒ, concat: ƒ, …]
  // bbox_min: (3) [-19, -11.75, 0, keys: ƒ, map: ƒ, filter: ƒ, slice: ƒ, concat: ƒ, …]
  // brand: "Discontinued"
  // color: undefined
  // combinations: undefined
  // currency: null
  // description: null
  // fixture: false
  // flags: [keys: ƒ, map: ƒ, filter: ƒ, slice: ƒ, concat: ƒ, …]
  // group_id: undefined
  // height: 23.5
  // id: "dfba8399c26d06600f33cdc7dfedcb0f60708b4c"
  // level: 1
  // light: undefined
  // materials: undefined
  // meta: undefined
  // meta_type: undefined
  // name: "Athens Tall Unit"
  // price: null
  // rollable: false
  // scaleable: true
  // sku: "158380779"
  // status: "approved"
  // supplierSize: null
  // supplier_id: 6931083
  // supplier_name: "Discontinued"
  // surface_height: 82
  // tags: (4) [1888, 1186, 13138, 14218, keys: ƒ, map: ƒ, filter: ƒ, slice: ƒ, concat: ƒ, …]
  // thumb: {thumb2D: 'https://thumbs-cdn.floorplanner.com/cdb/scaled/ren…00f33cdc7dfedcb0f60708b4c_01.top.png_w110h110.png', thumb3D: 'https://thumbs-cdn.floorplanner.com/cdb/scaled/ren…7dfedcb0f60708b4c_01.perspective.png_w110h110.png', thumbBW: 'https://mediacdn.floorplanner.com/cdb/scaled/rende…0f33cdc7dfedcb0f60708b4c_01.bw.top.png_w72h72.png', big3d: 'https://mediacdn.floorplanner.com/cdb/renders/df/d…6d06600f33cdc7dfedcb0f60708b4c_01.perspective.png', merge: ƒ, …}
  // url: "https://floorplanner.com"
  // url2d: "https://mediacdn.floorplanner.com/cdb/renders/df/dfba8399c26d06600f33cdc7dfedcb0f60708b4c_01.top.x3.png"
  // url3d: "https://mediacdn.floorplanner.com/cdb/models/new/df/dfba8399c26d06600f33cdc7dfedcb0f60708b4c_01.webglz"
  // urlBw: "https://mediacdn.floorplanner.com/cdb/renders/df/dfba8399c26d06600f33cdc7dfedcb0f60708b4c_01.bw.top.png"
  // width: 38
  // z_height: 160.5
};

export interface RoomplannerItem {
  asset: RoomplannerAsset,
  height: number,
  role: undefined
  rotation: number,
  width: number,
  x: number,
  y: number,
  z: number,
  z_height: number
  // height: 53.836
  // role: undefined
  // rotation: 47
  // width: 52.274
  // x: 495.91796875
  // y: 221.2500000000001
  // z: 0
  // z_height: 76.789
};

export interface RoomplannerProject {
  created_at: string
  creator_email: string
  creator_id: number
  creator_parent_id: number
  description: string
  design_count: number
  enable_autosave: boolean
  exported_at: string
  external_identifier: string
  features: string[]
  floor_count: number
  floors: any[]
  id: number
  legacy_editor: boolean
  level_id: number
  location: null
  name: string
  project_url: string
  public: boolean
  roomplan_email: string
  roomplan_id: number
  service_orders: any[]
  settings: any
  sponsored_project: boolean
  status: string
  template: null
  thumbnail: null
  thumbnail_3d: null
  updated_at: string
  user_id: number
  // created_at: "2022-06-28T11:15:58.000Z"
  // creator_email: "accounts@evolutioncycling.co.za"
  // creator_id: 71722692
  // creator_parent_id: 71722692
  // description: null
  // design_count: 1
  // enable_autosave: false
  // exported_at: null
  // external_identifier: null
  // features: ["hd"]
  // floor_count: 1
  // floors: [{id: 196778748, project_id: 124403103, name: "First floor", level: 0,…}]
  // id: 124403103
  // legacy_editor: false
  // level_id: 2
  // location: null
  // name: "Project Name"
  // project_url: "ol81dh"
  // public: false
  // roomplan_email: null
  // roomplan_id: null
  // service_orders: []
  // settings: {}
  // sponsored_project: false
  // status: "private"
  // template: null
  // thumbnail: null
  // thumbnail_3d: null
  // updated_at: "2022-06-28T11:15:58.000Z"
  // user_id: 71722692
}

export interface RoomplannerSaveResponse {
  assignee_email: string,
  assignee_id: number,
  city: string,
  contact_method: string,
  contact_time: null,
  country: string,
  country_code: null,
  created_at: string,
  deleted_at: null,
  design_id: number,
  email: string,
  hash_id: string,
  id: number,
  identifier: null,
  message: null,
  name: null,
  newsletter_signup: null,
  phonenumber: null,
  project_id: number,
  project_token: string,
  state: string,
  updated_at: string,
  user_branding_id: number,
  user_id: number,
  zip_code: null
  // assignee_email: null
  // assignee_id: null
  // city: "Sedgefield"
  // contact_method: "mail"
  // contact_time: null
  // country: "South Africa"
  // country_code: null
  // created_at: "2022-06-28T12:23:41.000Z"
  // deleted_at: null
  // design_id: 208406457
  // email: "amiller+fp@roguefitness.com"
  // hash_id: "lsseh54sgd"
  // id: 323556
  // identifier: null
  // message: null
  // name: null
  // newsletter_signup: null
  // phonenumber: null
  // project_id: 124405809
  // project_token: "eyJhbGciOiJFUzI1NiJ9.eyJhY3Rpb24iOiJtYW5hZ2UiLCJtb2RlbCI6IlByb2plY3QiLCJpZCI6MTI0NDA1ODA5LCJpYXQiOjE2NTY0MTkwMjEsImV4cCI6MTY1NzAyMzgyMX0.yxnt8WHOkKfHSpUZHtzUIYEtERInC4y02TCxfs9Iy3oFLw1rkp_8oz63SOa5OsdQ7aGWivOa1mh3yQqZZ_z9AA"
  // state: "Western Cape"
  // updated_at: "2022-06-28T12:23:41.000Z"
  // user_branding_id: 332592
  // user_id: 42574871
  // zip_code: null
}

export interface RoomplannerConfig {
  projectId: number | 'roomplanner',
  brandingId: number,
  roomplannerDisableSave: boolean,
  roomplannerTemplateID: string,
  roomplannerSubdomain: string,
  roomplannerHideFirstWizardStep: boolean,
  mountSelector: string | '#fp-editor-container',
  kind: string | 'roomplanner',
  user: {
    permissions: string[]
  },
  newRoomplan: boolean,
  language: string,
  projectAccessToken?: string
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    initFPEditor: (config: Object) => Promise<any>;
    api: any;
    floorplannerApi: any;
  }
}

@Component({
  selector: "abi-roomplanner",
  templateUrl: "./roomplanner.component.html",
  styleUrls: ["./roomplanner.component.scss"],
})
export class RoomplannerComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input() config: RoomplannerConfig;
  @Input() width: string = "1000";
  @Input() height: string = "1000";
  @Output() scriptLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() apiReady: EventEmitter<any> = new EventEmitter();
  @Output() initialSave: EventEmitter<RoomplannerSaveResponse> = new EventEmitter();
  @Output() initialState: EventEmitter<any> = new EventEmitter();
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Output() exported: EventEmitter<boolean> = new EventEmitter();

  floorPlannerApi: any;
  constructor(
    private ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if(changes.config) {
      console.log('config changed...');
      this.runFp();
    }
  }

  cleanupFp() {
    // this.floorPlannerApi?.unmount();
    console.log('unmounted...')
  }

  runFp(){

    this.floorPlannerApi?.unmount();
    const attrs = {
      "data-floorplanner-editor": "",
      crossorigin: "anonymous",
    };

    const apiExists = (win: Window) => {
      return 'initFPEditor' in win;
    };

    console.log('starting FP Script load...', window.initFPEditor);

    scriptLoader(
      "https://fp-editor-cdn.floorplanner.com/embed.js",
      attrs,
      undefined,
      apiExists
    ).then((win) => {
      if(!apiExists(window))throw new Error('failed FP script load');
      console.log('script loaded', win.initFPEditor);
      this.scriptLoaded.emit(true);
      this.initRoomplannerAndCallbacks(this.config);
    }).catch(e => {
      window.location.reload();
    });
  }

  initRoomplannerAndCallbacks(config: any){
      /**
       * Note Specific Roomplanner configs here
       * these are not documented, so proceed with caution
       */
       window.initFPEditor(config).then(api => {

        console.log('fp inited');
        // RUN IN LOCAL ZONE
        this.ngZone.run(() => {
          // get handles on api
          window.floorplannerApi = api;
          this.floorPlannerApi = api;// choose your poison
          this.apiReady.emit(api);


          // update the data of the component
          // Get Initial Products list (for later OnUpdate comparison)
          // this.items = api.state.items;
          this.initialState.emit(api.state);

          // SETUP CALLBACKS

          // ON UPDATE
          // Useful for knowing which products are in the Plan
          api.onUpdated = (data) => {
            this.ngZone.run(() => {
              this.updated.emit(data);
            });
          };

          // Example ZEUS implementation
          // api.onUpdated = () => {
          //   // sendMessage({ action : 'update', state : 'dirty'})
          //   // sendMessage({ action : 'skus', data: api.state.items.map(item => item.asset.sku)})
          //   console.log('updated')
          // };

          // Not likely used
          api.onExport = () => {
            this.ngZone.run(() => {
              console.log('exporting');
              this.exported.emit(true);
            });
            // sendMessage('export')
          };

          api.onSave = (response) => {
            this.ngZone.run(() => {
              console.log('saved', response);
              this.saved.emit(response);
            });
            // sendMessage({ action : 'update', state : 'dirty'})
            // sendMessage({ action : 'skus', data: api.state.items.map(item => item.asset.sku)})
          };
        });
      });
  }

  ngAfterViewInit(): void {
    // SETUP AJAX INTERCEPTOR FOR FLOORPLANNER CREATE
    const origOpen = XMLHttpRequest.prototype.open;
    const componentThis = this;
    XMLHttpRequest.prototype.open = function() {
      this.addEventListener('load', function(args) {
        console.log(args);
        if (this.responseURL && this.responseURL.indexOf('create.json') !== -1) {
          const newdata = JSON.parse(this.responseText);

          if ((newdata as RoomplannerSaveResponse).project_id) {
            // this doesnt exist
            componentThis.initialSave.emit(newdata);
          }
        }
      });

      origOpen.apply(this, arguments);
    };
  }

  ngOnDestroy(): void {
    this.cleanupFp();
  }

  ngOnInit(): void {
    console.log('initing FP');
  }
}
