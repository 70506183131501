
    <span (click)="$event.stopPropagation()" class="background">
        <mat-accordion
            *ngIf="nestedColumns?.length > 1; else flatList"
            displayMode="flat"
        >
            <mat-expansion-panel *ngFor="let node of nestedColumns">
                <mat-expansion-panel-header class="section-height">
                    {{ node.name | translate }}
                </mat-expansion-panel-header>
                <mat-selection-list
                    #colss
                    (selectionChange)="toggleColumn($event)"
                    class="py-0"
                >
                    <mat-list-option
                        color="primary"
                        *ngFor="let col of node.columns"
                        checkboxPosition="before"
                        [selected]="!col.hidden"
                        [value]="col"
                        class="text-nowrap row-height"
                    >
                        {{ col.realHeader }}
                    </mat-list-option>
                </mat-selection-list>
            </mat-expansion-panel>
        </mat-accordion>
        <ng-template #flatList>
            <mat-selection-list #colss (selectionChange)="toggleColumn($event)">
                <mat-list-option
                    color="primary"
                    *ngFor="let col of visibleColumns()"
                    checkboxPosition="before"
                    [selected]="!col.hidden"
                    [value]="col"
                    class="text-nowrap row-height"
                >
                    {{ col.realHeader }}
                </mat-list-option>
            </mat-selection-list>
        </ng-template>
    </span>
