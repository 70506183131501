import { Directive, Input, OnChanges } from "@angular/core";
import { CustomerContactDto } from "@shared/models";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { CustomerService } from "../services/customer.service";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiCustomerContact]"
})
export class CustomerContactTypeaheadDirective extends BaseObservableTypeaheadHelper<CustomerContactDto> implements OnChanges {
  // pass in the Customer ID to get a list of Contacts
  @Input()
  public abiCustomerContact: string;

  constructor(typeAhead: TypeaheadDirective, private customerService: CustomerService) {
    super(typeAhead, ["abiCustomerContact"]);
    this.inputDisplay = "name";
    this.dropDownDisplay = "name";
  }

  protected getId(item: CustomerContactDto): string {
    return item.contactId;
  }

  protected getName(item: CustomerContactDto): string {
    return `${item.firstName} ${item.lastName}`; // ~ ${item.serialNumber} ~ ${item.warehouseId}`;
  }

  protected filteredList(term: string): Observable<CustomerContactDto[]> {
    if(!this.abiCustomerContact) return of([]);
    return this.customerService.getCustomer(this.abiCustomerContact)
    .pipe(map(customer => customer.contacts));
  }
}

