import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatSelectionListChange } from "@angular/material/list";
// import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import { PublishSubscribe } from "@modules/common";
import { TableColumn } from "..";

export interface TreeNode {
  name: string;
  columns: TableColumn[];
}
export interface FlatNode {
  expandable: boolean;
  name: string;
  column?: TableColumn;
  columns?: TableColumn[];
  level: number;
}


@Component({
  selector: "abi-column-chooser",
  templateUrl: "./column-chooser.component.html",
  styleUrls: ["./column-chooser.component.scss"]
})
export class ColumnChooserComponent implements OnInit, OnChanges {

  @Input() btnClass = "btn-outline-primary btn";
  @Input() columns: TableColumn[];
  @Output() toggled: EventEmitter<TableColumn> = new EventEmitter();
  nestedColumns: TreeNode[];

  // treeControl: FlatTreeControl<FlatNode>;
  // treeFlattener: MatTreeFlattener<TreeNode | TableColumn, FlatNode>;
  // dataSource: MatTreeFlatDataSource<TreeNode | TableColumn, FlatNode>;

  constructor(private pubsub: PublishSubscribe) {
    // this.treeControl = new FlatTreeControl<FlatNode>(node => node.level, node => node.expandable);
    // this.treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable,
    //  node => "name" in node ? node.columns : null);
    // this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.nestedColumns = ([... new Set(this.columns.map(c => c.group))] as string[]).map(r => (
      {name: r, columns: this.columns.filter(cc => cc.group === r && cc.realHeader)}
      ));
    // this.dataSource.data = this.nestedColumns;
  }

  ngOnInit() {
  }
/*
  private transformer = (node: TreeNode | TableColumn, level: number) => {
    return {
      expandable: "name" in node,
      name: "name" in node ? node.name : this.header(node),
      column: "name" in node ? null : node,
      columns: "name" in node ? node.columns : null,
      level,
    };
  }
*/
  visibleColumns(): TableColumn[] {
    return this.columns ? this.columns.filter(c => c.realHeader) : [];
  }

  toggleColumn(option: MatSelectionListChange): void {
    const tc = option.options[0].value as TableColumn;
    tc.hidden = !option.options[0].selected;
    this.toggled.emit(tc);
  }

  closed() {
    this.pubsub.publish("closeSidebar");
  }

  // hasChild = (_: number, node: FlatNode ) => node.expandable;

}
