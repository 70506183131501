<div class="input-group">
    <input
        [(ngModel)]="currentResource"
        abiTypeahead
        abiResource
        container="body"
        [disabled]="allResources()"
        (blur)="blur()"
        [placeholder]="placeholder"
    />

    <div *ngIf="allowClear" class="input-group-append">
        <button
            class="btn"
            [ngClass]="{
                'btn-primary active': allResources(),
                'btn-outline-primary': !allResources()
            }"
            type="button"
            (click)="clearResource()"
            (blur)="blur()"
            [disabled]="disabled"
        >
            <i
                [class]="
                    allResources()
                        ? 'far fa-check-square fa-fw'
                        : 'far fa-square fa-fw'
                "
            ></i>
            {{ clearLabel }}
        </button>
    </div>
    <ng-content></ng-content>
</div>
