import { Pipe, PipeTransform } from "@angular/core";
import { NoZeroPipe } from "@modules/common/pipes/no-zero.pipe";
import { LookupObjectDto, newLookupObject } from "./LookupObjectDto";
import { StockWarehouseDto } from "./StockWarehouseDto";

export interface StockMasterDto extends LookupObjectDto {
  eanCode: string;

  price: number;
  mode: string;
  vatId: string;

  // descriptions: [string, string];// = ["", ""];

  description2: string;

  lastModified?: Date;
  warehouses?: StockWarehouseDto[]; // = [];
  modeId: string;
  statusId?: string;
  categories?: string[]; // = Array(5);

  quantity?: number;
  orderQuantityDemand?: number;
  jobQuantityDemand?: number;
  quantityOnOrder?: number;

  safetyLevel?: number;
  minOrderQuantity?: number;
  leadTimeValue?: number;
  weight?: number;

  typeId?: string;

  // Newly Added Fields
  availableFrom?: Date;
  availableTo?: Date;
  obsoleteFrom?: Date;
  endOfLiveFrom?: Date;
}

export function newStockMaster(typeId: string = "STK"): StockMasterDto {
  return {
    code: "",
    typeId,
    active: true,
    order: 0,
    description: "",
    description2: "",
    eanCode: "",
    lastModified: new Date(),
    mode: "",
    modeId: "",
    price: 0,
    statusId: "",
    warehouses: [],
    categories: [],
    safetyLevel: 0,
    minOrderQuantity: 0,
    leadTimeValue: 1,
    weight: 0,
    vatId: "01",

    availableFrom: Date.today(),
    availableTo: Date.maxDate(),
    obsoleteFrom: Date.maxDate(),
    endOfLiveFrom: Date.maxDate(),

  };
}

function userField5(self: StockMasterDto, flag: string): boolean {
  return self.modeId.includes(flag);
}
export function changeDescription(self: StockMasterDto): boolean {
  return userField5(self, "T");
}

export function changePrice(self: StockMasterDto): boolean {
  return userField5(self, "P");
}

export function changeQuantity(self: StockMasterDto): boolean {
  return userField5(self, "Q");
}

export function changeDiscount(self: StockMasterDto): boolean {
  return userField5(self, "D");
}

export function quantityBalance(self: StockMasterDto): number {
  return self.warehouses.reduce((p: number, w: StockWarehouseDto) => p + w.quantityBalance, 0);
}

export function quantityDemand(self: StockMasterDto): number {
  return self.orderQuantityDemand + self.jobQuantityDemand;
}

export function shortfall(self: StockMasterDto, warehouseId: string): number {
  const warehouse = self.warehouses.find(w => w.warehouseId === warehouseId);

  return Math.abs(Math.max(0, warehouse.demand - warehouse.onOrder -
    (warehouse.quantityBalance || 0)));
}

@Pipe({
  name: "stockmaster"
})
export class StockMasterPipe implements PipeTransform {
  transform(stock: StockMasterDto, member: string, format?: string): string | number {
    const nz = new NoZeroPipe();
    switch (member) {
      case "quantityBalance":
        return nz.transform(quantityBalance(stock));
      case "quantityDemand":
        return nz.transform(quantityDemand(stock));
      case "shortfall":
        return shortfall(stock, format);
    }
    return "";
  }
}

export function newStockBin(stockId: string, warehouseId: string, binId: string): StockBinDto {
  return {
    stockId,
    warehouseId,
    binId,
    quantityBalance: 0,
    safetyStockLevel: 0,
    eoq: 0,
  };
}
export interface StockBinDto {
  isNew?: boolean;
  stockId: string;
  warehouseId: string;// this will always limit the available bins when selecting a bin for StockBin Dto
  binId: string;
  quantityBalance: number;
  safetyStockLevel: number;
  eoq: number;// todo: check var name
  // bind description?
  // is isDefault?
}

export function newStockTake(): StockTakeDto {
  return {
    stockTakeId: "",
    description: "",
    warehouseId: "",
    cycleCount: 0,
    date: new Date(),
    statusID: "New",
    committed: 0,
    bins: [],
    initialCounts: 0,
    currentCount: 0,
  };
}
export type StockTakeStatusType = "New" | "Counting" | "Recounting" | "Validating" | "Committed";
export interface StockTakeDto {
  stockTakeId: string;
  warehouseId: string;
  description: string;
  cycleCount: number;
  date: Date;
  statusID: StockTakeStatusType;
  committed: number;
  bins: StockTakeBinDto[];
  initialCounts: number;
  currentCount?: number;
}

// These entities would be autgenerated by the api when the StockTake is created
export interface StockTakeBinDto {
  count: number;
  warehouseId: string;
  binId: string;
  stockId: string;
  serialNumber: string;
  date: Date;
  userId: string;
  quantity: number;
  validated: number;
  recounted: number;
  teamId: string;
  selected?: boolean;
  alert: number;
}
