import { Injectable } from '@angular/core';
import { ListResultDto, LookupObjectDto, SearchDto } from "@modules/models";
import { ChecklistDto, ChecklistEntryDto } from "@shared/models/checklist/ChecklistDto";
import { Observable } from "rxjs";
import { TestDto } from "@modules/quality-control/models/TestDto";
import { DataService, ServiceConfig } from "..";

export type AnswerFile = { attachmentId: string, name?: string, description?: string};

export function isActive(checklist: LookupObjectDto) {
  return checklist?.active === true || false;
}

export function newChecklistEntry(): ChecklistEntryDto {
  return {
    checklistId: '',
    answers: [],
    closed: 0,
    statusId: "",
    userId: ""
  };
}

export function newChecklist(active = false): ChecklistDto {
  return {
    // checklistId: 'new',
    code: '',
    name: '',
    description: '',
    questions: [],
    active,
    criterias: [],
    start: Date.today(),
    end: new Date(2099, 11, 31)
  };
}

@Injectable({
  providedIn: 'root'
})
export class ChecklistService extends DataService {

  constructor(config: ServiceConfig) { super(config); }

  // Job Checklist
  // jobs/{jobId}/checklist{GET, PUT}
  /**
   * Get checklist config - these will be linked to Jobs - note: the Job already includes the Answers dto
   * @param jobId string
   */
  getJobChecklist(jobId: string): Observable<ChecklistDto> {
    return this.http.get<ChecklistDto>(`jobs/${jobId}/checklist`);
  }

  updateResponse(jobId: string, checklistAnswers: ChecklistEntryDto): Observable<any> {
    return this.http.put<any>(`jobs/${jobId}/checklist`, checklistAnswers);
  }

  // ADMIN List
  // values/list/checklist
  queryChecklists(query: SearchDto): Observable<ListResultDto<any>> {
    const params: any = {...query.filters};
    params.pageNumber = query.pageNumber;
    params.pageSize = query.pageSize;
    params.orderBy = query.orderBy[0] || "";

    return this.http.get<ListResultDto<any>>('values/search/checklist', {params} );
  }

  // ADMIN Detail
  // jobs/checklist POST
  // jobs/checlist/{checklistid} PUT
  // jobs/checlist/{checklistid} GET
  createNewChecklist(checklist: ChecklistDto): Observable<ChecklistDto> {
    return this.http.post<ChecklistDto>('jobs/checklist', checklist);
  }

  updateChecklist(checklist: ChecklistDto): Observable<ChecklistDto> {
    return this.http.put<ChecklistDto>(`jobs/checklist/${checklist.code}`, checklist);
  }

  getChecklist(checklistId: string): Observable<ChecklistDto> {
    return this.http.get<ChecklistDto>(`values/item/checklist/${checklistId}`);
  }
}
