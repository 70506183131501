import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MachineCategoryDto, ModelMasterDto } from "@shared/models";
import { Observable } from "rxjs";
import { ModelService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiModel]"
})
export class ModelTypeaheadDirective extends BaseObservableTypeaheadHelper<ModelMasterDto> implements OnChanges {
  @Input() abiModel: string[];
  @Input() extraFilters: string; // applies an extra filter to the Model query path

  constructor(typeAhead: TypeaheadDirective, private modelService: ModelService) {
    super(typeAhead, ["abiModel"]);
    this.loading = false;
    this.inputDisplay = "code";
  }

  ngOnChanges(changes: SimpleChanges): void {
    const c = changes.abiModel;

    if (c && c.currentValue || (c.previousValue && c.previousValue !== c.currentValue)) {
      super.ngOnChanges(changes);
    }/*
    if (c && !c.firstChange) {
      const a = c.currentValue as string[];
      const b = c.previousValue as string[];
      if (!a || !b || a.join("||") !== b.join("||")) {
        super.ngOnChanges(changes);
      }
    } else {
      super.ngOnChanges(changes);
    }*/
  }

  protected getId(item: ModelMasterDto): string {
    return item.code;
  }
  protected getName(item: ModelMasterDto): string {
    return item.description;
  }

  private category(category: MachineCategoryDto | string): string {
    if (category) {
      if (typeof category === "object") {
        return (category.extras && category.extras.length) ? category.extras.join(",") : category.code;
      } else {
        return category;
      }
    }
    return "";
  }

  protected filteredList(term: string): Observable<ModelMasterDto[]> {
    return this.modelService.queryModels(!!this.abiModel ? this.abiModel.map(this.category) : null, term, this.extraFilters);
  }
}

