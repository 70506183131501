import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoggingService } from "@core/services";
import { map } from "rxjs/operators";

export * from "./app.service";
export * from "./list-data.service";
export * from "./data.service";
export * from "./list-data.service";
export * from "./ui.service";
export * from "./user.service";
export * from "./product-setting.service";
export * from "./machine.service";
export * from "./storage.service";
export * from "./stock.service";
export * from "./authentication.service";
export * from "./sales-order.service";
export * from "./tip-of-the-day.service";
export * from "./job.service";
export * from "./weblayout.service";
export * from "./report.service";
export * from "./survey.service";
export * from "./file.service";
export * from "./followups.service";
export * from "./snackbar.service";
export * from "./translate.service";
export * from "./checklist.service";
export * from "./geolocation.service";
export * from "./googlemapapi.service";
export * from "./keyboard-shortcut.service";
export * from "./dealer.service";
export * from "./audio.service";

// ListData extensions
export * from "./customer.service";
export * from "./lookup-list.service";
export * from "./model.service";
export * from "./resource.service";
export * from "./warehouse.service";
export * from "./customer.service";
export * from "./call-cycle.service";

@Injectable()
export class KeyGetter {
  public static mapskey: string;
  public static companies: string[];

  constructor(
    private http: HttpClient,
    private logger: LoggingService
  ) {}

  public getKey(): Promise<void> {
    return this.http
    .get<any>("values/key")
    .pipe(map((s) => s))
    .toPromise()
    .then((k) => {
      KeyGetter.mapskey = k.key;
      KeyGetter.companies = k.companies;
    })
    .catch(e => {
      KeyGetter.mapskey = '';
      KeyGetter.companies = [];
      this.logger.logException(new Error("Keys request failed"));
    });
  }
}
