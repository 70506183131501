
import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import moment from 'moment';
import { Subject } from "rxjs";

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale('en', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});

export interface HourSegmentEvent {
  date: Date;
  sourceEvent: MouseEvent;
};

export interface DayHeaderEvent {
  isoDayNumber: number;
  sourceEvent: MouseEvent | KeyboardEvent;
};

@Component({
  selector: 'abi-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnChanges {
  @Input() events: CalendarEvent[] = [];
  @Input() activeDayIsOpen: boolean = false;
  @Input() viewDate: Date = new Date();
  @Input() refresh: Subject<void>;
  @Input() view: CalendarView = CalendarView.Month;
  @Input() loading: boolean = false;

  @Output() clickEvent: EventEmitter<CalendarEvent> = new EventEmitter();
  @Output() clickColumnHeader: EventEmitter<DayHeaderEvent> = new EventEmitter();
  @Output() clickDay: EventEmitter<CalendarEvent> = new EventEmitter();
  @Output() clickHourSegment: EventEmitter<HourSegmentEvent> = new EventEmitter();
  @Output() viewChanged: EventEmitter<CalendarView> = new EventEmitter();
  @Output() viewDateChanged: EventEmitter<Date> = new EventEmitter();

  // viewDate: Date = new Date();
  // events: CalendarEvent[] = [];

  currentView: CalendarView = CalendarView.Month;

  constructor() {
    this.currentView = this.view;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes, 'changes');
    if(changes.view) {
      // mirror outside view with internal view
      this.currentView = this.view;
    }
  }

  onViewChanged(event: CalendarView) {
    this.viewChanged.emit(event);
  }

  eventClicked(event: CalendarEvent) {
    this.clickEvent.emit(event);
  }

  columnHeaderClicked(event: DayHeaderEvent) {
    // clickedColumn = $event.isoDayNumber
    this.clickColumnHeader.emit(event);
  }

  dayClicked(event: CalendarEvent) {
    // clickedDate = $event.day.date
    this.clickDay.emit(event);
  }

  hourSegmentClicked(event: HourSegmentEvent) {
    // clickedDate = $event.date
    this.clickHourSegment.emit(event);
  }

  onViewDateChanged(date: Date) {
    this.viewDateChanged.emit(date);
  }
}
