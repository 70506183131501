import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 * Provides a translated string as the Placeholder text for Text input and Texarea elements
 */
@Directive({
  selector: "input[abiProductHint], textarea[abiProductHint]"
})
export class ProductHintDirective  implements OnChanges {
  @Input("abiProductHint") public setting: string;

  constructor(private translate: TranslateService, private renderer: Renderer2, private el: ElementRef) {}

   ngOnChanges(changes: SimpleChanges) {
    if(this.setting)
      this.translate.get(this.setting).subscribe(value => this.renderer.setAttribute(this.el.nativeElement, "placeholder", value));
  }
}
