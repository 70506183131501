import { LookupObjectDto, PostalCodeDto } from "@modules/models";

// export interface ZonePostalCode extends PostalCodeDto {
export interface ZonePostalCode {
  isDefault: number;
  country: string;
  postalCode: string;
  startDate: Date;
  endDate: Date;
  description?: string;
}

export const newZonePostalCode = (): ZonePostalCode => {
  return {
    country: "",
    isDefault: 0,
    postalCode: "",
    startDate: new Date(),
    endDate: Date.maxDate(),
    description: "",
  };
}

export interface ZoneDto extends LookupObjectDto {
  crossDockId?: string;
  destinationType?: string;
  leadTime?: number;
  // Assuming we'll have postal codes here
  postalCodes?: ZonePostalCode[];
//   leadTimes?: ZoneLeadTime[];
//   addresses?: ZoneCustomerAddress[];
// }
}


export const newZone = (): ZoneDto => {
  return {
    code: "",
    description: "",
    active: true,
    postalCodes: [],
    crossDockId: "",
    destinationType: "",
    leadTime: 0,
  };
};
