import { ResourceMasterDto } from "./ResourceMasterDto";

export interface LookupObjectDto {
  code: string;
  description: string;
  order?: number;
  active: boolean;
}

export function newLookupObject(code: string = "", description: string = "", order: number = 1, active: boolean = true){
  return {
    code,
    description,
    order,
    active
  };
}

export function displayValue(obj: LookupObjectDto) {
  return `${obj.code} ~ ${obj.description}`;
}

export function codeOnly(item: string | LookupObjectDto | ResourceMasterDto): string {
  if (item) {
    if (typeof item === "object") {
      return item.code;
    }
    return item;
  }
  return "";
}
