import { Pipe, PipeTransform } from "@angular/core";
import { BaseAttachmentDto } from "./BaseAttachmentDto";
import { LookupObjectDto } from "./LookupObjectDto";

export interface NumberOrString {
  'number'?: number,
  'string'?: string,
}
export interface ExtraFields {
  [name: string]: NumberOrString
};

export interface ModelMasterDto extends LookupObjectDto {
  base: string;
  categories: string[];
  options: string[];
  skills: string[];
  extra?: ExtraFields;
  attachments?: BaseAttachmentDto[];
}

export interface FullModelMasterDto extends ModelMasterDto {
  extra: {
    [ key: string]: {
      'number'?: number
      'string'?: string
    }
  }
}

export function getModelCategory(self: ModelMasterDto, level: number): string {
  return self?.categories[level - 1] || "";
}

export function newModelMaster(): ModelMasterDto {
  return {
    code: "",
    description: "",
    base: "",
    categories: Array(5).fill(""),
    options: Array(5).fill(""),
    skills: Array(5).fill(""),
    active: true,
    attachments: [],
  };
}

@Pipe({
  name: "modelCategory"
})
export class ModelCategoryPipe implements PipeTransform  {
  transform(model: ModelMasterDto, level?: number): string {
    return getModelCategory(model, level || 1);
  }
}
