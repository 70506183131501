import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { codeOnly, ResourceMasterDto } from "@shared/models";
import { combineLatest } from "rxjs";
import { delay, filter } from "rxjs/operators";
import { AppQuery } from "../../app.store";
import { ResourceService } from "../../services";

const SELECT_RESOURCE_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectResourceComponent),
  multi: true
};

@Component({
  selector: "abi-select-resource",
  templateUrl: "./select-resource.component.html",
  styleUrls: ["./select-resource.component.scss"],
  providers: [SELECT_RESOURCE_VALUE_ACCESSOR]
})
export class SelectResourceComponent implements ControlValueAccessor, AfterViewInit {
  private _isAll: boolean;
  private _lastResource: ResourceMasterDto;
  private _currentResource: ResourceMasterDto;
  private _next: any;

  disabled = false;

  @Input() autoselectFirst: boolean = true;
  @Input() allowClear: boolean = false;
  @Input() clearLabel = "All";
  @Input() codeOnly = false;
  @Input() placeholder = "";
  @Output() selected: EventEmitter<ResourceMasterDto | string> = new EventEmitter();

  private _onTouched = () => { };
  private _onChange = (_: any) => { };

  get currentResource(): ResourceMasterDto {
    return this._currentResource;
  }

  set currentResource(value: ResourceMasterDto) {
    this.writeValue(value);
  }

  allResources(): boolean {
    return (this._isAll && this.allowClear) || this.disabled;
  }

  constructor(private resourceService: ResourceService, private _appQuery: AppQuery) {
  }

  ngAfterViewInit(): void {
    if(this.autoselectFirst) {
      // console.log('autoselecting...')
      combineLatest([this._appQuery.$tenant, this.resourceService.$loading, this.resourceService.$resources])
      .pipe(
        filter(([tenant, loading, resources]) => tenant && !loading && !!resources.length),
        delay(300), // delay autoselect as the model value may still be in 'process' - autoselect will happen after
      )
      .subscribe(([t, l, rs]) => {
        // autoselect first active resource
        if(!this.currentResource)
          this.writeValue(this._next || rs.filter(r => r.active).shift());
      });
    }
  }

  selectResource(resource: ResourceMasterDto) {
    this._onChange(this.codeOnly ? codeOnly(resource) : resource);
    this.selected.emit(this.codeOnly ? codeOnly(resource) : resource);
  }

  private doWriteValue(resource: any): void {
    let id = resource && typeof resource === "object" && "code" in resource ? resource.code : resource;
    if (!id || id === "_") {
      const ex = this.resourceService.currentResource;
      const res0 = this.resourceService.resources.values.length ? this.resourceService.resources.values[0].resourceId : "";
      id = ex ? ex.code : res0; // this._defaultRes ||
    }
    if (this.allowClear && id === "*" && !this._isAll) {
      this.clearResource();
    } else if ((!this._currentResource || this._currentResource.code !== id)) {// } && this.resourceService.resources) {
      // console.log('selecting this resource', id)
      this._currentResource = this.resourceService.selectResource(id);
      // console.log('selecting this resource', this._currentResource);
      this.selectResource(this._currentResource);
    }
  }

  writeValue(resource: any): void {
    // console.log('writeValue', resource)
    if (this.resourceService.resources?.length) {
      this.doWriteValue(resource);
    } else {
      this._next = resource;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  blur() {
    this._onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clearResource() {
    if (this._isAll) {
      this._currentResource = this.resourceService.selectResource(this._lastResource?.code);
      this.selectResource(this._currentResource);
      this._isAll = false;
      this._lastResource = null;
    } else {
      this._isAll = true;
      this._lastResource = this._currentResource || null;
      this.selectResource(null);
      this._currentResource = null;
    }
  }
}
