import { Directive, Input, OnChanges } from "@angular/core";
import { CustomerAddressDto, DealerBranchAddressDto, LookupAddressDto, LookupObjectDto } from "@shared/models";
import { Observable } from "rxjs";
import { CustomerService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * DealerBranches
 */
@Directive({
  selector: "input[abiTypeahead][abiCustomerAddress]"
})
export class CustomerAddressTypeaheadDirective extends BaseObservableTypeaheadHelper<LookupObjectDto> implements OnChanges {
  @Input() abiCustomerAddress: string;//customer code
  constructor(typeAhead: TypeaheadDirective, private customerService: CustomerService) {
    super(typeAhead, []);
    this.loading = false;
  }

  protected getFull(item: LookupObjectDto): string {
    return `${item.code} ~ ${item.description}`;
  }

  protected getId(item: LookupObjectDto): string {
    return item.code;
  }

  protected getName(item: LookupObjectDto): string {
    return item.description;
  }

  protected filteredList(term: string): Observable<LookupObjectDto[]> {
    return this.customerService.queryCustomerAddresses(term);
  }

}

