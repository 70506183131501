import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { alignNgbTimeToStep, convertTimeToDate, convertToDate, convertToNgbDate, convertToNgbTime } from "@modules/common/utilities/date.utilities";
import { NgbCalendar, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

type HandleDateChange = (days: number) => void;
interface TimeOption {
  time: NgbTimeStruct;
  label: string;
};

/**
 * A component that allows the user to select a time.
 * @todo: add support for 12 hour time, allow typing in the time, and add support for seconds.
 */
@Component({
  selector: "abi-time-input",
  templateUrl: "ability-time-input.component.html",
  styleUrls: ['./ability-time-input.component.scss'] // Updated path to CSS file
  // providers: [{
  //   provide: NG_VALUE_ACCESSOR,
  //   useExisting: forwardRef(() => AbilityDateSelectorComponent),
  //   multi: true
  // }]
})
export class AbilityTimeInputComponent implements OnInit, ControlValueAccessor {
  baseDate: Date = new Date();
  isDisabled = false;
  private _value: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  @Input() convertOnChange = true;
  @Input() minuteStep: number = 1;
  @Input() extraClass = "";
  @Input() disableInput = true;
  @Input() get value(): any {
    // console.log('get value', this._value, convertTimeToDate(this._value, this.baseDate)?.format("HH:mm"));
    return this._value ? convertTimeToDate(this._value, this.baseDate)?.format("HH:mm") : this.baseDate.format("HH:mm");
  }

  // TODO: issue here is that the date does not always validate, so check first if it's a good date, before writing it?
  set value(val: any) {
    // console.log('set value', val);
    this._value = alignNgbTimeToStep(convertToNgbTime(moment(val, "HH:mm")), this.minuteStep);
    // console.log('set value', val, this._value);
    // this._value = val;
    this.propagateChange(this._value);
    // this.propagateTouch();
  }

  get hour(): number {
    return this._value?.hour || 0;
  }

  set hour(val: number) {
    this._value.hour = val;
    this.propagateChange(this._value);
  }

  get minute(): number {
    return this._value?.minute || 0;
  }

  set minute(val: number) {
    this._value.minute = val;
    this.propagateChange(this._value);
  }

  propagateChange = (_: any) => { };
  propagateTouch = () => { };

  times: TimeOption[];

  constructor(private element: ElementRef, calendarService: NgbCalendar, private control: NgControl) {
    this.control.valueAccessor = this;

  }
  ngOnInit(): void {
    // this.isDayDisabled = (date: NgbDate): boolean => {
    //   if (this.calendar){
    //     const currentWeekDay = calendarService.getWeekday(date);
    //     return !this.calendar.workDays.some(workDay => workDay.dayOfWeek === currentWeekDay);
    //   }
    //   return false;
    // };
    const minuteParts = 60 / this.minuteStep;
    this.times = new Array<TimeOption>((24 * 60) / this.minuteStep)
    .fill(null)
    .map(
      (t, i) =>
        // generate Timestructs with 30 min intervals
        (t = {
          time: {
            hour: Math.floor(i / minuteParts),
            minute: (i * this.minuteStep) % 60,
            second: 0,
          },
          label: `${Math.floor(i / minuteParts).toString().padStart(2, '0')}:${((i * this.minuteStep) % 60).toString().padStart(2, '0')}`,
        })
    );
    // console.log('times', this.times, this.minuteStep);
    // console.log((24 * 60) / this.minuteStep)
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  // Code smell: a somewaht specific and repetitive peice of ui/business rule
  public get showError(): boolean {
    if (!this.control) {
     return false;
    }
    const { dirty, touched } = this.control;
    // console.log('showError', this.control, dirty, touched, this.invalid);
    return this.invalid ? (dirty || touched) : false;
  }

  getValidity() {
    return this.showError ? 'is-invalid' : '';
  }

  onBlur() {
    this.propagateTouch();
  }

  writeValue(obj: any): void {
    this._value = obj;
    // this._value = convertToDate(obj);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // prevDate() {
  //   const val = convertToDate(this.value || Date.today());
  //   this.value = val.add(-1, "day");
  //   return true;
  // }

  // canGoPrev() {
  //   return this.minDate ? convertToDate(this.value || Date.today()) > convertToDate(this.minDate) : true;
  // }

  // canGoNext() {
  //   return this.maxDate ? convertToDate(this.value || Date.today()) < convertToDate(this.maxDate) : true;
  // }

  // nextDate() {
  //   const val = convertToDate(this.value || Date.today());
  //   this.value = val.add(+1, "day");
  //   return true;
  // }

  valueStr() {
    const val = this.value;
    return val ? (val as Date).format() : "";
  }

  compareFn(c1: NgbTimeStruct, c2: NgbTimeStruct): boolean {
    return c1 && c2 ? c1.hour === c2.hour && c1.minute === c2.minute : c1 === c2;
  }
}
