import { Directive, OnChanges } from "@angular/core";
import { DealerBranchAddressDto, LookupAddressDto } from "@shared/models";
import { Observable } from "rxjs";
import { CustomerService } from "../services";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

/**
 * DealerBranches
 */
@Directive({
  selector: "input[abiTypeahead][abiDealerAddress]"
})
export class DealerBranchAddressTypeaheadDirective extends BaseObservableTypeaheadHelper<DealerBranchAddressDto> implements OnChanges {

  constructor(typeAhead: TypeaheadDirective, private customerService: CustomerService) {
    super(typeAhead, []);
    this.loading = false;
  }

  protected getFull(item: DealerBranchAddressDto): string {
    return `${item.addressId} ~ ${item.name}`;
  }

  protected getId(item: DealerBranchAddressDto): string {
    return `${item.dealerId}|${item.addressId}`;
  }
  protected getName(item: LookupAddressDto): string {
    return item.name;
  }

  protected filteredList(term: string): Observable<DealerBranchAddressDto[]> {
    return this.customerService.queryAddresses(term);
  }

}

