export interface ListResultDto<T> {
  items: T[];
  totalResults: number;
  errors?: string[];
  message?: string;
}

export function newListResult<T>(dataSet?: T[]): ListResultDto<T> {
  return {
    items: dataSet || [],
    totalResults: dataSet?.length || 0,
    message: ""
  };
}

