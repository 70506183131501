import { NgModule } from "@angular/core";
import { SharedModule } from "../common/common.module";
import { SidebarComponent } from "./sidebar/sidebar.component";

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    SharedModule
  ],
  exports: [SidebarComponent]
})
export class SidebarModule { }
