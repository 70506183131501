import { BaseAttachmentDto } from "./BaseAttachmentDto";
import { CustomerAddressDto } from "./CustomerAddressDto";
import { CustomerCallCycleDto } from "./index";

// public string VisitId { get; set; }
// public string AddressId { get; set; }
// public string CycleId { get; set; }
// public string ResourceId { get; set; }
// public string BookedDate { get; set; }

// public DateTime PlannedStartTime { get; set; }
// public DateTime PlannedEndTime { get; set; }

// public DateTime ActualStartTime { get; set; }
// public string ActualStartLocation { get; set; }

// public DateTime ActualEndTime { get; set; }
// public string ActualEndLocation { get; set; }

// public string StatusId { get; set; }
// /*
// public string VisitCheck { get; set; }
// public string VisitCheckReason { get; set; }
// public int CheckReasonApproved { get; set; }*/
// public string CheckUserId { get; set; }
// public string Notes { get; set; }
// public int PositionActual { get; set; }
// public int PositionMap { get; set; }

// public CustomerCallCycleDto CallCycle { get; set; }

/**
 * The Structure that we use for the Calendar (CallCycleAdmin)
 * Creating Visits in the Calendar (with optional Cycles for recurrences)
 */
export interface CustomerVisitSetupDto {
  visitId: string;
  addressId: string;
  customerId: string; // to be added
  cycleId?: string;
  resourceId: string;
  bookedDate?: Date; // ? duplicate data? - for backend...
  plannedStartTime: Date;
  plannedEndTime: Date;
  notes: string; // appointment notes
  callCycle?: CustomerCallCycleDto;
  address?: CustomerAddressDto
}

/**
 * The Backend DTO that we are to expect
 */
export interface CustomerVisitDto extends CustomerVisitSetupDto {
  actualStartTime?: Date;
  actualStartLocation?: string;
  actualStartDistance?: number;
  actualEndTime?: Date;
  actualEndLocation?: string;
  actualEndDistance?: number;

  statusId?: string;
  rating?: string;

  // whats this for?
  // visitCheck: string;
  // visitCheckReason: string;
  // chekReasonApproved: number;
  notes1?: string;
  notes2?: string;
  notes3?: string;

  checkUserId?: string;

  positionActual?: number;
  positionMap?: number;

  // For Routesheet
  mapsOrder?: number;
  visitOrder?: number;

  attachments?: BaseAttachmentDto[],
  closed?: number
}


export function newVisitSetup(): CustomerVisitSetupDto {
  return {
    visitId: null,
    resourceId: "",
    customerId: "",
    addressId: "",
    plannedStartTime: new Date(),
    plannedEndTime: new Date(),
    notes: ""
  };
}
